import React, { Component } from "react";
import loadingGif from './assets/loading.gif';

class Sillas extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			loading: false,
			productos: [],
		}
		
	}

	componentDidMount(){
		this.getData();
	}
	
	async getData() {
		
		this.setState({loading:true,resultado:null });
		
		let bodyData = {
				user: sessionStorage.getItem('user'),
				CODART: this.state.numserie
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/stock/get_stock_epis",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								console.log(result);
								this.setState({loading: false,productos:result });
								
							})
							
		.catch((error) => {
			console.error(error);
		});
	}

	async cambiarMinimo(index,value){
		if(value==''){
			this.state.productos[index].MINIMO = value;
			this.setState({state:this.state});
			return;
		}
		let bodyData = {
			user: sessionStorage.getItem('user'),
			producto: {
				CODART: this.state.productos[index].CODART,
				STOCKMIN: value,
				PUNTOPEDIDO: 0,
			}
		};
	
	let data ={
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': 'Bearer '+sessionStorage.getItem('token'),
		},
		body: JSON.stringify(bodyData)
	}
	
	fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/actualizar_linea_stock_minimo",data)
						.then(result => result.json())
						.then(result => {
							if(result[0]!=null)
							if(result[0].token==0){
								alert('Renovando sesión...');
								window.location.reload(false);
							}
							if(result.length==0){
								alert("Error, no se puede conectar con el servidor")
							}
							if(result[0].DONE==0){
								alert("Error al actualizar el stock mínimo")
							}
							else this.state.productos[index].MINIMO = value;
							this.setState({state:this.state});
						})
						
	.catch((error) => {
		console.error(error);
	});
	}
	
	render() {
		return (
		<div>
			<h2 style={{marginBottom:25}}>GESTIÓN EPIs</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.productos.length>0&&
				<div>
					<table className="tablaTareas">
						<thead>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td>
									CÓD. ART.
								</td>
								<td>
									DESCRIPCIÓN
								</td>
								<td style={{textAlign:"center"}}>
									STOCK
								</td>
								<td style={{textAlign:"center"}}>
									MÍNIMO
								</td>
							</tr>
						</thead>
						{this.state.productos.map(({ CODART,DESCART,CANTIDAD,MINIMO },index) => (
						<tbody className="tablaTareas" style={{backgroundColor:"#FFF"}} key={index}>
							<tr>
								<td>
									{CODART}
								</td>
								<td>
									{DESCART}
								</td>
								<td style={{textAlign:"center"}}>
									{CANTIDAD}
								</td>
								<td>
									<input type="number" style={{width:50}} value={MINIMO} onChange={(e) => {
										this.cambiarMinimo(index,e.target.value);
									}}/>
								</td>
							</tr>
						</tbody>
					))}
					</table>
				</div>
			}
		</div>
		);
	}

}
 
export default Sillas;