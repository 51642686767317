import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';

class Salidas extends Component {
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	state = {
		username:'',
		password:'',
		tareas: [],
		recibidas: true,
		creandoTarea: false,
		descripcion: '',
		asunto: '',
		destinatario: null,
		usuarios: [],
	};
	
	componentDidMount(){
		this.handleSubmit();
	}
	
	doNothing(day){
		//console.log(day);
	}
	
	cerrarTarea(index){
		window.confirm('¿Estás seguro? Vas a marcar la tarea como terminada y se notificará a la persona que creó la tarea.') ? this.doCerrarTarea(index) : this.doNothing()
		
	}
	
	async doCerrarTarea(index){
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			tarea: this.state.tareas[index].ID
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/tareas/completar_tarea",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.handleSubmit();
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async handleSubmit(event){
		
		if(this.state.startDate==null){
			var today = ((new Date().getMonth() + 1)+"/"+new Date().getDate()+"/"+new Date().getFullYear());
			var tomorrow = today;
		}
		else{
				var today=""+(this.state.startDate._d.getMonth()+1)+"/"+this.state.startDate._d.getDate()+"/"+this.state.startDate._d.getFullYear();
			if(this.state.endDate!=null)
				var tomorrow = ""+(this.state.endDate._d.getMonth()+1)+"/"+this.state.endDate._d.getDate()+"/"+this.state.endDate._d.getFullYear();
			else var tomorrow= today;
		}
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			date: tomorrow,
			yesterday: today,
			emisor: this.state.recibidas ? null : sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/tareas/get_tareas",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.tareas = result;
								this.setState({loading: false });
							})
							
		.catch((error) => {
			console.error(error);
		});
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/tareas/get_usuarios",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.usuarios = result[0];
							})
							
		.catch((error) => {
			console.error(error);
		});

	}
	
	
	async cambioSelect(recibidas){
		if(this.state.startDate==null){
			var today = ((new Date().getMonth() + 1)+"/"+new Date().getDate()+"/"+new Date().getFullYear());
			var tomorrow = today;
		}
		else{
				var today=""+(this.state.startDate._d.getMonth()+1)+"/"+this.state.startDate._d.getDate()+"/"+this.state.startDate._d.getFullYear();
			if(this.state.endDate!=null)
				var tomorrow = ""+(this.state.endDate._d.getMonth()+1)+"/"+this.state.endDate._d.getDate()+"/"+this.state.endDate._d.getFullYear();
			else var tomorrow= today;
		}
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			date: tomorrow,
			yesterday: today,
			emisor: recibidas ? null : sessionStorage.getItem('user'),
		};
		this.state.recibidas = recibidas;
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/tareas/get_tareas",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.tareas = result;
								this.setState({loading: false });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async crearTarea(){
		if(this.state.destinatario==null){
			alert('Es necesario indicar un destinatario');
			return;
		}
		if(this.state.asunto==''){
			alert('Es necesario especificar un asunto');
			return;
		}
		
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			emisor: sessionStorage.getItem('user'),
			receptor: this.state.destinatario,
			titulo: this.state.asunto,
			texto: this.state.descripcion,
		};
		this.state.creandoTarea=false;
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/tareas/crear_tarea",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.handleSubmit();
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	render() {
		return (
		<div>
			<div style={{float: "right",marginRight:50}}>
				<DateRangePicker
					startDate={this.state.startDate} // momentPropTypes.momentObj or null,
					startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
					endDate={this.state.endDate} // momentPropTypes.momentObj or null,
					endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
					onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
					focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
					startDatePlaceholderText= "Desde"
					endDatePlaceholderText= "Hasta"
					isOutsideRange={(day) => this.doNothing(day)}
					displayFormat="DD/MM/yyyy"
					firstDayOfWeek={1}
				/>
				<button type="button" onClick={this.handleSubmit} style={{marginLeft:50}}>BUSCAR</button>
			</div>
			<div style={{float: "right",margin:15}}>
				<form action="/action_page.php">
					<select name="tareas" id="tareas" onChange={(e) => this.cambioSelect(e.target.value=='recibidas')}>
						<option value="recibidas">Recibidas</option>
						<option value="enviadas">Enviadas</option>
					</select>
				</form>
			</div>
			{!this.state.creandoTarea&&
			<div style={{float: "right",margin:13}}>
				<button type="button" onClick={() => this.setState({creandoTarea:!this.state.creandoTarea,destinatario:null,asunto:'',descripcion:''})} style={{marginLeft:50}}>{!this.state.creandoTarea ? 'CREAR NUEVA TAREA' : 'CANCELAR'}</button>
			</div>
			}
			<h2 style={{marginBottom:25}}>TAREAS</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{this.state.creandoTarea&&
				<div style={{marginBottom:30}}>
					
						<input
							type="text" 
							value={this.state.asunto}
							onChange={(e) => this.setState({asunto: e.target.value})}
							placeholder="Asunto de la tarea"
							style={{margin:10,width:'20%'}}
							maxlength="32"
						/>
						<select name="tareas" id="tareas" onChange={(e) => this.setState({destinatario: e.target.value})}>
							<option value="destinatario" disabled selected="selected">Selecciona el destinatario</option>
							{this.state.usuarios.map(({ value,label },index) => (
								<option value={value}>{label}</option>	
							))}
						</select>
						<button type="button" onClick={() => this.crearTarea()} style={{marginLeft:50}}>CREAR TAREA</button>
						<button type="button" onClick={() => this.setState({creandoTarea:!this.state.creandoTarea,destinatario:null,asunto:'',descripcion:''})} style={{marginLeft:10}}>{!this.state.creandoTarea ? 'CREAR NUEVA TAREA' : 'CANCELAR'}</button>
						<textarea 
							type="text" 
							value={this.state.descripcion}
							onChange={(e) => this.setState({descripcion: e.target.value})}
							placeholder="Descripción de la tarea"
							
						/>
				</div>
			}
			{!this.state.loading&&this.state.tareas.length>0&&
				<a>
					<table className="tablaTareas">
						<thead>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td>
									FECHA
								</td>
								{this.state.recibidas&&
								<td>
									ENVIADA POR
								</td>
								}
								{!this.state.recibidas&&
								<td>
									ENVIADA A
								</td>
								}
								<td>
									ASUNTO
								</td>
								<td>
									DESCRIPCIÓN
								</td>
								{this.state.recibidas&&
								<td>
									COMPLETAR
								</td>
								}
							</tr>
						</thead>
					{this.state.tareas.map(({ EMISOR_NAME,RECEPTOR_NAME,FECHA_CREACION,ID,TEXTO,TITULO,FECHA_CIERRE,IMAGENES },index) => (
						<tbody className="tablaTareas" style={FECHA_CIERRE!=null ? {backgroundColor:"#cdeeee"} : {backgroundColor:"#FFF"}} key={ID}>
							<tr key={ID}>
								<td>
									{FECHA_CREACION}
									{FECHA_CIERRE!=null&&<a><br/>{FECHA_CIERRE}</a>}
								</td>
								<td>
									{EMISOR_NAME}{RECEPTOR_NAME}
								</td>
								<td>
									{TITULO}
								</td>
								<td>
									{TEXTO}
								</td>
								{this.state.recibidas&&
									<td style={{textAlign:'center'}}>
										{FECHA_CIERRE==null&&
											<button type="button" onClick={()=>this.cerrarTarea(index)} style={{textAlign:'center'}}>X</button>
										}
									</td>
								}
							</tr>
							{IMAGENES!=null&&IMAGENES.length>0&&
							<tr style={{width:'100%',textAlign:'center'}}>
								<td colspan="5">
									{IMAGENES.map(({IMG},index) => (
										<img key={index} src={IMG} style={{maxHeight:'450px',padding:5}} />
									))}
								</td>
							</tr>
							}
						</tbody>
					))}
					</table>
					
				</a>
			}
      </div>
    );
  }
}
 
export default Salidas;