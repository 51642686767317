import React, { Component,useState } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';
import './styles/general.css';
import { compose, withProps } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";
import Geocode from "react-geocode";




const smallRedMarker = require('./assets/smallRedMarker.png');
const smallBlueMarker = require('./assets/smallBlueMarker.png');
const smallGreenMarker = require('./assets/smallGreenMarker.png');
const smallYellowMarker = require('./assets/smallYellowMarker.png');
const mediumYellowMarker = require('./assets/mediumYellowMarker.png');
const bigYellowMarker = require('./assets/bigYellowMarker.png');

const mediumRedMarker = require('./assets/mediumRedMarker.png');
const bigRedMarker = require('./assets/bigRedMarker.png');
//Igual pero en azul

const mediumBlueMarker = require('./assets/mediumBlueMarker.png');
const bigBlueMarker = require('./assets/bigBlueMarker.png');
//Igual pero en verde

const mediumGreenMarker = require('./assets/mediumGreenMarker.png');
const bigGreenMarker = require('./assets/bigGreenMarker.png');

const greyMarker = require('./assets/greyMarker.png');

const smallOrangeMarker = require('./assets/smallOrangeMarker.png');
const mediumOrangeMarker = require('./assets/mediumOrangeMarker.png');
const bigOrangeMarker = require('./assets/bigOrangeMarker.png');


var activeMarker = 1;
var ignorarFacturacion = false;



const API_KEY = 'AIzaSyBi-2SDDXX2FogHf15oJW6VA-v979sK46E';
const MapComponent = compose(
	withProps({
		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=Function.prototype`,
	  	loadingElement: <div style={{ height: `100%` }} />,
	  	containerElement: <div style={{ height: `800px` }} />,
	  	mapElement: <div style={{ height: `100%` }} />,
	}),
	withScriptjs,
	withGoogleMap
  )((props) => {
	const [activeMarker, setActiveMarker] = useState(null);

	const handleActiveMarker = (marker) => {
		if (marker === activeMarker) {
		setActiveMarker(null);
		} else {
		setActiveMarker(marker);
		}
	};

	const onSubmitNothing = (e) => {
		console.log(e);
		e.preventDefault();
	}

	const onChangeTipo = async (e,index) => {
		props.markers[index].CLASI = e.target.value;
		if(props.markers[index].CLASI!=props.markers[index].CLASI_ORI){
			props.markers[index].CARGANDO = true;
			await setActiveMarker(null);
			setActiveMarker(props.markers[index].id);
			
			let bodyData = {
				user: sessionStorage.getItem('user'),
				CODCLI: props.markers[index].id,
				CLASI: props.markers[index].CLASI,
			};
			
			let data ={
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+sessionStorage.getItem('token'),
				},
				body: JSON.stringify(bodyData)
			}
			
			await fetch("http://192.168.0.19:49264"+"/api/modulo/mapa/cambiar_clasificacion_cliente",data)
								.then(result => result.json())
								.then(result => {
									if(result[0]!=null)
									if(result[0].token==0){
										alert('Renovando sesión...');
										window.location.reload(false);
									}
									if(result.length==0||result[0].DONE==0){
										props.markers[index].CLASI = props.markers[index].CLASI_ORI;
										setActiveMarker(null);
										alert('Error al cambiar la clasificación del cliente');
										return;
									}
								})
								
			.catch((error) => {
				alert(error);
				console.error(error);
			});
			props.markers[index].CLASI_ORI = props.markers[index].CLASI;
			props.markers[index].CARGANDO = false;
			await setActiveMarker(null);
			setActiveMarker(props.markers[index].id);
		}
	}

	const getIcon = (CLASI,FACT) => {

		FACT = parseFloat(FACT);

		if(ignorarFacturacion)
			return CLASI=='ORTO' ? mediumRedMarker : CLASI=='FCIA' ? mediumBlueMarker : CLASI=='OTROS' ?  mediumGreenMarker : CLASI=='EXTRUSIO' ? mediumOrangeMarker : mediumYellowMarker;

		if(FACT<=0)
			return greyMarker;
		if(FACT>35000)
			return CLASI=='ORTO' ? bigRedMarker : CLASI=='FCIA' ? bigBlueMarker : CLASI=='OTROS' ?  bigGreenMarker : CLASI=='EXTRUSIO' ? bigOrangeMarker : bigYellowMarker;
		
		if(FACT>10000)
			return CLASI=='ORTO' ? mediumRedMarker : CLASI=='FCIA' ? mediumBlueMarker : CLASI=='OTROS' ?  mediumGreenMarker : CLASI=='EXTRUSIO' ? mediumOrangeMarker : mediumYellowMarker;
		
		return CLASI=='ORTO' ? smallRedMarker : CLASI=='FCIA' ? smallBlueMarker : CLASI=='OTROS' ?  smallGreenMarker : CLASI=='EXTRUSIO' ? smallOrangeMarker : smallYellowMarker;
		
	};

	return (
		<GoogleMap
		defaultZoom={8}
		defaultCenter={{ lat: 39.0193842, lng: -1.8872672 }}
		>
			{props.markers.map(({ id, CODCLI,DIRCLI1,PROVINCIA,DTOCLI,POBCLI,NOMCLI,latitude,longitude,address,CLASI,FACT,CLASI_ORI,CARGANDO },index) => (
				<Marker
					key={id}
					position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
					icon={getIcon(CLASI,FACT)}
					onClick={() => handleActiveMarker(id)}
				>
				{activeMarker === id ? (
					<InfoWindow onCloseClick={() => setActiveMarker(null)}>
						<div>
							<b style={{color:'black'}}>{NOMCLI}</b>
							<br></br>{DIRCLI1}
							<br></br>{DTOCLI} {POBCLI}
							<br></br>{PROVINCIA}
							<br></br>{
								//Format FACT as currency
								new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(FACT)
							}
							<br></br><a style={{color:'#1a73e8'}} target="_blank" href={false ? 
							"https://www.google.com/maps/search/?api=1&query="+latitude+","+longitude+""
						: "https://www.google.com/maps/search/"+address }>Ver en Google maps</a>
							{!CARGANDO&&<div>
								<br></br><select name="tipo" value={CLASI} onChange={(e) => onChangeTipo(e,index)} style={{width:'100%'}}>
									<option value="ORTO">Ortopedia</option>
									<option value="FCIA">Farmacia</option>
									<option value="OTROS">Otros</option>
									<option value="EXTRUSIO">Extrusión</option>
									<option value="OTHER">Sin clasificar</option>
								</select>
							</div>
							}
							{CARGANDO&&<div>
								<br></br>CARGANDO...
								</div>
							}
						</div>
					</InfoWindow>
				) : null}
				</Marker>
			))}

		</GoogleMap>
	);
  }
);


// Configurar la API de Geocoding
Geocode.setApiKey(API_KEY);
Geocode.setLanguage('es');
Geocode.setRegion('es');


class Salidas extends Component {
	constructor(props) {
		super(props);
		this.onSubmitNothing = this.onSubmitNothing.bind(this);
	}
	
	onSubmitNothing(event){
		event.preventDefault();
	}
	
	state = {
		username:'',
		password:'',
		filtro: '',
		matrices: [],
		tipo: 'N',
		selectedPlace: activeMarker,
		markers: [],
		API_KEY: 'AIzaSyBi-2SDDXX2FogHf15oJW6VA-v979sK46E',
		loading: false,
		GOTDATA: false,
		showNoFacturacion: true,
		showSinClasificar: true,
		showOrto: true,
		showFcia: true,
		showOtros: true,
		showExtrusion: true,
		mostrando: null,
		facturacion: 0,
	};
	

	async componentDidMount(){

		//ESPERAR A TENER PRESUPUESTO GRATUITO EN GOOGLE MAPS, SE GASTA PRÁCTICAMENTE TODO EN EL GEOCODE, ASÍ QUE LO QUE HAY QUE HACER ES LLAMARLO UNA SOLA VEZ, ASIGNAR
		//AL CLIENTE Y YA LUEGO SIMPLEMENTE CARGAR EL MAPA (QUE NO GASTA APENAS)

		/*await this.getData();
		//Recorre markers y obtiene las coordenadas de cada dirección
		const markers = await Promise.all(this.state.markers.map(async marker => {
			const coordinates = await this.geocodeAddress(marker.address);
			return { ...marker, ...coordinates };
		}));
		this.setState({ markers });*/
	}

	refrescar(){
		this.setState({selectedPlace: activeMarker});
	}

	drawMap = () => {
		this.state.facturacion = 0;
		this.state.mostrando = 0;
		return (
		  <MapComponent
			isMarkerShown
			markers={this.filtrar(this.state.markers)}
			showUserLocation={false}
		  />
		);
	  }
	
	filtrar(markers){
		//Devuelve un array con los markers que cumplen con los filtros de las variables que comienzan con show
		
		var markersFiltrados = [];
		for(var i=0;i<markers.length;i++){
			if(!ignorarFacturacion && this.state.showNoFacturacion && parseFloat(markers[i].FACT)<=0){
				markersFiltrados.push(markers[i]);
				this.state.mostrando += 1;
				this.state.facturacion += parseFloat(markers[i].FACT);
			}
			if(this.state.showSinClasificar && markers[i].CLASI=='OTHER'&& (parseFloat(markers[i].FACT)>0||ignorarFacturacion)){
				markersFiltrados.push(markers[i]);
				this.state.mostrando += 1;
				this.state.facturacion += parseFloat(markers[i].FACT);
			}
			if(this.state.showOrto && markers[i].CLASI=='ORTO'&& (parseFloat(markers[i].FACT)>0||ignorarFacturacion)){
				markersFiltrados.push(markers[i]);
				this.state.mostrando += 1;
				this.state.facturacion += parseFloat(markers[i].FACT);
			}
			if(this.state.showFcia && markers[i].CLASI=='FCIA'&& (parseFloat(markers[i].FACT)>0||ignorarFacturacion)){
				markersFiltrados.push(markers[i]);
				this.state.mostrando += 1;
				this.state.facturacion += parseFloat(markers[i].FACT);
			}
			if(this.state.showOtros && markers[i].CLASI=='OTROS'&& (parseFloat(markers[i].FACT)>0||ignorarFacturacion)){
				markersFiltrados.push(markers[i]);
				this.state.mostrando += 1;
				this.state.facturacion += parseFloat(markers[i].FACT);
			}
			if(this.state.showExtrusion && markers[i].CLASI=='EXTRUSIO'&& (parseFloat(markers[i].FACT)>0||ignorarFacturacion)){
				markersFiltrados.push(markers[i]);
				this.state.mostrando += 1;
				this.state.facturacion += parseFloat(markers[i].FACT);
			}
		}
		return markersFiltrados;
	}

							
	async geocodeAddress(address) {
		try {
		  const response = await Geocode.fromAddress(address);
		  const { lat, lng } = response.results[0].geometry.location;
		  return { latitude: lat, longitude: lng };
		} catch (error) {
		  console.log(error);
		  console.log(address);
		  return null;
		}
	  }
	
	doNothing(day){
		//console.log("no hago nada")
		//console.log(day);
	}
	
	
	async getData(){
		
		this.setState({loading: true });

		if(this.state.startDate==null){
			var today = ((new Date().getMonth() + 1)+"/"+new Date().getDate()+"/"+new Date().getFullYear());
			var tomorrow = today;
		}
		else{
				var today=""+(this.state.startDate._d.getMonth()+1)+"/"+this.state.startDate._d.getDate()+"/"+this.state.startDate._d.getFullYear();
			if(this.state.endDate!=null)
				var tomorrow = ""+(this.state.endDate._d.getMonth()+1)+"/"+this.state.endDate._d.getDate()+"/"+this.state.endDate._d.getFullYear();
			else var tomorrow= today;
		}
		

		let bodyData = {
			user: sessionStorage.getItem('user'),
			date: tomorrow,
			yesterday: today,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		await fetch(await "http://192.168.0.19:49264"+"/api/modulo/mapa/clientes",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.markers = result[0];
								this.state.facturacionTotal = 0;
								for(var i=0;i<this.state.markers.length;i++){
									this.state.markers[i].CLASI_ORI = this.state.markers[i].CLASI;
									this.state.facturacionTotal += parseFloat(this.state.markers[i].FACT);
								}
							})
							
		.catch((error) => {
			alert(error);
			this.state.loading = false;
			this.setState({loading: false });
			console.error(error);
		});
		if(this.state.loading){
			const markers = await Promise.all(this.state.markers.map(async marker => {
				//Solo llamar a geocode si no tiene latitud y longitud
				if(marker.latitude==null || marker.longitude==null){
					var coordinates = await this.geocodeAddress(marker.address);
					marker.latitude = coordinates.latitude;
					marker.longitude = coordinates.longitude;
					await this.saveData([marker]);
				}
				return { ...marker, ...coordinates };
			}));
			
			//this.saveData(markers);

			this.setState({ markers,loading:false,GOTDATA:true });
		}
	}

	async saveData(markers){
		for(var i=0;i<markers.length;i++){
			let bodyData = {
				user: sessionStorage.getItem('user'),
				CODCLI: markers[i].id,
				LATITUD: markers[i].latitude,
				LONGITUD: markers[i].longitude,
			};
			
			let data ={
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': 'Bearer '+sessionStorage.getItem('token'),
				},
				body: JSON.stringify(bodyData)
			}
			
			await fetch("http://192.168.0.19:49264"+"/api/modulo/mapa/asignar_localizacion",data)
								.then(result => result.json())
								.then(result => {
									if(result[0]!=null)
									if(result[0].token==0){
										alert('Renovando sesión...');
										window.location.reload(false);
									}
									console.log("Hecho "+bodyData.CODCLI+" "+bodyData.LATITUD+" "+bodyData.LONGITUD);
								})
								
			.catch((error) => {
				alert(error);
				console.log("ERROR "+bodyData.CODCLI+" "+bodyData.LATITUD+" "+bodyData.LONGITUD);
				console.error(error);
			});
		}
	}

	leyendaMarcadores(){

		//Devuelve una leyenda con los colores de los marcadores y su significado, debe aparecer un ejemplo de cada marcador (la imagen)
		return(
			<div style={{position: "absolute",bottom: 0,right: 0,margin: 20,zIndex:99}}>
				<div style={{backgroundColor: "#EEE",padding: 10,color: "#000",borderRadius: 10,marginBottom: 10}}>
					<div style={{display: "inline-block",marginRight: 10}}>
						<img src={smallRedMarker} />
					</div>
					<div style={{display: "inline-block"}}>
						Ortopedias
					</div>
					<div style={{display: "inline-block",marginLeft: 10}}>
						<input type="checkbox" checked={this.state.showOrto} onChange={() => this.handleOrtopediasChange()} />
					</div>
				</div>
				<div style={{backgroundColor: "#EEE",padding: 10,color: "#000",borderRadius: 10,marginBottom: 10}}>
					<div style={{display: "inline-block",marginRight: 10}}>
						<img src={smallBlueMarker} />
					</div>
					<div style={{display: "inline-block"}}>
						Farmacias
					</div>
					<div style={{display: "inline-block",marginLeft: 10}}>
						<input type="checkbox" checked={this.state.showFcia} onChange={() => this.handleFarmaciasChange()} />
					</div>
				</div>
				<div style={{backgroundColor: "#EEE",padding: 10,color: "#000",borderRadius: 10,marginBottom: 10}}>
					<div style={{display: "inline-block",marginRight: 10}}>
						<img src={smallGreenMarker} />
					</div>
					<div style={{display: "inline-block"}}>
						Otros
					</div>
					<div style={{display: "inline-block",marginLeft: 10}}>
						<input type="checkbox" checked={this.state.showOtros} onChange={() => this.handleOtrosChange()} />
					</div>
				</div>
				<div style={{backgroundColor: "#EEE",padding: 10,color: "#000",borderRadius: 10,marginBottom: 10}}>
					<div style={{display: "inline-block",marginRight: 10}}>
						<img src={smallOrangeMarker} />
					</div>
					<div style={{display: "inline-block"}}>
						Extrusión
					</div>
					<div style={{display: "inline-block",marginLeft: 10}}>
						<input type="checkbox" checked={this.state.showExtrusion} onChange={() => this.handleExtrusionChange()} />
					</div>
				</div>
				<div style={{backgroundColor: "#EEE",padding: 10,color: "#000",borderRadius: 10,marginBottom: 10}}>
					<div style={{display: "inline-block",marginRight: 10}}>
						<img src={smallYellowMarker} />
					</div>
					<div style={{display: "inline-block"}}>
						Sin clasificar
					</div>
					<div style={{display: "inline-block",marginLeft: 10}}>
						<input type="checkbox" checked={this.state.showSinClasificar} onChange={() => this.handleSinClasificarChange()} />
					</div>
				</div>
				{!ignorarFacturacion&&
					<div style={{backgroundColor: "#EEE",padding: 10,color: "#000",borderRadius: 10,marginBottom: 10}}>
						<div style={{display: "inline-block",marginRight: 10}}>
							<img src={greyMarker} />
						</div>
						<div style={{display: "inline-block"}}>
							Sin facturación
						</div>
						<div style={{display: "inline-block",marginLeft: 10}}>
							<input type="checkbox" checked={this.state.showNoFacturacion} onChange={() => this.handleNoFacturacionChange()} />
						</div>
					</div>
				}
				<div style={{backgroundColor: "#EEE",padding: 10,color: "#000",borderRadius: 10,marginBottom: 10}}>
					<div style={{display: "inline-block"}}>
						Ignorar facturación
					</div>
					<div style={{display: "inline-block",marginLeft: 10}}>
						<input type="checkbox" checked={ignorarFacturacion} onChange={() => this.handleIgnorarFacturacionChange()} />
					</div>
				</div>
			</div>
		);
	}
	handleIgnorarFacturacionChange(){
		ignorarFacturacion = !ignorarFacturacion;
		this.setState({state: this.state});
	}
	handleNoFacturacionChange(){
		this.setState({showNoFacturacion: !this.state.showNoFacturacion});
	}
	handleSinClasificarChange(){
		this.setState({showSinClasificar: !this.state.showSinClasificar});
	}
	handleOrtopediasChange(){
		this.setState({showOrto: !this.state.showOrto});
	}
	handleFarmaciasChange(){
		this.setState({showFcia: !this.state.showFcia});
	}
	handleExtrusionChange(){
		this.setState({showExtrusion: !this.state.showExtrusion});
	}
	handleOtrosChange(){
		this.setState({showOtros: !this.state.showOtros});
	}

	render() {
		return (
		<div>
			<div style={{float: "right",marginRight:50}}>
				<DateRangePicker
					startDate={this.state.startDate} // momentPropTypes.momentObj or null,
					startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
					endDate={this.state.endDate} // momentPropTypes.momentObj or null,
					endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
					onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
					focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
					startDatePlaceholderText= "Desde"
					endDatePlaceholderText= "Hasta"
					isOutsideRange={(day) => this.doNothing(day)}
					displayFormat="DD/MM/yyyy"
					firstDayOfWeek={1}
				/>
				<button type="button" onClick={() => this.getData()} style={{marginLeft:50}}>BUSCAR</button>
			</div>
			<h2 style={{marginBottom:25}}>MAPA</h2>
			{this.leyendaMarcadores()}
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.GOTDATA&&this.drawMap()}
			{this.state.mostrando!=null&&
				<div style={{position: "absolute",top: 245,left: 150,margin: 20,zIndex:99}}>
					<div style={{textAlign:"center",marginTop:25}}>
						{this.state.facturacion>0&&
							<h3>Mostrando {this.state.mostrando} clientes que facturaron {
							Number(this.state.facturacion).toLocaleString('es-ES', {minimumFractionDigits: 2, maximumFractionDigits: 2})} € 
								({Math.round(this.state.facturacion*100/this.state.facturacionTotal)}%)
							</h3>
						}
						{(this.state.facturacion==null||this.state.facturacion==0)&&
							<h3>Mostrando {this.state.mostrando} clientes</h3>
						}
					</div>
				</div>
			}
      </div>
    );
  }
}
export default Salidas;