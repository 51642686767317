import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';
import './styles/general.css';

class Salidas extends Component {
	constructor(props) {
		super(props);
		this.onSubmitNothing = this.onSubmitNothing.bind(this);
	}
	
	onSubmitNothing(event){
		event.preventDefault();
	}
	
	state = {
		username:'',
		password:'',
		filtro: '',
		matrices: [],
		tipo: 'N',
	};
	
	componentDidMount(){
		this.getData();
	}
	
	doNothing(day){
		//console.log(day);
	}
	
	
	async getData(){
		
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/get_matrices",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.matrices = result[0];

								this.state.matrices.forEach( matriz => {
									if(matriz.SERIE_DEFECTO==matriz.NUMSERIE)
										this.getImagenMatriz(matriz);
									if(matriz.PESO=='0')
										matriz.PESO = '-1';
								});
								
								this.setState({loading: false });
							})
							
		.catch((error) => {
			alert(error);
			this.setState({loading: false });
			console.error(error);
		});

	}
	
	async getImagenMatriz(matriz){
		matriz.LOADINGIMAGE = true;
		this.setState({state: this.state});
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: matriz.CODART,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/get_imagen_articulo",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								matriz.LOADINGIMAGE = false;
								if(result.IMAGEN!='NO')
									matriz.IMAGEN = result.IMAGEN;
								this.setState({state: this.state});
							})
							
		.catch((error) => {
			alert(error);
			matriz.LOADINGIMAGE = false;
			this.setState({state: this.state});
			console.error(error);
		});
		
	}
	
	
	addRegistro(index){
		this.state.matrices[index].ADD_clicked = true;
		this.state.matrices[index].ADD ? this.state.matrices[index].ADD=false : this.state.matrices[index].ADD=true;
		this.setState({state: this.state });
	}
	
	eliminarRegistro(index,ind){
		window.confirm('¿Estás seguro? Vas a eliminar un registro.') ? this.doEliminarRegistro(index,ind) : this.doNothing()
	}
	
	async doEliminarRegistro(index,ind){
		this.state.matrices[index].CARGANDO = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: this.state.matrices[index].CODART,
			NUMSERIE: this.state.matrices[index].NUMSERIE,
			ID: this.state.matrices[index].LINEAS[ind].ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/eliminar_registro_matriz",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.length==0){
									alert("Se ha producido algún error");
								}
								else{
									this.state.matrices[index].LINEAS = result[0];
									this.state.matrices[index].OPEN = true;
									this.state.matrices[index].SEARCH = true;
									this.state.matrices[index].ADD = false;
								}
								
								this.state.matrices[index].CARGANDO = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			this.state.matrices[index].CARGANDO = false;
			this.setState({state: this.state });
			console.error(error);
		});
	}
	
	async doAddRegistro(index){
		if(this.state.matrices[index].NUEVAS_OBSERVACIONES==null||this.state.matrices[index].NUEVAS_OBSERVACIONES.length==0){
			alert("Introduce primero unas observaciones");
			return;
		}
		
		this.state.matrices[index].CARGANDO = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: this.state.matrices[index].CODART,
			NUMSERIE: this.state.matrices[index].NUMSERIE,
			OBSERVACIONES: this.state.matrices[index].NUEVAS_OBSERVACIONES,
			TIPO: this.state.tipo,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/add_registro_matriz",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.length==0){
									alert("Se ha producido algún error");
								}
								else{
									this.state.matrices[index].LINEAS = result[0];
									this.state.matrices[index].OPEN = true;
									this.state.matrices[index].SEARCH = true;
									this.state.matrices[index].ADD = false;
								}
								
								this.state.matrices[index].CARGANDO = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			this.state.matrices[index].CARGANDO = false;
			this.setState({state: this.state });
			console.error(error);
		});
	}
	
	async getLineas(index){
		
		if(this.state.matrices[index].NUMSERIE=='')
			return;
		
		if(this.state.matrices[index].ADD_clicked){
			this.state.matrices[index].ADD_clicked = false;
			return;
		}
		
		if(this.state.matrices[index].SEARCH){
			this.state.matrices[index].OPEN = !this.state.matrices[index].OPEN;
			this.setState({state: this.state });
			return;
		}
		
		this.state.matrices[index].CARGANDO = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: this.state.matrices[index].CODART,
			NUMSERIE: this.state.matrices[index].NUMSERIE,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/get_registros_matrices",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.length==0){
									alert("Se ha producido algún error");
								}
								else{
									this.state.matrices[index].LINEAS = result[0];
									this.state.matrices[index].OPEN = true;
									this.state.matrices[index].SEARCH = true;
								}
								
								this.state.matrices[index].CARGANDO = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			this.state.matrices[index].CARGANDO = false;
			this.setState({state: this.state });
			console.error(error);
		});
	}
	
	filtrar(index){
		var salida = this.state.matrices[index];
		var FILTRO = this.state.filtro.toUpperCase();
		if(salida.CODART.includes(FILTRO)||salida.DESCART.includes(FILTRO)||salida.ARTALIAS.includes(FILTRO))
			return true;
		else return false;
	}
	
	altaMatriz(index){
		this.state.matrices[index].ADD_clicked = true;
		window.confirm('¿Estás seguro? Vas a dar de alta una nueva matriz de este código y la existente se marcará como obsoleta. La nueva matriz será la '+this.state.matrices[index].ARTALIAS+'-'+(parseInt(this.state.matrices[index].SERIE_DEFECTO)+1)) ? this.doAltaMatriz(index) : this.doNothing()
	}
	
	async doAltaMatriz(index){
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: this.state.matrices[index].CODART,
			NUMSERIE: parseInt(this.state.matrices[index].SERIE_DEFECTO)+1,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/nueva_matriz",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE!=1){
									alert("Se ha producido algún error");
								}
								else{
									this.getData();
									return;
								}
								this.setState({loading: false });
							})
							
		.catch((error) => {
			alert("Se ha producido algún error");
			this.setState({loading: false });
			console.error(error);
		});
	}
	
	async abrirPlano(ARTALIAS,index){
		this.state.matrices[index].ADD_clicked = true;
		let bodyData = {
			path: "\\\\SERVER001\\Forta\\Extrusión\\PLANOS\\PLANOS PERFILES\\"+ARTALIAS+".pdf",
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/manuales/descargar_PDF",data)
							.then(result => result.json())
							.then(result => {
								if(result.DONE!=1){
									alert("No se encuentra el plano solicitado");
									return;
								}
								// base64 string
								var base64str = result.PDF;

								// decode base64 string, remove space for IE compatibility
								var binary = atob(base64str.replace(/\s/g, ''));
								var len = binary.length;
								var buffer = new ArrayBuffer(len);
								var view = new Uint8Array(buffer);
								for (var i = 0; i < len; i++) {
									view[i] = binary.charCodeAt(i);
								}

								// create the blob object with content-type "application/pdf"               
								var blob = new Blob( [view], { type: "application/pdf" });
								var url = URL.createObjectURL(blob);
								
								window.open(url);
								
							})
							
		.catch((error) => {
			this.setState({loading: false });
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	getTipo(TIPO){
		switch(TIPO){
			case 'O': return 'OTROS';
			case 'EXTRUSIONADO': return 'EXTRUSIONADO';
			case 'N': return 'NITRURADO';
			case 'B': return 'BAJA';
		}
	}
	
	async guardarPeso(index){
				
		this.state.matrices[index].ADD_clicked = true;
		this.state.matrices[index].LOADINGPESO = true;
		this.setState({state:this.state})
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: this.state.matrices[index].CODART,
			PESO: this.state.matrices[index].PESO,
			NUMSERIE: this.state.matrices[index].NUMSERIE,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/guardar_peso",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE!=1){
									alert("Se ha producido algún error");
								}
								else{
									this.state.matrices[index].PESO_ORI=this.state.matrices[index].PESO;
								}
								this.state.matrices[index].LOADINGPESO = false;
								this.setState({state:this.state})
							})
							
		.catch((error) => {
			alert("Se ha producido algún error");
			this.state.matrices[index].LOADINGPESO = false;
			this.setState({state:this.state})
			console.error(error);
		});
	}
	
	async guardarVolumen(index){
				
		this.state.matrices[index].ADD_clicked = true;
		this.state.matrices[index].LOADINGVOLUMEN = true;
		this.setState({state:this.state})
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: this.state.matrices[index].CODART,
			VOLUMEN: this.state.matrices[index].VOLUMEN
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/guardar_volumen",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE!=1){
									alert("Se ha producido algún error");
								}
								else{
									this.state.matrices[index].VOLUMEN_ORI=this.state.matrices[index].VOLUMEN;
								}
								this.state.matrices[index].LOADINGVOLUMEN = false;
								this.setState({state:this.state})
							})
							
		.catch((error) => {
			alert("Se ha producido algún error");
			this.state.matrices[index].LOADINGVOLUMEN = false;
			this.setState({state:this.state})
			console.error(error);
		});
	}


	
	render() {
		return (
		<div>
			{this.state.imagen!=null&&
				<div style={{position:"fixed",width:"85%",marginTop:-250,zIndex:99}}>
					<a style={{cursor:'pointer'}} onClick={() => this.setState({imagen:null})}>
						<img alt="Imagen ampliada" src={this.state.imagen} style={{display: "block",marginLeft: "auto", marginRight: "auto", width: "65%"}}/>
					</a>
				</div>
			}
			{this.state.imagen!=null&&
				<a style={{cursor:'pointer'}} onClick={() => this.setState({imagen:null})}>
					<div style={{position:"fixed",width:"100%",height:"300%",margin:-300,paddingRight:260,backgroundColor:'#ccc',zIndex:98,opacity:0.9}}>
					
					</div>
				</a>
			}
			<div style={{float: "right",margin:15,marginTop:0}}>
				<form onSubmit={this.onSubmitNothing}>
				  <label>
					<input
						  type="text" 
						  value={this.state.filtro}
						  onChange={(e) => this.setState({filtro: e.target.value})}
						  placeholder="Filtro"
						/>
				  </label>
				</form>
			</div>
			<h2 style={{marginBottom:25}}>MATRICES</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.matrices.length>0&&
				<a>
					<table className="tablaTareas">
						<thead>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td>
									COD. MATRIZ
								</td>
								<td>
									MATRIZ
								</td>
								<td>
									DESCRIPCIÓN
								</td>
								<td>
									KG TOTAL
								</td>
								<td>
									PESO
								</td>
								<td>
									LONG. CORTE
								</td>
								<td style={{width:150}}>
								</td>
								<td style={{width:45}}>
								</td>
								<td style={{width:45}}>
								</td>
								<td style={{width:45}}>
								</td>
							</tr>
						</thead>
					{this.state.matrices.map(({ PESO_TEORICO,LOADINGPESO,LOADINGVOLUMEN,LOADINGIMAGE,PESO_ORI,VOLUMEN_ORI,IMAGEN,SERIE_DEFECTO,ARTALIAS,DESCART,KG,CODART,NUMSERIE,UNIDADES,OPEN,SEARCH,CARGANDO,LINEAS,ADD,NUEVAS_OBSERVACIONES,ADD_clicked,PESO,VOLUMEN },index) => (
						this.filtrar(index)&&
						<tbody className="tablaEntrada" style={{backgroundColor: NUMSERIE=='' ? "#ffebeb" : UNIDADES>0 ? "#FFF" : "#CCC"}} key={index}>
							<tr onClick={() => this.getLineas(index)}>
								<td>
									{CODART}
								</td>
								<td>
									{ARTALIAS}-{NUMSERIE}
								</td>
								<td>
									{DESCART}
								</td>
								<td>{KG.toFixed(0)} kg</td>
								{!LOADINGPESO&&NUMSERIE!=''&&
									<td>
										<label>
											<input
												onClick={() => {this.state.matrices[index].ADD_clicked = true;this.setState({state:this.state})}}
												type="number" 
												value={PESO!='-1' ? PESO+"" : PESO_TEORICO+''}
												onChange={(e) => {this.state.matrices[index].PESO=e.target.value;this.setState({state:this.state})}}
												placeholder={PESO_ORI+""}
												style={{marginRight:10,width:70}}
												min="0"
											/>
										  </label>
										 kg/m {PESO!=PESO_ORI&&PESO!=''&&PESO!='-1'&&PESO!='0'&&<button type="button" onClick={() => {this.guardarPeso(index)}} style={{height:34}}>GUARDAR</button>}
										{PESO!=PESO_ORI&&PESO!=''&&PESO!='-1'&&PESO!='0'&&<button type="button" onClick={() => {this.state.matrices[index].PESO=PESO_ORI;this.state.matrices[index].ADD_clicked = true;this.setState({state:this.state})}} style={{height:34,marginLeft:5}}>X</button>}
									</td>
								}
								{NUMSERIE==''&&
									<td>
										Crea una nueva matriz
									</td>
								}
								{LOADINGPESO&&
									<td>
										{PESO} kg/m <img alt="Loading..." src={loadingGif} style={{height:50,marginBottom:-20,marginTop:-20}}/>
									</td>
								}
								{SERIE_DEFECTO==NUMSERIE&&!LOADINGVOLUMEN&&
								<td>
									<label>
										<input
											onClick={() => {this.state.matrices[index].ADD_clicked = true;this.setState({state:this.state})}}
											type="number" 
											value={VOLUMEN+""}
											onChange={(e) => {this.state.matrices[index].VOLUMEN=e.target.value;this.setState({state:this.state})}}
											placeholder={VOLUMEN_ORI+""}
											style={{marginRight:10,width:70}}
											min="0"
										/>
									  </label> m {VOLUMEN!=VOLUMEN_ORI&&VOLUMEN!=''&&VOLUMEN!='0'&&<button type="button" onClick={() => {this.guardarVolumen(index)}} style={{height:34}}>GUARDAR</button>}
									{VOLUMEN!=VOLUMEN_ORI&&VOLUMEN!=''&&VOLUMEN!='0'&&<button type="button" onClick={() => {this.state.matrices[index].VOLUMEN=VOLUMEN_ORI;this.state.matrices[index].ADD_clicked = true;this.setState({state:this.state})}} style={{height:34,marginLeft:5}}>X</button>}
								</td>
								}
								{LOADINGVOLUMEN&&
									<td>
										{VOLUMEN} m <img alt="Loading..." src={loadingGif} style={{height:50,marginBottom:-20,marginTop:-20}}/>
									</td>
								}
								{SERIE_DEFECTO!=NUMSERIE&&
									<td></td>
								}
								{!LOADINGIMAGE&&IMAGEN!=null&&							
									<td>
										<a style={{cursor:'pointer'}} onClick={() => {this.state.matrices[index].ADD_clicked=true;this.setState({imagen:IMAGEN})}}>
											<img src={IMAGEN} style={{width:150}}/>
										</a>
									</td>
								}
								{!LOADINGIMAGE&&IMAGEN==null&&							
									<td>
									</td>
								}
								{LOADINGIMAGE&&
									<td>
										<div style={{textAlign:"center"}}>
											<img alt="Loading..." src={loadingGif} style={{height:50,marginBottom:-20,marginTop:-20}}/>
										</div>
									</td>
								}
								<td onClick={() => this.doNothing()}>
									{SERIE_DEFECTO==NUMSERIE&&
										<button type="button" onClick={() => this.altaMatriz(index)} style={{height:34}}>NUEVA MATRIZ</button>
									}
								</td>
								<td onClick={() => this.doNothing()}>
									<button type="button" onClick={() => this.abrirPlano(ARTALIAS,index)} style={{height:34}}>PLANO</button>
								</td>
								<td onClick={() => this.doNothing()}>
									<button style={{marginRight:5,float:'right',height:34,width:34,fontSize:24}} type="button" onClick={()=> this.addRegistro(index)}>
										{!ADD&&
											<b>+</b>
										}
										{ADD&&
											<b>-</b>
										}
									</button>
								</td>
							</tr>
							{CARGANDO&&
								<tr style={{cursor:"auto"}}>
									<td colspan="10">
										<div style={{textAlign:"center"}}>
											<img alt="Loading..." src={loadingGif} style={{height:50,marginBottom:-20,marginTop:-20}}/>
										</div>
									</td>
								</tr>
							}
							{OPEN&&LINEAS.length==0&&
								<tr>
									<td colspan="10" style={{padding:0,textAlign:'center',backgroundColor:'#eee',color:"#000",cursor:"auto"}}>	
										<p>NO HAY REGISTROS NI EXTRUSIONES DE ESTA MATRIZ</p>
									</td>
								</tr>
							}
							{OPEN&&LINEAS.length>0&&
								<tr>
									<td colspan="10" style={{padding:0}}>
										<table className="tablaTareas">
											{LINEAS.map(({ FECHA,OBSERVACIONES,TIPO },ind) => (
												<tbody key={ind}>
													{ind==0&&
														<tr style={{backgroundColor:"#339999",color:"#fff",cursor:"auto"}}>
															<td>
																FECHA
															</td>
															<td>
																TIPO DE REGISTRO
															</td>
															<td>
																OBSERVACIONES
															</td>
															<td style={{width:60}}>
															</td>
														</tr>
													}
													<tr style={{backgroundColor:"#eee",color:"#000",cursor:"auto"}}>
														<td>
															{FECHA}
														</td>
														<td>
															{this.getTipo(TIPO)}
														</td>
														<td>
															{TIPO=='EXTRUSIONADO' ? OBSERVACIONES+' kg' : OBSERVACIONES}
														</td>
														<td>
															{TIPO!='EXTRUSIONADO'&&TIPO!='B'&&
																<button type="button" onClick={() => this.eliminarRegistro(index,ind)} style={{height:34}}>ELIMINAR</button>
															}
														</td>
													</tr>
												</tbody>
											))}
										</table>
									</td>
								</tr>
							}
							{ADD&&
								<tr style={{cursor:"auto",backgroundColor:"#cdeeee",color:"#000"}}>
									<td colspan="9" style={{padding:10}}>
										<b>OBSERVACIONES DEL NUEVO REGISTRO: </b>
										<textarea
											value={NUEVAS_OBSERVACIONES==null ? '' : NUEVAS_OBSERVACIONES}
											onChange={(e) => {this.state.matrices[index].NUEVAS_OBSERVACIONES=e.target.value;this.setState({state: this.state})}}
											style={{marginLeft:10,width:800,marginBottom:-18,height:50}}
										/>
										<select name="tareas" id="tareas" onChange={(e) => this.state.tipo=e.target.value} style={{marginLeft:10,marginRight:5}}>
											<option value="N">Nitrurado</option>
											<option value="B">Baja de matriz</option>
											<option value="O">Otro</option>
										</select>
										<button style={{marginLeft:5,fontSize:24,marginBottom:10}} type="button" onClick={()=> this.doAddRegistro(index)}>CREAR REGISTRO</button>
									</td>
								</tr>
							}
						</tbody>
					))}
					</table>
					
				</a>
			}
      </div>
    );
  }
}
 
export default Salidas;