import React, { Component } from "react";
import loadingGif from './assets/loading.gif';

class Sillas extends Component {
	
	constructor(props){
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.state = {
			loading: false,
			searched: false,
			data: [],
			noResults: false,
			numserie: '',
			valueForm: '',
			facts: [],
			regus: [],
			reparaciones: [],
		}
		
	}
	
	handleSubmit(event) {
		this.buscar(this.state.valueForm);
		event.preventDefault();
	}
	handleChange(event) {
		this.setState({valueForm: event.target.value});
	}
  
	componentDidMount(){
		//this.getData();
		/*
		const windowUrl = window.location.search;
		const params = windowUrl.substring(11);
		if(params!=null&&params!='')
			this.buscar(params);*/
	}
	
	buscar(numserie){
		this.state.numserie = numserie;
		this.searchSerie();
	}
	
	async searchSerie() {
		
		
		this.state.loading = true;
		this.setState({state: this.state });
		
		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: this.state.numserie
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/sillas/buscar_numserie",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.length<1||result==null||result=='')
									this.state.noResults = true;
								else this.state.noResults = false;
								this.state.data = result[0];
								this.state.facts = result[1];
								//console.log(this.state.facts);
								this.state.regus = result[2];
								this.state.reparaciones = result[3];
								this.state.reparaciones.forEach( reparacion => {
									reparacion.SEARCH = false;
									reparacion.OPEN = false;
								});
								this.state.searched = true;
								this.state.loading = false;
								this.setState({state: this.state });
								//alert(this.state.data[0].SERIE);
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
  
	//<p>INTRODUCE NÚMERO DE SERIE: <input></input> <button onClick={()=>this.buscar()}>BUSCAR</button></p>
	render() {
		return (
		<div>
			<h2>BÚSQUEDA DE PRODUCTOS POR NÚMERO DE SERIE</h2>
			<br></br>
			<form onSubmit={this.handleSubmit}>
				<label for="fnumserie">INTRODUCE NÚMERO DE SERIE: </label>
				<input type="text" id="fnumserie" name="fnumserie" value={this.state.valueForm} onChange={this.handleChange}/>
				<input type="submit" value="Buscar"/>
			</form>
			
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{this.state.noResults&&
				<p>No se ha encontrado información...</p>
			}
			{!this.state.noResults&&
				<div>
					{this.state.searched&&false&&
						<div>
							<br></br>
							<p><b>Nº SERIE:</b> {this.state.numserie}</p>
							<p><b>REFERENCIA PRODUCTO:</b> {this.state.data[0].CODART}</p>
							<p><b>DESCRIPCIÓN PRODUCTO:</b> {this.state.data[0].DESCART}</p>
							<br></br>
							<p><b>ORDEN Nº:</b> {this.state.data[0].SERIE}/{this.state.data[0].NUMDOC}</p>
							{this.state.data[0].QUERY==1&&
								<p><b>FABRICADA EL:</b> {this.state.data[0].FECHAFIN}</p>
							}
							{this.state.data[0].QUERY==2&&
								<p><b>PENDIENTE DE FABRICAR</b></p>
							}
							<br></br>
							<p><b>CÓDIGO DE CLIENTE:</b> {this.state.data[0].CODCLI}</p>
							<p><b>CLIENTE:</b> {this.state.data[0].NOMCLI}</p>
						</div>
					}
					{!this.state.loading&&this.state.data.map(({ CODART,CODCLI,DESCART,FECHAFIN,NOMCLI,NUMDOC,QUERY,SERIE },index) => (
						<div key={index}>
							<p><b>{CODART} - {DESCART} - {this.state.numserie}</b></p>
							<p><b>ORDEN</b> {SERIE}/{NUMDOC} del {FECHAFIN}
								{CODCLI!=null&&<a> de {NOMCLI} ({CODCLI})</a>}
								{CODCLI==null&&<a> de stock</a>}
							</p>
						</div>
					))}
					{!this.state.loading&&this.state.facts.map(({ CODCLI,FECHA,NOMCLI,NUMDOC,RAZON,SERIE },index) => (
						<div key={index}>
							<p><b>ALBARÁN</b> {SERIE}/{NUMDOC} del {FECHA} de {NOMCLI} ({RAZON} - {CODCLI})</p>
						</div>
					))}
					{!this.state.loading&&this.state.regus.map(({ NUMDOC,FECHA,SERIE },index) => (
						<div key={index}>
							<p><b>REGULACIÓN</b> {SERIE}/{NUMDOC} del {FECHA}</p>
						</div>
					))}
					{!this.state.loading&&this.state.reparaciones.map(({ NUMDOC,FECHA,SERIE,DESCRIPCION,SEARCH,OPEN },index) => (
						<div key={index}>
							<p><b>REPARACIÓN</b> {SERIE}/{NUMDOC} del {FECHA}. {DESCRIPCION}</p>
						</div>
					))}
				</div>
			}
			
		</div>
		);
	}

}
 
export default Sillas;