import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';
import './styles/general.css';

class Salidas extends Component {
	constructor(props) {
		super(props);
		this.onSubmitNothing = this.onSubmitNothing.bind(this);
		this.getData = this.getData.bind(this);
	}
	
	onSubmitNothing(event){
		event.preventDefault();
	}
	
	state = {
		username:'',
		password:'',
		filtro: '',
		pedidos: [],
		tipo: 'N',
	};
	
	componentDidMount(){
		this.getData();
	}
	
	doNothing(day){
		//console.log(day);
	}
	
	
	async getData(){
		if(this.state.startDate==null){
			var today = ((new Date().getMonth() + 1)+"/"+new Date().getDate()+"/"+new Date().getFullYear());
			var tomorrow = today;
		}
		else{
				var today=""+(this.state.startDate._d.getMonth()+1)+"/"+this.state.startDate._d.getDate()+"/"+this.state.startDate._d.getFullYear();
			if(this.state.endDate!=null)
				var tomorrow = ""+(this.state.endDate._d.getMonth()+1)+"/"+this.state.endDate._d.getDate()+"/"+this.state.endDate._d.getFullYear();
			else var tomorrow= today;
		}
		this.setState({loading: true });

		let bodyData = {
			user: sessionStorage.getItem('user'),
			seccion: 6,
			date: tomorrow,
			yesterday: today,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/get_ordenes_internas",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.pedidos = result;
								console.log(result);
								this.setState({loading: false });
							})
							
		.catch((error) => {
			alert(error);
			this.setState({loading: false });
			console.error(error);
		});

	}

	async marcarLinea(ind,ID,index){
		
		this.state.pedidos[index].LINEAS[ind].CARGANDO = true;
		this.setState({state: this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			orden: {ID: this.state.pedidos[index].ID},
			ID: ID,
		};

		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/check_linea_orden_interna",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								for(var i=0;i<this.state.pedidos[index].LINEAS.length;i++){
									for(var j=0;j<result[0].length;j++){
										if(this.state.pedidos[index].LINEAS[i].ID==result[0][j].ID)
											this.state.pedidos[index].LINEAS[i].ESTADO=result[0][j].ESTADO;
									}
								}
								
								if(result[1][0].CONTADOR>0)
									this.state.pedidos[index].ESTADO='P';
								else this.state.pedidos[index].ESTADO='F';
								this.state.pedidos[index].LINEAS[ind].CARGANDO = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			this.state.pedidos[index].CARGANDO = false;
			this.setState({state: this.state });
			console.error(error);
		});
	}

	async getLineas(index){
		
		if(this.state.pedidos[index].SEARCH){
			this.state.pedidos[index].OPEN = !this.state.pedidos[index].OPEN;
			this.setState({state: this.state });
			return;
		}
		
		this.state.pedidos[index].CARGANDO = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			id: this.state.pedidos[index].ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/get_lineas_ordenes_internas",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.length==0){
									alert("Se ha producido algún error");
								}
								else{
									this.state.pedidos[index].LINEAS = result;
									this.state.pedidos[index].OPEN = true;
									this.state.pedidos[index].SEARCH = true;
								}
								
								this.state.pedidos[index].CARGANDO = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			this.state.pedidos[index].CARGANDO = false;
			this.setState({state: this.state });
			console.error(error);
		});
	}
	
	render() {
		return (
		<div>
			<div style={{float: "right",marginRight:50}}>
				<DateRangePicker
					startDate={this.state.startDate} // momentPropTypes.momentObj or null,
					startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
					endDate={this.state.endDate} // momentPropTypes.momentObj or null,
					endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
					onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
					focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
					startDatePlaceholderText= "Desde"
					endDatePlaceholderText= "Hasta"
					isOutsideRange={(day) => this.doNothing(day)}
					displayFormat="DD/MM/yyyy"
					firstDayOfWeek={1}
				/>
				<button type="button" onClick={this.getData} style={{marginLeft:50}}>BUSCAR</button>
			</div>
			<h2 style={{marginBottom:25}}>ÓRDENES INTERNAS</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.pedidos.length>0&&
				<a>
					<table className="tablaTareas">
						<thead>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td>
									FECHA
								</td>
								<td>
									Nº ORDEN
								</td>
								<td>
									PERSONA
								</td>
								<td>
									OBSERVACIONES
								</td>
							</tr>
						</thead>
					{this.state.pedidos.map(({ SERIE,NUMDOC,ID,NOMBRE,FECHA,CARGANDO,OPEN,SEARCH,LINEAS,OBSERVACIONES,ESTADO },index) => (
						<tbody className="tablaEntrada" style={{backgroundColor: ESTADO=='F' ? '#CDEEEE' : "#FFF"}} key={ID}>
							<tr onClick={() => this.getLineas(index)}>
								<td>
									{FECHA}
								</td>
								<td>
									{SERIE}/{NUMDOC}
								</td>
								<td>
									{NOMBRE}
								</td>
								<td>
									{OBSERVACIONES}
								</td>
							</tr>
							{CARGANDO&&
								<tr style={{cursor:"auto"}}>
									<td colspan="9">
										<div style={{textAlign:"center"}}>
											<img alt="Loading..." src={loadingGif} style={{height:50,marginBottom:-20,marginTop:-20}}/>
										</div>
									</td>
								</tr>
							}
							{OPEN&&LINEAS.length>0&&
								<tr>
									<td colspan="5" style={{padding:0}}>
										<table className="tablaTareas">
											{LINEAS.map(({ CANTIDAD,CODART,DESCART,ESTADO,ID,CARGANDO },ind) => (
												<tbody key={ind}>
													{ind==0&&
														<tr style={{backgroundColor:"#339999",color:"#fff",cursor:"auto"}}>
															<td>
																CÓD. ART.
															</td>
															<td>
																DESCRIPCIÓN ARTÍCULO
															</td>
															<td>
																CANTIDAD (kg)
															</td>
															<td>
																COMPLETAR
															</td>
														</tr>
													}
													<tr style={{backgroundColor: ESTADO=='F' ? "#CDEEEE" : "#eee",color:"#000",cursor:"auto"}}>
														<td>
															{CODART}
														</td>
														<td>
															{DESCART}
														</td>
														<td>
															{CANTIDAD}kg
														</td>
														<td>
															{ESTADO!='F'&&!CARGANDO&&
																<button type="button" onClick={() => this.marcarLinea(ind,ID,index)} style={{marginLeft:0}}>X</button>
															}
															{CARGANDO&&
																<img alt="Loading..." src={loadingGif} style={{height:57,marginTop:-20,marginBottom:-20,marginLeft:-20}}/>
															}
														</td>
													</tr>
												</tbody>
											))}
										</table>
									</td>
								</tr>
							}
						</tbody>
					))}
					</table>
					
				</a>
			}
      </div>
    );
  }
}
 
export default Salidas;