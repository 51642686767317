import React, { Component } from "react";
import loadingGif from './assets/loading.gif';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';

class Sillas extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			loading: false,
			empleados: [],
			startDate: null,
			endDate: null,
			SELECCIONADA: {idFabricacion: 0,detalles: [],CODEMP:null},
		}
		
	}

	componentDidMount(){
		
	}
	
	doNothing(){

	}

	async search(){
		if(this.state.endDate==null)
			this.state.endDate = this.state.startDate;
		this.setState({loading:true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			yesterday: ""+(this.state.startDate._d.getMonth()+1)+"/"+this.state.startDate._d.getDate()+"/"+this.state.startDate._d.getFullYear(),
			date: ""+(this.state.endDate._d.getMonth()+1)+"/"+this.state.endDate._d.getDate()+"/"+this.state.endDate._d.getFullYear(),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/estadisticas/fabricacion_empleado",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								this.state.empleados = result;
								for(var i=0;i<this.state.empleados.length;i++){
									this.state.empleados[i].OPEN = false;
									this.state.empleados[i].SEARCH = false;
									this.state.empleados[i].loading = false;
									this.state.empleados[i].SHOW = true;
									this.state.empleados[i].CRONO = false;
								}
								this.setState({loading: false });
								console.log(result);
							})
							
		.catch((error) => {
			console.error(error);
		});
	}

	async doGetProduct(index){
		if(this.state.endDate==null)
			this.state.endDate = this.state.startDate;
		
		let bodyData = {
				user: sessionStorage.getItem('user'),
				empleado: this.state.empleados[index].CODEMP,
				yesterday: ""+(this.state.startDate._d.getMonth()+1)+"/"+this.state.startDate._d.getDate()+"/"+this.state.startDate._d.getFullYear(),
				date: ""+(this.state.endDate._d.getMonth()+1)+"/"+this.state.endDate._d.getDate()+"/"+this.state.endDate._d.getFullYear(),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/estadisticas/fabricacion_empleado_articulos",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.empleados[index].PRODUCTOS = result;
								this.state.empleados[index].OPEN = true;
								this.state.empleados[index].SEARCH = true;
								this.state.empleados[index].loading = false;
								this.setState({state: this.state });
								this.getCrono(index);
							})
							
		.catch((error) => {
			console.error(error);
		});
	}

	async getCrono(index){
		if(this.state.endDate==null)
			this.state.endDate = this.state.startDate;
		if(this.state.empleados[index].CRONO){
			this.state.empleados[index].CRONO = false;
			this.setState({state:this.state});
			return;
		}
		this.state.empleados[index].loadingCrono = true;
		this.setState({state: this.state });
		let bodyData = {
				user: sessionStorage.getItem('user'),
				empleado: this.state.empleados[index].CODEMP,
				yesterday: ""+(this.state.startDate._d.getMonth()+1)+"/"+this.state.startDate._d.getDate()+"/"+this.state.startDate._d.getFullYear(),
				date: ""+(this.state.endDate._d.getMonth()+1)+"/"+this.state.endDate._d.getDate()+"/"+this.state.endDate._d.getFullYear(),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/estadisticas/cronograma_por_empleado",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.empleados[index].loadingCrono = false;
								this.state.empleados[index].CRONO = !this.state.empleados[index].CRONO;
								this.state.empleados[index].CRONOGRAMA = result.intervalos;
								this.state.empleados[index].horaInicio = ((new Date(result.HORA_INICIO)).getUTCHours())+':00';
								this.state.empleados[index].horaFinal = ((new Date(result.HORA_FINAL)).getUTCHours())+':00';
								this.state.empleados[index].perc = parseInt(result.perc*100)/100;
								this.setState({state:this.state});
							})
							
		.catch((error) => {
			console.error(error);
		});
	}

	async getInterval(index,ind){
		if(this.state.empleados[index].CRONOGRAMA[ind].detalles!=null){
			this.setState({SELECCIONADA: {idFabricacion:this.state.empleados[index].CRONOGRAMA[ind].idFabricacion,detalles: this.state.empleados[index].CRONOGRAMA[ind].detalles,CODEMP: this.state.empleados[index].CODEMP}})
			return;		
		}
		this.setState({SELECCIONADA: {idFabricacion:this.state.empleados[index].CRONOGRAMA[ind].idFabricacion,detalles: [],CODEMP: this.state.empleados[index].CODEMP},SEARCHING_FAB:true})
		
		let bodyData = {
				user: sessionStorage.getItem('user'),
				IDFABRICACION: this.state.empleados[index].CRONOGRAMA[ind].idFabricacion
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/estadisticas/descripcion_fabricacion",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.empleados[index].CRONOGRAMA[ind].detalles = result[0];
								this.state.SELECCIONADA.detalles = result[0];
								this.setState({SEARCHING_FAB:false});
							})
							
		.catch((error) => {
			console.error(error);
		});
	}

	getProducts(index){
		if(!this.state.empleados[index].SEARCH) {
			this.state.empleados[index].OPEN = true;
			this.state.empleados[index].loading = true;
			this.setState({state: this.state });
			this.doGetProduct(index);
		}
		else{
			if(this.state.empleados[index].OPEN)
				this.state.empleados[index].OPEN = false;
			else this.state.empleados[index].OPEN = true;
			this.setState({state: this.state });
		}
	}
	

	render() {
		return (
		<div>
			<div style={{float: "right",marginRight:50}}>
				<DateRangePicker
					startDate={this.state.startDate} // momentPropTypes.momentObj or null,
					startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
					endDate={this.state.endDate} // momentPropTypes.momentObj or null,
					endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
					onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
					focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
					startDatePlaceholderText= "Desde"
					endDatePlaceholderText= "Hasta"
					isOutsideRange={(day) => this.doNothing(day)}
					displayFormat="DD/MM/yyyy"
					firstDayOfWeek={1}
				/>
				<button type="button" onClick={() => this.search()} style={{marginLeft:50}}>BUSCAR</button>
			</div>
			<h2 style={{marginBottom:25}}>PERSONAL FÁBRICA</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.empleados.length>0&&
				<div>
					<table className="tablaEntrada">
					<thead>
						<tr style={{backgroundColor:"#339999",color:"#fff"}}>
							<td style={{width:'150px'}}>
								CÓDIGO PERSONA
							</td>
							<td>
								NOMBRE
							</td>
							<td style={{width:'150px'}}>
								COSTE €/h
							</td>
						</tr>
					</thead>
					{this.state.empleados.map(({CODEMP,NOMBRE,OPEN,SEARCH,loading,PRODUCTOS,SHOW,CRONO,loadingCrono,CRONOGRAMA,horaInicio,horaFinal,perc,PRCCOSTE},index) => (
						<tbody className="tablaEntrada" style={{backgroundColor:"#FFF"}} key={CODEMP}>
							<tr key={index}  onClick={() => this.getProducts(index)}>
								<td>{CODEMP}</td>
								<td>{NOMBRE}</td>
								<td>{(""+PRCCOSTE).replace('.',',')}</td>
							</tr>
							{(loading||loadingCrono)&&
								<tr style={{backgroundColor:'#ccc'}}>
									<td colSpan={3} style={{textAlign:'center'}}><img alt="Loading..." src={loadingGif} style={{height:50,margin:-20}}/></td>
								</tr>
							}
							{!loadingCrono&&CRONO&&OPEN&&
								<>
									<tr style={{backgroundColor:'#feffeb',border:0}}>
										<td style={{border:0}}>
											<b style={{color:'#000'}}>{horaInicio}</b>
										</td>
										<td style={{textAlign:'center',border:0}}>
											<b style={{color:'#000'}}>{perc}%</b>
										</td>
										<td style={{textAlign:'right',border:0}}>
											<b style={{color:'#000'}}>{horaFinal}</b>
										</td>
									</tr>
									<tr style={{backgroundColor:'#fff'}}>
										<td colSpan={3} style={{padding:0}}>
											<div style={{width:'100%',marginLeft:'1.5%'}}>
												{CRONOGRAMA.map(({ idFabricacion,duracion,detalles },ind) => (
													<>
														{idFabricacion!=null&&
															<div className='crono' style={{width:duracion*0.0097+'%',backgroundColor:this.state.SELECCIONADA.idFabricacion==idFabricacion ? '#00FF00' : '#339999'}} onClick={() => this.getInterval(index,ind)}></div>
														}
														{idFabricacion==null&&
															<div className='cronoEmpty' style={{width:duracion*0.0097+'%'}}></div>
														}
													</>
												))}
											</div>
										</td>
									</tr>
									{CRONO&&OPEN&&this.state.SELECCIONADA.CODEMP==CODEMP&&this.state.SELECCIONADA.detalles.map(({ CODART,CTD,DESCART,FECHA_INICIO,FECHA_FIN,PAUSAS },ind) => (
										<tr style={{backgroundColor:'#feffeb'}}>
											<td><b style={{color:'#000'}}>{CODART}</b></td>
											<td>
												Desde {FECHA_INICIO} hasta {FECHA_FIN}{(PAUSAS>0 ? ' ('+(parseInt((PAUSAS*60.00)*100)/100)+' min. pausado)' : '') + ' - '}
												<b style={{color:'#000'}}>{DESCART}</b>
											</td>
											<td><b style={{color:'#000'}}>{CTD}</b></td>
										</tr>
									))}
									{CRONO&&OPEN&&this.state.SELECCIONADA.CODEMP==CODEMP&&this.state.SEARCHING_FAB&&
										<tr style={{backgroundColor:'#feffeb'}}>
											<td colSpan={3} style={{textAlign:'center'}}><img alt="Loading..." src={loadingGif} style={{height:50,margin:-20}}/></td>
										</tr>
									}
								</>
							}
							{!loading&&OPEN&&PRODUCTOS.length>0&&
								<tr style={{backgroundColor:"#CDEEEE"}}>
									<td style={{width:'150px'}}>
										CÓDIGO ARTÍCULO
									</td>
									<td>
										DESCRIPCIÓN ARTÍCULO FABRICADO
									</td>
									<td style={{width:'150px'}}>
										UNIDADES
									</td>
								</tr>
							}
							{!loading&&OPEN&&PRODUCTOS.map(({CODART,DESCART,CTD},index) => (
								<tr key={index} style={{backgroundColor:'#ccc'}}>
									<td>{CODART}</td>
									<td>{DESCART}</td>
									<td>{CTD}</td>
								</tr>
							))}
						</tbody>
					))}
					</table>
				</div>
			}
		</div>
		);
	}

}
 
export default Sillas;