import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';
import tochosPNG from './assets/tochos.png';
import './styles/general.css';

class Salidas extends Component {
	
	constructor(props) {
		super(props);
		this.getData = this.getData.bind(this);
	}
	
	state = {
		username:'',
		password:'',
		ordenes: [],
		recibidas: true,
		creandoTarea: false,
		descripcion: '',
		asunto: '',
		destinatario: null,
		usuarios: [],
		IDPEDV: null,
		fabricar:null,
		operario: null,
		operarios: [],
		tochos: [],
		tocho: {CODART: null},
		imagen: null,
		fabricando: 0,
		extrusion: null,
		matrices: [],
		matriz: {NUMSERIE: null},
		culote: null,
		despunte: null,
		medidaMaxima: null,
		pesoTocho: null,
	};
	
	async componentDidMount(){
		await this.getMatrices();
		this.getData();
	}
	
	doNothing(day){
		//console.log(day);
	}
	
	getNombreOperario(){
		for(var i=0;i<this.state.operarios.length;i++)
			if(this.state.operarios[i].value==this.state.operario)
				return this.state.operarios[i].label;
		return 'Operario sin selecionar';
	}
	
	async getMatrices(){
		
		//this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		await fetch("http://192.168.0.19:49264"+"/api/modulo/extrusion/get_matrices",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.matrices = result[0];
								this.setState({pesoTocho:result[1][0].PESO_TOCHO,culote:result[1][0].CULOTE,despunte:result[1][0].DESPUNTE,medidaMaxima:result[1][0].MEDIDA_MAXIMA});
							})
							
		.catch((error) => {
			alert(error);
			//this.setState({loading: false });
			console.error(error);
		});

	}
	
	async getData(event){
		this.state.matriz.NUMSERIE = null;
		this.setState({loading: true,fabricar:null });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			
		};
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/get_ordenes_diarias",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('No tiene acceso...');
									return;
								}
								this.state.ordenes = result[0];
								this.state.operarios = result[1];
								this.state.tochos = result[3];
								this.state.programacion = result[5];
								if(result[2].length>0){
									this.state.operario = result[2][0].CODEMP;
									this.state.fabricando = result[2][0].IDLINORDEN;
									this.state.matriz.NUMSERIE = result[2][0].SERIE_MATRIZ;
									this.state.extrusion = {
										ID: result[2][0].ID,
										SERIE: result[2][0].SERIE,
										NUMDOC: result[2][0].NUMDOC,
										OBSERVACIONES: '',
										TOCHOS: [{
											UNIDADES: '',
											MEDIDA: '',
											COLADA: null,
											CODART: result[2][0].TOCHO,
										}],
									}
									this.state.tocho = {
										LONGITUD: result[2][0].LONGITUD_TOCHO,
										PESO: result[2][0].PESO_TOCHO,
										CODART: result[2][0].TOCHO,
										DESCART: result[2][0].DESCART_TOCHO,
										COLADAS: result[4],
									}
									//console.log(this.state.tocho);
								}
								this.calcularTochosProgramacion();
								this.setState({loading: false });
							})
							
		.catch((error) => {
			alert('Se ha producido algún error...');
			this.setState({loading:false});
			console.error(error);
		});

	}
	
	calcularKgExtrusion(){
		
		var peso = 0;
		this.state.extrusion.TOCHOS.forEach( tocho => peso+=tocho.MEDIDA*tocho.UNIDADES*this.state.tocho.PESO/1000 );
		return peso;
		
		
		if(this.state.ordenes[this.state.fabricar].TOCHOSFABRICADOS==null)
			return 0;
		return this.state.ordenes[this.state.fabricar].TOCHOSFABRICADOS*this.state.tocho.PESO;
	}
	
	checkIDPROD(IDPROD,index){
		if(this.state.IDPROD==IDPROD){
			if(this.state.ordenes.length==index+1) this.state.IDPROD = null;
			return false;
		}
		if(this.state.ordenes.length==index+1) this.state.IDPROD = null;
		else this.state.IDPROD=IDPROD
		return true;
	}
	
	countTochosFijos(){
		var count = 0;
		for(var i=0;i<this.state.extrusion.TOCHOS.length;i++)
			if(this.state.extrusion.TOCHOS[i].FIJO!=1)
				count++;
		return count;
	}
	
	async goLinea(index){
		this.setState({fabricar:index,loading:true});
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			IDLIN: this.state.ordenes[index].ID,
			TOCHO: this.state.tocho.CODART,
			ID: this.state.extrusion!=null ? this.state.extrusion.ID : null,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/get_linea_orden",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('No tiene acceso...');
									return;
								}
								this.state.ordenes[this.state.fabricar].IMAGEN_PERFIL = result[0][0].IMAGEN;
								this.state.ordenes[this.state.fabricar].IMAGEN_MATRIZ = result[1][0].IMAGEN;
								if(this.state.ordenes[this.state.fabricar].SERIE_MATRIZ!='')
									this.state.matriz.NUMSERIE = this.state.ordenes[this.state.fabricar].SERIE_MATRIZ;
								this.state.tocho.COLADAS = result[2];
								if(result.length>3&&this.state.fabricando==this.state.ordenes[this.state.fabricar].ID){
									this.state.extrusion.TOCHOS = result[3];
									this.state.matriz.NUMSERIE = result[4][0].SERIE_MATRIZ;
									if(this.countTochosFijos()==0)
										this.state.extrusion.TOCHOS.push({
											UNIDADES: '',
											MEDIDA: '',
											COLADA: null,
											CODART: this.state.tocho.CODART
										});
								}
								this.setState({loading:false});
							})
							
		.catch((error) => {
			alert('Se ha producido algún error...');
			this.setState({loading:false});
			console.error(error);
		});
		
	}
	
	
	cancelarExtrusion(){
		window.confirm('¿Estás seguro de que quieres cancelar la extrusión actual?') ? this.doCancelarExtrusion() : this.doNothing()
	}
	
	async doCancelarExtrusion(){
		this.setState({loading:true});
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.extrusion.ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/cancelar_extrusion",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('No tiene acceso...');
									return;
								}
								if(result.DONE==1){
									this.setState({fabricando:0,extrusion:null,loading:false,matriz:{NUMSERIE: null},tocho: {CODART: null}})
								}
								else {
									alert("Se ha producido algún error");
									this.setState({loading:false});
								}
							})
							
		.catch((error) => {
			alert('Se ha producido algún error...');
			this.setState({loading:false});
			console.error(error);
		});
		
	}
	
	finalizarExtrusion(){
		/*
		for(var i=0;i<this.state.extrusion.TOCHOS.length;i++){
			if(this.state.extrusion.TOCHOS[i].UNIDADES==''||this.state.extrusion.TOCHOS[i].UNIDADES<=0){
				alert("Las unidades de tochos deben ser mayores de cero");
				return;
			}
			if(this.state.extrusion.TOCHOS[i].MEDIDA==''||this.state.extrusion.TOCHOS[i].MEDIDA<=0){
				alert("Las medidas de los tochos deben ser mayores de cero");
				return;
			}
			if(this.state.extrusion.TOCHOS[i].COLADA==null){
				alert("Debes seleccionar todas las coladas");
				return;
			}
		}*/
		
		
		if(this.state.extrusion.TOCHOS.length==0){
			alert("Debes indicar y guardar algún tocho extruido");
			return;
		}
		var contador = 0;
		for(var i=0;i<this.state.extrusion.TOCHOS.length;i++){
			if(this.state.extrusion.TOCHOS[i].FIJO==1)
				contador++;
		}
		if(contador==0){
			alert("Debes indicar y guardar algún tocho extruido");
			return;
		}
		
		if(this.state.extrusion.TOCHOS[this.state.extrusion.TOCHOS.length-1].UNIDADES!=''&&this.state.extrusion.TOCHOS[this.state.extrusion.TOCHOS.length-1].UNIDADES!=0){
			alert("Guarda o borra la información de las líneas de los tochos.");
			return;
		}
		
		window.confirm('¿Estás seguro? Vas a dar por finalizada la extrusión, las líneas de tochos que no estén guardadas no se tendrán en cuenta.') ? this.checkFinalizarExtrusion() : this.doNothing()
		
	}
	
	checkFinalizarExtrusion(){
		
		this.doFinalizarExtrusion(false);
		return;

		window.confirm("¿Quieres dar por terminada la orden? Si lo haces desaparecerá del listado. Pulsa Aceptar para 'SÍ', 'Cancelar' para NO") ? this.doFinalizarExtrusion(true) : this.doFinalizarExtrusion(false)
		return;
		
		if(this.calcularKgExtrusion()+this.state.ordenes[this.state.fabricar].EXTRUSIONADO>=this.state.ordenes[this.state.fabricar].KG){
			window.confirm("¿Quieres dar por terminada la orden? Pulsa Aceptar para 'SÍ', 'Cancelar' para NO") ? this.doFinalizarExtrusion(true) : this.doFinalizarExtrusion(false)
		}
		else 
			this.doFinalizarExtrusion(false);
	}
	
	async doFinalizarExtrusion(cerrar){
		this.setState({loading:true});
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.extrusion.ID,
			kg: this.calcularKgExtrusion(),
			tochos: this.state.extrusion.TOCHOS,
			cerrar: cerrar,
			OBSERVACIONES: this.state.extrusion.OBSERVACIONES,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/finalizar_extrusion",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('No tiene acceso...');
									return;
								}
								if(result.DONE==1){
									window.location.reload(false);
								}
								else {
									alert("Se ha producido algún error");
									this.setState({loading:false});
								}
							})
							
		.catch((error) => {
			alert('Se ha producido algún error...');
			this.setState({loading:false});
			console.error(error);
		});
	}
	
	filtrar(index){
		var salida = this.state.matrices[index];
		if(salida.ARTALIAS==this.state.ordenes[this.state.fabricar].ARTALIAS)
			return true;
		else return false;
	}
	
	cerrarExtrusion(){
		window.confirm("¿Estás seguro de que quieres dar por finalizada esta orden de extrusión?") ? this.doCerrarExtrusion() : this.doNothing();
	}

	async doCerrarExtrusion(){
		this.setState({loading:true});
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			IDPROD: this.state.ordenes[this.state.fabricar].IDPROD,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/cerrar_orden",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('No tiene acceso...');
									return;
								}
								if(result.DONE==1){
									this.getData();
									//this.setState({loading:false});
								}
								else {
									alert("Se ha producido algún error");
									this.setState({loading:false});
								}
							})
							
		.catch((error) => {
			alert('Se ha producido algún error...');
			this.setState({loading:false});
			console.error(error);
		});
	}

	async comenzarExtrusion(){
		
		if(this.state.matriz.NUMSERIE==null){
			alert("Selecciona una matriz para comenzar a extrusionar");
			return;
		}
			
		
		if(this.state.operario==null){
			alert("Selecciona un operario para poder comenzar a extrusionar");
			return;
		}
		
		if(this.state.tocho.CODART==null){
			alert("Indica un tocho antes de comenzar a extrusionar");
			return;
		}
		
		this.setState({loading:true});
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			IDLIN: this.state.ordenes[this.state.fabricar].ID,
			CODEMP: this.state.operario,
			COLADA: this.state.tocho.COLADA,
			TOCHO: this.state.tocho.CODART,
			SERIE_MATRIZ: this.state.matriz.NUMSERIE,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/empezar_extrusion",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('No tiene acceso...');
									return;
								}
								if(result.DONE==1){
									this.state.extrusion = {
										ID: result.ID,
										SERIE: result.SERIE,
										NUMDOC: result.NUMDOC,
										OBSERVACIONES: '',
										TOCHOS: [{
											UNIDADES: '',
											MEDIDA: '',
											COLADA: null,
											CODART: result.datos.TOCHO,
										}],
									}
									this.state.tocho = {
										PESO: result.datos.PESO_TOCHO,
										CODART: result.datos.TOCHO,
										LONGITUD: result.datos.LONGITUD_TOCHO,
										DESCART: result.datos.DESCART_TOCHO,
										COLADAS: result.COLADAS,
									}
									this.setState({fabricando:this.state.ordenes[this.state.fabricar].ID,loading:false});
								}
								else {
									alert("Se ha producido algún error");
									this.setState({loading:false});
								}
							})
							
		.catch((error) => {
			alert('Se ha producido algún error...');
			this.setState({loading:false});
			console.error(error);
		});
	}
	
	/*abrirPlano(){
		window.open("\\\\SERVER001\\Forta\\Extrusión\\PLANOS PERFILES EXTRUSION\\"+this.state.ordenes[this.state.fabricar].ALIASMATRIZ+".pdf")
	}*/
	
	async abrirPlano(){
		this.setState({loading:true });
		let bodyData = {
			path: "\\\\SERVER001\\Forta\\Extrusión\\PLANOS\\PLANOS PERFILES\\"+this.state.ordenes[this.state.fabricar].ARTALIAS+".pdf",
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/manuales/descargar_PDF",data)
							.then(result => result.json())
							.then(result => {
								
								// base64 string
								var base64str = result.PDF;

								// decode base64 string, remove space for IE compatibility
								var binary = atob(base64str.replace(/\s/g, ''));
								var len = binary.length;
								var buffer = new ArrayBuffer(len);
								var view = new Uint8Array(buffer);
								for (var i = 0; i < len; i++) {
									view[i] = binary.charCodeAt(i);
								}

								// create the blob object with content-type "application/pdf"               
								var blob = new Blob( [view], { type: "application/pdf" });
								var url = URL.createObjectURL(blob);
								
								window.open(url);
								this.setState({loading: false });
							})
							
		.catch((error) => {
			this.setState({loading: false });
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async removeTocho(index){
		if(this.state.extrusion.TOCHOS[index].FIJO!=1){
			this.state.extrusion.TOCHOS.splice(index,1);
			if(this.countTochosFijos()==0)
				this.state.extrusion.TOCHOS.push({
					UNIDADES: '',
					MEDIDA: '',
					COLADA: null,
					CODART: this.state.tocho.CODART
				});
			this.setState({state:this.state});
			return;
		}
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.extrusion.TOCHOS[index].ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/remove_tocho_extrusion",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('No tiene acceso...');
									return;
								}
								if(result.DONE==1){
									this.state.extrusion.TOCHOS.splice(index,1);
									if(this.countTochosFijos()==0)
										this.state.extrusion.TOCHOS.push({
											UNIDADES: '',
											MEDIDA: '',
											COLADA: null,
											CODART: this.state.tocho.CODART
										});
									this.setState({state:this.state});
								}
								else {
									alert("Se ha producido algún error");
									this.setState({state:this.state});
								}
								this.setState({state:this.state});
							})
							
		.catch((error) => {
			alert('Se ha producido algún error...');
			this.setState({state:this.state});
			console.error(error);
		});
		
		
	}
	
	async addTocho(){
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.extrusion.ID,
			tocho: this.state.extrusion.TOCHOS[this.state.extrusion.TOCHOS.length-1],
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/add_tocho_extrusion",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('No tiene acceso...');
									return;
								}
								if(result.DONE>0){
									
									this.state.extrusion.TOCHOS[this.state.extrusion.TOCHOS.length-1].FIJO = 1;
									this.state.extrusion.TOCHOS[this.state.extrusion.TOCHOS.length-1].ID = result.DONE;
									
									this.state.extrusion.TOCHOS.push({
										UNIDADES: '',
										MEDIDA: '',
										CODART: this.state.tocho.CODART
									});
								}
								else {
									alert("Se ha producido algún error");
									this.setState({loading:false});
								}
								this.setState({state:this.state});
							})
							
		.catch((error) => {
			alert('Se ha producido algún error...');
			this.setState({loading:false});
			console.error(error);
		});
		
	}
	
	seleccionarMatriz(NUMSERIE){
		this.setState({matriz: {NUMSERIE:NUMSERIE}});
	}
	
	async seleccionarTocho(CODART){
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: CODART,
			IDLIN: this.state.ordenes[this.state.fabricar].ID,
		};
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/get_tocho",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('No tiene acceso...');
									return;
								}
								if(result.DONE==-1){
									alert("CAMBIO DE TOCHO: No puedes elegir un tocho distinto al que se ha elegido previamente en esta misma fabricación, para ello deberías crear otra fabricación nueva.")
									this.setState({loading:false});
									return;
								}
								else result = result.resultado;
								this.setState({tocho: {CODART: CODART,COLADAS: result[0],COLADA:null},loading:false});
							})
							
		.catch((error) => {
			alert('Se ha producido algún error...');
			this.setState({loading:false});
			console.error(error);
		});
	}
	
	seleccionarColada(e,index){
		//this.state.tocho.COLADA = LOTE;
		this.state.extrusion.TOCHOS[index].COLADA = e.target.value;
		this.setState({state:this.state})
	}
	
	seleccionarAddMatriz(e){
		this.setState({addMatriz: e.target.value})
	}
	
	async getLineas(index){
		
		if(this.state.matrices[index].ADD_clicked){
			this.state.matrices[index].ADD_clicked = false;
			return;
		}
		
		if(this.state.matrices[index].SEARCH){
			this.state.matrices[index].OPEN = !this.state.matrices[index].OPEN;
			this.setState({state: this.state });
			return;
		}
		
		this.state.matrices[index].CARGANDO = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: this.state.matrices[index].CODART,
			NUMSERIE: this.state.matrices[index].NUMSERIE,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/get_registros_matrices",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.length==0){
									alert("Se ha producido algún error");
								}
								else{
									this.state.matrices[index].LINEAS = result[0];
									this.state.matrices[index].OPEN = true;
									this.state.matrices[index].SEARCH = true;
								}
								
								this.state.matrices[index].CARGANDO = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			this.state.matrices[index].CARGANDO = false;
			this.setState({state: this.state });
			console.error(error);
		});
	}
	
	addRegistro(index){
		this.state.matrices[index].ADD_clicked = true;
		this.state.matrices[index].ADD ? this.state.matrices[index].ADD=false : this.state.matrices[index].ADD=true;
		this.setState({state: this.state });
	}
	
	async doAddRegistro(index){
		if(this.state.matrices[index].NUEVAS_OBSERVACIONES==null||this.state.matrices[index].NUEVAS_OBSERVACIONES.length==0){
			alert("Introduce primero unas observaciones");
			return;
		}
		
		this.state.matrices[index].CARGANDO = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: this.state.matrices[index].CODART,
			NUMSERIE: this.state.matrices[index].NUMSERIE,
			OBSERVACIONES: this.state.matrices[index].NUEVAS_OBSERVACIONES,
			TIPO: 'MANUAL',
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/add_registro_matriz",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.length==0){
									alert("Se ha producido algún error");
								}
								else{
									this.state.matrices[index].LINEAS = result[0];
									this.state.matrices[index].OPEN = true;
									this.state.matrices[index].SEARCH = true;
									this.state.matrices[index].ADD = false;
								}
								
								this.state.matrices[index].CARGANDO = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			this.state.matrices[index].CARGANDO = false;
			this.setState({state: this.state });
			console.error(error);
		});
	}
	
	getLotePerfil(index){
		for(var i=0;i<this.state.tocho.COLADAS.length;i++){
			if(this.state.tocho.COLADAS[i].LOTE==this.state.extrusion.TOCHOS[index].COLADA){
				return this.state.tocho.COLADAS[i].LOTE_PERFIL
			}
		}
		
		return null;
	}
	
	calcularTochosProgramacion(){
		for(var i=0;i<this.state.ordenes.length;i++){
			var kg = this.state.ordenes[i].KG;
			var numSalidas = this.state.ordenes[i].PARAM2;
			var pesoPorMetro = this.state.ordenes[i].PESO;
			var medidaCorte = this.state.ordenes[i].LONG_CORTE;
			var numeroBarrasSolas = Math.floor(this.state.medidaMaxima/medidaCorte);
			var numeroBarras = numSalidas * numeroBarrasSolas;
			numeroBarras+=1;
			do {
				numeroBarras-=1;
				var longitudTotal = (numeroBarras*medidaCorte+(this.state.despunte))*numSalidas;
				
				var kgTocho = (numeroBarras*medidaCorte*pesoPorMetro)*numSalidas;	//kg neto por tocho
				
				var pesoTotal = longitudTotal*pesoPorMetro;
				
				var metrosTocho = (((pesoTotal / this.state.pesoTocho) + this.state.culote)*1000).toFixed(0);
			} while((metrosTocho>605&&numeroBarras>1)||this.state.medidaMaxima<medidaCorte*numeroBarras)
			this.state.ordenes[i].NTOCHOS = (kg/kgTocho).toFixed(2);
			this.state.ordenes[i].LONG_TOCHO = metrosTocho;
		}
	}
	
	getTipo(TIPO){
		switch(TIPO){
			case 'O': return 'OTROS';
			case 'EXTRUSIONADO': return 'EXTRUSIONADO';
			case 'N': return 'NITRURADO';
			case 'B': return 'BAJA';
		}
	}
	
	render() {
		return (
		<div>
			{this.state.imagen!=null&&
				<div style={{position:"fixed",width:"90%",marginTop:-250,zIndex:99}}>
					<a style={{cursor:'pointer'}} onClick={() => this.setState({imagen:null})}>
						<img alt="Imagen ampliada" src={this.state.imagen} style={{display: "block",marginLeft: "auto", marginRight: "auto", width: "65%"}}/>
					</a>
				</div>
			}
			{this.state.imagen!=null&&
				<a style={{cursor:'pointer'}} onClick={() => this.setState({imagen:null})}>
					<div style={{position:"fixed",width:"100%",height:"300%",margin:-300,paddingRight:260,backgroundColor:'#ccc',zIndex:98,opacity:0.9}}>
					
					</div>
				</a>
			}
		
			<div style={{float: "right",marginRight:50}}>
				<button type="button" onClick={this.getData} style={{marginLeft:50}}>{this.state.fabricar==null ? 'REFRESCAR' : '←'}</button>
			</div>
			<h2 style={{marginBottom:25}}>PROGRAMACIÓN DIARIA</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			
			
			{!this.state.loading&&this.state.fabricar!=null&&
				<div style={{width:"100%"}}>
					<div style={{marginBottom:30}}>
						{false&&
							<table className="tablaTareas">
								<thead>
									{this.state.ordenes[this.state.fabricar].IDPEDV!=null&&
										<tr style={{backgroundColor:"#339999",color:"#fff"}}>
											<td style={{width:"8%"}}>
												FECHA
											</td>
											<td style={{width:"8%"}}>
												ORDEN Nº
											</td>
											<td style={{width:"8%"}}>
												PEDIDO Nº
											</td>
											<td style={{width:"12%"}}>
												CÓD. CLIENTE
											</td>
											<td style={{width:"30%"}}>
												NOMBRE CLIENTE
											</td>
											<td style={{width:"34%"}}>
												OBSERVACIONES
											</td>
										</tr>
									}
									{this.state.ordenes[this.state.fabricar].IDPEDV==null&&
										<tr style={{backgroundColor:"#339999",color:"#fff"}}>
											<td style={{width:"8%"}}>
												FECHA
											</td>
											<td style={{width:"8%"}}>
												ORDEN Nº
											</td>
											<td style={{width:"8%"}}>
												ORDEN INTERNA DE
											</td>
											<td style={{width:"34%"}}>
												OBSERVACIONES
											</td>
										</tr>
									}
								</thead>
								<tbody>
									{this.state.ordenes[this.state.fabricar].IDPEDV!=null&&
										<tr>
											<td>
												{this.state.ordenes[this.state.fabricar].FECHA}
											</td>
											<td>
												{this.state.ordenes[this.state.fabricar].SERIE}/{this.state.ordenes[this.state.fabricar].NUMDOC}
											</td>
											<td>
												{this.state.ordenes[this.state.fabricar].SERIE_PEDIDO}/{this.state.ordenes[this.state.fabricar].NUMDOC_PEDIDO}
											</td>
											<td>
												{this.state.ordenes[this.state.fabricar].CODCLI}
											</td>
											<td>
												{this.state.ordenes[this.state.fabricar].NOMCLI}
											</td>
											<td>
												{this.state.ordenes[this.state.fabricar].OBSERVACIONES}
											</td>
										</tr>
									}
									{this.state.ordenes[this.state.fabricar].IDPEDV==null&&
										<tr>
											<td>
												{this.state.ordenes[this.state.fabricar].FECHA}
											</td>
											<td>
												{this.state.ordenes[this.state.fabricar].SERIE}/{this.state.ordenes[this.state.fabricar].NUMDOC}
											</td>
											<td>
												{this.state.ordenes[this.state.fabricar].NOMBRE}
											</td>
											<td>
												{this.state.ordenes[this.state.fabricar].OBSERVACIONES}
											</td>
										</tr>
									}
								</tbody>
							</table>
						}
						<table className="tablaTareas">
							<thead>
								<tr style={{backgroundColor:"#339999",color:"#fff"}}>
									<td>
										REF. PERFIL
									</td>
									<td>
										NOMBRE PERFIL
									</td>
									<td>
										kg
									</td>
									<td>
										MATRIZ
									</td>
									<td>
										LONG. PERFIL
									</td>
									<td>
										Nº TOCHOS
									</td>
									<td>
										LONG. TOCHO
									</td>
									<td>
										MAD.
									</td>
								</tr>
							</thead>
							<tbody style={{backgroundColor:this.state.fabricando==this.state.ordenes[this.state.fabricar].ID ? "#feffeb" : "#FFF"}}>
								<tr>
									<td>
										{this.state.ordenes[this.state.fabricar].CODART}
									</td>
									<td>
										{this.state.ordenes[this.state.fabricar].DESCART}
									</td>
									<td>
										{(this.state.ordenes[this.state.fabricar].KG_FAB+this.state.ordenes[this.state.fabricar].PENDIENTE).toFixed(0)} / {this.state.ordenes[this.state.fabricar].KG} kg
									</td>
									<td>
										{this.state.ordenes[this.state.fabricar].ARTALIAS}{this.state.matriz.NUMSERIE!=null&&this.state.fabricando==this.state.ordenes[this.state.fabricar].ID ? '-'+this.state.matriz.NUMSERIE : this.state.ordenes[this.state.fabricar].SERIE_MATRIZ!=''&&'-'+this.state.ordenes[this.state.fabricar].SERIE_MATRIZ}
									</td>
									<td>
										{this.state.ordenes[this.state.fabricar].LONG_CORTE} m
									</td>
									<td>
										{this.state.ordenes[this.state.fabricar].NTOCHOS}
									</td>
									<td>
										{this.state.ordenes[this.state.fabricar].LONG_TOCHO} mm
									</td>
									<td>
										{this.state.ordenes[this.state.fabricar].MADURACION}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					
					<div style={{width:"25%",float:"left"}}>
						{false&&
						<div style={{float:"left"}}>
							<div style={{marginTop:11,marginBottom:11}}><b>PERFIL</b></div>
							<div style={{width:250,height:250,border:"1px solid black",textAlign:'center'}}>
								{this.state.ordenes[this.state.fabricar].IMAGEN_PERFIL!=null&&this.state.ordenes[this.state.fabricar].IMAGEN_PERFIL!='data:image/png;base64,'&&
									<a style={{cursor:'pointer'}} onClick={() => this.setState({imagen:this.state.ordenes[this.state.fabricar].IMAGEN_PERFIL})}>
										<img alt="Imagen perfil" src={this.state.ordenes[this.state.fabricar].IMAGEN_PERFIL} style={{height:250}}/>
									</a>
								}
								{!(this.state.ordenes[this.state.fabricar].IMAGEN_PERFIL!=null&&this.state.ordenes[this.state.fabricar].IMAGEN_PERFIL!='data:image/png;base64,')&&
									<div style={{marginTop:38,fontSize:150,fontWeight:"bold"}}>
										?
									</div>
								}
							</div>
						</div>
						}
						<div style={{float:"left",marginLeft:50}}>
							<button type="button" onClick={() => this.abrirPlano()} style={{margin:10}}>ABRIR PLANO COMPLETO</button>
							<div style={{width:250,height:250,border:"1px solid black",textAlign:'center'}}>
								{this.state.ordenes[this.state.fabricar].IMAGEN_MATRIZ!=null&&this.state.ordenes[this.state.fabricar].IMAGEN_MATRIZ!='data:image/png;base64,'&&
									<a style={{cursor:'pointer'}} onClick={() => this.setState({imagen:this.state.ordenes[this.state.fabricar].IMAGEN_MATRIZ})}>
										<img alt="Imagen matriz" src={this.state.ordenes[this.state.fabricar].IMAGEN_MATRIZ} style={{height:250}}/>
									</a>
								}
								{!(this.state.ordenes[this.state.fabricar].IMAGEN_MATRIZ!=null&&this.state.ordenes[this.state.fabricar].IMAGEN_MATRIZ!='data:image/png;base64,')&&
									<div style={{marginTop:38,fontSize:150,fontWeight:"bold"}}>
										?
									</div>
								}
							</div>
						</div>
					</div>
					{this.state.fabricando>0&&this.state.fabricando==this.state.ordenes[this.state.fabricar].ID&&
						<div style={{width:"75%",float:"left"}}>
							<div style={{float:"right",marginRight:10}}>
								<button type="button" onClick={() => this.cancelarExtrusion()} style={{marginLeft:50}}>CANCELAR EXTRUSIÓN</button>
							</div>
							<p style={{fontSize:24}}><b>EXTRUSIÓN {this.state.extrusion.SERIE}/{this.state.extrusion.NUMDOC}</b></p>
							<p><b>OPERARIO:</b> {this.getNombreOperario()}</p>
							
							<p><b>BARRA DE TOCHO:</b> {this.state.tocho.CODART} - {this.state.tocho.DESCART}</p>
							{false&&<p><b>PESO DE LA BARRA DE TOCHO:</b> {this.state.tocho.PESO} kg/m - {(this.state.tocho.PESO*this.state.tocho.LONGITUD).toFixed(0)} kg/barra</p>}
								
							<p><b>PERFIL PENDIENTE DE EXTRUSIONAR:</b> {(this.state.ordenes[this.state.fabricar].KG-this.state.ordenes[this.state.fabricar].KG_FAB).toFixed(0)} kg</p>
							
							<p><b>TOCHOS EXTRUIDOS:</b></p>
							
							{this.state.extrusion.TOCHOS.map(({ UNIDADES,MEDIDA,COLADA,LOTE_PERFIL,INDICACIONES,FIJO },index) => (
								<p key={index}>
									{FIJO!=1&&
										<input
											type="number" 
											value={UNIDADES}
											onChange={(e) => {this.state.extrusion.TOCHOS[index].UNIDADES=e.target.value;this.setState({state: this.state})}}
											style={{marginRight:10,width:50}}
											min="0"
										/>
									}
									{FIJO==1&&
										UNIDADES+' '
									}
									{UNIDADES==1 ? 'tocho' : 'tochos'} de
									{FIJO!=1&&
										<input
											type="number" 
											value={MEDIDA}
											onChange={(e) => {this.state.extrusion.TOCHOS[index].MEDIDA=e.target.value;this.setState({state: this.state})}}
											style={{marginLeft:10,width:50}}
											min="0"
										/>
									}
									{FIJO==1&&
										' '+MEDIDA
									}
									mm
									{FIJO!=1&&
										<select name="coladas" id="coladas" onChange={(e) => this.seleccionarColada(e,index)} value={COLADA} style={{marginLeft:10,marginRight:3}}>
											<option value="colada" disabled selected="selected">Colada</option>
											{this.state.tocho.COLADAS.map(({ LOTE },index) => (
												<option key={index} value={LOTE}>{LOTE}</option>
											))}
										</select>
									}
									{FIJO==1&&
										' '+COLADA
									}
									{this.getLotePerfil(index)!=null&&<a>, nuestro lote <b>{this.getLotePerfil(index)}</b>, </a>}
									peso por tocho de {(this.state.tocho.PESO*this.state.extrusion.TOCHOS[index].MEDIDA/1000).toFixed(0)}kg, total de {(this.state.tocho.PESO*this.state.extrusion.TOCHOS[index].MEDIDA*this.state.extrusion.TOCHOS[index].UNIDADES/1000).toFixed(0)}kg
										
										{FIJO==1&&<button type="button" onClick={() => this.removeTocho(index)} style={{marginLeft:20}}>ELIMINAR</button>}
										{index==this.state.extrusion.TOCHOS.length-1&&this.state.extrusion.TOCHOS[index].COLADA!=null&&
					this.state.extrusion.TOCHOS[index].MEDIDA!=''&&this.state.extrusion.TOCHOS[index].UNIDADES!=0										
					? <button type="button" onClick={() => this.addTocho()} style={{marginLeft:20}}>GUARDAR Y AÑADIR LÍNEA</button> : ''}
								</p>
							))}
							<p><b>PESO TOTAL:</b> {(this.calcularKgExtrusion()).toFixed(0)} kg</p>
							
							<p><b>OBSERVACIONES:</b>
								<textarea
									value={this.state.extrusion.OBSERVACIONES}
									onChange={(e) => {this.state.extrusion.OBSERVACIONES=e.target.value;this.setState({state: this.state})}}
									style={{marginLeft:10,width:650}}
								/>
							</p>
							
							<div style={{textAlign:'center'}}>
								<button type="button" onClick={() => this.finalizarExtrusion()} style={{marginLeft:50}}>FINALIZAR EXTRUSIÓN</button>
							</div>
						</div>
					}
					{this.state.fabricando>0&&this.state.fabricando!=this.state.ordenes[this.state.fabricar].ID&&
						<div style={{width:"50%",float:"left"}}>
							<p style={{fontSize:24}}><b>SE ESTÁ EXTRUSIONANDO OTRO PERFIL</b></p>
						</div>
					}
					{this.state.ordenes[this.state.fabricar].ESTADO=='T'&&
						<div style={{width:"50%",float:"left"}}>
							<p style={{fontSize:24}}><b>ESTA LÍNEA DE LA ORDEN YA ESTÁ EXTRUSIONADA Y CERRADA</b></p>
						</div>
					}
					{this.state.fabricando==0&&
						<div style={{width:"50%",float:"left"}}>
							<select name="tareas" id="tareas" onChange={(e) => this.setState({operario: e.target.value})} value={this.state.operario}>
								<option value="operario" disabled selected="selected">Selecciona operario</option>
								{this.state.operarios.map(({ value,label },index) => (
									<option key={index} value={value}>{label}</option>
								))}
							</select>
							<button type="button" onClick={() => this.comenzarExtrusion()} style={{marginLeft:50}}>COMENZAR EXTRUSIÓN</button>
							<button type="button" onClick={() => this.cerrarExtrusion()} style={{marginLeft:30}}>DAR EXTRUSIÓN POR FINALIZADA</button>
							<p><b>PERFIL PENDIENTE DE EXTRUSIONAR:</b> {(this.state.ordenes[this.state.fabricar].KG-this.state.ordenes[this.state.fabricar].KG_FAB).toFixed(0)} kg</p>
							{false&&
								<p><b>COLADA DEL TOCHO:</b>	
									<input
										type="text" 
										value={this.state.ordenes[this.state.fabricar].COLADATOCHO}
										onChange={(e) => {this.state.ordenes[this.state.fabricar].COLADATOCHO=e.target.value;this.setState({state: this.state})}}
										style={{marginLeft:10,width:250}}
										maxlength="32"
									/>
								</p>
							}
							<select name="tochos" id="tochos" onChange={(e) => this.seleccionarTocho(e.target.value)} value={this.state.tocho.CODART}>
								<option value="tocho" disabled selected="selected">Selecciona el tocho</option>
								{this.state.tochos.map(({ value,label },index) => (
									<option key={index} value={value}>{label}</option>
								))}
							</select>
							
							<select name="tochos" id="tochos" onChange={(e) => this.seleccionarMatriz(e.target.value)} value={this.state.matriz.NUMSERIE}>
								<option value="tocho" disabled selected="selected">Selecciona la matriz</option>
								{this.state.matrices.map(({ ARTALIAS,CODART,NUMSERIE },index) => (
								this.state.ordenes[this.state.fabricar].CODART_MATRIZ==CODART &&
									<option key={index} value={NUMSERIE}>{ARTALIAS}-{NUMSERIE}</option>
								))}
							</select>
							{this.state.tocho.CODART!=null&&false&&
								<select name="coladas" id="coladas" onChange={(e) => this.seleccionarColada(e)} value={this.state.tocho.COLADA}>
									<option value="colada" disabled selected="selected">Selecciona la colada del tocho</option>
									{this.state.tocho.COLADAS.map(({ LOTE },index) => (
										<option key={index} value={LOTE}>{LOTE}</option>
									))}
								</select>
							}
						</div>
					}
					<img id="separador"/>
				</div>
			}
			
			
			{!this.state.loading&&this.state.ordenes.length>0&&this.state.fabricar==null&&
				<a>
					<table className="tablaTareas">
						<thead>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td>
									ORDEN
								</td>
								<td>
									REF. PERFIL
								</td>
								<td>
									NOMBRE PERFIL
								</td>
								<td>
									MATRIZ
								</td>
								<td>
									LONG. PERFIL
								</td>
								<td>
									kg
								</td>
								<td>
									Nº TOCHOS
								</td>
								<td>
									LONG. TOCHO
								</td>
							</tr>
						</thead>
						{this.state.ordenes.map(({ CERRADA,SERIE,NUMDOC,CODART,DESCART,KG,N_TOCHOS,LONG_TOCHO,LONG_CORTE,ARTALIAS,PESO,PARAM2,NTOCHOS,KG_FAB,CODART_MATRIZ,SERIE_MATRIZ,PENDIENTE },index) => ( CERRADA==0 &&
							<tbody key={index}>
								<tr  style={{backgroundColor:this.state.fabricando==this.state.ordenes[index].ID ? "#feffeb" : "#FFF"}} onClick={() => this.goLinea(index)}>
									<td>
										{SERIE}/{NUMDOC}
									</td>
									<td>
										{CODART}
									</td>
									<td>
										{DESCART}
									</td>
									<td>
										{SERIE_MATRIZ!='' ? ARTALIAS+'-'+SERIE_MATRIZ : ARTALIAS}
									</td>
									<td>
										{LONG_CORTE} m
									</td>
									<td>
										{(KG_FAB+PENDIENTE).toFixed(0)} / {KG} kg
									</td>
									<td>
										{NTOCHOS}
									</td>
									<td>
										{LONG_TOCHO} mm
									</td>
								</tr>
							</tbody>
						))}
					</table>
				</a>
			}
			{this.state.matrices.length>0&&this.state.fabricar!=null&&
				<a><p style={{marginTop:30}}><b>HISTÓRICO DE LAS MATRICES:</b></p>
				<table className="tablaTareas">
					<thead>
						<tr style={{backgroundColor:"#339999",color:"#fff"}}>
							<td>
								COD. MATRIZ
							</td>
							<td>
								MATRIZ
							</td>
							<td>
								DESCRIPCIÓN
							</td>
							<td style={{width:45}}>
							</td>
						</tr>
					</thead>
					{this.state.matrices.map(({ ARTALIAS,DESCART,CODART,NUMSERIE,UNIDADES,OPEN,SEARCH,CARGANDO,LINEAS,ADD,NUEVAS_OBSERVACIONES },index) => (
						this.filtrar(index)&& <tbody className="tablaEntrada" style={{backgroundColor: NUMSERIE==this.state.matriz.NUMSERIE ? "#cdeeee" : UNIDADES>0 ? "#FFF" : "#CCC"}} key={index}>
							<tr onClick={() => this.getLineas(index)} key={index}>
								<td>
									{CODART}
								</td>
								<td>
									{ARTALIAS}-{NUMSERIE}
								</td>
								<td>
									{DESCART}
									
								</td>
								<td onClick={() => this.doNothing()}>
									<button style={{marginRight:5,float:'right',height:34,width:34,fontSize:24}} type="button" onClick={()=> this.addRegistro(index)}>
										{!ADD&&
											<b>+</b>
										}
										{ADD&&
											<b>-</b>
										}
									</button>
								</td>
							</tr>
							{CARGANDO&&
								<tr style={{cursor:"auto"}}>
									<td colspan="3">
										<div style={{textAlign:"center"}}>
											<img alt="Loading..." src={loadingGif} style={{height:50,marginBottom:-20,marginTop:-20}}/>
										</div>
									</td>
								</tr>
							}
							{OPEN&&LINEAS.length==0&&
								<tr>
									<td colspan="4" style={{padding:0,textAlign:'center',backgroundColor:'#eee',color:"#000",cursor:"auto"}}>	
										<p>NO HAY REGISTROS NI EXTRUSIONES DE ESTA MATRIZ</p>
									</td>
								</tr>
							}
							{OPEN&&LINEAS.length>0&&
								<tr>
									<td colspan="4" style={{padding:0}}>
										<table className="tablaTareas">
											{LINEAS.map(({ FECHA,OBSERVACIONES,TIPO },index) => (
												<tbody key={index}>
													{index==0&&
														<tr style={{backgroundColor:"#339999",color:"#fff",cursor:"auto"}}>
															<td>
																FECHA
															</td>
															<td>
																TIPO DE REGISTRO
															</td>
															<td>
																OBSERVACIONES
															</td>
														</tr>
													}
													<tr style={{backgroundColor:"#eee",color:"#000",cursor:"auto"}}>
														<td>
															{FECHA}
														</td>
														<td>
															{this.getTipo(TIPO)}
														</td>
														<td>
															{TIPO=='EXTRUSIONADO' ? OBSERVACIONES+' kg' : OBSERVACIONES}
														</td>
													</tr>
												</tbody>
											))}
										</table>
									</td>
								</tr>
							}
							{ADD&&
								<tr style={{cursor:"auto",backgroundColor:"#cdeeee",color:"#000"}}>
									<td colspan="4" style={{padding:10}}>
										<b>OBSERVACIONES DEL NUEVO REGISTRO: </b>
										<textarea
											value={NUEVAS_OBSERVACIONES==null ? '' : NUEVAS_OBSERVACIONES}
											onChange={(e) => {this.state.matrices[index].NUEVAS_OBSERVACIONES=e.target.value;this.setState({state: this.state})}}
											style={{marginLeft:10,width:800,marginBottom:-18,height:50}}
										/>
										<button style={{marginLeft:5,fontSize:24,marginBottom:10}} type="button" onClick={()=> this.doAddRegistro(index)}>CREAR REGISTRO</button>
									</td>
								</tr>
							}
						</tbody>
					))}
					</table>
					
				</a>
			}
			<div style={{height:250}}>
			</div>
      </div>
    );
  }
}
 
export default Salidas;