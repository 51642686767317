import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';

class Salidas extends Component {
	
	constructor(props) {
		super(props);
		this.handleRowBackground = this.handleRowBackground.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onSubmitNothing = this.onSubmitNothing.bind(this);
	}
	
	state = {
		username:'',
		password:'',
		loggedIn: false,
		startDate: null,
		endDate: null,
		focusedInput: null,
		loading:false,
		inventarios: [],
		imagen: null,
		filtro: '',
	};
	
	componentDidMount(){
		setInterval(() => this.handleSubmit(null), 300000)
	}
	
	onSubmitNothing(event){
		event.preventDefault();
	}
	
	doNothing(day){
		//console.log(day);
	}
	
	handleRowBackground(e){
		console.log(e);
	}
	
	filaClick(ID){
		console.log(ID);
	}
	
	async handleSubmit(event){
		
		if(this.state.startDate==null)
			return;
		
		var today=""+(this.state.startDate._d.getMonth()+1)+"/"+this.state.startDate._d.getDate()+"/"+this.state.startDate._d.getFullYear();
		if(this.state.endDate!=null)
			var tomorrow = ""+(this.state.endDate._d.getMonth()+1)+"/"+this.state.endDate._d.getDate()+"/"+this.state.endDate._d.getFullYear();
		else var tomorrow= today;
		
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			date: tomorrow,
			yesterday: today,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/inyeccion/get_inventarios_material",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Se ha producido un error');
									return;
								}
								this.state.inventarios = result[0];
								this.state.inventarios.forEach(orden => {orden.SEARCH = false;orden.OPEN=false;orden.LOADING = false});
								this.setState({loading: false });
							})
							
		.catch((error) => {
			console.error(error);
		});

	}
	
	openLineas(index){
		if(this.state.inventarios[index].OPEN)
			this.state.inventarios[index].OPEN = false;
		else {
			if(this.state.inventarios[index].SEARCH)
				this.state.inventarios[index].OPEN = true;
			else {
				this.getLineas(index);
				return;
			}
		}
		this.setState({state: this.state});
	}
	
	async getLineas(index){
		
		this.state.inventarios[index].LOADING = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			id: this.state.inventarios[index].ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/inyeccion/get_lineas_inventario_inyeccion",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Se ha producido un error');
									return;
								}
								this.state.inventarios[index].LOADING = false;
								this.state.inventarios[index].OPEN = true;
								this.state.inventarios[index].SEARCH = true;
								this.state.inventarios[index].LINEAS = result[0];
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	render() {
		return (
		<div>
			<div style={{float: "right",marginRight:50}}>
				<DateRangePicker
					startDate={this.state.startDate} // momentPropTypes.momentObj or null,
					startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
					endDate={this.state.endDate} // momentPropTypes.momentObj or null,
					endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
					onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
					focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
					startDatePlaceholderText= "Desde"
					endDatePlaceholderText= "Hasta"
					isOutsideRange={(day) => this.doNothing(day)}
					displayFormat="DD/MM/yyyy"
					firstDayOfWeek={1}
				/>
				<button type="button" onClick={this.handleSubmit} style={{marginLeft:50}}>BUSCAR</button>
			</div>
			<h2 style={{marginBottom:25}}>INVENTARIOS</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.inventarios.length>0&&<a>
				<table className="tablaEntrada">
					<tr style={{backgroundColor:"#339999",color:"#fff"}}>
						<td style={{width:"25%"}}>
							FECHA
						</td>
						<td style={{width:"50%"}}>
							OPERARIO
						</td>
						<td style={{width:"25%"}}>
							TOTAL
						</td>
					</tr>
					</table>
				{this.state.inventarios.map(({ ID,TOTAL,NOMBRE,FECHA,LINEAS,OPEN,SEARCH,SHOW,LOADING },index) => (
					<a key={ID}>
					<table className="tablaEntrada" style={OPEN&&SHOW ? {backgroundColor:"#cdeeee"} : {backgroundColor:"#FFF"}}>
						<tr key={ID} onClick={() => this.openLineas(index)}>
							<td style={{width:"25%"}}>
								{FECHA}
							</td>
							<td style={{width:"50%"}}>
								{NOMBRE}
							</td>
							<td style={{width:"25%"}}>
								{Math.round(TOTAL*100)/100} €
							</td>
						</tr>
						</table>
						<table className="tablaLineas">
							{LOADING&&
								<div style={{textAlign:"center"}}>
									<img alt="Loading..." src={loadingGif} style={{height:150}}/>
								</div>
							}
							{OPEN&&!LOADING&&LINEAS.length>0&&
								<tr style={{backgroundColor:"#339999",color:"#fff"}}>
									<td>COD. ART.</td>
									<td>ALIAS</td>
									<td>DESCRIPCIÓN</td>
									<td>CANTIDAD</td>
								</tr>
							}
							{OPEN&&!LOADING&&LINEAS.map(({CODART,ARTALIAS,CANTIDAD,DESCART},index) => (
								<tr key={index}>
									<td>{CODART}</td>
									<td>{ARTALIAS}</td>
									<td>{DESCART}</td>
									<td>{CANTIDAD} kg</td>
								</tr>
							))}
						</table>
					</a>
				))}
				</a>
			}
      </div>
    );
  }
}
 
export default Salidas;