import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';

class Salidas extends Component {
	
	constructor(props) {
		super(props);
		this.onSubmitNothing = this.onSubmitNothing.bind(this);
	}
	
	state = {
		username:'',
		password:'',
		loading:true,
		clientes: [],
	};
	
	componentDidMount(){
		this.getData();
	}
	
	doNothing(day){
		//console.log(day);
	}
	
	onSubmitNothing(event){
		event.preventDefault();
	}
	
	async getData(event){
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/listado_clientes/almacenes_virtuales",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==1)
									this.state.clientes = result.CLIENTES;
								else alert("Se ha producido algún error...")
								this.setState({loading: false });
							})
							
		.catch((error) => {
			console.error(error);
		});

	}

	async getLineas(index){
		
		if(this.state.clientes[index].LINEAS!=null){
			this.state.clientes[index].OPEN = !this.state.clientes[index].OPEN;
			this.setState({state:this.state});
			return;
		}

		this.state.clientes[index].LOADING = true;
		this.setState({state:this.state});


		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODCLI: this.state.clientes[index].CODCLI,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/listado_clientes/get_lineas_virtuales",data)
							.then(result => result.json())
							.then(result => {
								if(result.DONE==1){
									this.state.clientes[index].LINEAS = result.LINEAS;
									this.state.clientes[index].LOADING = false;
									this.state.clientes[index].OPEN = true;
								}
								else alert("Se ha producido algún error buscando las líneas...")
								this.setState({loading: false });
								
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	render() {
		return (
		<div>
			<h2 style={{marginBottom:25}}>LISTADO DE ALMACENES VIRTUALES</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.clientes.length>0&&<a>
				<table className="tablaEntrada">
					<tr style={{backgroundColor:"#339999",color:"#fff"}}>
						<td style={{width:"10%"}}>
							CÓDIGO CLIENTE
						</td>
						<td style={{width:"45%"}}>
							NOMBRE COMERCIAL
						</td>
						<td style={{width:"45%"}}>
							RAZÓN SOCIAL
						</td>
					</tr>
					</table>
				{this.state.clientes.map(({ CODCLI,NOMCLI,RAZON,OPEN,LINEAS,LOADING },index) => (
					<a key={index}>
					<table className="tablaEntrada" style={{backgroundColor:"#FFF"}}>
						<tr onClick={() => this.getLineas(index)}>
							<td style={{width:"10%"}}>
								{CODCLI}
							</td>
							<td style={{width:"45%"}}>
								{NOMCLI}
							</td>
							<td style={{width:"45%"}}>
								{RAZON}
							</td>
						</tr>
						</table>
						<table className="tablaLineas">
							{OPEN&&!LOADING&&
								<tr style={{backgroundColor:"#339999",color:"#fff"}}>
									<td style={{width:"10%"}}>
										CÓDIGO ARTÍCULO
									</td>
									<td style={{width:"45%"}}>
										DESCRIPCIÓN ARTÍCULO
									</td>
									<td style={{width:"15%"}}>
										UDS. ENVIADAS/TOTALES
									</td>
									<td style={{width:"15%"}}>
										ALBARÁN
									</td>
									<td style={{width:"15%"}}>
										FECHA
									</td>
								</tr>
							}
							{LOADING&&
								<div style={{textAlign:"center"}}>
									<img alt="Loading..." src={loadingGif} style={{height:150}}/>
								</div>
							}
							{OPEN&&!LOADING&&LINEAS.map(({CODART,DESCLIN,RESTO_VIRTUALES,VIRTUALES,SERIE,NUMDOC,FECHA},index) => (
									<tr>
										<td style={{width:"10%"}}>{CODART}</td>
										<td style={{width:"40%"}}>{DESCLIN}</td>
										<td style={{width:"13%"}}>{RESTO_VIRTUALES}/{VIRTUALES}</td>
										<td style={{width:"13%"}}>{SERIE}/{NUMDOC}</td>
										<td style={{width:"13%"}}>{FECHA}</td>
									</tr>
							))}
						</table>
					</a>
				))}
				</a>
			}
      </div>
    );
  }
}
 
export default Salidas;