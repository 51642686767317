import React, { Component } from "react";
import 'react-dates/initialize';
import {
  Route,
  NavLink,
  HashRouter,
  Link
} from "react-router-dom";
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';
import crearPNG from './assets/plus_icon.png';
import detailsPNG from './assets/details_icon.png';

class Salidas extends Component {
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.changePrioridad = this.changePrioridad.bind(this);
		this.changeTipo = this.changeTipo.bind(this);
		
	}
	
	state = {
		username:'',
		password:'',
		incidencias: [],
		operarios: [],
		maquinas: [],
		departamentos: [],
		dpto: null,
		loading: true,
		file: null,
		IMAGENES: [],
		incidencia: -1,
		prioridad: -1,
		tipo: null,
		maquina: null,
		destinatario: null,
		busquedaProv: '',
		proveedores: [],
		articulos: [],
		busquedaArt: '',
		proveedor: null,
		articulosBus: [],
		descripcion:'',
		asunto:'',
		inhabilitar: false,
		devolucion: false,
		clickando: false,
	};
	
	componentDidMount(){
		this.handleSubmit();
	}
	
	doNothing(day){
		console.log("Llego");
		//console.log(day);
	}
	
	changeHandler(event){
		if(event.target.files.length>0)
			this.state.IMAGENES.push({IMG: URL.createObjectURL(event.target.files[0]),file: event.target.files[0]});
		this.setState({state:this.state});
	}
	
	changePrioridad(event){
		this.setState({prioridad: event.target.value});
	}
	
	changeTipo(event){
		this.setState({devolucion:false,maquina:null, proveedor: null,tipo: event.target.value,proveedor:null,busquedaProv:''});
	}
	
	async handleSubmit(event){
		
		if(this.state.startDate==null){
			var today = ((new Date().getMonth() + 1)+"/"+new Date().getDate()+"/"+new Date().getFullYear());
			var tomorrow = today;
		}
		else{
				var today=""+(this.state.startDate._d.getMonth()+1)+"/"+this.state.startDate._d.getDate()+"/"+this.state.startDate._d.getFullYear();
			if(this.state.endDate!=null)
				var tomorrow = ""+(this.state.endDate._d.getMonth()+1)+"/"+this.state.endDate._d.getDate()+"/"+this.state.endDate._d.getFullYear();
			else var tomorrow= today;
		}
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			date: tomorrow,
			yesterday: today,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/incidencias/get_incidencias",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Error incidencias...');
									return;
								}
								this.state.incidencias = result[0];
								this.state.dpto = result[1][0].DEPARTAMENTO;
								this.state.incidencias.forEach( incidencia => {
									incidencia.ARTICULOS = [];
									incidencia.OPEN_ANSWER = false;
									incidencia.ANSWER = {
										operario: null,
										receptor: null,
										descripcion: '',
										IMAGENES: [],
									}
									incidencia.LINEAS = [];
									incidencia.OPEN = false;
									incidencia.SEARCH = false;
								});
								
								this.setState({loading: false });
							})
							
		.catch((error) => {
			console.error(error);
		});
		
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/incidencias/maquinas_personal",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								this.state.maquinas = result[0];
								this.state.operarios = result[1];
								this.state.departamentos = result[2];
								//this.state.departamentos.push({value:'0',label:'NINGUNO - FINALIZAR'});
								
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
		
	}
	
	async getLineas(index){
		if(this.state.clickando){
			this.state.clickando = false;
			return;
		}
		if(this.state.incidencias[index].SEARCH){
			this.state.incidencias[index].OPEN = !this.state.incidencias[index].OPEN;
			this.setState({state:this.state });
			return;
		}
		
		this.state.incidencias[index].LOADING = true;
		this.setState({state: this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			IDINCI: this.state.incidencias[index].ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/incidencias/get_detalles_incidencia",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								this.state.incidencias[index].LOADING = false;
								this.state.incidencias[index].ARTICULOS = result[0];
								this.state.incidencias[index].LINEAS = result[1];
								this.state.incidencias[index].OPEN = true;
								this.state.incidencias[index].SEARCH = true;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	quitarImagen(index){
		this.state.IMAGENES.splice(index,1);
		this.setState({state: this.state });
	}
	
	openAnswer(index){
		this.state.clickando = true;
		this.setState({incidencia: index,creandoTarea:true});
	}
	
	goToNoConformidad(index){
		
	}
	
	async buscarArticulo(){
		this.setState({loading: true });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			busqueda: this.state.busquedaArt,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/buscar_producto",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								result.forEach(articulo => articulo.CANTIDAD=1);
								this.state.articulosBus = result;
								
								this.setState({loading: false });
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async buscarProveedor(){
		this.setState({loading: true });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			busqueda: this.state.busquedaProv,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/buscar_proveedor",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								this.state.proveedores = result;
								this.setState({loading: false });
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	quitarArticulo(index){
		this.state.articulos.splice(index,1);
		this.setState({state: this.state });
	}
	
	crearIncidencia(){
		
		if(this.state.devolucion)
			if(this.state.articulos.length==0){
				alert('Para generar una devolución necesitas indicar al menos un artículo.');
				return;
			}

		if(this.state.descripcion==''||this.state.destinatario==null||this.state.titulo==''||this.state.prioridad==-1||this.state.tipo==null){
			alert('Rellena todos los datos obligatorios (destinatario, título, prioridad, tipo y descripción)');
			return;
		}
		window.confirm('¿Estás seguro? Vas a crear una nueva incidencia y se notificará a los destinatarios correspondientes.') ? this.doCrearIncidencia() : this.doNothing();
	}
	
	mandarRespuesta(){
		if(this.state.descripcion==''||this.state.destinatario==null){
			alert('Rellena todos los datos obligatorios (destinatario y descripción)');
			return;
		}
		
		window.confirm('¿Estás seguro? Vas a contestar a la incidencia seleccionada y se notificará, en su caso, a los destinatarios correspondientes.') ? this.doMandarRespuesta() : this.doNothing();
	}
	
	
	async doCrearIncidencia(){
		
		this.setState({printing:true });
		
		let formData = new FormData();
		formData.append('user', sessionStorage.getItem('user'));
		formData.append('receptor', this.state.destinatario);
		formData.append('descripcion', this.state.descripcion);
		formData.append('titulo', this.state.asunto);
		formData.append('token',sessionStorage.getItem('token'));
		formData.append('tipo', this.state.tipo);
		formData.append('prioridad', this.state.prioridad);
		formData.append('devolucion',this.state.devolucion);
		if(this.state.inhabilitar)
			formData.append('inhabilita', 1);
		if(this.state.proveedor!=null)
			formData.append('codpro', this.state.proveedor.CODPRO);
		else formData.append('codpro', null);
		formData.append('codmaq', this.state.maquina);
		formData.append('productos', JSON.stringify(this.state.articulos));
		for(var i=0;i<this.state.IMAGENES.length;i++){
			formData.append('file',this.state.IMAGENES[i].file)
		}

		
		let data ={
			method: 'POST',
			headers: {
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: formData
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/incidencias/crear_incidencia",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.resultado==1){
									alert('Datos enviados');
									this.setState({devolucion:false,tipo:null,prioridad:-1,incidencia: -1,inhabilitar:false,creandoTarea:!this.state.creandoTarea,destinatario:null,asunto:'',descripcion:'',IMAGENES:[],articulos:[]});
									this.handleSubmit();
									return;
								}
								else alert('Algo ha salido mal');
								this.setState({printing: false });
								
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async doMandarRespuesta(){
		
		this.setState({printing:true });
		
		let formData = new FormData();
		formData.append('user', sessionStorage.getItem('user'));
		formData.append('receptor', this.state.destinatario);
		formData.append('descripcion', this.state.descripcion);
		formData.append('ID', this.state.incidencias[this.state.incidencia].ID);
		formData.append('token',sessionStorage.getItem('token'));
		formData.append('devolucion',this.state.devolucion);
		
		for(var i=0;i<this.state.IMAGENES.length;i++){
			formData.append('file',this.state.IMAGENES[i].file)
		}
		
		/*
		
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'multipart/form-data',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
		*/
		
		let data ={
			method: 'POST',
			headers: {
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: formData
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/incidencias/crear_linea_incidencia",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.resultado==1){
									alert('Datos enviados');
									this.setState({devolucion:false,tipo:null,prioridad:-1,incidencia: -1,inhabilitar:false,creandoTarea:!this.state.creandoTarea,destinatario:null,asunto:'',descripcion:'',IMAGENES:[],articulos:[]});
									this.handleSubmit();
									return;
								}
								else alert('Algo ha salido mal');
								this.setState({printing: false });
								
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	render() {
		return (
		<div>
			<div style={{float: "right",marginRight:50}}>
				<DateRangePicker
					startDate={this.state.startDate} // momentPropTypes.momentObj or null,
					startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
					endDate={this.state.endDate} // momentPropTypes.momentObj or null,
					endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
					onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
					focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
					startDatePlaceholderText= "Desde"
					endDatePlaceholderText= "Hasta"
					isOutsideRange={(day) => this.doNothing(day)}
					displayFormat="DD/MM/yyyy"
					firstDayOfWeek={1}
				/>
				<button type="button" onClick={this.handleSubmit} style={{marginLeft:50}}>BUSCAR</button>
			</div>
			{!this.state.creandoTarea&&
			<div style={{float: "right",margin:13}}>
				<button type="button" onClick={() => this.setState({inhabilitar:false,tipo:null,prioridad:-1,incidencia: -1,creandoTarea:!this.state.creandoTarea,destinatario:null,asunto:'',descripcion:'',IMAGENES:[],articulos:[]})} style={{marginLeft:50}}>{!this.state.creandoTarea ? 'CREAR NUEVA INCIDENCIA' : 'CANCELAR'}</button>
			</div>
			}
			<h2 style={{marginBottom:25}}>INCIDENCIAS</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{this.state.creandoTarea&&
				<div style={{marginBottom:30}}>
					<div style={{width:'100%'}}>
						{this.state.incidencia==-1&&
							<input
								type="text" 
								value={this.state.asunto}
								onChange={(e) => this.setState({asunto: e.target.value})}
								placeholder="Título de la incidencia"
								style={{margin:10,width:'20%'}}
								maxlength="32"
							/>
						}
						<select style={{margin:10}} name="tareas" id="tareas" onChange={(e) => this.setState({devolucion:false,destinatario: e.target.value})}>
							<option value="destinatario" disabled selected="selected">Selecciona el destinatario</option>
							{this.state.departamentos.map(({ value,label },index) => (
								<option value={value}>{label}</option>	
							))}
							{this.state.incidencia!=-1&&
								<option value={0}>NINGUNO - FINALIZAR</option>
							}
						</select>
						<label for="image_uploads" style={{cursor: 'pointer'}}><button type="button" style={{pointerEvents: 'none'}}>Seleccionar imagen (opcional)</button></label>
						{this.state.incidencia!=-1&&this.state.incidencias[this.state.incidencia].NOMPRO!=null&&this.state.incidencias[this.state.incidencia].ARTICULOS.length>0&&this.state.destinatario=='COMPRAS'&&
							<label style={{marginLeft:10}}>
								<input type="checkbox" 
									checked={this.state.devolucion}
									onChange={(text) => this.setState({devolucion: !this.state.devolucion})}
								/>
								Crear devolución
							</label>
						}
						<button type="button" onClick={() => this.state.incidencia==-1 ? this.crearIncidencia() : this.mandarRespuesta()} style={{marginLeft:50,marginRight:10}}>{this.state.incidencia==-1 ? "CREAR INCIDENCIA" : "MANDAR RESPUESTA"}</button>
						<input type="file" id="image_uploads" name="file" onChange={this.changeHandler} style={{display:'none'}} />
						<button type="button" onClick={() => this.setState({tipo:null,prioridad:-1,incidencia:-1,inhabilitar:false,creandoTarea:!this.state.creandoTarea,destinatario:null,asunto:'',descripcion:''})} style={{marginLeft:10}}>{!this.state.creandoTarea ? 'CREAR NUEVA INCIDENCIA' : 'CANCELAR'}</button>
					</div>
					{this.state.incidencia==-1&&
					<div style={{width:'100%'}}>
						<div style={{width:'100%'}}>
							<div onChange={this.changePrioridad} style={{marginBottom:10,float:'left'}}>
								<a style={{fontWeight:'bold'}}>Prioridad: </a>
								<input type="radio" id="alta" name="prioridad" value="2"/>
								<label for="alta">Alta</label>
								<input type="radio" id="media" name="prioridad" value="1"/>
								<label for="media">Media</label>
								<input type="radio" id="baja" name="prioridad" value="0"/>
								<label for="baja">Baja</label>
							</div>
							<div onChange={this.changeTipo} style={{marginLeft:50,marginBottom:10,float:'left'}}>
								<a style={{fontWeight:'bold'}}>Tipo: </a>
								<input type="radio" id="interna" name="tipo" value="I"/>
								<label for="interna">Interna (Forta)</label>
								<input type="radio" id="externa" name="tipo" value="E"/>
								<label for="externa">Externa (proveedor)</label>
							</div>
							{this.state.tipo=='I'&&
								<div>
									<select style={{margin:10,marginTop:0,float:'left'}} name="maquinas" id="maquinas" onChange={(e) => this.setState({maquina: e.target.value})}>
										<option value="maquina" disabled selected="selected">Selecciona máquina (opcional)</option>
										{this.state.maquinas.map(({ value,label },index) => (
											<option value={value}>{label}</option>	
										))}
									</select>
									<label style={{marginRight:20}}>
										<input type="checkbox" 
											checked={this.state.inhabilitar}
											onChange={(text) => this.setState({inhabilitar: !this.state.inhabilitar})}
										/>
										¿La máquina queda inhabilitada?
									</label>
								</div>
								
							}
							{this.state.tipo=='E'&&
								<div style={{margin:10,marginTop:0,marginBottom:0,float:'left'}}>
									{this.state.proveedor==null&&
										<form onSubmit={() => this.buscarProveedor()}>
											<input
												type="text" 
												value={this.state.busquedaProv}
												onChange={(e) => this.setState({busquedaProv: e.target.value})}
												placeholder="Busca proveedor (opcional)"
												style={{width:'250px',margin:10,marginTop:0,marginBottom:0}}
												maxlength="32"
											/>
										</form>
									}
									{this.state.proveedor!=null&&
										<div style={{float:'left'}}>
											<a style={{margin:10,marginTop:0,border: "1px solid black",padding:3,marginBottom:0}}>{this.state.proveedor.NOMPRO}</a>
											<button type="button" onClick={() => this.setState({proveedor:null,busquedaProv:null,devolucion:false})} style={{}}>X</button>
										</div>
									}
									{this.state.proveedor!=null&&this.state.destinatario=='COMPRAS'&&
										<label style={{marginLeft:10,float:'left'}}>
											<input type="checkbox" 
												checked={this.state.devolucion}
												onChange={(text) => this.setState({devolucion: !this.state.devolucion})}
											/>
											Crear devolución
										</label>
									}
								</div>
							}
							{this.state.tipo=='E'&&this.state.proveedores.length>0&&
								<table className="tablaEntrada">
									<thead>
										<tr style={{backgroundColor:"#339999",color:"#fff"}}>
											<td>
												COD.PRO.
											</td>
											<td>
												NOMBRE PROVEEDOR
											</td>
											<td>
												RAZÓN SOCIAL PROVEEDOR
											</td>
										</tr>
									</thead>
									<tbody>
										{this.state.proveedores.map(({RAZON,NOMPRO,CODPRO},index) => (
											<tr key={index} onClick={() => this.setState({proveedor:this.state.proveedores[index],proveedores:[]})}>
												<td>
													{CODPRO}
												</td>
												<td>
													{NOMPRO}
												</td>
												<td>
													{RAZON}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							}
						</div>
						<br></br><br></br>
						<div style={{width:'100%'}}>
							<a style={{fontWeight:'bold',float:'left'}}>Artículos afectados: </a>
							<form onSubmit={() => this.buscarArticulo()}>
								<input
									type="text" 
									value={this.state.busquedaArt}
									onChange={(e) => this.setState({busquedaArt: e.target.value})}
									placeholder="Busca artículo (opcional)"
									style={{width:'20%',margin:10,marginTop:0,marginBottom:0}}
									maxlength="32"
								/>
							</form>
							
							{this.state.articulos.length>0&&
								<div style={{margin:20}}>
									<table className="tablaTareas">
										<thead>
											<tr style={{backgroundColor:"#339999",color:"#fff"}}>
												<td>
													COD.ART.
												</td>
												<td>
													DESCRIPCIÓN
												</td>
												<td>
													CANTIDAD
												</td>
												<td style={{textAlign:'center'}}>
													QUITAR
												</td>
											</tr>
										</thead>
										<tbody>
											{this.state.articulos.map(({CODART,DESCART,CANTIDAD},index) => (
												<tr key={index}>
													<td>
														{CODART}
													</td>
													<td>
														{DESCART}
													</td>
													<td>
														<input
															type="number" 
															value={this.state.articulos[index].CANTIDAD}
															onChange={(e) => {this.state.articulos[index].CANTIDAD=e.target.value.replace('.','').replace(',','');this.setState({state: this.state})}}
														/>
													</td>
													<td style={{textAlign:'center'}}>
														<button type="button" onClick={()=>this.quitarArticulo(index)} style={{textAlign:'center'}}>X</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							}
							
							{this.state.articulosBus.length>0&&
								<table className="tablaEntrada">
									<thead>
										<tr style={{backgroundColor:"#339999",color:"#fff"}}>
											<td>
												COD.ART.
											</td>
											<td>
												DESCRIPCIÓN
											</td>
										</tr>
									</thead>
									<tbody>
										{this.state.articulosBus.map(({CODART,DESCART},index) => (
											<tr key={index} onClick={() => {this.state.articulos.push(this.state.articulosBus[index]);this.setState({articulosBus:[],busquedaArt:''})}}>
												<td>
													{CODART}
												</td>
												<td>
													{DESCART}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							}
						</div>
					</div>
					}
					<textarea 
						type="text" 
						value={this.state.descripcion}
						onChange={(e) => this.setState({descripcion: e.target.value})}
						placeholder="Descripción de la incidencia"
						style={{marginTop:10}}
					/>
					{this.state.IMAGENES.map(({IMG},index) => (
						<div key={index} style={{float:'left'}}>
							<img src={IMG} style={{maxHeight:'350px',padding:5,marginRight:-50}} />
							<button type="button" onClick={() => this.quitarImagen(index)} style={{marginBottom:20}}>QUITAR</button>
						</div>
					))}
				</div>
			}
			{!this.state.loading&&this.state.incidencias.length>0&&
				<a>
					<table className="tablaEntrada">
						<thead>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td style={{width:"8%"}}>
									FECHA
								</td>
								<td style={{width:"6%"}}>
									NºDOC
								</td>
								<td style={{width:'2%',textAlign:"center"}}>
									!
								</td>
								<td style={{width:"12%"}}>
									CREADA POR
								</td>
								<td style={{width:"12%"}}>
									LA TIENE
								</td>
								<td style={{width:"14%"}}>
									TÍTULO
								</td>
								<td style={{width:"6%"}}>
									TIPO
								</td>
								<td style={{width:"11%"}}>
									MÁQUINA
								</td>
								<td style={{width: this.state.dpto=='INGENIERÍA' ? "15%" : "25%"}}>
									PROVEEDOR
								</td>
								{this.state.dpto=='INGENIERÍA'&&
									<td style={{width:"10%"}}>
										Inf. No conf.
									</td>
								}
								<td style={{width:"4%"}}>
									
								</td>
							</tr>
						</thead>
					</table>
					{this.state.incidencias.map(({ NOMBRE_ACTUAL,ID,TITULO,DESCRIPCION,FECHA_INCI,TIPO,DESCMAQ,NOMPRO,CODPRO,EMISOR,SERIE,NUMDOC,ESTADO,NOMBRE,PRIORIDAD,LOADING,OPEN,SEARCH,LINEAS,ARTICULOS,RECEPTOR,INFORMES },index) => (
						<a key={ID}>
							<table className="tablaEntrada" style={this.state.incidencia==index ? {backgroundColor:'#fffe9c'} : ESTADO=='T' ? {backgroundColor:"#cdeeee"} : RECEPTOR!=this.state.dpto ? {backgroundColor:'#DDD'} : {backgroundColor:"#FFF"}}>
								<tr key={ID}  onClick={() => this.getLineas(index)}>
									<td style={{width:"8%"}}>
										{FECHA_INCI}
									</td>
									<td style={{width:"6%"}}>
										{SERIE}/{NUMDOC}
									</td>
									<td style={PRIORIDAD==2 ? {width:"2%",backgroundColor:"#ff0000"} : PRIORIDAD==1 ? {width:"2%",backgroundColor:"#ffff00"} : {width:"2%",backgroundColor:"#0000ff"}}>
									
									</td>
									<td style={{width:"12%"}}>
										{NOMBRE}
									</td>
									<td style={{width:"12%"}}>
										{NOMBRE_ACTUAL}
									</td>
									<td style={{width:"14%"}}>
										{TITULO}
									</td>
									<td style={{width:"6%"}}>
										{TIPO=='I' ? 'Interna' : 'Externa'}
									</td>
									<td style={{width:"11%"}}>
										{DESCMAQ}
									</td>
									<td style={{width: this.state.dpto=='INGENIERÍA' ? "15%" : "25%"}}>
										{NOMPRO}
									</td>
									{this.state.dpto=='INGENIERÍA'&&CODPRO!=null&&
										<td style={{textAlign:'center',width:"10%"}}>
											{ESTADO=='A'&&this.state.dpto=='INGENIERÍA'&&CODPRO!=null&&ARTICULOS.length>0&&
												<Link to={{pathname:"NoConformidad",incidencia:this.state.incidencias[index]}}>
													<img alt="Crear informe de no conformidad" src={crearPNG} style={{float:'left',marginLeft:5}}/>
												</Link>
											}
											{ESTADO=='A'&&this.state.dpto=='INGENIERÍA'&&CODPRO!=null&&ARTICULOS.length==0&&
												<img alt="Crear informe de no conformidad" src={crearPNG} style={{opacity:0.5,float:'left',marginLeft:5}}/>
											}
											{ESTADO=='A'&&this.state.dpto=='INGENIERÍA'&&CODPRO!=null&&INFORMES.map(({ ID },index) => (
												<Link to={{pathname:"NoConformidad",ID:ID}}>
													<img alt="Ver detalles del informe de no conformidad" src={detailsPNG} style={{float:'left',marginLeft:5}}/>
												</Link>
											))}
										</td>
									}
									<td style={{textAlign:'center',width:"4%"}}>
										{ESTADO=='A'&&RECEPTOR==this.state.dpto&&
											<button type="button" onClick={()=>this.openAnswer(index)} style={{textAlign:'center'}}>&#10149;</button>
										}
									</td>
								</tr>
							</table>
							{OPEN&&ARTICULOS.length>0&&
								<table className="tablaLineas">
									<thead>
										<tr style={{backgroundColor:"#339999",color:"#fff"}}>
											<td>COD.ART.</td>
											<td>DESCRIPCIÓN ARTÍCULO</td>
											<td>CANTIDAD</td>
										</tr>
									</thead>
								{OPEN&&!LOADING&&ARTICULOS.map(({CANTIDAD,CODART,DESCART,IDINCI},index) => (
										<tr>
											<td>{CODART}</td>
											<td>{DESCART}</td>
											<td>{CANTIDAD}</td>
										</tr>
									))}
								</table>
							}
							<table className="tablaLineas">
								{LOADING&&
									<div style={{textAlign:"center"}}>
										<img alt="Loading..." src={loadingGif} style={{height:150}}/>
									</div>
								}
								{OPEN&&!LOADING&&LINEAS.length>0&&
									<thead>
										<tr style={{backgroundColor:"#339999",color:"#fff"}}>
											<td>DE</td>
											<td>PARA</td>
											<td>FECHA</td>
											<td>DESCRIPCION</td>
											<td>IMÁGENES</td>
										</tr>
									</thead>
								}
								{OPEN&&!LOADING&&LINEAS.map(({CODEMP,DESCRIPCION,ESTADO,FECHA,ID,IDINCI,IMAGENES,NOMBRE,RECEPTOR_DPTO},index) => (
									<tr>
										<td>{NOMBRE}</td>
										<td>{RECEPTOR_DPTO==0 ? '-' : RECEPTOR_DPTO}</td>
										<td>{FECHA}</td>
										<td>{DESCRIPCION}</td>
										<td style={{textAlign:'center'}}>
											{IMAGENES.map(({IMG},index) => (
												<img key={index} src={IMG} style={{maxHeight:'350px',padding:5}} />
											))}
										</td>
									</tr>
								))}
								
							</table>
						</a>
					))}
					
				</a>
			}
      </div>
    );
  }
}
 
export default Salidas;