import React, { Component } from "react";
import editIcon from './assets/details_icon.png';
import closeIcon from './assets/close_icon.png';
import editIconSmall from './assets/edit_icon_small.png';
import backIcon from './assets/arrow_icon.png';
import exchangeIcon from './assets/exchange_icon.png';
import checkIcon from './assets/check_icon.png';
import addIcon from './assets/plus_icon.png';
 
class Acliente extends Component {
	
	constructor(props) {
		super(props);
		this.selectChange = this.selectChange.bind(this);
		this.selectAction = this.selectAction.bind(this);
		this.selectCauseChange = this.selectCauseChange.bind(this);
		this.obsTextChange = this.obsTextChange.bind(this);
		this.costChange = this.costChange.bind(this);
		this.editoIncidencia = this.editoIncidencia.bind(this);
		this.state = {incidencias: [],loading:true,cuales:1,enIncidencia:false,
		razonSocial: null,
		actionTypes: null,
		actionTypesOri: null,
		actions: [],
		incidenceNumber: 0,
		causeOpen: false,
		costOpen: false,
		IP: "http://192.168.0.19:49264",
		isBlocked: false,
		clientOpen: true,
		observOpen: false,
		causes: null,
		actionTypeOpen: false,
		actionOpen: false,
		productsOpen: false,
		warranty:false,
		isOpen: true,
		gettingPiece: null,
		products: [],
		observ: "",
		costAux: '',
		cost: 0,
		IDINCI: 0,
		CODCLI: '',
		NOMCLI: '',
		NOMFISCAL: '',
		FECHA_APERTURA: '',
		FECHA_CIERRE:'',
		searchingProduct: false,
		productSearch: '',
		searchingClient: false,
		clientSearch:'',
		clientsList:[],
		searchingPiece: false,
		productsList: [],
		par: true,
		cause: {
			id: 8,
			desc: "(?)",
		}
		};
	}
	
	componentDidMount(){
		//this.getData();
		this.addAction();
	}
	
	selectChange(event){
		this.state.cuales = event.target.value;
		this.getData();
	}
	
	selectAction(event){
		var aux = JSON.parse(JSON.stringify(this.state.actionTypesOri.find(el => el.IDACCION == event.target.value)));
		aux.OBSERVACIONES = "";
		aux.EDITING = false;
		if(this.state.actions.length==0)
			aux.KEYID = this.state.actions.length;
		else
			aux.KEYID = this.state.actions[this.state.actions.length-1].KEYID+1;
		aux.ELIMINADA = false;
		aux.FECHA = (new Date().getDate()+"/"+(new Date().getMonth() + 1)+"/"+new Date().getFullYear());
		this.state.actions.push(aux);
		this.setState({actionOpen:true,actionTypeOpen:false});
		//this.addAction();
	}
	
	removeAction(KEYID){
		//this.state.actions = this.state.actions.filter(item => item !== this.state.actions.find(el=>el.KEYID ==KEYID));
		this.state.actions.find(el => el.KEYID == KEYID).ELIMINADA = true;
		this.setState({state: this.state });
	}
	removeProduct(KEYID){
		this.state.products.find(el => el.KEYID == KEYID).ELIMINADA = true;
		this.setState({state: this.state });
	}
	removePiece(IDPIEZAINCI,KEYID){
		((this.state.products.find(el => el.KEYID == KEYID)).PIECES.find(el => el.IDPIEZAINCI == IDPIEZAINCI)).ELIMINADA=true;
		this.setState({state: this.state });
	}
	
	selectCauseChange(event){
		this.state.cause.desc = event.target.value.substring(2);
		this.state.cause.id = event.target.value.charAt(0);
		this.abrirCausas();
	}
	
	obsTextChange(event){
		this.state.observ = event.target.value;
	}
	costChange(event){
		if(event.target.value=='')
			this.state.cost = '0';
		else
			this.state.cost = event.target.value;
	}
	
	nuevaIncidencia(){
		//Faltaría el paso de buscar un cliente, habría que añadirlo y de esa pantalla ya venir a esta con el cliente seleccionado.
		this.resetIncidencia();
		this.setState({searchingClient:true});
		
		
		this.setState({enIncidencia: '-'});
	}
	
	resetIncidencia(){
		this.setState({
			razonSocial: null,
			actions: [],
			incidenceNumber: 0,
			causeOpen: false,
			costOpen: false,
			isBlocked: false,
			clientOpen: true,
			observOpen: false,
			actionTypeOpen: false,
			actionOpen: false,
			productsOpen: false,
			warranty:false,
			isOpen: true,
			gettingPiece: null,
			products: [],
			observ: "",
			costAux: '',
			cost: 0,
			IDINCI: 0,
			CODCLI: '',
			NOMCLI: '',
			NOMFISCAL: '',
			FECHA_APERTURA: '',
			FECHA_CIERRE:'',
			searchingProduct: false,
			searchingPiece: false,
			productsList: [],
			cause: {
				id: 8,
				desc: "(?)",
			}
		});
	}
	cerrarIncidencia(){
		this.state.isOpen = false;
		this.save();
	}
	guardarIncidencia(){
		this.save();
	}
	abrirIncidencia(){
		this.setState({isOpen:true});
	}
	
	editoIncidencia(incidencia,CODCLI,NOMCLI,NOMFISCAL,FECHA_APERTURA,FECHA_CIERRE){
		this.state.enIncidencia = incidencia;
		this.state.IDINCI = incidencia;
		this.state.CODCLI = CODCLI;
		this.state.NOMCLI = NOMCLI;
		this.state.NOMFISCAL = NOMFISCAL;
		this.state.FECHA_APERTURA = FECHA_APERTURA;
		this.state.FECHA_CIERRE = FECHA_CIERRE;
		this.getIncidenceData();
	}
	
	
	async searchClient() {
		// is text empty? Aqui tendria que hacer la consulta a php. Probamos a hacer la consulta cuando no se pase ningún valor.
		//Asumimos que nos pasan el código del cliente
		var text = this.state.clientSearch;
		this.state.loading = true;
		this.setState({state: this.state });
		//this.eraseClients();	//Borramos la consulta anterior
		
		if (text === null || text === ''){
			this.state.clientsList = new Array();
			this.state.loading = false;
			return null;
		}

		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: text
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await this.state.IP+"/api/modulo/clientes/buscar_cliente",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.clientsList = result;
								this.state.loading = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async searchProduct() {
		var text = this.state.productSearch;
		this.state.loading = true;
		this.setState({state: this.state });
		
		if (text === null || text === ''){
			this.state.productsList = new Array();
			this.state.loading = false;
			return null;
		}

		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: text
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await this.state.IP+"/api/modulo/acliente/buscar_producto",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.productsList = result;
								this.state.loading = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async getIncidenceData(){
		
		this.state.loading = true;
		this.setState({state: this.state });
		
		let bodyData = {
				user: sessionStorage.getItem('user'),
				inciId: this.state.enIncidencia,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await this.state.IP+"/api/modulo/acliente/info_incidencia",data)
							.then(result => result.json())
							.then(result => {
								this.state.cost = result[0].COSTE;
								this.state.warranty = result[0].GARANTIA;
								this.state.cause.id = result[0].IDCAUSA;
								this.state.cause.desc = "???";
								this.state.isOpen = result[0].ABIERTA;
								this.state.observ = result[0].COMENTARIOS;
								
								if(result[0].ACTIONS!=null)
									for(var i=0;i<result[0].ACTIONS.length;i++){
										var aux = JSON.parse(JSON.stringify(this.state.actionTypesOri.find(el => el.IDACCION == result[0].ACTIONS[i].IDTIPOACCION)));
										aux.OBSERVACIONES = result[0].ACTIONS[i].COMENTARIOS;
										aux.EDITING = false;
										aux.KEYID = result[0].ACTIONS[i].IDACCION;
										aux.ELIMINADA = result[0].ACTIONS[i].ELIMINADA;
										aux.FECHA = result[0].ACTIONS[i].FECHA;
										this.state.actions.push(aux);
									}
									
								if(result[0].PRODUCTS!=null)
									for(var i=0;i<result[0].PRODUCTS.length;i++){
										var aux = {};
										aux.OBSERVACIONES = result[0].PRODUCTS[i].COMENTARIOS;
										aux.EDITING = false;
										aux.KEYID = result[0].PRODUCTS[i].IDPRODINCI;
										aux.ELIMINADA = result[0].PRODUCTS[i].ELIMINADA;
										aux.QTY = result[0].PRODUCTS[i].CANTIDAD;
										(result[0].PRODUCTS[i].HAYLOTES=='T') ? aux.HAYLOTES = 1 : aux.HAYLOTES = 0;
										(result[0].PRODUCTS[i].HAYNUMSERIE=='T') ? aux.HAYNUMSERIE = 1 : aux.HAYNUMSERIE = 0;
										aux.LOTE = result[0].PRODUCTS[i].LOTE;
										aux.CODART = result[0].PRODUCTS[i].CODART;
										aux.DESCART = result[0].PRODUCTS[i].DESCART;
										if(result[0].PRODUCTS[i].PIECES == null)
											aux.PIECES = [];
										else{
											aux.PIECES = result[0].PRODUCTS[i].PIECES;
											for(var j=0;j<aux.PIECES.length;j++)
												aux.PIECES[j].EDITING = false;
										}
										this.state.products.push(aux);
									}
								if(!this.state.isOpen)
									this.state.isBlocked = true;
								//this.setState({loading: false });
								this.getCauses();
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async getCauses(){
		let bodyData = {
			user: sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await"http://192.168.0.19:49264"+"/api/modulo/acliente/causas",data)
							.then(result => result.json())
							.then(result => {
								this.state.causes = result;
								for(var i=0;i<result.length;i++){
									if(result[i].IDCAUSA==this.state.cause.id){
										this.state.cause.desc = result[i].DESCRIPCION;
										i=result.length;
									}
								}
								this.setState({loading: false });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async getData(){
		
		this.state.loading = true;
		this.setState({state: this.state });
		
		let bodyData = {
				user: sessionStorage.getItem('user'),
				type: parseInt(this.state.cuales),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await this.state.IP+"/api/modulo/acliente/incidencias",data)
							.then(result => result.json())
							.then(result => {
								this.state.incidencias = result;
								this.getCauses();
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	goBack(){
		this.state.products = [];
		this.state.actions = [];
		this.setState({enIncidencia: false});
	}
	
	async removeInci(){
		
		this.setState({loading: true });
		const { navigation } = this.props;
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			inciId: this.state.enIncidencia,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await this.state.IP+"/api/modulo/acliente/eliminar_incidencia",data)
							.then(result => result.json())
							.then(result => {
								this.state.loading= false ;
								this.getData();
								window.location.reload(false);
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	countNotRemoved(array){
		var count = 0;
		for(var i=0;i<array.length;i++){
			if(!array[i].ELIMINADA)
				count++;
		}
		return count;
	}
	
	async save(){
		this.setState({loading: true });
		const { navigation } = this.props;
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			client: this.state.CODCLI,
			obs: this.state.observ,
			cause: this.state.cause.id,
			idInci: this.state.enIncidencia,
			actions: this.state.actions,
			products: this.state.products,
			cost: this.state.cost,
			warranty: (this.state.warranty) ? 1 : 0,
			open: (this.state.isOpen) ? 1 : 0,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}



		if(this.state.enIncidencia=='-'){
			fetch(await this.state.IP+"/api/modulo/acliente/nueva_incidencia",data)
								.then(result => result.json())
								.then(result => {
									this.state.loading= false ;
									this.getData();
									window.location.reload(false);
								})
								
			.catch((error) => {
				console.error(error);
			});
		}
		else {
			fetch(await this.state.IP+"/api/modulo/acliente/guardar_incidencia",data)
								.then(result => result.json())
								.then(result => {
									this.state.loading= false ;
									this.getData();
									window.location.reload(false);
								})
								
			.catch((error) => {
				console.error(error);
			});
		}
		
		
	}
	
	async addAction(){
		if(this.state.isBlocked){
			this.isBlocked();
			return;
		}
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await this.state.IP+"/api/modulo/acliente/tipos_acciones",data)
							.then(result => result.json())
							.then(result => {
								this.state.actionTypesOri = result;
								
								var aux = [];
								var auxTipo = [];
								
								//{tipo: result[i].TIPO,descripcion: result[i].DESCRIPCION,idaccion: result[i].IDACCION}
								
								for(var i=0;i<result.length;i++){
									var auxBool = false;
									for(var j=0;j<aux.length;j++){
										if(aux[j].tipo == result[i].TIPO){
											auxBool = true;
											j=aux.length;
										}
									}
									if(!auxBool)
										aux.push({tipo: result[i].TIPO,datos: [],isOpen:false});
								}
								
								for(var i=0;i<result.length;i++){
									for(var j=0;j<aux.length;j++){
										if(result[i].TIPO==aux[j].tipo){
											aux[j].datos.push({descripcion: result[i].DESCRIPCION,idaccion: result[i].IDACCION});
											j=aux.length;
										}
									}
								}
								
								this.state.actionTypes = aux;
								if(this.state.incidenceNumber!='-'){
									this.getData();
								}
								//this.setState({actionTypeOpen: true });
							})
							
		.catch((error) => {
			console.error(error);
		});
		
	}
	
	abrirObservaciones(){
		this.setState({observOpen: !this.state.observOpen});
	}
	
	abrirCausas(){
		this.setState({causeOpen: !this.state.causeOpen});
	}
	abrirCoste(){
		this.setState({costOpen: !this.state.costOpen});
	}
	changeWarranty(){
		this.setState({warranty:!this.state.warranty});
	}
	abrirCausa(KEYID,EDITING){
		(this.state.actions.find(el => el.KEYID == KEYID)).EDITING=!EDITING;
		this.setState({state: this.state });
	}
	
	abrirProducto(KEYID,EDITING){
		(this.state.products.find(el => el.KEYID == KEYID)).EDITING=!EDITING;
		this.setState({state: this.state });
	}
	
	abrirPieza(IDPIEZAINCI,EDITING,KEYID){
		((this.state.products.find(el => el.KEYID == KEYID)).PIECES.find(el => el.IDPIEZAINCI == IDPIEZAINCI)).EDITING=!EDITING;
		this.setState({state: this.state });
	}
	
	crearAccion(){
		this.setState({actionTypeOpen: !this.state.actionTypeOpen});
	}
	crearProducto(){
		this.setState({searchingProduct: true,searchingPiece: false});
	}
	crearPieza(KEYID){
		this.state.gettingPiece = KEYID;
		this.setState({searchingProduct: true,searchingPiece: true});
	}
	
	chooseProduct(CODART,DESCART,HAYLOTES,HAYNUMSERIE){
		if(!this.state.searchingPiece) this.createProduct(CODART,DESCART,HAYLOTES,HAYNUMSERIE);
		else this.createPiece(CODART,DESCART,HAYLOTES,HAYNUMSERIE);
	}
	
	chooseClient(codcli,nomcli,razon){
		this.setState({
			CODCLI: codcli,
			NOMCLI: nomcli,
			NOMFISCAL: razon,
			searchingClient: false,
		});
	}
	
	createProduct(codart,descart,lote,serie){
		if(codart!='-'){
			var aux = {
				CODART: codart,
				DESCART: descart,
				QTY: '1',
				LOTE: '',
				EDITING: false,
				ELIMINADA: 0,
				OBSERVACIONES: '',
				PIECES: [],
			}
			if(lote=='T') aux.HAYLOTES = 1;
			else aux.HAYLOTES = 0;
			if(serie=='T') aux.HAYNUMSERIE = 1;
			else aux.HAYNUMSERIE = 0;
			if(this.state.products.length==0)
				aux.KEYID = this.state.products.length;
			else
				aux.KEYID = this.state.products[this.state.products.length-1].KEYID+1;
			this.state.products.push(aux);
		}
		this.setState({searchingProduct: false});
	}
	
	createPiece(codart,descart,lote,serie){
		var product = this.state.products.find(el => el.KEYID == this.state.gettingPiece);
		this.state.gettingPiece = null;
		
		if(codart!='-'){
			var aux = {
				CODART: codart,
				DESCART: descart,
				LOTE: '',
				EDITING: false,
				ELIMINADA: 0,
				COMENTARIOS: '',
			}
			if(lote=='T') aux.HAYLOTES = 1;
			else aux.HAYLOTES = 0;
			if(serie=='T') aux.HAYNUMSERIE = 1;
			else aux.HAYNUMSERIE = 0;
			if(product.PIECES.length==0)
				aux.IDPIEZAINCI = product.PIECES.length;
			else
				aux.IDPIEZAINCI = product.PIECES[product.PIECES.length-1].IDPIEZAINCI+1;
			product.PIECES.push(aux);
		}
		this.setState({searchingProduct: false});
	}
	
	render() {
		
		if(this.state.loading){
			return (<div></div>);
		}
		else {
			if(this.state.searchingClient){
				
				return (
					<div>
						<div>
							<a style={{cursor:"pointer"}} onClick={()=>this.setState({searchingClient: false,enIncidencia: false})}><img src={backIcon} alt="back" /></a>
						</div>
						<form onSubmit={()=>this.searchClient()}>
							<input onChange={e => this.state.clientSearch=e.target.value}></input>
							<input type="submit" value="BUSCAR CLIENTE"/>
						</form>
						
						{this.state.clientsList.map(({ codcli, nomcli, razon }) => (
							<a className="cursorPointer" onClick={()=>this.chooseClient(codcli,nomcli,razon)} key={codcli}>
								<div style = {{width:"100%",backgroundColor:"#cee5e3",padding:"3px",border:"1px solid grey",}}>
									{codcli} - {nomcli} - {razon}
								</div>
							</a>
							))}
						
					</div>
				);
				
				
			}else			
			if(this.state.searchingProduct){
				
				return (
					<div>
						<div>
							<a style={{cursor:"pointer"}} onClick={()=>this.setState({searchingProduct: false})}><img src={backIcon} alt="back" /></a>
						</div>
						<form onSubmit={()=>this.searchProduct()}>
							<input onChange={e => this.state.productSearch=e.target.value}></input>
							<input type="submit" value="BUSCAR PRODUCTO"/>
						</form>
						
						{this.state.productsList.map(({ CODART, DESCART, HAYLOTES, HAYNUMSERIE }) => (
							<a className="cursorPointer" onClick={()=>this.chooseProduct(CODART,DESCART,HAYLOTES,HAYNUMSERIE)} key={CODART}>
								<div style = {{width:"100%",backgroundColor:"#cee5e3",padding:"3px",border:"1px solid grey",}}>
									{CODART} - {DESCART}
								</div>
							</a>
							))}
						
					</div>
				);
				
				
			}else			
		if(this.state.enIncidencia)
			return (
				<div>
				{this.state.isOpen&&
					<button className="floatRight" style={{cursor:"pointer"}} onClick={()=>this.cerrarIncidencia()}>
						CERRAR INCIDENCIA
					</button>
				}
				{!this.state.isOpen&&
					<button className="floatRight" style={{cursor:"pointer"}} onClick={()=>this.abrirIncidencia()}>
						ABRIR INCIDENCIA
					</button>
				}
					<div>
						<a style={{cursor:"pointer"}} onClick={()=>this.goBack()}><img src={backIcon} alt="back" /></a>
					</div>
					<div>
						<table className="tablaIncidencias">
							<tr>
								<th>Nº</th>
								<th>CÓD. CLIENTE</th>
								<th>NOMBRE CLIENTE</th>
								<th>RAZÓN SOCIAL</th>
								<th>FECHA APERTURA</th>
								<th>FECHA CIERRE</th>
							</tr>
							<tr>
								<td>{this.state.IDINCI}</td>
								<td>{this.state.CODCLI}</td>
								<td>{this.state.NOMCLI}</td>
								<td>{this.state.NOMFISCAL}</td>
								<td>{this.state.FECHA_APERTURA}</td>
								<td>{((this.state.FECHA_CIERRE!=null) ? <a>{this.state.FECHA_CIERRE}</a> : <a>-</a>)}</td>
							</tr>
						</table>
					</div>
					<div>
						<hr></hr>
						<p>
							{this.state.isOpen&&!this.state.observOpen&&<a className="cursorPointer" onClick={()=>this.abrirObservaciones()}><img src={editIconSmall} alt="edit" /></a>}
							{this.state.isOpen&&this.state.observOpen&&<a className="cursorPointer" onClick={()=>this.abrirObservaciones()}><img src={checkIcon} alt="edit" /></a>}
							<b> OBSERVACIONES GENERALES: </b>
								{!this.state.observOpen&&
								this.state.observ}
								
								{this.state.observOpen&&
									<textarea onChange={this.obsTextChange}>
										{this.state.observ}
									</textarea>
								}
							
						</p>
					</div>
					
					<hr></hr>
					<div style={{width:"100%",height:"30px"}}>
						<div style={{width:"33%",float:"left"}}>
							{this.state.isOpen&&!this.state.causeOpen&&<a className="cursorPointer" onClick={()=>this.abrirCausas()}><img src={editIconSmall} alt="edit" /></a>}
							{this.state.isOpen&&this.state.causeOpen&&<a className="cursorPointer" onClick={()=>this.abrirCausas()}><img src={closeIcon} alt="edit" /></a>}
							<b> CAUSA: </b>
							{!this.state.causeOpen&&<a>{this.state.cause.desc}</a>}
							{this.state.causeOpen&&<select onChange={this.selectCauseChange}>
										<option value="selecciona" disabled selected>Selecciona una causa</option>
								{this.state.causes.map(({ IDCAUSA,DESCRIPCION }) => (
										<option key={IDCAUSA} value={IDCAUSA+" "+DESCRIPCION}>{DESCRIPCION}</option>
								))}
								</select>
							}
							
						</div>
						<div style={{width:"33%",float:"left"}}>
							{this.state.isOpen&&<a className="cursorPointer" onClick={()=>this.changeWarranty()}><img src={exchangeIcon} alt="edit" /></a>}
							<b> GARANTÍA:</b> {this.state.warranty&&<a>SÍ</a>}{!this.state.warranty&&<a>NO</a>}
						</div>
						<div style={{width:"33%",float:"left"}}>
							{!this.state.costOpen&&this.state.isOpen&&<a className="cursorPointer" onClick={()=>this.abrirCoste()}><img src={editIconSmall} alt="edit" /></a>}
							{this.state.costOpen&&this.state.isOpen&&<a className="cursorPointer" onClick={()=>this.abrirCoste()}><img src={checkIcon} alt="edit" /></a>}
							<b> COSTE:</b> {!this.state.costOpen&&<a>{this.state.cost} €</a>}
								{this.state.costOpen&&<input type="text" placeholder="€" onChange={this.costChange}></input>}
						</div>
					</div>
					<div>
						<hr></hr>
						
						<a>
							<p>{this.state.isOpen&&<a className="cursorPointer" onClick={()=>this.crearProducto()}><img src={addIcon} alt="edit" /></a>}<b> &nbsp;&nbsp;PRODUCTOS AFECTADOS:</b></p>
						</a>
					
						{this.state.products.map(({ OBSERVACIONES,CODART,DESCART,LOTE,KEYID,ELIMINADA,QTY,EDITING,PIECES,HAYLOTES,HAYNUMSERIE }) => (
						<div key={KEYID}>
						
						{!ELIMINADA&&
							<div style={{width:"100%",height:"auto"}}>
								<div style={{width:"60%",float:"left"}}>
									{this.state.isOpen&&!EDITING&&<a><a className="cursorPointer" onClick={()=>this.removeProduct(KEYID)}><img src={closeIcon} alt="edit" /></a>&nbsp;<a className="cursorPointer"  onClick={()=>this.abrirProducto(KEYID,EDITING)}><img src={editIconSmall} alt="edit" /></a></a>}
									{this.state.isOpen&&EDITING&&<a><a className="cursorPointer" onClick={()=>this.removeProduct(KEYID)}><img src={closeIcon} alt="edit" /></a>&nbsp;<a className="cursorPointer"  onClick={()=>this.abrirProducto(KEYID,EDITING)}><img src={checkIcon} alt="edit" /></a></a>}<a>
										<b style={{color: "#000"}}> {CODART} - {DESCART}</b>
									</a>
									
								</div>
									{!EDITING&&
										<div><p>
											{HAYLOTES==1&&
													<a>Cantidad: {QTY}	- Lote: {LOTE}</a>
											}
											{HAYNUMSERIE==1&&
													<a>Cantidad: {QTY}	- Serie: {LOTE}</a>
											}
											{HAYNUMSERIE!=1&&HAYLOTES!=1&&
													<a>Cantidad: {QTY}</a>
											}
										</p></div>
									}
									{EDITING&&
										<div><p>
											{HAYLOTES==1&&
													<a>Cantidad: <input placeholder={QTY} onChange={e=>(this.state.products.find(el => el.KEYID == KEYID)).QTY = e.target.value}></input>	- Lote: <input placeholder={LOTE} onChange={e=>(this.state.products.find(el => el.KEYID == KEYID)).LOTE = e.target.value}></input></a>
											}
											{HAYNUMSERIE==1&&
													<a>Cantidad: <input placeholder={QTY} onChange={e=>(this.state.products.find(el => el.KEYID == KEYID)).QTY = e.target.value}></input>	- Serie: <input placeholder={LOTE} onChange={e=>(this.state.products.find(el => el.KEYID == KEYID)).LOTE = e.target.value}></input></a>
											}
											{HAYNUMSERIE!=1&&HAYLOTES!=1&&
													<a>Cantidad: <input placeholder={QTY} onChange={e=>(this.state.products.find(el => el.KEYID == KEYID)).QTY = e.target.value}></input></a>
											}
										</p></div>
									}
								<div style={{width:"40%",float:"left"}}>
									
								</div>
								<div>
								{!EDITING&&OBSERVACIONES!=null&&OBSERVACIONES!=''&&
									<p>
										Observaciones sobre el producto: {OBSERVACIONES}
									</p>
								}
								{EDITING&&
									<p>
										Observaciones sobre el producto:
										<textarea onChange={e=>(this.state.products.find(el => el.KEYID == KEYID)).OBSERVACIONES = e.target.value}>
											{OBSERVACIONES}
										</textarea>
									</p>
								}
								</div>
							</div>
						}
						{!ELIMINADA&&
							<a>
								<a><b>&nbsp;&nbsp;&nbsp;{this.state.isOpen&&<a className="cursorPointer" onClick={()=>this.crearPieza(KEYID)}><img src={addIcon} alt="edit" /></a>}&nbsp;&nbsp;PIEZAS AFECTADAS:</b></a>
							</a>
						}
						{!ELIMINADA&&PIECES.map(({ COMENTARIOS,CODART,DESCART,LOTE,IDPIEZAINCI,ELIMINADA,EDITING }) => (
							<p key={IDPIEZAINCI}>
								{!ELIMINADA&&<div>
									<div>
										<div>
											<a>
											<a>&nbsp;&nbsp;&nbsp;<a className="cursorPointer" onClick={()=>this.removePiece(IDPIEZAINCI,KEYID)}><img src={closeIcon} alt="edit" /></a>
											&nbsp;{!EDITING&&<a className="cursorPointer"  onClick={()=>this.abrirPieza(IDPIEZAINCI,EDITING,KEYID)}><img src={editIconSmall} alt="edit" /></a>}
											{EDITING&&<a className="cursorPointer"  onClick={()=>this.abrirPieza(IDPIEZAINCI,EDITING,KEYID)}><img src={checkIcon} alt="edit" /></a>}</a>
												&nbsp;<b style={{color: "#000"}}>{CODART} - {DESCART}</b>  - Lote: {!EDITING&&<a>{LOTE}</a>}{EDITING&&
												<input placeholder={LOTE} onChange={e=>(this.state.products.find(el => el.KEYID == KEYID)).PIECES.find(el => el.IDPIEZAINCI == IDPIEZAINCI).LOTE = e.target.value}></input>
												}
											</a>
										</div>
									</div>
									<div>
									{!EDITING&&COMENTARIOS!=null&&COMENTARIOS!=''&&
										<a>
											&nbsp;&nbsp;&nbsp;&nbsp;Observaciones sobre la pieza: {COMENTARIOS}
										</a>
									}
									{EDITING&&
									<a>
											Observaciones sobre la pieza:
											<textarea onChange={e=>(PIECES.find(el => el.IDPIEZAINCI == IDPIEZAINCI)).COMENTARIOS = e.target.value}>
												{COMENTARIOS}
											</textarea>
										</a>
									}
									</div>
								</div>
								}
							</p>
						))}
						
						
						{!ELIMINADA&&<hr></hr>}
						</div>
					))}
						<hr></hr>
						<a>
							<p>
							{this.state.isOpen&&!this.state.actionTypeOpen&&<a className="cursorPointer" onClick={()=>this.crearAccion()}><img src={addIcon} alt="edit" /></a>}
							{this.state.isOpen&&this.state.actionTypeOpen&&<a className="cursorPointer" onClick={()=>this.crearAccion()}><img src={closeIcon} alt="edit" /></a>}
							<b> &nbsp;&nbsp;ACCIONES LLEVADAS A CABO:</b>
							</p>
						</a>
						
						{this.state.actionTypeOpen&&
							<select onChange={this.selectAction}>
									<option value="selecciona" disabled selected>Selecciona una acción</option>
								{this.state.actionTypesOri.map(({ TIPO,DESCRIPCION,IDACCION }) => (
									<option value={IDACCION}>{DESCRIPCION}</option>
								))}
							</select>
						}
						
						{this.state.actions.map(({ OBSERVACIONES,IDACCION,TIPO,DESCRIPCION,FECHA,EDITING,ELIMINADA,KEYID }) => (
						<div key={KEYID}>
						{!ELIMINADA&&<div>
							{!EDITING&&(OBSERVACIONES==null||OBSERVACIONES=='')&&
								<p>
								&nbsp;&nbsp;&nbsp;{this.state.isOpen&&<a><a className="cursorPointer" onClick={()=>this.removeAction(KEYID)}><img src={closeIcon} alt="edit" /></a>&nbsp;<a className="cursorPointer" onClick={()=>this.abrirCausa(KEYID,false)}><img src={editIconSmall} alt="edit" /></a></a>}<b style={{color:"#000"}}> {DESCRIPCION} - {FECHA}</b>
								</p>
							}
							{!EDITING&&OBSERVACIONES!=null&&OBSERVACIONES!=''&&
								<p>
								&nbsp;&nbsp;&nbsp;{this.state.isOpen&&<a><a className="cursorPointer" onClick={()=>this.removeAction(KEYID)}><img src={closeIcon} alt="edit" /></a>&nbsp;<a className="cursorPointer" onClick={()=>this.abrirCausa(KEYID,false)}><img src={editIconSmall} alt="edit" /></a></a>}<b style={{color:"#000"}}> {DESCRIPCION} - {FECHA}</b> &nbsp;&nbsp;&nbsp;&nbsp;Observaciones: {OBSERVACIONES}
								</p>
							}
							
							{EDITING&&
								<p>
									&nbsp;&nbsp;&nbsp;<a className="cursorPointer" onClick={()=>this.abrirCausa(KEYID,true)}><img src={checkIcon} alt="edit" /></a><b style={{color:"#000"}}> {DESCRIPCION} - {FECHA}</b> &nbsp;&nbsp;&nbsp;&nbsp;Observaciones:
									<textarea onChange={e=>(this.state.actions.find(el => el.KEYID == KEYID)).OBSERVACIONES = e.target.value}>
										{OBSERVACIONES}
									</textarea>
								</p>
							}
							
						</div>
						}
						</div>
					))}
						
						
					</div>
					
					<p style={{textAlign: "center"}}>
						<button style={{cursor:"pointer"}} onClick={()=>this.guardarIncidencia()}>
							GUARDAR CAMBIOS
						</button>
						<button className="floatRight" style={{cursor:"pointer"}} onClick={()=>this.removeInci()}>
							ELIMINAR INCIDENCIA
						</button>
					</p>
				</div>
			);
		else
		return (
			<div>
				<button className="floatRight" style={{cursor:"pointer"}} onClick={()=>this.nuevaIncidencia()}>
					NUEVA INCIDENCIA
				</button>
				<h2>INCIDENCIAS <select onChange={this.selectChange}>
					{(this.state.cuales=='1')&&<option selected="selected" value="1">ABIERTAS</option>}
					{(this.state.cuales!='1')&&<option value="1">ABIERTAS</option>}
					
					{(this.state.cuales=='0')&&<option selected="selected" value="0">CERRADAS</option>}
					{(this.state.cuales!='0')&&<option value="0">CERRADAS</option>}
					
					{(this.state.cuales=='2')&&<option selected="selected" value="2">TODAS</option>}
					{(this.state.cuales!='2')&&<option value="2">TODAS</option>}
					
				</select></h2>
				
				<table className="tablaIncidencias">
					<tr>
						<th>Nº</th>
						<th>CÓD. CLIENTE</th>
						<th>NOMBRE CLIENTE</th>
						<th>RAZÓN SOCIAL</th>
						<th>FECHA APERTURA</th>
						<th>FECHA CIERRE</th>
					</tr>
					{this.state.incidencias.map(({ IDINCI,COMENTARIOS,GARANTIA,ABIERTA,FECHA_APERTURA,FECHA_CIERRE,IDCAUSA,COSTE,CODCLI,NOMCLI,NOMFISCAL }) => (								
						<tr>
							<td>{IDINCI}</td>
							<td>{CODCLI}</td>
							<td>{NOMCLI}</td>
							<td>{NOMFISCAL}</td>
							<td>{FECHA_APERTURA}</td>
							<td>{((!ABIERTA) ? <a>{FECHA_CIERRE}</a> : <a>-</a>)}</td>
							<td><a className="cursorPointer" onClick={()=>this.editoIncidencia(IDINCI,CODCLI,NOMCLI,NOMFISCAL,FECHA_APERTURA,FECHA_CIERRE)}><img src={editIcon} alt="edit" /></a></td>
						</tr>
					))}
				</table>
			</div>
		);
	}}
}
 
export default Acliente;