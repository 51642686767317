import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';

class Home extends Component {
	
	constructor(props) {
		super(props);
		this.handleRowBackground = this.handleRowBackground.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onSubmitNothing = this.onSubmitNothing.bind(this);
	}
	
	state = {
		username:'',
		password:'',
		loggedIn: false,
		startDate: null,
		endDate: null,
		focusedInput: null,
		loading:false,
		entradas: [],
		imagen: null,
		filtro: '',
	};
	
	componentDidMount(){
		setInterval(() => this.handleSubmit(null), 300000)
	}
	
	onSubmitNothing(event){
		event.preventDefault();
	}
	
	doNothing(day){
		//console.log(day);
	}
	
	handleRowBackground(e){
		console.log(e);
	}
	
	filaClick(ID){
		console.log(ID);
	}
	
	async handleSubmit(event){
		
		if(this.state.startDate==null)
			return;
		
		var today=""+(this.state.startDate._d.getMonth()+1)+"/"+this.state.startDate._d.getDate()+"/"+this.state.startDate._d.getFullYear();
		if(this.state.endDate!=null)
			var tomorrow = ""+(this.state.endDate._d.getMonth()+1)+"/"+this.state.endDate._d.getDate()+"/"+this.state.endDate._d.getFullYear();
		else var tomorrow= today;
		
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			date: tomorrow,
			yesterday: today,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/almacen/entradas_material",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.entradas = result;
								this.state.entradas.forEach(orden => {orden.SHOW = true;orden.IMAGENES = [];orden.LOADING = false});
								this.setState({loading: false });
							})
							
		.catch((error) => {
			console.error(error);
		});

	}
	
	async getLineas(index){
		
		if(this.state.entradas[index].OPEN){
			this.state.entradas[index].OPEN = false;
			this.setState({state: this.state });
			return;
		}
		
		if(this.state.entradas[index].SEARCH){
			this.state.entradas[index].OPEN = true;
			this.setState({state: this.state });
			return;
		}
		this.state.entradas[index].LOADING = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			IDENTRADA: this.state.entradas[index].ID,
			IMAGENES: true,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/almacen/lineas_entrada",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.entradas[index].LOADING = false;
								this.state.entradas[index].OPEN = true;
								this.state.entradas[index].SEARCH = true;
								this.state.entradas[index].LINEAS = result.LINEAS;
								this.state.entradas[index].IMAGENES = result.IMAGENES;
								this.state.entradas[index].IND = index;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async duplicarLinea(IND,index){
		
		this.state.entradas[IND].LOADING = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			IDENTRADA: this.state.entradas[IND].ID,
			linea: this.state.entradas[IND].LINEAS[index],
			IMAGENES: true,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/almacen/duplicar_linea_entrada",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.entradas[IND].LOADING = false;
								this.state.entradas[IND].OPEN = true;
								this.state.entradas[IND].SEARCH = true;
								this.state.entradas[IND].LINEAS = result.LINEAS;
								this.state.entradas[IND].IMAGENES = result.IMAGENES;
								this.state.entradas[IND].IND = IND;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	filtrar(index){
		var salida = this.state.entradas[index];
		var FILTRO = this.state.filtro.toUpperCase();
		if(salida.ALBARAN_PROVEEDOR.includes(FILTRO)||salida.NOMPRO.includes(FILTRO)||salida.CODPRO.includes(FILTRO)||(salida.NUMDOC+"/"+salida.SERIE).includes(FILTRO)||salida.USERNAME.toUpperCase().includes(FILTRO)||salida.OBSERVACIONES.toUpperCase().includes(FILTRO))
			return true;
		else return false;
	}
	
	editoCantidad(IND,index,value){
		this.state.entradas[IND].LINEAS[index].CANTIDAD_ALT = value;
		this.setState({state: this.state });
	}
	
	async editarCantidad(IND,index,guardar){
		if(this.state.entradas[IND].LINEAS[index].EDITANDO==undefined||this.state.entradas[IND].LINEAS[index].EDITANDO==null||this.state.entradas[IND].LINEAS[index].EDITANDO==false){
			this.state.entradas[IND].LINEAS[index].CANTIDAD_ALT = this.state.entradas[IND].LINEAS[index].CANTIDAD;
			this.state.entradas[IND].LINEAS[index].EDITANDO = true;
		}
		else this.state.entradas[IND].LINEAS[index].EDITANDO = !this.state.entradas[IND].LINEAS[index].EDITANDO;
		
		if(guardar&&this.state.entradas[IND].LINEAS[index].EDITANDO==false){
			if(this.state.entradas[IND].LINEAS[index].CANTIDAD != this.state.entradas[IND].LINEAS[index].CANTIDAD_ALT){
				
				//this.setState({loading: true });
				let bodyData = {
					user: sessionStorage.getItem('user'),
					ID: this.state.entradas[IND].LINEAS[index].ID,
					CANTIDAD: this.state.entradas[IND].LINEAS[index].CANTIDAD_ALT,
					IDENTRADA: this.state.entradas[IND].LINEAS[index].IDENTRADA,
				};
				let data ={
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+sessionStorage.getItem('token'),
					},
					body: JSON.stringify(bodyData)
				}

				fetch(await "http://192.168.0.19:49264"+"/api/modulo/almacen/editar_linea_entrada",data)
									.then(result => result.json())
									.then(result => {
										if(result[0]!=null)
										if(result[0].token==0){
											alert('Renovando sesión...');
											window.location.reload(false);
										}
										if(result.resultado==1){
											this.state.entradas[IND].LINEAS[index].CANTIDAD = this.state.entradas[IND].LINEAS[index].CANTIDAD_ALT;
											if(this.state.entradas[IND].LINEAS[index].CANTIDAD<=0){
												this.state.entradas[IND].LINEAS.splice(index,1);
												if(this.state.entradas[IND].LINEAS.length==0)
													this.state.entradas.splice(IND,1);
											}
										}
										else alert('Algo ha salido mal');
										this.setState({loading: false });
									})
									
				.catch((error) => {
					console.error(error);
				});
				
			}
		}
		this.setState({state: this.state });
	}
	
	async cambiarLinea(value,IND,index){
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.entradas[IND].LINEAS[index].ID,
			IDLIN: value,
			CODART: this.state.entradas[IND].LINEAS[index].CODART,
			CANTIDAD: this.state.entradas[IND].LINEAS[index].CANTIDAD,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/almacen/cambiar_linea",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	render() {
		return (
		<div>
			<div style={{float: "right",marginRight:50}}>
				<DateRangePicker
					startDate={this.state.startDate} // momentPropTypes.momentObj or null,
					startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
					endDate={this.state.endDate} // momentPropTypes.momentObj or null,
					endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
					onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
					focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
					startDatePlaceholderText= "Desde"
					endDatePlaceholderText= "Hasta"
					isOutsideRange={(day) => this.doNothing(day)}
					displayFormat="DD/MM/yyyy"
					firstDayOfWeek={1}
				/>
				<button type="button" onClick={this.handleSubmit} style={{marginLeft:50}}>BUSCAR</button>
			</div>
			<div style={{float: "right",margin:15}}>
				<form onSubmit={this.onSubmitNothing}>
				  <label>
					<input
						  type="text" 
						  value={this.state.filtro}
						  onChange={(e) => this.setState({filtro: e.target.value})}
						  placeholder="Filtro"
						/>
				  </label>
				</form>
			</div>
			<h2 style={{marginBottom:25}}>ENTRADAS DE MATERIAL</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.entradas.length>0&&<a>
				<table className="tablaEntrada">
					<tr style={{backgroundColor:"#339999",color:"#fff"}}>
						<td style={{width:"8%"}}>
							FECHA
						</td>
						<td style={{width:"2%",textAlign:"center"}}>
							!
						</td>
						<td style={{width:"8%"}}>
							Nº DOC
						</td>
						<td style={{width:"15%"}}>
							SU ALBARÁN Nº
						</td>
						<td style={{width:"7%"}}>
							BULTOS
						</td>
						<td style={{width:"24%"}}>
							PROVEEDOR
						</td>
						<td style={{width:"10%"}}>
							PERSONA
						</td>
						<td style={{width:"7%"}}>
							ALMAC.
						</td>
						<td style={{width:"20%"}}>
							OBSERVACIONES
						</td>
					</tr>
					</table>
				{this.state.entradas.map(({ ID,IND,NOMPRO,CODPRO,NUMDOC,SERIE,OBSERVACIONES,INCIDENCIA,BULTOS,FECHA,ALBARAN_PROVEEDOR,USERNAME,LINEAS,OPEN,SEARCH,SHOW,IMAGENES,LOADING,CODALM },index) => (
					<a key={ID}>
						{this.filtrar(index)&&<a>
					<table className="tablaEntrada" style={OPEN&&SHOW ? {backgroundColor:"#cdeeee"} : {backgroundColor:"#FFF"}}>
						<tr key={ID} onClick={() => this.getLineas(index)}>
							<td style={{width:"8%"}}>
								{FECHA}
							</td>
							<td style={INCIDENCIA ? {width:"2%",backgroundColor:"#F55"} : {width:"2%",backgroundColor:"#cdeeee"}}>
								
							</td>
							<td style={{width:"8%"}}>
								{SERIE}/{NUMDOC}
							</td>
							<td style={{width:"15%"}}>
								{ALBARAN_PROVEEDOR}
							</td>
							<td style={{width:"7%"}}>
								{BULTOS}
							</td>
							<td style={{width:"24%"}}>
								{CODPRO} - {NOMPRO}
							</td>
							<td style={{width:"10%"}}>
								{USERNAME}
							</td>
							<td style={CODALM!=1 ? {width:"7%",backgroundColor:"#edff73"} : {width:"7%"}}>
								{CODALM}
							</td>
							<td style={{width:"20%"}}>
								{OBSERVACIONES}
							</td>
						</tr>
						</table>
						<table className="tablaLineas">
							{LOADING&&
								<div style={{textAlign:"center"}}>
									<img alt="Loading..." src={loadingGif} style={{height:150}}/>
								</div>
							}
							{SHOW&&OPEN&&!LOADING&&LINEAS.length>0&&
								<tr style={{backgroundColor:"#339999",color:"#fff"}}>
									<td>COD. ART.</td>
									<td>DE NUESTRO PEDIDO DE COMPRA Nº</td>
									<td>DESCRIPCIÓN</td>
									<td>CANTIDAD</td>
								</tr>
							}
							{SHOW&&OPEN&&!LOADING&&LINEAS.map(({IDLIN,CODART,DESCLIN,CANTIDAD,CANTIDAD_ALT,ID,IDENTRADA,SERIE,NUMDOC,IDPEDC,EDITANDO,OTRAS_LINEAS},index) => (
									<tr key={index}>
										<td>{CODART}</td>
										<td>
											<select name="otras_lineas" id="otras_lineas" onChange={(e) => this.cambiarLinea(e.target.value,IND,index)}>
												<option value="default" disabled selected="selected">{SERIE}/{NUMDOC}</option>
												{OTRAS_LINEAS!=null&&OTRAS_LINEAS.map(({ IDLIN,SERIE,NUMDOC },index) => (
													<option value={IDLIN} key={index}>{SERIE+'/'+NUMDOC}</option>	
												))}
											</select>
											<button type="button" onClick={()=>this.duplicarLinea(IND,index)} style={{marginLeft:5}}>DUPLICAR LÍNEA</button>
										</td>
										<td>{DESCLIN}</td>
										<td>
											{!EDITANDO&&CANTIDAD}
											{!EDITANDO&&<button type="button" onClick={()=>this.editarCantidad(IND,index)} style={{marginLeft:5}}>EDITAR</button>}
											{EDITANDO&&
												<label>
													<input
														  type="numeric" 
														  value={CANTIDAD_ALT}
														  onChange={(e) => this.editoCantidad(IND,index,e.target.value)}
														  placeholder={CANTIDAD_ALT}
															size={7}
														/>
												  </label>
											}
											{EDITANDO&&<button type="button" onClick={()=>this.editarCantidad(IND,index,true)} style={{marginLeft:5}}>GUARDAR</button>}
											{EDITANDO&&<button type="button" onClick={()=>this.editarCantidad(IND,index)} style={{marginLeft:5}}>X</button>}
										</td>
									</tr>
							))}
							{SHOW&&OPEN&&!LOADING&&IMAGENES.length>0&&
								<tr style={{width:'100%',textAlign:'center'}}>
									<td colspan="4">
										{SHOW&&OPEN&&!LOADING&&IMAGENES.map(({IMG},index) => (
											<img key={index} src={IMG} style={{maxHeight:'450px',padding:5}} />
										))}
									</td>
								</tr>
							}
						</table>
						</a>}
					</a>
				))}
				</a>
			}
      </div>
    );
  }
}
 
export default Home;