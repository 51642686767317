import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';
import {
  Route,
  NavLink,
  HashRouter,
  Link
} from "react-router-dom";

class Salidas extends Component {
	
	constructor(props) {
		super(props);
		this.changeHandler = this.changeHandler.bind(this);
	}
	
	state = {
		username:'',
		password:'',
		esNuevo: true,
		seguimiento: '',
		descripcion: '',
		informe: null,
		accion: '',
		loading:true,
		articulos: [],
		IMAGENES: [],
		ID: null,
	};
	
	async componentDidMount(){
		
		if(this.props.location.ID!=null){
			this.state.ID = this.props.location.ID;
			this.state.esNuevo= false;
			this.getData(this.state.ID);
			return;
		}
		else
		if(this.props.location.incidencia==null){
			const { history } = this.props;
			history.push('/incidencias')
			return;
		}
		else{
			this.state.informe= this.props.location.incidencia;
			this.state.informe.SERIE_INCI = this.state.informe.SERIE;
			this.state.informe.NUMDOC_INCI = this.state.informe.NUMDOC;
			this.setState({loading:false,articulos:this.props.location.articulos});
		}
		
	}
	
	quitarImagen(index){
		if(this.state.esNuevo)
			this.state.IMAGENES.splice(index,1);
		else this.state.IMAGENES[index].REMOVE = true;
		this.setState({state: this.state });
	}	
	
	doNothing(day){
		//console.log(day);
	}
	
	changeHandler(event){
		if(event.target.files.length>0)
			this.state.IMAGENES.push({IMG: URL.createObjectURL(event.target.files[0]),file: event.target.files[0]});
		this.setState({state:this.state});
	}
	
	async getData(){
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/incidencias/get_informe_no_conformidad",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.informe = result[0][0];
								
								this.state.descripcion = this.state.informe.DESCRIPCION;
								this.state.accion = this.state.informe.ACCION;
								this.state.seguimiento = this.state.informe.SEGUIMIENTO;
								
								this.state.informe.ARTICULOS = result[1];
								this.state.IMAGENES = result[2];
								this.setState({loading: false });
							})
		.catch((error) => {
			console.error(error);
		});

	}
	
	async generarPDF(){
		this.setState({loading:true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/incidencias/get_PDF_no_conformidad",data)
							.then(result => result.blob())
							.then(result => {
								const file = new Blob([result], {
								  type: "application/pdf"
								});
								//Build a URL from the file
								const fileURL = URL.createObjectURL(file);
								//Open the URL on new Window
								window.open(fileURL);
								
								this.setState({loading: false });
								return;
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.resultado!=0){
									alert('Hecho');
									this.setState({loading: false });
									return;
								}
								else alert('Algo ha salido mal');
								this.setState({loading: false });
								
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async guardar(){
		this.setState({loading:true });
		
		let formData = new FormData();
		formData.append('user', sessionStorage.getItem('user'));
		formData.append('ID', this.state.ID);
		formData.append('descripcion', this.state.descripcion);
		formData.append('accion', this.state.accion);
		formData.append('seguimiento', this.state.seguimiento);
		formData.append('productos', JSON.stringify(this.state.informe.ARTICULOS));
		
		var aux = [];
		
		for(var i=0;i<this.state.IMAGENES.length;i++){
			if(this.state.IMAGENES[i].CAMINO==null)
				formData.append('file',this.state.IMAGENES[i].file)
			if(this.state.IMAGENES[i].REMOVE)
				aux.push({CAMINO: this.state.IMAGENES[i].CAMINO});
		}
		formData.append('borrar_img', JSON.stringify(aux));

		let data ={
			method: 'POST',
			headers: {
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: formData
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/incidencias/guardar_informe_no_conformidad",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.resultado!=0){
									this.setState({loading: false });
									return;
								}
								else alert('Algo ha salido mal');
								this.setState({loading: false });
								
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async crearNuevo(){
		
		this.setState({printing:true });
		
		let formData = new FormData();
		formData.append('user', sessionStorage.getItem('user'));
		formData.append('descripcion', this.state.descripcion);
		formData.append('accion', this.state.accion);
		formData.append('seguimiento', this.state.seguimiento);
		formData.append('codpro', this.state.informe.CODPRO);
		formData.append('IDINCI', this.state.informe.ID);
		formData.append('productos', JSON.stringify(this.state.informe.ARTICULOS));
		for(var i=0;i<this.state.IMAGENES.length;i++){
			formData.append('file',this.state.IMAGENES[i].file)
		}

		
		let data ={
			method: 'POST',
			headers: {
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: formData
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/incidencias/crear_informe_no_conformidad",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.resultado!=0){
									alert('Datos enviados');
									const { history } = this.props;
									history.push('/incidencias')
									return;
								}
								else alert('Algo ha salido mal');
								this.setState({printing: false });
								
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
		
	}
	
	quitarArticulo(index){
		if(this.state.esNuevo)
			this.state.informe.ARTICULOS.splice(index,1);
		else
			this.state.informe.ARTICULOS[index].REMOVE = true;
		this.setState({})
	}
	
	render() {
		return (
		<div>
			
			<h2 style={{marginBottom:25}}>{this.state.esNuevo ? 'NUEVO ' : ''}INFORME DE NO CONFORMIDAD</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&
				<div>
					<table className="tablaTareas">
						<thead>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td style={{textAlign:"center"}}>
									FECHA
								</td>
								<td style={{textAlign:"center"}}>
									Nº DOC.
								</td>
								<td style={{textAlign:"center"}}>
									Nº INCIDENCIA
								</td>
								<td style={{textAlign:"center"}}>
									COD.PRO.
								</td>
								<td>
									NOMBRE PROVEEDOR
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td style={{textAlign:"center"}}>
									{this.state.informe.FECHA_INCI}
								</td>
								<td style={{textAlign:"center"}}>
									{this.state.ID == null ? '-' : this.state.informe.SERIE+"/"+this.state.informe.NUMDOC}
								</td>
								<td style={{textAlign:"center"}}>
									{this.state.informe.SERIE_INCI}/{this.state.informe.NUMDOC_INCI}
								</td>
								<td style={{textAlign:"center"}}>
									{this.state.informe.CODPRO}
								</td>
								<td>
									{this.state.informe.NOMPRO}
								</td>
							</tr>
						</tbody>
					</table>
					{this.state.informe.ARTICULOS.length>0&&
						<table className="tablaTareas" style={{marginTop:20}}>
							<thead>
								<tr style={{backgroundColor:"#339999",color:"#fff"}}>
									<td>COD.ART.</td>
									<td>DESCRIPCIÓN ARTÍCULO</td>
									<td>CANTIDAD</td>
									<td></td>
								</tr>
							</thead>
						{this.state.informe.ARTICULOS.map(({CANTIDAD,CODART,DESCART,IDINCI,REMOVE},index) => (
								<tbody>
								{!REMOVE&&
									<tr>
										<td>{CODART}</td>
										<td>{DESCART}</td>
										<td>{CANTIDAD}</td>
										<td style={{textAlign:"center"}}>
											<button type="button" onClick={() => {this.quitarArticulo(index)}} style={{}}>X</button>
										</td>
									</tr>
								}
								</tbody>
							))}
						</table>
					}
					<p style={{marginBottom:0}}><b>IMÁGENES DEL INFORME</b></p>
					<label for="image_uploads" style={{cursor: 'pointer'}}><button type="button" style={{pointerEvents: 'none'}}>Añadir imagen</button></label>
					<input type="file" id="image_uploads" name="file" onChange={this.changeHandler} style={{display:'none'}} />
					<div style={{width:'100%',display:'block'}}>
						{this.state.IMAGENES.map(({IMG,REMOVE},index) => (
							<div key={index} style={{float:'left'}}>
								{!REMOVE&&<a>
									<img src={IMG} style={{maxHeight:'250px',padding:5,marginRight:-50}} />
									<button type="button" onClick={() => this.quitarImagen(index)} style={{marginBottom:20}}>QUITAR</button>
								</a>
								}
							</div>
						))}
					</div>
					<img style={{height:'0px',width:'100%'}} />
					<p style={{marginBottom:0}}><b>DESCRIPCIÓN DE LA NO CONFORMIDAD</b></p>
					<textarea 
						type="text" 
						value={this.state.descripcion}
						onChange={(e) => this.setState({descripcion: e.target.value})}
						placeholder=""
						style={{marginBottom:10}}
					/>
					<p style={{marginBottom:0}}><b>ACCIÓN CORRECTIVA</b></p>
					<textarea 
						type="text" 
						value={this.state.accion}
						onChange={(e) => this.setState({accion: e.target.value})}
						placeholder=""
						style={{marginBottom:10}}
					/>
					<p style={{marginBottom:0}}><b>SEGUIMIENTO, CONTROL Y CARGO</b></p>
					<textarea 
						type="text" 
						value={this.state.seguimiento}
						onChange={(e) => this.setState({seguimiento: e.target.value})}
						placeholder=""
						style={{marginBottom:10}}
					/>
					<p style={{textAlign:'center'}}>
						<button type="button" onClick={() => {this.state.esNuevo ? this.crearNuevo() : this.guardar()}} style={{marginLeft:10}}>GUARDAR</button>
						<button type="button" onClick={() => this.generarPDF()} style={{marginLeft:10}}>GENERAR PDF</button>
						<Link to={{pathname:"Incidencias"}}><button type="button" onClick={() => this.doNothing()} style={{marginLeft:10}}>CANCELAR CAMBIOS</button></Link>
					</p>
				</div>
			}
      </div>
    );
  }
}
 
export default Salidas;