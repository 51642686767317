import React, { Component } from "react";
import loadingGif from './assets/loading.gif';

class Sillas extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			loading: false,
			productos: [],
		}
		
	}

	componentDidMount(){
		this.getData();
		/*
		const windowUrl = window.location.search;
		const params = windowUrl.substring(11);
		if(params!=null&&params!='')
			this.buscar(params);*/
	}
	
	buscar(numserie){
		this.state.numserie = numserie;
		this.searchSerie();
	}
	
	async getData() {
		
		this.setState({loading:true,resultado:null });
		
		let bodyData = {
				user: sessionStorage.getItem('user'),
				CODART: this.state.numserie
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/stock/get_prevision_sillas_iny",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.setState({loading: false,productos:result });
								
								for(var i=0;i<this.state.productos.length;i++)
									this.getPrevisto(i);
								
								//alert(this.state.data[0].SERIE);
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async getPrevisto(index) {
		let bodyData = {
				user: sessionStorage.getItem('user'),
				CODART: this.state.productos[index].CODART,
		};
		//console.log(index);
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/stock/get_prevision_articulo",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.productos[index].PREVISION = result.PREVISION;
								this.state.productos[index].SEARCH = true;
								this.setState({state:this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
  
	//<p>INTRODUCE NÚMERO DE SERIE: <input></input> <button onClick={()=>this.buscar()}>BUSCAR</button></p>
	render() {
		return (
		<div>
			<h2 style={{marginBottom:25}}>STOCK PIEZAS INYECCIÓN SILLAS</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.productos.length>0&&
				<div>
					<table className="tablaTareas">
						<thead>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td>
									CÓD. ART.
								</td>
								<td>
									DESCRIPCIÓN
								</td>
								<td>
									PREVISIÓN
								</td>
							</tr>
						</thead>
						{this.state.productos.map(({ CODART,DESCART,STOCK,ORDENES,SEARCH,PREVISION },index) => (
						<tbody className="tablaTareas" style={{backgroundColor:"#FFF"}} key={index}>
							<tr>
								<td>
									{CODART}
								</td>
								<td>
									{DESCART}
								</td>
								<td style={{textAlign:"center"}}>
									{!SEARCH&&
										<img alt="Loading..." src={loadingGif} style={{height:50,marginBottom:-20,marginTop:-20}}/>
									}
									{SEARCH&&
										<div>
											{PREVISION}
										</div>
									}
								</td>
							</tr>
						</tbody>
					))}
					</table>
				</div>
			}
		</div>
		);
	}

}
 
export default Sillas;