import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';
import './styles/general.css';

class Salidas extends Component {
	constructor(props) {
		super(props);
		this.onSubmitNothing = this.onSubmitNothing.bind(this);
	}
	
	onSubmitNothing(event){
		event.preventDefault();
	}
	
	state = {
		username:'',
		password:'',
		filtro: '',
		pedidos: [],
		tipo: 'N',
	};
	
	componentDidMount(){
		this.getData();
	}
	
	doNothing(){
		//console.log(day);
	}
	
	
	async getData(){
		
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/get_pedidos",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.pedidos = result[0];
								for(var i=0;i<this.state.pedidos.length;i++){
									this.state.pedidos[i].LINEAS = [];
									this.state.pedidos[i].OPEN = false;
								}
								
								this.setState({loading: false });
							})
							
		.catch((error) => {
			alert(error);
			this.setState({loading: false });
			console.error(error);
		});

	}

	async getLineas(index){
		
		if(this.state.pedidos[index].SEARCH){
			this.state.pedidos[index].OPEN = !this.state.pedidos[index].OPEN;
			this.setState({state: this.state });
			return;
		}
		
		this.state.pedidos[index].CARGANDO = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			IDPEDV: this.state.pedidos[index].IDPEDV,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/get_lineas_pedido",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.length==0){
									alert("Se ha producido algún error");
								}
								else{
									this.state.pedidos[index].LINEAS = result[0];
									this.state.pedidos[index].OPEN = !this.state.pedidos[index].OPEN;
									this.state.pedidos[index].SEARCH = true;
								}
								
								this.state.pedidos[index].CARGANDO = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			this.state.pedidos[index].CARGANDO = false;
			this.setState({state: this.state });
			console.error(error);
		});
	}
	
	empaquetado(index){
		this.state.pedidos[index].OPEN = !this.state.pedidos[index].OPEN;
		this.state.pedidos[index].EMPAQUETADO_OPEN = !this.state.pedidos[index].EMPAQUETADO_OPEN;
		this.setState({state:this.state});
	}

	async crearPaquete(index){
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			LONGITUD: this.state.pedidos[index].LONGITUD_NEW,
			BARRAS: this.state.pedidos[index].BARRAS_NEW,
			COLADA: this.state.pedidos[index].COLADA_NEW,
			ALEACION: this.state.pedidos[index].ALEACION_NEW,
			CODART: this.state.pedidos[index].CODART_NEW,
			IDPEDV: this.state.pedidos[index].IDPEDV
		};

		if(!(bodyData.LONGITUD!=null&&bodyData.LONGITUD!=''&&bodyData.BARRAS!=null&&bodyData.BARRAS!=''&&bodyData.COLADA!=null&&bodyData.COLADA!=''&&bodyData.ALEACION!=null&&bodyData.ALEACION!=''&&bodyData.CODART!=null&&bodyData.CODART!='')){
			alert('¡Rellena todos los datos!');
			return;
		}

		this.state.pedidos[index].CARGANDO = true;
		this.setState({state: this.state });

		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/crear_paquete",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==0){
									alert("Se ha producido algún error");
									this.state.pedidos[index].CARGANDO = false;
								}
								else {
									this.state.pedidos[index].PAQUETES = result.PAQUETES;
									this.state.pedidos[index].CARGANDO = false;
									this.state.pedidos[index].BARRAS_NEW = '';
									this.state.pedidos[index].LONGITUD_NEW = '';
									this.state.pedidos[index].COLADA_NEW = '';
									this.state.pedidos[index].ALEACION_NEW = '';
								}
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			alert(error);
			this.setState({loading: false });
			console.error(error);
		});
	}
	
	async getEtiqueta(IDPAQUETE,index,ind){
		
		this.state.pedidos[index].PAQUETES[ind].CARGANDO = true;
		this.setState({state: this.state });

		let bodyData = {
			user: sessionStorage.getItem('user'),
			IDPAQUETE:IDPAQUETE,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/etiqueta_paquete",data)
							.then(result => result.json())
							.then(result => {
								if(result.DONE!=1){
									alert("No se encuentra el PDF solicitado");
									return;
								}
								// base64 string
								var base64str = result.PDF;

								// decode base64 string, remove space for IE compatibility
								var binary = atob(base64str.replace(/\s/g, ''));
								var len = binary.length;
								var buffer = new ArrayBuffer(len);
								var view = new Uint8Array(buffer);
								for (var i = 0; i < len; i++) {
									view[i] = binary.charCodeAt(i);
								}

								// create the blob object with content-type "application/pdf"               
								var blob = new Blob( [view], { type: "application/pdf" });
								var url = URL.createObjectURL(blob);
								
								window.open(url);
								this.state.pedidos[index].PAQUETES[ind].CARGANDO = false;
								this.setState({state: this.state });
		
								
							})
							
		.catch((error) => {
			this.setState({loading: false });
			alert("ERROR DE RED");
			console.error(error);
		});
	}

	async removeEtiqueta(IDPAQUETE,index){
		let bodyData = {
			user: sessionStorage.getItem('user'),
			IDPAQUETE: IDPAQUETE,
		};

		this.state.pedidos[index].CARGANDO = true;
		this.setState({state: this.state });

		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/eliminar_paquete",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==0){
									alert("Se ha producido algún error");
									this.state.pedidos[index].CARGANDO = false;
								}
								else {
									this.state.pedidos[index].PAQUETES = result.PAQUETES;
									this.state.pedidos[index].CARGANDO = false;
								}
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			alert(error);
			this.setState({loading: false });
			console.error(error);
		});
	}

	render() {
		return (
		<div>
			<h2 style={{marginBottom:25}}>PEDIDOS PENDIENTES</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.pedidos.length>0&&
				<a>
					<table className="tablaTareas">
						<thead>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td style={{width:100}}>
									FECHA
								</td>
								<td style={{width:100}}>
									Nº PEDIDO
								</td>
								<td style={{width:100}}>
									CODCLI
								</td>
								<td>
									NOMCLI
								</td>
								<td>
									OBSERVACIONES
								</td>
								<td>
									EMPAQUETADO
								</td>
							</tr>
						</thead>
					{this.state.pedidos.map(({ SERIE,NUMDOC,IDPEDV,CODCLI,NOMCLI,FECHA_PEDIDO,CARGANDO,OPEN,SEARCH,LINEAS,OBSERVACIONES,EMPAQUETADO_OPEN,PAQUETES,COLADA_NEW,BARRAS_NEW,CODART_NEW,LONGITUD_NEW,ALEACION_NEW },index) => (
						<tbody className="tablaEntrada" style={{backgroundColor: "#FFF"}} key={IDPEDV}>
							<tr onClick={() => this.getLineas(index)}>
								<td>
									{FECHA_PEDIDO}
								</td>
								<td>
									{SERIE}/{NUMDOC}
								</td>
								<td>
									{CODCLI}
								</td>
								<td>
									{NOMCLI}
								</td>
								<td>
									{OBSERVACIONES}
								</td>
								<td onClick={() => this.doNothing()}>
									<button type="button" onClick={() => this.empaquetado(index)} style={{margin:0}}>EMPAQUETADO</button>
								</td>
							</tr>
							{CARGANDO&&
								<tr style={{cursor:"auto"}}>
									<td colspan="6">
										<div style={{textAlign:"center"}}>
											<img alt="Loading..." src={loadingGif} style={{height:50,marginBottom:-20,marginTop:-20}}/>
										</div>
									</td>
								</tr>
							}
							{EMPAQUETADO_OPEN&&
								<tr>
									<td colspan="6" style={{padding:0}}>
										<table className="tablaTareas">
											<tr style={{backgroundColor:"#685642",color:"#fff",cursor:"auto"}}>
												<td>
													ARTÍCULO
												</td>
												<td>
													Nº BARRAS
												</td>
												<td>
													LONGITUD
												</td>
												<td>
													COLADA
												</td>
												<td>
													ALEACIÓN
												</td>
												<td>
													
												</td>
											</tr>
											{PAQUETES!=null&&PAQUETES.map(({ CODART,IDPEDV,BARRAS,LONGITUD,COLADA,ALEACION,ID,CARGANDO },ind) => (
												<tr style={{backgroundColor:"#bcb2a7",color:"#000",cursor:"auto"}} key={ind}>
													<td style={{height:21}}>
														{CODART}
													</td>
													<td>
														{BARRAS}
													</td>
													<td>
														{LONGITUD}
													</td>
													<td>
														{COLADA}
													</td>
													<td>
														{ALEACION}
													</td>
													{!CARGANDO&&
														<td>
															<button type="button" onClick={() => this.getEtiqueta(ID,index,ind)} style={{margin:0}}>ETIQUETA</button>
															<button type="button" onClick={() => this.removeEtiqueta(ID,index)} style={{marginLeft:10}}>X</button>
														</td>
													}
													{CARGANDO&&
														<td style={{textAlign:"center"}}>
															<img alt="Loading..." src={loadingGif} style={{height:50,margin:-30,padding:-30,marginTop:-50,marginBottom:-20}}/>
														</td>
													}
												</tr>
											))}
											<tr style={{backgroundColor:"#bcb2a7",color:"#000",cursor:"auto"}}>
												<td>
													<select style={{margin:0,width:'95%'}} name="articulos" id="articulos" onChange={(e) => {this.state.pedidos[index].CODART_NEW= e.target.value;this.setState({state:this.state})}}>
														<option value="destinatario" disabled selected="selected">Selecciona el artículo</option>
														{LINEAS.map(({ CODART,DESCLIN },index) => (
															<option value={CODART}>{DESCLIN}</option>	
														))}
													</select>
												</td>
												<td>
													<input
														type="number" 
														value={BARRAS_NEW}
														onChange={(e) => {this.state.pedidos[index].BARRAS_NEW = e.target.value;this.setState({state:this.state})}}
														placeholder="Nº Barras"
														style={{margin:0,width:'95%'}}
													/>
												</td>
												<td>
													<input
														type="number" 
														value={LONGITUD_NEW}
														onChange={(e) => {this.state.pedidos[index].LONGITUD_NEW = e.target.value;this.setState({state:this.state})}}
														placeholder="Longitud por barra (metros)"
														style={{margin:0,width:'85%'}}
													/> m
												</td>
												<td>
													<input
														type="text" 
														value={COLADA_NEW}
														onChange={(e) => {this.state.pedidos[index].COLADA_NEW = e.target.value;this.setState({state:this.state})}}
														placeholder="Colada"
														style={{margin:0,width:'95%'}}
													/>
												</td>
												<td>
													<input
														type="text" 
														value={ALEACION_NEW}
														onChange={(e) => {this.state.pedidos[index].ALEACION_NEW = e.target.value;this.setState({state:this.state})}}
														placeholder="Aleación"
														style={{margin:0,width:'95%'}}
													/>
												</td>
												<td>
													<button type="button" onClick={() => this.crearPaquete(index)} style={{margin:0}}>CREAR PAQUETE</button>
												</td>
											</tr>
										</table>
									</td>
								</tr>
							}
							{OPEN&&LINEAS.length>0&&
								<tr>
									<td colspan="6" style={{padding:0}}>
										<table className="tablaTareas">
											{LINEAS.map(({ UNIDADES,CODART,DESCLIN,BARRAS,ALEACION,LONGITUD },ind) => (
												<tbody key={ind}>
													{ind==0&&
														<tr style={{backgroundColor:"#339999",color:"#fff",cursor:"auto"}}>
															<td>
																CÓD. ART.
															</td>
															<td>
																DESCRIPCIÓN ARTÍCULO
															</td>
															<td>
																CANTIDAD (kg)
															</td>
															<td>
																Nº BARRAS
															</td>
															<td>
																LONGITUD (mm)
															</td>
															<td>
																ALEACION
															</td>
														</tr>
													}
													<tr style={{backgroundColor:"#eee",color:"#000",cursor:"auto"}}>
														<td>
															{CODART}
														</td>
														<td>
															{DESCLIN}
														</td>
														<td>
															{UNIDADES}kg
														</td>
														<td>
															{BARRAS}
														</td>
														<td>
															{LONGITUD}mm
														</td>
														<td>
															{ALEACION}
														</td>
													</tr>
												</tbody>
											))}
										</table>
									</td>
								</tr>
							}
						</tbody>
					))}
					</table>
					
				</a>
			}
      </div>
    );
  }
}
 
export default Salidas;