import React, { Component } from "react";
import loadingGif from './assets/loading.gif';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';

class Sillas extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			loading: false,
			pedidos: [],
			fabricacion: [],
			pendiente: [],
			stock: [],
			startDate: null,
			endDate: null,
		}
		
	}

	componentDidMount(){
		this.getPendiente();
		this.getStock();
	}

	async getStock(){
		this.setState({loadingStock:true});
			
		let bodyData = {
			user: sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/stock/stock_totales",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								this.state.stock = result;
								this.setState({loadingStock:false});
							})
							
	.catch((error) => {
		console.error(error);
	});
}

	async getPendiente(){
		this.setState({loadingPendiente:true});
			
		let bodyData = {
			user: sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/stock/pendiente",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.pendiente = result;
								
								if(result[0].TOTAL!=null)
									this.state.total = result[0].TOTAL;
								
								for(var i=0;i<this.state.pendiente.length;i++){
									this.state.pendiente[i].OPEN = false;
									this.state.pendiente[i].SEARCH = true;
									this.state.pendiente[i].loading = false;
									
									
									for(var j=0;j<this.state.pendiente[i].FAMILIA.length;j++){
										this.state.pendiente[i].FAMILIA[j].OPEN = false;
										this.state.pendiente[i].FAMILIA[j].SEARCH = true;
										this.state.pendiente[i].FAMILIA[j].loading = false;
										
									}
									
								}
								
								this.state.pendiente[0].SECCIONES = [];
								if(this.state.pendiente[0].FAMILIA.length>1){
									this.state.pendiente[0].SECCIONES[0] = {SECCION: this.state.pendiente[0].FAMILIA[0].SECCION,CTD: this.state.pendiente[0].FAMILIA[0].CTD,FAMILIAS: [],CAR4:this.state.pendiente[0].FAMILIA[0].CAR4};
									this.state.pendiente[0].SECCIONES[0].FAMILIAS.push(this.state.pendiente[0].FAMILIA[0]);
									for(var i=1;i<this.state.pendiente[0].FAMILIA.length;i++){	//ANDADORES	
										var done = 0;
										for(var j=0;j<this.state.pendiente[0].SECCIONES.length&&done==0;j++){
											if(this.state.pendiente[0].FAMILIA[i].CAR4==this.state.pendiente[0].SECCIONES[j].CAR4){
												done = 1;
												this.state.pendiente[0].SECCIONES[j].FAMILIAS.push(this.state.pendiente[0].FAMILIA[i])
												this.state.pendiente[0].SECCIONES[j].CTD += this.state.pendiente[0].FAMILIA[i].CTD;
											}
										}
										if(done==0){
											this.state.pendiente[0].SECCIONES.push({SECCION: this.state.pendiente[0].FAMILIA[i].SECCION,CTD: this.state.pendiente[0].FAMILIA[i].CTD,FAMILIAS: [],CAR4:this.state.pendiente[0].FAMILIA[i].CAR4});
											this.state.pendiente[0].SECCIONES[this.state.pendiente[0].SECCIONES.length-1].FAMILIAS.push(this.state.pendiente[0].FAMILIA[i])
										}
									}
								}
								this.state.loading = false;
								this.setState({loadingPendiente:false});
							})
							
		.catch((error) => {
			console.error(error);
		});
	}

	async getFabricacionCAR4(DESDE,HASTA,CAR5,CAR4,index,ind) {
		
		if(this.state.fabricacion[index].LINEAS[ind].OPEN){
			this.state.fabricacion[index].LINEAS[ind].OPEN=false;
			this.setState({state:this.state });
			return;
		}
		if(this.state.fabricacion[index].LINEAS[ind].SEARCH){
			this.state.fabricacion[index].LINEAS[ind].OPEN=true;
			this.setState({state:this.state });
			return;
		}

		this.state.fabricacion[index].LINEAS[ind].LOADING = true;
		this.setState({state:this.state });
		if(HASTA==null)
			HASTA = DESDE;
			
		let bodyData = {
			user: sessionStorage.getItem('user'),
			DESDE: ""+(DESDE._d.getMonth()+1)+"/"+DESDE._d.getDate()+"/"+DESDE._d.getFullYear(),
			HASTA: ""+(HASTA._d.getMonth()+1)+"/"+HASTA._d.getDate()+"/"+HASTA._d.getFullYear(),
			CAR5: CAR5,
			CAR4: CAR4,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/estadisticas/fabricacion_diaria",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==1){
									this.state.fabricacion[index].LINEAS[ind].LINEAS = result.fabricacion;
									this.state.fabricacion[index].LINEAS[ind].OPEN = true;
									this.state.fabricacion[index].LINEAS[ind].SEARCH = true;
								}
								else alert("Se ha producido algún error");
								this.state.fabricacion[index].LINEAS[ind].LOADING = false;
								this.setState({state:this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}

	async getFabricacionCAR8(DESDE,HASTA,CAR5,CAR4,CAR8,index,ind,i) {
		
		if(this.state.fabricacion[index].LINEAS[ind].LINEAS[i].OPEN){
			this.state.fabricacion[index].LINEAS[ind].LINEAS[i].OPEN=false;
			this.setState({state:this.state });
			return;
		}
		if(this.state.fabricacion[index].LINEAS[ind].LINEAS[i].SEARCH){
			this.state.fabricacion[index].LINEAS[ind].LINEAS[i].OPEN=true;
			this.setState({state:this.state });
			return;
		}

		this.state.fabricacion[index].LINEAS[ind].LINEAS[i].LOADING = true;
		this.setState({state:this.state });
		if(HASTA==null)
			HASTA = DESDE;
			
		let bodyData = {
			user: sessionStorage.getItem('user'),
			DESDE: ""+(DESDE._d.getMonth()+1)+"/"+DESDE._d.getDate()+"/"+DESDE._d.getFullYear(),
			HASTA: ""+(HASTA._d.getMonth()+1)+"/"+HASTA._d.getDate()+"/"+HASTA._d.getFullYear(),
			CAR5: CAR5,
			CAR8: CAR8,
			CAR4: CAR4,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/estadisticas/fabricacion_diaria",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==1){
									this.state.fabricacion[index].LINEAS[ind].LINEAS[i].LINEAS = result.fabricacion;
									this.state.fabricacion[index].LINEAS[ind].LINEAS[i].OPEN = true;
									this.state.fabricacion[index].LINEAS[ind].LINEAS[i].SEARCH = true;
								}
								else alert("Se ha producido algún error");
								this.state.fabricacion[index].LINEAS[ind].LINEAS[i].LOADING = false;
								this.setState({state:this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}

	async getFabricacionCAR5(DESDE,HASTA,CAR5,index) {

		if(this.state.fabricacion[index].OPEN){
			this.state.fabricacion[index].OPEN=false;
			this.setState({state:this.state });
			return;
		}
		if(this.state.fabricacion[index].SEARCH){
			this.state.fabricacion[index].OPEN=true;
			this.setState({state:this.state });
			return;
		}
		this.state.fabricacion[index].LOADING = true;
		this.setState({state:this.state });
		if(HASTA==null)
			HASTA = DESDE;
			
		let bodyData = {
			user: sessionStorage.getItem('user'),
			DESDE: ""+(DESDE._d.getMonth()+1)+"/"+DESDE._d.getDate()+"/"+DESDE._d.getFullYear(),
			HASTA: ""+(HASTA._d.getMonth()+1)+"/"+HASTA._d.getDate()+"/"+HASTA._d.getFullYear(),
			CAR5: CAR5,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/estadisticas/fabricacion_diaria",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==1){
									this.state.fabricacion[index].LINEAS = result.fabricacion;
									this.state.fabricacion[index].OPEN = true;
									this.state.fabricacion[index].SEARCH = true;
									if(result.fabricacion.length==1){
										this.state.fabricacion[index].LINEAS[0].NOTSHOW = true;
										this.state.fabricacion[index].LOADING = false;
										this.getFabricacionCAR4(DESDE,HASTA,CAR5,result.fabricacion[0].CAR4,index,0);
										return;
									}
								}
								else alert("Se ha producido algún error");
								this.state.fabricacion[index].LOADING = false;
								this.setState({state:this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}

	async getFabricacion(DESDE,HASTA,CAR5,CAR8,CAR4) {
		if(HASTA==null)
			HASTA = DESDE;
		this.setState({loadingFabricacion:true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			DESDE: ""+(DESDE._d.getMonth()+1)+"/"+DESDE._d.getDate()+"/"+DESDE._d.getFullYear(),
			HASTA: ""+(HASTA._d.getMonth()+1)+"/"+HASTA._d.getDate()+"/"+HASTA._d.getFullYear(),
			CAR5: CAR5,
			CAR8: CAR8,
			CAR4: CAR4,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/estadisticas/fabricacion_diaria",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==1){
									this.state.fabricacion = result.fabricacion;
								}
								else alert("Se ha producido algún error");
								this.setState({loadingFabricacion:false});
							})
							
		.catch((error) => {
			console.error(error);
		});
	}

	async getPedidosCAR4(DESDE,HASTA,CAR5,CAR4,index,ind) {
		
		if(this.state.pedidos[index].LINEAS[ind].OPEN){
			this.state.pedidos[index].LINEAS[ind].OPEN=false;
			this.setState({state:this.state });
			return;
		}
		if(this.state.pedidos[index].LINEAS[ind].SEARCH){
			this.state.pedidos[index].LINEAS[ind].OPEN=true;
			this.setState({state:this.state });
			return;
		}

		this.state.pedidos[index].LINEAS[ind].LOADING = true;
		this.setState({state:this.state });
		if(HASTA==null)
			HASTA = DESDE;
			
		let bodyData = {
			user: sessionStorage.getItem('user'),
			DESDE: ""+(DESDE._d.getMonth()+1)+"/"+DESDE._d.getDate()+"/"+DESDE._d.getFullYear(),
			HASTA: ""+(HASTA._d.getMonth()+1)+"/"+HASTA._d.getDate()+"/"+HASTA._d.getFullYear(),
			CAR5: CAR5,
			CAR4: CAR4,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/estadisticas/pedidos_diarios",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==1){
									this.state.pedidos[index].LINEAS[ind].LINEAS = result.pedidos;
									this.state.pedidos[index].LINEAS[ind].OPEN = true;
									this.state.pedidos[index].LINEAS[ind].SEARCH = true;
								}
								else alert("Se ha producido algún error");
								this.state.pedidos[index].LINEAS[ind].LOADING = false;
								this.setState({state:this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}

	async getPedidosCAR8(DESDE,HASTA,CAR5,CAR4,CAR8,index,ind,i) {
		
		if(this.state.pedidos[index].LINEAS[ind].LINEAS[i].OPEN){
			this.state.pedidos[index].LINEAS[ind].LINEAS[i].OPEN=false;
			this.setState({state:this.state });
			return;
		}
		if(this.state.pedidos[index].LINEAS[ind].LINEAS[i].SEARCH){
			this.state.pedidos[index].LINEAS[ind].LINEAS[i].OPEN=true;
			this.setState({state:this.state });
			return;
		}

		this.state.pedidos[index].LINEAS[ind].LINEAS[i].LOADING = true;
		this.setState({state:this.state });
		if(HASTA==null)
			HASTA = DESDE;
			
		let bodyData = {
			user: sessionStorage.getItem('user'),
			DESDE: ""+(DESDE._d.getMonth()+1)+"/"+DESDE._d.getDate()+"/"+DESDE._d.getFullYear(),
			HASTA: ""+(HASTA._d.getMonth()+1)+"/"+HASTA._d.getDate()+"/"+HASTA._d.getFullYear(),
			CAR5: CAR5,
			CAR8: CAR8,
			CAR4: CAR4,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/estadisticas/pedidos_diarios",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==1){
									this.state.pedidos[index].LINEAS[ind].LINEAS[i].LINEAS = result.pedidos;
									this.state.pedidos[index].LINEAS[ind].LINEAS[i].OPEN = true;
									this.state.pedidos[index].LINEAS[ind].LINEAS[i].SEARCH = true;
								}
								else alert("Se ha producido algún error");
								this.state.pedidos[index].LINEAS[ind].LINEAS[i].LOADING = false;
								this.setState({state:this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}

	async getPedidosCAR5(DESDE,HASTA,CAR5,index) {

		if(this.state.pedidos[index].OPEN){
			this.state.pedidos[index].OPEN=false;
			this.setState({state:this.state });
			return;
		}
		if(this.state.pedidos[index].SEARCH){
			this.state.pedidos[index].OPEN=true;
			this.setState({state:this.state });
			return;
		}
		this.state.pedidos[index].LOADING = true;
		this.setState({state:this.state });
		if(HASTA==null)
			HASTA = DESDE;
			
		let bodyData = {
			user: sessionStorage.getItem('user'),
			DESDE: ""+(DESDE._d.getMonth()+1)+"/"+DESDE._d.getDate()+"/"+DESDE._d.getFullYear(),
			HASTA: ""+(HASTA._d.getMonth()+1)+"/"+HASTA._d.getDate()+"/"+HASTA._d.getFullYear(),
			CAR5: CAR5,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/estadisticas/pedidos_diarios",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==1){
									this.state.pedidos[index].LINEAS = result.pedidos;
									this.state.pedidos[index].OPEN = true;
									this.state.pedidos[index].SEARCH = true;
									if(result.pedidos.length==1){
										this.state.pedidos[index].LINEAS[0].NOTSHOW = true;
										this.state.pedidos[index].LOADING = false;
										this.getPedidosCAR4(DESDE,HASTA,CAR5,result.pedidos[0].CAR4,index,0);
										return;
									}
								}
								else alert("Se ha producido algún error");
								this.state.pedidos[index].LOADING = false;
								this.setState({state:this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}


	getStockProducts(familia,tipo){
		if(!this.state.stock[familia].FAMILIA[tipo].SEARCH) {
			this.state.stock[familia].FAMILIA[tipo].OPEN = true;
			this.setState({state: this.state });
			this.state.stock[familia].FAMILIA[tipo].loading = true;
			this.doGetStockProducts(familia,tipo);
		}
		else{
			if(this.state.stock[familia].FAMILIA[tipo].OPEN)
				this.state.stock[familia].FAMILIA[tipo].OPEN = false;
			else this.state.stock[familia].FAMILIA[tipo].OPEN = true;
			this.setState({state: this.state });
		}
	}
	
	getStockFamily(familia){
		if(!this.state.stock[familia].SEARCH) {
			this.state.stock[familia].INDEX = familia;
			this.state.stock[familia].OPEN = true;
			this.state.stock[familia].loading = true;
			this.setState({state: this.state });
			this.doGetStockFamily(familia);
		}
		else{
			if(this.state.stock[familia].OPEN)
				this.state.stock[familia].OPEN = false;
			else this.state.stock[familia].OPEN = true;
			this.setState({state: this.state });
		}
	}
	
	async doGetStockFamily(familia){
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			familia: this.state.stock[familia].CAR5,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/stock/stock_familia",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								//this.state.clientsList = result;
								
								this.state.stock[familia].FAMILIA = result;
								for(var i=0;i<this.state.stock[familia].FAMILIA.length;i++){
									this.state.stock[familia].FAMILIA[i].OPEN = false;
									this.state.stock[familia].FAMILIA[i].SEARCH = false;
									this.state.stock[familia].FAMILIA[i].loading = true;
								}
								
								this.state.stock[familia].OPEN = true;
								this.state.stock[familia].SEARCH = true;
								this.state.stock[familia].loading = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	
	async doGetStockProducts(familia,tipo){
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			tipo: this.state.stock[familia].FAMILIA[tipo].CAR8,
			familia: this.state.stock[familia].CAR5,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/stock/stock_tipo",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								//this.state.clientsList = result;
								
								this.state.stock[familia].FAMILIA[tipo].PRODUCTOS = result;
								this.state.stock[familia].FAMILIA[tipo].OPEN = true;
								this.state.stock[familia].FAMILIA[tipo].SEARCH = true;
								this.state.stock[familia].FAMILIA[tipo].loading = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}


	async getPedidos(DESDE,HASTA,CAR5,CAR8,CAR4) {
		if(HASTA==null)
			HASTA = DESDE;
		this.setState({loadingPedidos:true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			DESDE: ""+(DESDE._d.getMonth()+1)+"/"+DESDE._d.getDate()+"/"+DESDE._d.getFullYear(),
			HASTA: ""+(HASTA._d.getMonth()+1)+"/"+HASTA._d.getDate()+"/"+HASTA._d.getFullYear(),
			CAR5: CAR5,
			CAR8: CAR8,
			CAR4: CAR4,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/estadisticas/pedidos_diarios",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==1){
									this.state.pedidos = result.pedidos;
								}
								else alert("Se ha producido algún error");
								this.setState({loadingPedidos:false});
							})
							
		.catch((error) => {
			console.error(error);
		});
	}

	calcularTotales(){
		this.state.andadoresTotal = 0;
		for(var i=0;i<this.state.andadores.length;i++){
			this.state.andadoresTotal+=this.state.andadores[i].QTY;
			this.state.andadores[i].OPEN = false;
			this.state.andadores[i].SEARCH = false;
			this.state.andadores[i].loading = true;
		}
		
		this.state.bastonesTotal = 0;
		for(var i=0;i<this.state.bastones.length;i++){
			this.state.bastonesTotal+=this.state.bastones[i].QTY;
			this.state.bastones[i].OPEN = false;
			this.state.bastones[i].SEARCH = false;
			this.state.bastones[i].loading = true;
		}
		
		this.state.sillasTotal = 0;
		for(var i=0;i<this.state.sillas.length;i++){
			this.state.sillasTotal+=this.state.sillas[i].QTY;
			this.state.sillas[i].OPEN = false;
			this.state.sillas[i].SEARCH = false;
			this.state.sillas[i].loading = true;
		}
		
		this.state.gruasTotal = 0;
		for(var i=0;i<this.state.gruas.length;i++){
			this.state.gruasTotal+=this.state.gruas[i].QTY;
			this.state.gruas[i].OPEN = false;
			this.state.gruas[i].SEARCH = false;
			this.state.gruas[i].loading = true;
		}
		
	}
	
	doNothing(){

	}

	search(){
		if(this.state.startDate==null) return; 
		else {
			this.getPedidos(this.state.startDate,this.state.endDate);
			this.getFabricacion(this.state.startDate,this.state.endDate)
		}
	}

	getFamily(familia){
		if(this.state.pendiente[familia].OPEN)
			this.state.pendiente[familia].OPEN = false;
		else this.state.pendiente[familia].OPEN = true;
		this.state.pendiente[familia].INDEX = familia;
		this.setState({state: this.state });
	}

	getProducts(familia,tipo){
		if(this.state.pendiente[familia].FAMILIA[tipo].OPEN)
				this.state.pendiente[familia].FAMILIA[tipo].OPEN = false;
		else this.state.pendiente[familia].FAMILIA[tipo].OPEN = true;
			this.setState({state: this.state });
		return;
	}

	getProductos(indexPedidos,index,indexFamilia){
		if(this.state.pendiente[indexPedidos].SECCIONES[index].FAMILIAS[indexFamilia].OPEN)
				this.state.pendiente[indexPedidos].SECCIONES[index].FAMILIAS[indexFamilia].OPEN = false;
		else this.state.pendiente[indexPedidos].SECCIONES[index].FAMILIAS[indexFamilia].OPEN = true;
			this.setState({state: this.state });
		return;
	}

	render() {
		return (
		<div>
			<div style={{float: "right",marginRight:50}}>
				<DateRangePicker
					startDate={this.state.startDate} // momentPropTypes.momentObj or null,
					startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
					endDate={this.state.endDate} // momentPropTypes.momentObj or null,
					endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
					onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
					focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
					startDatePlaceholderText= "Desde"
					endDatePlaceholderText= "Hasta"
					isOutsideRange={(day) => this.doNothing(day)}
					displayFormat="DD/MM/yyyy"
					firstDayOfWeek={1}
				/>
				<button type="button" onClick={() => this.search()} style={{marginLeft:50}}>BUSCAR</button>
			</div>
			<h2 style={{marginBottom:25}}>CUADRO DE MANDO</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&
				<div style={{width: "100%",height: "auto",border: "2px solid #313642",display: "grid",gridTemplateColumns: "1fr 1fr",gridTemplateRows: "1fr 1fr"}}>
					<div style={{border: "1px solid #313642",gridColumn: "1 / 2", gridRow: "1 / 2",height:'350px',overflow: "auto",position:'relative',textAlign:'center'}}>
						<h1 style={{padding:5,margin:0,position:'sticky',top:0,backgroundColor:'#313642',color:'white'}}>PEDIDOS DIARIOS</h1>
						{this.state.loadingPedidos&&
							<img alt="Loading..." src={loadingGif} style={{maxHeight: '295px', maxWidth: '100%', width: 'auto', height: 'auto'}}/>
						}
						{!this.state.loadingPedidos&&
							<table className="tablaEntrada">
								<thead>
									<tr style={{backgroundColor:"#339999",color:"#fff"}}>
										<td>
											ARTÍCULO
										</td>
										<td>
											CANTIDAD
										</td>
									</tr>
								</thead>
									{this.state.pedidos.map(({CAR5,NOMBRE,UNIDADES,LOADING,LINEAS,OPEN,NOTSHOW},index) => (
										<tbody className="tablaEntrada" style={{backgroundColor:"#FFF"}} key={index}>
											<tr key={index}  onClick={() => this.getPedidosCAR5(this.state.startDate,this.state.endDate,CAR5,index)}>
												<td>{NOMBRE}</td>
												<td>{UNIDADES}</td>
											</tr>
											{LOADING&&<tr><td><img alt="Loading..." src={loadingGif} style={{height:50,margin:-10}}/></td></tr>}
											{!LOADING&&OPEN&&LINEAS!=null&&LINEAS.length>0&&LINEAS.map(({CAR4,NOMBRE,UNIDADES,LOADING,LINEAS,OPEN,NOTSHOW},ind) => (
												<>
												{!NOTSHOW&&
													<tr key={ind} style={{backgroundColor:"#eee"}} onClick={() => this.getPedidosCAR4(this.state.startDate,this.state.endDate,CAR5,CAR4,index,ind)}>
														<td>{NOMBRE}</td>
														<td>{UNIDADES}</td>
													</tr>
												}
												{LOADING&&<tr><td><img alt="Loading..." src={loadingGif} style={{height:50,margin:-10}}/></td></tr>}
												{!LOADING&&OPEN&&LINEAS!=null&&LINEAS.length>0&&LINEAS.map(({CAR8,NOMBRE,UNIDADES,LOADING,LINEAS,OPEN,NOTSHOW},i) => (
													<>
													{!NOTSHOW&&
														<tr key={i} style={{backgroundColor:"#DDD"}} onClick={() => this.getPedidosCAR8(this.state.startDate,this.state.endDate,CAR5,CAR4,CAR8,index,ind,i)}>
															<td>{NOMBRE}</td>
															<td>{UNIDADES}</td>
														</tr>
													}
													{LOADING&&<tr><td><img alt="Loading..." src={loadingGif} style={{height:50,margin:-10}}/></td></tr>}
													{!LOADING&&OPEN&&LINEAS!=null&&LINEAS.length>0&&LINEAS.map(({DESCART,UNIDADES},INDEX) => (
														<tr key={INDEX} style={{backgroundColor:"#ccc"}}>
															<td>{DESCART}</td>
															<td>{UNIDADES}</td>
														</tr>
													))}
													</>
												))}
												</>
											))}
										</tbody>
									))}
							</table>
						}
					</div>
					<div style={{border: "1px solid #313642",gridColumn: "2 / 3", gridRow: "1 / 2",height:'350px',overflow: "auto",textAlign:'center'}}>
						<h1 style={{padding:5,margin:0,position:'sticky',top:0,backgroundColor:'#313642',color:'white'}}>FABRICACIÓN DIARIA</h1>
						{this.state.loadingFabricacion&&
							<img alt="Loading..." src={loadingGif} style={{maxHeight: '295px', maxWidth: '100%', width: 'auto', height: 'auto'}}/>
						}
						{!this.state.loadingFabricacion&&
							<table className="tablaEntrada">
								<thead>
									<tr style={{backgroundColor:"#339999",color:"#fff"}}>
										<td>
											ARTÍCULO
										</td>
										<td>
											CANTIDAD
										</td>
									</tr>
								</thead>
									{this.state.fabricacion.map(({CAR5,NOMBRE,UNIDADES,LOADING,LINEAS,OPEN,NOTSHOW},index) => (
										<tbody className="tablaEntrada" style={{backgroundColor:"#FFF"}} key={index}>
											<tr key={index}  onClick={() => this.getFabricacionCAR5(this.state.startDate,this.state.endDate,CAR5,index)}>
												<td>{NOMBRE}</td>
												<td>{UNIDADES}</td>
											</tr>
											{LOADING&&<tr><td><img alt="Loading..." src={loadingGif} style={{height:50,margin:-10}}/></td></tr>}
											{!LOADING&&OPEN&&LINEAS!=null&&LINEAS.length>0&&LINEAS.map(({CAR4,NOMBRE,UNIDADES,LOADING,LINEAS,OPEN,NOTSHOW},ind) => (
												<>
												{!NOTSHOW&&
													<tr key={ind} style={{backgroundColor:"#eee"}} onClick={() => this.getFabricacionCAR4(this.state.startDate,this.state.endDate,CAR5,CAR4,index,ind)}>
														<td>{NOMBRE}</td>
														<td>{UNIDADES}</td>
													</tr>
												}
												{LOADING&&<tr><td><img alt="Loading..." src={loadingGif} style={{height:50,margin:-10}}/></td></tr>}
												{!LOADING&&OPEN&&LINEAS!=null&&LINEAS.length>0&&LINEAS.map(({CAR8,NOMBRE,UNIDADES,LOADING,LINEAS,OPEN,NOTSHOW},i) => (
													<>
													{!NOTSHOW&&
														<tr key={i} style={{backgroundColor:"#DDD"}} onClick={() => this.getFabricacionCAR8(this.state.startDate,this.state.endDate,CAR5,CAR4,CAR8,index,ind,i)}>
															<td>{NOMBRE}</td>
															<td>{UNIDADES}</td>
														</tr>
													}
													{LOADING&&<tr><td><img alt="Loading..." src={loadingGif} style={{height:50,margin:-10}}/></td></tr>}
													{!LOADING&&OPEN&&LINEAS!=null&&LINEAS.length>0&&LINEAS.map(({DESCART,UNIDADES},INDEX) => (
														<tr key={INDEX} style={{backgroundColor:"#ccc"}}>
															<td>{DESCART}</td>
															<td>{UNIDADES}</td>
														</tr>
													))}
													</>
												))}
												</>
											))}
										</tbody>
									))}
							</table>
						}
					</div>
					<div style={{border: "1px solid #313642",gridColumn: "1 / 2", gridRow: "2 / 3",height:'350px',overflow: "auto",textAlign:'center'}}>
						<h1 style={{padding:5,margin:0,position:'sticky',top:0,backgroundColor:'#313642',color:'white'}}>PENDIENTE ESTRICTO</h1>
						{this.state.loadingPendiente&&
							<img alt="Loading..." src={loadingGif} style={{maxHeight: '295px', maxWidth: '100%', width: 'auto', height: 'auto'}}/>
						}
						{!this.state.loadingPendiente&&
							<table className="tablaEntrada">
								<thead>
									<tr style={{backgroundColor:"#339999",color:"#fff"}}>
										<td>
											ARTÍCULO
										</td>
										<td>
											CANTIDAD
										</td>
									</tr>
								</thead>
									{this.state.pendiente.map(({CAR5,DESCCAR,CTD,loading,SECCIONES,FAMILIA,OPEN},indexPendiente) => (
										<tbody className="tablaEntrada" style={{backgroundColor:"#FFF"}} key={indexPendiente}>
											<tr key={indexPendiente}  onClick={() => this.getFamily(indexPendiente)}>
												<td>{DESCCAR}</td>
												<td>{CTD}</td>
											</tr>

											{!loading&&OPEN&&SECCIONES==null&&FAMILIA.map(({CAR8,CAR4,CTD,OPEN,SEARCH,loading,PRODUCTOS,DESCCAR},index) => (
												<>
													<tr key={CAR8} style={{backgroundColor:"#ddd"}} onClick={() => this.getProducts(indexPendiente,index)}>
														<td>{DESCCAR}</td>
														<td>{CTD}</td>
													</tr>
												{!loading&&OPEN&&PRODUCTOS.map(({CODART,DESCART,CTD},index) => (
													<>
														<tr key={index} style={{backgroundColor:"#ccc"}}>
															<td>{DESCART}</td>
															<td>{CTD}</td>
														</tr>
													</>
												))}
												</>
											))}

											{!loading&&OPEN&&SECCIONES!=null&&SECCIONES.map(({CAR8,CAR4,CTD,OPEN,SEARCH,loading,FAMILIAS,SECCION},index) => (
												<>
													<tr key={SECCION} style={{backgroundColor:"#eee"}} onClick={() => {this.state.pendiente[indexPendiente].SECCIONES[index].OPEN = !this.state.pendiente[indexPendiente].SECCIONES[index].OPEN;this.setState({state:this.state})}}>
														<td>{SECCION}</td>
														<td>{CTD}</td>
													</tr>
													{!loading&&OPEN&&FAMILIAS.map(({CAR8,CAR4,CTD,OPEN,SEARCH,loading,PRODUCTOS,DESCCAR},indexFamilia) => (
														<>
															<tr key={CAR8} style={{backgroundColor:"#ddd"}} onClick={() => this.getProductos(indexPendiente,index,indexFamilia)}>
																<td>{DESCCAR}</td>
																<td>{CTD}</td>
															</tr>
														{!loading&&OPEN&&PRODUCTOS.map(({CODART,DESCART,CTD},index) => (
															<>
																<tr key={index} style={{backgroundColor:"#ccc"}}>
																	<td>{DESCART}</td>
																	<td>{CTD}</td>
																</tr>
															</>
														))}
														</>
													))}
												</>
											))}
										</tbody>
									))}
							</table>
						}
					</div>
					<div style={{border: "1px solid #313642",gridColumn: "2 / 2", gridRow: "2 / 3",height:'350px',overflow: "auto",textAlign:'center'}}>
						<h1 style={{padding:5,margin:0,position:'sticky',top:0,backgroundColor:'#313642',color:'white'}}>STOCK</h1>
						{this.state.loadingStock&&
							<img alt="Loading..." src={loadingGif} style={{maxHeight: '295px', maxWidth: '100%', width: 'auto', height: 'auto'}}/>
						}
						{!this.state.loadingStock&&
							<table className="tablaEntrada">
								<thead>
									<tr style={{backgroundColor:"#339999",color:"#fff"}}>
										<td>
											ARTÍCULO
										</td>
										<td>
											CANTIDAD
										</td>
									</tr>
								</thead>
									{this.state.stock.map(({CAR5,DESCCAR,CTD,loading,SECCIONES,FAMILIA,OPEN},indexStock) => (CTD!=0&&
										<tbody className="tablaEntrada" style={{backgroundColor:"#FFF"}} key={indexStock}>
											<tr key={indexStock}  onClick={() => this.getStockFamily(indexStock)}>
												<td>{DESCCAR}</td>
												<td>{CTD}</td>
											</tr>
											{!loading&&OPEN&&FAMILIA.map(({CAR8,CAR4,CTD,OPEN,SEARCH,loading,PRODUCTOS,DESCCAR},index) => (CTD!=0&&
												<>
													<tr key={CAR8} style={{backgroundColor:"#ddd"}} onClick={() => this.getStockProducts(indexStock,index)}>
														<td>{DESCCAR}</td>
														<td>{CTD}</td>
													</tr>
												{!loading&&OPEN&&PRODUCTOS.map(({CODART,DESCART,CTD},index) => (CTD!=0&&
													<>
														<tr key={index} style={{backgroundColor:"#ccc"}}>
															<td>{DESCART}</td>
															<td>{CTD}</td>
														</tr>
													</>
												))}
												</>
											))}
										</tbody>
									))}
							</table>
						}
					</div>
				</div>
			}
		</div>
		);
	}

}
 
export default Sillas;