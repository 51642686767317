import React, { Component, } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';
import moment from "moment"
import {
	Resizable,
	Charts,
	ChartContainer,
	ChartRow,
	YAxis,
	BarChart,
	styler,
	EventChart,
	TimeMarker
} from "react-timeseries-charts";
import { TimeSeries, TimeRange, Index } from "pondjs";

class Salidas extends Component {
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	state = {
		loading: false,
		timerange: null,
		data: null,
		timeseries: null,
		today: new Date(),
		yesterday : new Date(),
		tomorrow: new Date(),
		esperando: true,
		originalTimerange: null,
		selected: {id:"",title:""},
		hover: {id:"",title:""},
		now: new Date(),
		tiempos: [],
		es_maquina: 1,
		startDate: null,
	};
	
	componentDidMount(){
	}
	
	handleCheckbox = () => {
		this.setState({es_maquina: this.state.es_maquina==1 ? 0 : 1})
	};
	
	diferenciaTiempo(FIN,INICIO,PAUSA){
		var fin = new Date(this.getDateForSQL(FIN));
		var inicio = new Date(this.getDateForSQL(INICIO));
		const diffTime = Math.abs(fin - inicio);
		const diffMin = Math.ceil(diffTime / (1000 * 60)); 
		return (Math.ceil(diffMin-(PAUSA*60)))+" min";
	}
	
	getDateForSQL(date){
		var auxDate = date;
		var auxDay = date.substring(0,2);
		auxDay=auxDay.replace("/","");
		auxDate = auxDate.substring(auxDay.length+1);
		var auxMonth = auxDate.substring(0,2);
		auxMonth=auxMonth.replace("/","");
		var auxYear = auxDate.replace(auxMonth+"/","");
		if(auxDay.length<2) auxDay= "0"+auxDay;
		if(auxMonth.length<2) auxMonth= "0"+auxMonth;
		auxDate = auxMonth+"/"+auxDay+"/"+auxYear;
		
		return auxDate;
	}
	
	tiempoPorPieza(FIN,INICIO,CANTIDAD,PAUSA,NOMBRE){
		
		if(CANTIDAD==0)
			return PAUSA != null ? this.state.es_maquina&&NOMBRE!='SIERRA'&&NOMBRE!='CURVADORA 1'&&NOMBRE!='CURVADORA 2' ? "(en avería rápida "+(PAUSA*60).toFixed(2)+" minutos)" : "(pausado durante "+(PAUSA*60).toFixed(2)+" minutos)" : "";
		var fin = new Date(this.getDateForSQL(FIN));
		var inicio = new Date(this.getDateForSQL(INICIO));
		const diffTime = Math.abs(fin - inicio);
		const diffMin = Math.ceil(diffTime / (1000)); //segundos
		
		return "("+((Math.ceil(diffMin-(PAUSA*60*60)))/CANTIDAD).toFixed(2)+" segundos por pieza"+(PAUSA!=null ? this.state.es_maquina&&NOMBRE!='SIERRA'&&NOMBRE!='CURVADORA 1'&&NOMBRE!='CURVADORA 2' ? ", en avería rápida "+(PAUSA*60).toFixed(2)+" minutos)" : ", pausado durante "+(PAUSA*60).toFixed(2)+" minutos)" : ")");
	}
	
	async handleSubmit(event){
		
		if(this.state.loading) return;
		
		if(this.state.startDate==null)
			return;
		
		this.setState({loading: true,tiempos:[] });
		
		this.state.today=""+(this.state.startDate._d.getMonth()+1)+"/"+this.state.startDate._d.getDate()+"/"+this.state.startDate._d.getFullYear();
		if(this.state.endDate!=null)
			this.state.tomorrow = ""+(this.state.endDate._d.getMonth()+1)+"/"+this.state.endDate._d.getDate()+"/"+this.state.endDate._d.getFullYear();
		else this.state.tomorrow= this.state.today;
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			date: this.state.tomorrow,
			yesterday: this.state.today,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/cronograma/general",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								var fmt='DD/MM/YYYY HH:mm:ss';
								//SIERRA
								var tiempos = result.tiempos;
								
								this.state.now = result.tiempoActual;
								
								this.state.today = new Date(this.state.today);
								this.state.tomorrow = new Date(this.state.tomorrow);
								console.log(tiempos);
								tiempos.forEach( (tiempo,ind) => {
									var points = [];
									var suma = 0;
									tiempo.forEach( (t,index) => {
										suma += t.TIEMPO;
										if(t.ESTADO=='INCIDENCIA'){
											points.push([new TimeRange(moment(t.INICIO,fmt),moment(t.FIN,fmt)),"INCIDENCIA "+t.SERIE+"/"+t.NUMDOC+", TÍTULO: "+t.TITULO,"INCIDENCIA",t.ESTADO,t.ID+"I",null,null]);
										}
										else if(t.ESTADO=='PAUSA'){
											points.push([new TimeRange(moment(t.INICIO,fmt),moment(t.FIN,fmt)),"EN AVERÍA RÁPIDA DE "+t.INICIO+" A "+t.FIN+" UN TOTAL DE "+this.diferenciaTiempo(t.FIN,t.INICIO,0),"PAUSA",t.ESTADO,t.ID+"P"+index+ind,null,null]);
										}
										else if(t.ESTADO!='EMPTY'){
											if(t.ID_PROCESO=='LIMPIEZA'){
												t.TIPO='LIMPIEZA';
											}
											else t.TIPO='Tipo';
											
											if(t.ESTADO=='A'&&t.PREPARACION==null)	//estamos cambiando el molde
												t.ESTADO='C';
											
											if(t.PREPARACION!=null&&t.PREPARACION!=t.INICIO){
												points.push([new TimeRange(moment(t.INICIO,fmt),moment(t.PREPARACION,fmt)),t.INICIO+" - "+t.PREPARACION+" - TOTAL: "+this.diferenciaTiempo(t.PREPARACION,t.INICIO,0)+'\n'+"PREPARACIÓN / CAMBIO DE MOLDE","CAMBIO_MOLDE",'M',t.ID+"CAMBIO",t.INCIDENCIAS,0]);
												points.push([new TimeRange(moment(t.PREPARACION,fmt),moment(t.FIN,fmt)),t.PREPARACION+" - "+t.FIN+" - TOTAL: "+this.diferenciaTiempo(t.FIN,t.PREPARACION,t.PAUSED_TIME)+" "+this.tiempoPorPieza(t.FIN,t.PREPARACION,t.CANTIDAD,t.PAUSED_TIME,t.NOMBRE)+'\n'+t.DESCART+" - "+t.CANTIDAD+"uds.","Tipo",t.ESTADO,t.ID,t.INCIDENCIAS,t.PAUSED_TIME]); 
											}
											else {
												if(t.ID_PROCESO=='LIMPIEZA'){
													points.push([new TimeRange(moment(t.INICIO,fmt),moment(t.FIN,fmt)),t.INICIO+" - "+t.FIN+" - TOTAL: "+this.diferenciaTiempo(t.FIN,t.INICIO,t.PAUSED_TIME)+'\n'+"LIMPIEZA DE HORNO",t.TIPO,t.ESTADO,t.ID,t.INCIDENCIAS,t.PAUSED_TIME]);
												}
												else points.push([new TimeRange(moment(t.INICIO,fmt),moment(t.FIN,fmt)),t.INICIO+" - "+t.FIN+" - TOTAL: "+this.diferenciaTiempo(t.FIN,t.INICIO,t.PAUSED_TIME)+" "+this.tiempoPorPieza(t.FIN,t.INICIO,t.CANTIDAD,t.PAUSED_TIME,t.NOMBRE)+'\n'+t.DESCART+" - "+t.CANTIDAD+"uds.",t.TIPO,t.ESTADO,t.ID,t.INCIDENCIAS,t.PAUSED_TIME]);
											}/*
											if(t.DESCART=='DESCARGA')
												console.log(points);
											*/
										}
									});
									var data = {
										name: "sierra",
										columns: ["timerange","title","type","estado","id","inci","pause"],
										points: points,
										tz: 'ES'
									};
									
									this.state.tiempos.push({
										data: data,
										timeseries: new TimeSeries(data),
										nombre: tiempo.length>0 ? tiempo[0].NOMBRE : '',
										es_maquina: tiempo.length>0 ? tiempo[0].MAQUINA : 0,
										show: tiempo.length>0,
										marcas: tiempo[0].ENCENDIDOS==null ? [] : tiempo[0].ENCENDIDOS,
										suma: (suma/60).toFixed(2),
									});
									
								});
								/*
								
								//CURVADORA1
								var tiempos = result.tiemposCurvadora1;
								var points = [];
								tiempos.forEach( tiempo => {
									points.push([new TimeRange(moment(tiempo.INICIO,fmt),moment(tiempo.FIN,fmt)),tiempo.DESCART+" - "+tiempo.CANTIDAD+"uds.","Tipo",tiempo.ESTADO,tiempo.ID]);
								});
								this.state.data = {
									name: "sierra",
									columns: ["timerange","title","type","estado","id"],
									points: points,
									tz: 'ES'
								};
								this.state.timeseriesCurvadora1 = new TimeSeries(this.state.data);
								
								//CURVADORA1
								var tiempos = result.tiemposCurvadora2;
								var points = [];
								tiempos.forEach( tiempo => {
									points.push([new TimeRange(moment(tiempo.INICIO,fmt),moment(tiempo.FIN,fmt)),tiempo.DESCART+" - "+tiempo.CANTIDAD+"uds.","Tipo",tiempo.ESTADO,tiempo.ID]);
								});
								this.state.data = {
									name: "sierra",
									columns: ["timerange","title","type","estado","id"],
									points: points,
									tz: 'ES'
								};
								this.state.timeseriesCurvadora2 = new TimeSeries(this.state.data);
								*/
								
								
								//this.state.timerange = this.state.timeseriesCurvadora1.timerange();
								//this.state.originalTimerange = this.state.timerange;
								this.state.timerange = new TimeRange(this.state.today.getTime(),this.state.tomorrow.getTime()+86400000);
								this.setState({loading: false,esperando:false });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	doNothing(day){
		//console.log(day);
	}
	
	colorear(event){
		
		var color = event.get("title").includes("CAMBIO") ? "#5a5aff" : "#339999";
		if(event.get("title").includes("DESCARGA")||event.get("title").includes("ORGANIZ")||event.get("type").includes("CAMBIO_MOLDE")||event.get("title").includes("CAMBIAR DISCO")||event.get("title").includes("LIMPIAR SIERRA"))
			color = '#5a5aff'
		if(event.get("inci")!=null&&event.get("inci")!='00000'&&event.get("inci").length>4)
			color = '#ff3a3a'
		if(event.get("inci")!=null&&(event.get("inci").length==4&&event.get("inci")!='0000'))
			color = '#ff3a3a'
		
		if(event.get("pause")!=null)
			color="#fff000";
		
		
		if(event.get("estado")=='A') 
			if(event.get("pause")!=null)
				color="#fff994";
			else
				color="#cdeeee";
		if(event.get("estado")=='C') 
			color="#c5c5ff";
		if(event.get("estado")=='M') 
			color="#5a5aff";
		
		if(event.get("estado")=='INCIDENCIA') 
			color="#ff4444";
		
		if(event.get("type")=='LIMPIEZA') 
			if(event.get("estado")=='A') 
				color="#edffc5";
			else color="#bfff36";
			
		if(event.get("estado")=='PAUSA') 
			color="#ff9600";
		
		if(this.state.selected.id==event.get("id")||this.state.hover.id==event.get("id"))
			return {
					fill: color,
					opacity: 0.4
				};
		return {
			fill: color
		};
		/*
		switch (state) {
			case "normal":
				return {
					fill: color
				};
			case "hover":
				return {
					fill: color,
					opacity: 0.4
				};
			case "selected":
				return {
					fill: color
				};
			default:
				return {
					fill: color,
					opacity: 0.4
				};
		}*/
	}
	
	handleTrackerChanged = tracker => {
		//console.log(tracker);
	}
	
	handleTimeRangeChange = timerange => {
		
		if(timerange.begin().getTime()<this.state.today.getTime()||timerange.end().getTime()>this.state.tomorrow.getTime()+86400000)
			if(timerange.end().getTime()-timerange.begin().getTime()==this.state.timerange.end().getTime()-this.state.timerange.begin().getTime())
				return;
		
		this.state.timerange = timerange;
		
		if(this.state.timerange.begin().getTime()<this.state.today.getTime())
			this.state.timerange = new TimeRange(this.state.today.getTime(),this.state.timerange.end().getTime());
		
		if(this.state.timerange.end().getTime()>this.state.tomorrow.getTime()+86400000)
			this.state.timerange = new TimeRange(this.state.timerange.begin().getTime(),this.state.tomorrow.getTime()+86400000);
		
			
		this.setState({ state:this.state });
	};
	
	getSelected(){
		if(this.state.selected.id!='')
			return this.state.selected.title;
		return this.state.hover.title;
	}
	
	
	render() {
		/*
		const series = new TimeSeries({
		  name: "hilo_rainfall",
		  columns: ["index", "precip"],
		  points: this.state.data.map(([d, value]) => [
			Index.getIndexString("1h", new Date(d)),
			value
		  ])
		});*/
		if(this.state.esperando){
			return (
				<div>
					<div style={{float: "right",marginRight:50}}>
						<label style={{marginRight:20}}>
							<input type="checkbox" 
								checked={this.state.es_maquina==1}
								onChange={this.handleCheckbox}
							/>
							Máquinas / operarios
						</label>
						<DateRangePicker
							startDate={this.state.startDate} // momentPropTypes.momentObj or null,
							startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
							endDate={this.state.endDate} // momentPropTypes.momentObj or null,
							endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
							onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
							focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
							onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
							startDatePlaceholderText= "Desde"
							endDatePlaceholderText= "Hasta"
							isOutsideRange={(day) => this.doNothing(day)}
							displayFormat="DD/MM/yyyy"
							firstDayOfWeek={1}
						/>
						<button type="button" onClick={this.handleSubmit} style={{marginLeft:50}}>BUSCAR</button>
					</div>
					<h2 style={{marginBottom:25}}>CRONOGRAMAS</h2>
					{this.state.loading&&
						<div style={{textAlign:"center",paddingTop:60}}>
							<img alt="Loading..." src={loadingGif} style={{height:350}}/>
						</div>
					}
				</div>
			);
		} else
		return (
		<div style={{minHeight:'750px'}}>
			<div style={{float: "right",marginRight:50}}>
				<label style={{marginRight:20}}>
					<input type="checkbox" 
						checked={this.state.es_maquina==1}
						onChange={this.handleCheckbox}
					/>
					Máquinas / operarios
				</label>
				<DateRangePicker
					startDate={this.state.startDate} // momentPropTypes.momentObj or null,
					startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
					endDate={this.state.endDate} // momentPropTypes.momentObj or null,
					endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
					onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
					focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
					startDatePlaceholderText= "Desde"
					endDatePlaceholderText= "Hasta"
					isOutsideRange={(day) => this.doNothing(day)}
					displayFormat="DD/MM/yyyy"
					firstDayOfWeek={1}
				/>
				<button type="button" onClick={this.handleSubmit} style={{marginLeft:50}}>BUSCAR</button>
			</div>
			<h2 style={{marginBottom:25}}>CRONOGRAMAS</h2>
			{this.state.loading&&
				<div style={{textAlign:"center",paddingTop:60}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&
				<div style={{width:'100%',paddingTop:60}}>
					<div style={{textAlign:"right",width:'10%',float:"left"}}>
						{this.state.tiempos.map(({nombre,data,timeseries,show,es_maquina,suma},index) => (
							<div key={index}>
								{show&&es_maquina==this.state.es_maquina&&
									<p style={{marginTop:3,marginBottom:15,marginRight:5,whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>{suma} {nombre}</p>
								}
							</div>
						))}
					</div>
					<Resizable  style={{textAlign:"center",width:'90%',float:"left"}}>
						<ChartContainer
							timeRange={this.state.timerange}
							enablePanZoom={true}
							onTimeRangeChanged={this.handleTimeRangeChange}
							onTrackerChanged={this.handleTrackerChanged} >
							>
							{this.state.tiempos.map(({nombre,data,timeseries,show,es_maquina,marcas},index) => (
								<ChartRow height={show&&es_maquina==this.state.es_maquina ? "33" : "0"} key={index}>
									<Charts>
										<EventChart
											onMouseOver={(e) => this.setState({hover: {id:e.get("id"),title:e.get("title")}})}
											onMouseLeave={(e) => this.setState({hover: {id:'',title:''}})}
											onSelectionChange={(e) => {
													if(this.state.selected.id==e.get("id")) 
														this.setState({selected: {id:'',title:''}});
													else this.setState({selected: {id:e.get("id"),title:e.get("title")}})
													}}
											series={timeseries}
											style={(e) => this.colorear(e)}
											/>
										<TimeMarker
											time={moment(this.state.now,'DD/MM/YYYY HH:mm:ss')}
											infoStyle={{line: {strokeWidth: "2px", stroke: "#000",strokeHeight:350}}}/>
											
										{marcas.map(({FECHA,ENCENDIDA},index) => (
											<TimeMarker
												key={index}
												time={moment(FECHA,'DD/MM/YYYY HH:mm:ss')}
												infoStyle={{line: {strokeWidth: "2px", stroke: ENCENDIDA ? "#00FF00" : "#FF0000",strokeHeight:350}}}
												/>
										))}
									</Charts>
								</ChartRow>
							))}
						</ChartContainer>
					</Resizable >
					<div style={{whiteSpace: "pre-line"}}>
						{this.getSelected().length>0&&
							<p>{this.getSelected()}</p>
						}
						{this.getSelected().length==0&&
							<p>Pasa por una línea para ver detalles<br></br>Clicka una línea para mantener los detalles de dicha línea</p>
						}
						<p>____________________________________________________________________________</p>
					</div>
				</div>
			}
      </div>
    );
  }
}
//<ChartRow height="30" title="SIERRA" titleStyle={{}}>
//<YAxis label="CURVA.2" min={0} max={0} />

/*
<ChartRow height="33">
								<Charts>
									<EventChart
										onMouseOver={(e) => this.setState({hover: {id:e.get("id"),title:e.get("title")}})}
										onMouseLeave={(e) => this.setState({hover: {id:'',title:''}})}
										onSelectionChange={(e) => {
												if(this.state.selected.id==e.get("id")) 
													this.setState({selected: {id:'',title:''}});
												else this.setState({selected: {id:e.get("id"),title:e.get("title")}})
												}}
										series={this.state.timeseriesSierra}
										style={(e) => this.colorear(e)}
										/>
									<TimeMarker
										time={moment(this.state.now,'DD/MM/YYYY HH:mm:ss')}
										infoStyle={{line: {strokeWidth: "2px", stroke: "#000"}}}/>
								</Charts>
							</ChartRow>
							<ChartRow height="33">
								<Charts>
									<EventChart
										onMouseOver={(e) => this.setState({hover: {id:e.get("id"),title:e.get("title")}})}
										onMouseLeave={(e) => this.setState({hover: {id:'',title:''}})}
										onSelectionChange={(e) => {
												if(this.state.selected.id==e.get("id")) 
													this.setState({selected: {id:'',title:''}});
												else this.setState({selected: {id:e.get("id"),title:e.get("title")}})
												}}
										series={this.state.timeseriesCurvadora1}
										style={(e) => this.colorear(e)}
										/>
									<TimeMarker
										time={moment(this.state.now,'DD/MM/YYYY HH:mm:ss')}
										infoStyle={{line: {strokeWidth: "2px", stroke: "#000",strokeHeight:350}}}/>
								</Charts>
							</ChartRow>
							<ChartRow height="33">
								<Charts>
									<EventChart
										onMouseOver={(e) => this.setState({hover:{id:e.get("id"),title:e.get("title")}})}
										onMouseLeave={(e) => this.setState({hover: {id:'',title:''}})}
										onSelectionChange={(e) => {
												if(this.state.selected.id==e.get("id")) 
													this.setState({selected: {id:'',title:''}});
												else this.setState({selected: {id:e.get("id"),title:e.get("title")}})
												}}
										series={this.state.timeseriesCurvadora2}
										style={(e) => this.colorear(e)}
										/>
									<TimeMarker
										time={moment(this.state.now,'DD/MM/YYYY HH:mm:ss')}
										infoStyle={{line: {strokeWidth: "2px", stroke: "#000"}}}/>
								</Charts>
							</ChartRow>
							*/
								
 
export default Salidas;