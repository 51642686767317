import React, { Component } from "react";
import loadingGif from './assets/loading.gif';

class Sillas extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			loading: false,
			searched: false,
			data: [],
			noResults: false,
			numserie: '',
			valueForm: '',
			facts: [],
			regus: [],
			reparaciones: [],
			producto: null,
			productos: [],
			indirectos: 0.15,
		}
		
	}
	/*
	handleSubmit(event) {
		this.buscarEstructuraInicial(this.state.valueForm);
		event.preventDefault();
	}
	handleChange(event) {
		this.setState({valueForm: event.target.value});
	}*/
  
	componentDidMount(){
		this.getData();
		/*
		this.buscarEstructuraInicial('0601.0015',0);
		this.buscarEstructuraInicial('0601.0202',1);
		this.buscarEstructuraInicial('0601.0203',2);
		this.buscarEstructuraInicial('0601.0204',3);
		this.buscarEstructuraInicial('0601.0207',4);

		this.buscarEstructuraInicial('0601.0015',0);
		this.buscarEstructuraInicial('0601.0202',1);
		this.buscarEstructuraInicial('0601.0203',2);
		this.buscarEstructuraInicial('0601.0204',3);
		this.buscarEstructuraInicial('0601.0207',4);
		*/
		//this.getData();
		/*
		const windowUrl = window.location.search;
		const params = windowUrl.substring(11);
		if(params!=null&&params!='')
			this.buscar(params);*/
	}

	async getData(){
		let bodyData = {
			user: sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/costes/articulos",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								for(var i=0;i<result.length;i++){
									this.buscarEstructuraInicial(result[i].CODART,i);
								}
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async buscarEstructura(CODART,DESCART,productsIndex) {
		this.state.productos[productsIndex].push({ESTRUCTURA: null,CODART: CODART,DESCART: DESCART,OPEN: true,COSTE_UNITARIO:'?',MANO_UNITARIO: '?'});
		this.setState({state:this.state});
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: CODART
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/costes/estructura_articulo",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.indirectos = result.INDIRECTOS;
								for(var i=0;i<this.state.productos[productsIndex].length;i++){
									if(this.state.productos[productsIndex][i].CODART==CODART){
										this.state.productos[productsIndex][i].ESTRUCTURA = result.ESTRUCTURA;
										this.state.productos[productsIndex][i].COSTE_UNITARIO = result.PRCCOMPRA;
										this.state.productos[productsIndex][i].MANO_UNITARIO= result.PRCMANO;
									}
								}
								//this.state.productos.push({ESTRUCTURA: result.ESTRUCTURA,CODART: result.CODART,DESCART: result.DESCART});

								this.setState({state:this.state});
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async buscarEstructuraInicial(CODART,productsIndex) {
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: CODART
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/costes/estructura_articulo",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.productos[productsIndex] = [];
								this.state.indirectos = result.INDIRECTOS;
								this.state.productos[productsIndex].push({ESTRUCTURA: result.ESTRUCTURA,CODART: result.CODART,DESCART: result.DESCART,OPEN: true,COSTE_UNITARIO:result.PRCCOMPRA,MANO_UNITARIO: result.PRCMANO});
								this.setState({loading: false });
								//alert(this.state.data[0].SERIE);
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	getCosteUnitario(CODART,productsIndex){
		for(var i=0;i<this.state.productos[productsIndex].length;i++){
			if(this.state.productos[productsIndex][i].CODART==CODART){
				return this.state.productos[productsIndex][i].COSTE_UNITARIO;
			}
		}
		
		return '?';
	}
	
	getMano(CODART,productsIndex){
		var found = -1
		for(var i=0;i<this.state.productos[productsIndex].length;i++){
			if(this.state.productos[productsIndex][i].CODART==CODART){
				found = i;
			}
		}
		
		var manoTotal = 0;
		
		if(found!=-1&&this.state.productos[productsIndex][found].ESTRUCTURA!=null){
			for(var i=0;i<this.state.productos[productsIndex][found].ESTRUCTURA.length;i++){
				//var coste = this.getCosteUnitario(this.state.productos[found].ESTRUCTURA[i].CODARTC);
				var coste = this.getMano(this.state.productos[productsIndex][found].ESTRUCTURA[i].CODARTC,productsIndex);
				if(coste=='?'||coste==null)
					return '?';
				else manoTotal += coste*this.state.productos[productsIndex][found].ESTRUCTURA[i].UNICOMPO;
			}
		}
		else return '?';
		
		manoTotal += this.state.productos[productsIndex][found].MANO_UNITARIO;
		return manoTotal;
	}
	
	getCoste(CODART,productsIndex){

		var found = -1
		for(var i=0;i<this.state.productos[productsIndex].length;i++){
			if(this.state.productos[productsIndex][i].CODART==CODART){
				found = i;
			}
		}
		
		var costeTotal = 0;
		
		if(found!=-1&&this.state.productos[productsIndex][found].ESTRUCTURA!=null){
			for(var i=0;i<this.state.productos[productsIndex][found].ESTRUCTURA.length;i++){
				//var coste = this.getCosteUnitario(this.state.productos[productsIndex][found].ESTRUCTURA[i].CODARTC);
				var coste = this.getCoste(this.state.productos[productsIndex][found].ESTRUCTURA[i].CODARTC,productsIndex);
				if(coste=='?'||coste==null)
					return '?';
				else costeTotal += coste*this.state.productos[productsIndex][found].ESTRUCTURA[i].UNICOMPO;
			}
		}
		else return '?';
		
		costeTotal += this.state.productos[productsIndex][found].COSTE_UNITARIO;
		return costeTotal;
	}
	getEstructuras(){
		var aux = [];
		for(var i=0;i<this.state.productos.length;i++){
			if(this.state.productos[i]!=null){
					aux.push(this.getEstructura(this.state.productos[i][0].CODART,this.state.productos[i][0].DESCART,1,0,i));
			}
		}
		return aux;
	}

	getEstructura(CODART,DESCART,UNIDADES,NIVEL,productsIndex){
		var found = -1;
		for(var i=0;i<this.state.productos[productsIndex].length;i++){
			if(this.state.productos[productsIndex][i].CODART==CODART){
				found = i;
			}
		}
		if(found==-1){
			this.buscarEstructura(CODART,DESCART,productsIndex);
		}
		else{
			if(this.state.productos[productsIndex][found].OPEN&&this.state.productos[productsIndex][found].ESTRUCTURA!=null)
				for(var i=0;i<this.state.productos[productsIndex][found].ESTRUCTURA.length;i++){
					this.getEstructura(this.state.productos[productsIndex][found].ESTRUCTURA[i].CODARTC,this.state.productos[productsIndex][found].ESTRUCTURA[i].DESCARTC,this.state.productos[productsIndex][found].ESTRUCTURA[i].UNICOMPO*UNIDADES,NIVEL+1,productsIndex)
				}
		}

		if(NIVEL==0)
			return (
				<>
					<tr style={{color:'#000',backgroundColor: '#FFF'}} onClick={() => {this.state.productos[productsIndex][found].OPEN = !this.state.productos[productsIndex][found].OPEN;this.setState({state:this.state});}}>
						<td>
							{CODART}
						</td>
						<td>
							{DESCART}
						</td>
						<td>
							{found!=-1&&this.state.productos[productsIndex][found].COSTE_UNITARIO!='?'&&this.state.productos[productsIndex][found].COSTE_UNITARIO!='?' ? this.getCoste(CODART,productsIndex)=='?' ? '?' : ((this.getCoste(CODART,productsIndex)*UNIDADES+this.getMano(CODART,productsIndex)*UNIDADES)/(1-this.state.indirectos)).toFixed(2)+" €" : '?'}
						</td>
					</tr>
				</>
			);		

		//this.setState({state: this.state });
	}
  
	render() {
		return (
		<div>
			<h2>COSTES POR PRODUCTO</h2>
			<br></br>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&
				<div>
					<table className="tablaTareas">
						<thead>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td style={{width:95,textAlign:'center'}}>
									CÓDIGO
								</td>
								<td>
									DESCRIPCIÓN
								</td>
								<td style={{width:75,textAlign:'center'}}>
									COSTE
								</td>
							</tr>
						</thead>
						<tbody className="tablaEntrada" style={{backgroundColor:"#FFF"}}>
							{this.getEstructuras()}
						</tbody>
					</table>
				</div>
			}
		</div>
		);
	}

}
 
export default Sillas;