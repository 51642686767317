import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';

class Salidas extends Component {
	
	constructor(props) {
		super(props);
		this.handleRowBackground = this.handleRowBackground.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.onSubmitNothing = this.onSubmitNothing.bind(this);
	}
	
	state = {
		username:'',
		password:'',
		loggedIn: false,
		startDate: null,
		endDate: null,
		focusedInput: null,
		loading:false,
		entradas: [],
		imagen: null,
		filtro: '',
	};
	
	componentDidMount(){
		setInterval(() => this.handleSubmit(null), 300000)
	}
	
	onSubmitNothing(event){
		event.preventDefault();
	}
	
	doNothing(day){
		//console.log(day);
	}
	
	handleRowBackground(e){
		console.log(e);
	}
	
	filaClick(ID){
		console.log(ID);
	}
	
	async handleSubmit(event){
		
		if(this.state.startDate==null)
			return;
		
		var today=""+(this.state.startDate._d.getMonth()+1)+"/"+this.state.startDate._d.getDate()+"/"+this.state.startDate._d.getFullYear();
		if(this.state.endDate!=null)
			var tomorrow = ""+(this.state.endDate._d.getMonth()+1)+"/"+this.state.endDate._d.getDate()+"/"+this.state.endDate._d.getFullYear();
		else var tomorrow= today;
		
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			date: tomorrow,
			yesterday: today,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/get_ordenes_salida",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.entradas = result;
								this.state.entradas.forEach(orden => {orden.SHOW = true;orden.IMAGENES = [];orden.LOADING = false});
								this.setState({loading: false });
							})
							
		.catch((error) => {
			console.error(error);
		});

	}
	
	async getLineas(index){
		
		if(this.state.entradas[index].OPEN){
			this.state.entradas[index].OPEN = false;
			this.setState({state: this.state });
			return;
		}
		
		if(this.state.entradas[index].SEARCH){
			this.state.entradas[index].OPEN = true;
			this.setState({state: this.state });
			return;
		}
		this.state.entradas[index].LOADING = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: "ALGO",
			id: this.state.entradas[index].ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/get_lineas_ordenes_salida",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.entradas[index].LOADING = false;
								this.state.entradas[index].OPEN = true;
								this.state.entradas[index].SEARCH = true;
								this.state.entradas[index].LINEAS = result;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	filtrar(index){
		var salida = this.state.entradas[index];
		var FILTRO = this.state.filtro.toUpperCase();
		if(this.getProceso(salida.PROCESO).includes(FILTRO)||salida.NOMFISCAL.includes(FILTRO)||salida.CODPRO.includes(FILTRO)||(salida.NUMDOC+"/"+salida.SERIE).includes(FILTRO)||salida.NOMBRE.toUpperCase().includes(FILTRO)||salida.OBSERVACIONES.toUpperCase().includes(FILTRO))
			return true;
		else return false;
	}
	
	getProceso(PROCESO){
		switch(PROCESO){
			case 'L': return 'LACADO';
			break;
			case 'D': return 'DEVOLUCIÓN';
			break;
			case 'M': return 'MECANIZADO';
			break;
			case 'Z': return 'ZINCADO';
			break;
			case 'S': return 'SEP. PIEZAS';
			break;
			case 'V': return 'VARIOS';
			break;
			default: return 'OTRO';
		}
	}
	
	render() {
		return (
		<div>
			<div style={{float: "right",marginRight:50}}>
				<DateRangePicker
					startDate={this.state.startDate} // momentPropTypes.momentObj or null,
					startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
					endDate={this.state.endDate} // momentPropTypes.momentObj or null,
					endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
					onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
					focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
					startDatePlaceholderText= "Desde"
					endDatePlaceholderText= "Hasta"
					isOutsideRange={(day) => this.doNothing(day)}
					displayFormat="DD/MM/yyyy"
					firstDayOfWeek={1}
				/>
				<button type="button" onClick={this.handleSubmit} style={{marginLeft:50}}>BUSCAR</button>
			</div>
			<div style={{float: "right",margin:15}}>
				<form onSubmit={this.onSubmitNothing}>
				  <label>
					<input
						  type="text" 
						  value={this.state.filtro}
						  onChange={(e) => this.setState({filtro: e.target.value})}
						  placeholder="Filtro"
						/>
				  </label>
				</form>
			</div>
			<h2 style={{marginBottom:25}}>ÓRDENES DE SALIDA</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.entradas.length>0&&<a>
				<table className="tablaEntrada">
					<tr style={{backgroundColor:"#339999",color:"#fff"}}>
						<td style={{width:"8%"}}>
							FECHA
						</td>
						<td style={{width:"8%"}}>
							Nº DOC
						</td>
						<td style={{width:"15%"}}>
							PROCESO
						</td>
						<td style={{width:"25%"}}>
							PROVEEDOR
						</td>
						<td style={{width:"10%"}}>
							PERSONA
						</td>
						<td style={{width:"34%"}}>
							OBSERVACIONES
						</td>
					</tr>
					</table>
				{this.state.entradas.map(({ ID,NOMFISCAL,PROCESO,CODPRO,NUMDOC,SERIE,OBSERVACIONES,FECHA,NOMBRE,LINEAS,OPEN,SEARCH,SHOW,IMAGENES,LOADING },index) => (
					<a key={ID}>
						{this.filtrar(index)&&<a>
					<table className="tablaEntrada" style={OPEN&&SHOW ? {backgroundColor:"#cdeeee"} : {backgroundColor:"#FFF"}}>
						<tr key={ID} onClick={() => this.getLineas(index)}>
							<td style={{width:"8%"}}>
								{FECHA}
							</td>
							<td style={{width:"8%"}}>
								{SERIE}/{NUMDOC}
							</td>
							<td style={{width:"15%"}}>
								{this.getProceso(PROCESO)}
							</td>
							<td style={{width:"25%"}}>
								{CODPRO} - {NOMFISCAL}
							</td>
							<td style={{width:"10%"}}>
								{NOMBRE}
							</td>
							<td style={{width:"34%"}}>
								{OBSERVACIONES}
							</td>
						</tr>
						</table>
						<table className="tablaLineas">
							{LOADING&&
								<div style={{textAlign:"center"}}>
									<img alt="Loading..." src={loadingGif} style={{height:150}}/>
								</div>
							}
							{SHOW&&OPEN&&!LOADING&&LINEAS.length>0&&
								<tr style={{backgroundColor:"#339999",color:"#fff"}}>
									<td>COD. ART.</td>
									<td>DESCRIPCIÓN</td>
									<td>CANTIDAD</td>
								</tr>
							}
							{SHOW&&OPEN&&!LOADING&&LINEAS.map(({ID,CODART,DESCART,CANTIDAD},index) => (
									<tr>
										<td>{CODART}</td>
										<td>{DESCART}</td>
										<td>{CANTIDAD}</td>
									</tr>
							))}
							{SHOW&&OPEN&&!LOADING&&IMAGENES.length>0&&
								<tr style={{width:'100%',textAlign:'center'}}>
									<td colspan="3">
										{SHOW&&OPEN&&!LOADING&&IMAGENES.map(({IMG},index) => (
											<img src={IMG} style={{maxHeight:'450px',padding:5}} />
										))}
									</td>
								</tr>
							}
						</table>
						</a>}
					</a>
				))}
				</a>
			}
      </div>
    );
  }
}
 
export default Salidas;