import React, { Component } from "react";
import deployIcon from './assets/desplegar_icon.png';
import checkIcon from './assets/check_icon.png';
import detailsIcon from './assets/details_icon.png';
import closeIcon from './assets/close_icon.png';
import plusIcon from './assets/plus_icon.png';
import backIcon from './assets/arrow_icon.png';
import editIconSmall from './assets/edit_icon_small.png';
import exchangeIcon from './assets/exchange_icon.png';

class Lotes extends Component {
	
	constructor(props){
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmitModels = this.handleSubmitModels.bind(this);
		this.handleChangeModels = this.handleChangeModels.bind(this);
		this.handleSubmitFamily = this.handleSubmitFamily.bind(this);
		this.handleChangeFamily = this.handleChangeFamily.bind(this);
		
		this.state = {
			loading: false,
			searched: false,
			data: [],
			noResults: false,
			referencia: '',
			tipoBusqueda: 0,
			modelsSearch: false,
			valueForm: '',
			valueFormModels: '',
			searching: false,
			models: [],
			modelsInfo: [],
			families: [],
			inLote: false,
			selectedLote: {},
			editingLote: false,
			searchingProduct: false,
			productsList: [],
			productSearch: '',
			searchingArticulo: false,
			searchingProvee: false,
			selectedComponent: '',
		}
		
	}
  
	componentDidMount(){
		
		if(!this.state.modelsSearch){
			this.state.modelsSearch = true;
			if(this.state.models.length==0)this.searchModels();
		}
	}
	
	buscarRef(){
		this.searchReferencia(this.state.referencia);
	}
	
	buscarLote(lote){
		this.state.lote = lote;
		//this.searchLote();
	}
	
	editarLote(){
		this.setState({editingLote: !this.state.editingLote });
	}
	
	
	async searchModels() {
		this.state.loading = true;
		this.state.searching = true;
		this.setState({state: this.state });
		
		let bodyData = {
				user: sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/buscar_modelos",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								this.state.models = result;
								this.state.families = [{CAR5:"ANDA"},{CAR5:"BASTO"}];
								this.state.searching = false;
								this.state.loading = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async searchReferencia(referencia) {
		this.state.loading = true;
		this.state.noResults = true;
		this.setState({state: this.state });
		
		
		
		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: referencia
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/buscar_referencia",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.length<1||result==null||result=='')
									this.state.noResults = true;
								else this.state.noResults = false;
								
								for(var i=0;i<result.length;i++){
									result[i].SEARCHED = 0;
									result[i].OPEN = 0;
									result[i].COMPONENTES = [];
									result[i].COMPONENTES.CODART = '';
									result[i].COMPONENTES.CODPRO = '';
									result[i].COMPONENTES.LOTE = '';
								}
								
								this.state.data = result;
								this.state.tipoBusqueda = 1;
								this.state.searched = true;
								this.state.loading = false;
								this.setState({state: this.state });
								//alert(this.state.data[0].SERIE);
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async searchComponentsLote(lote) {
		this.state.loading = true;
		this.setState({state: this.state });
		
		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: lote
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/buscar_componentes_lote",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								//this.state.data = result;
								
								for(var i=0;i<this.state.data.length;i++)
									if(this.state.data[i].ID == lote)
										this.state.data[i].COMPONENTES = result;
								
								this.state.loading = false;
								this.setState({state: this.state });
								//alert(this.state.data[0].SERIE);
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async searchByFamily(family) {
		this.state.loading = true;
		this.setState({state: this.state });
		
		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: family
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/buscar_por_familia",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								//this.state.data = result;
								
								this.state.modelsInfo=result;
								this.state.loading = false;
								this.setState({state: this.state });
								//alert(this.state.data[0].SERIE);
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async searchByModel(model) {
		this.state.loading = true;
		this.setState({state: this.state });
		
		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: model
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/buscar_por_modelo",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								//this.state.data = result;
								
								this.state.modelsInfo=result;
								this.state.loading = false;
								this.setState({state: this.state });
								//alert(this.state.data[0].SERIE);
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async loteDetails(lote) {
		this.state.loading = true;
		this.setState({state: this.state });
		
		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: lote
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/info_lote",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								//this.state.data = result;
								this.state.selectedLote = result;
								this.editar(1);
								this.state.loading = false;
								this.setState({state: this.state });
								//alert(this.state.data[0].SERIE);
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	openLote(loteID,OPEN){	//Esto debe ir al lote para verlo detallado
		return;
		if(loteID!=null&&OPEN==0){
			for(var i=0;i<this.state.data.length;i++)
				if(this.state.data[i].ID == loteID){
					if(this.state.data[i].SEARCHED == 0)
						this.searchComponentsLote(loteID);
					this.state.data[i].SEARCHED = 1;
					this.state.data[i].OPEN = 1;
				}
		}
		else if(OPEN==1)
			for(var i=0;i<this.state.data.length;i++)
				if(this.state.data[i].ID == loteID)
					this.state.data[i].OPEN = 0;
		this.setState({state: this.state });
	}
	
	handleSubmit(event) {
		this.state.referencia = this.state.valueForm;
		this.buscarRef();
		event.preventDefault();
	}
	handleChange(event) {
		this.setState({valueForm: event.target.value});
	}
	handleSubmitModels(event) {
		this.searchByModel(this.state.valueFormModels);
		event.preventDefault();
	}
	handleChangeModels(event) {
		this.setState({valueFormModels: event.target.value});
	}
	handleSubmitFamily(event) {
		this.searchByFamily(this.state.valueFormFamily);
		event.preventDefault();
	}
	handleChangeFamily(event) {
		this.setState({valueFormFamily: event.target.value});
	}
	editar(aux){	//1 = inLote
		if(this.state.inLote) this.state.inLote=false;
		else this.state.inLote = true;
		if(aux==1) this.state.inLote=true;
		this.state.editingLote = false;
		if(!this.state.inLote) this.state.selectedLote = {};
		else
		{
			this.buscarRef();
			this.state.modelsInfo = {};
		}
		this.setState({state: this.state });
	}
	deleteComponent(component){
		
		for(var i=0;i<this.state.selectedLote.COMPONENTES.length;i++){
			if(this.state.selectedLote.COMPONENTES[i].CODART == component){
				this.state.selectedLote.COMPONENTES.splice(i,1);
				this.setState({state: this.state });
			}
		}
	}
	deleteArticulo(articulo){
		
		for(var i=0;i<this.state.selectedLote.ARTICULOS.length;i++){
			if(this.state.selectedLote.ARTICULOS[i].CODART == articulo){
				this.state.selectedLote.ARTICULOS.splice(i,1);
				this.setState({state: this.state });
				i=this.state.selectedLote.ARTICULOS.length;
			}
		}
	}
	
	cambiarActual(articulo,ACTUAL){
		for(var i=0;i<this.state.selectedLote.ARTICULOS.length;i++){
			if(this.state.selectedLote.ARTICULOS[i].CODART == articulo){
				this.state.selectedLote.ARTICULOS[i].ACTUAL = !ACTUAL;
				this.setState({state: this.state });
				i=this.state.selectedLote.ARTICULOS.length;
			}
		}
	}
	
	async searchProduct() {
		var text = this.state.productSearch;
		this.state.loading = true;
		this.setState({state: this.state });
		
		if (text === null || text === ''){
			this.state.productsList = new Array();
			this.state.loading = false;
			return null;
		}

		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: text
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/buscar_producto",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.productsList = result;
								this.state.loading = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	async searchProvee() {
		var text = this.state.productSearch;
		this.state.loading = true;
		this.setState({state: this.state });
		
		if (text === null || text === ''){
			this.state.productsList = new Array();
			this.state.loading = false;
			return null;
		}

		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: text
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/buscar_proveedor",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.productsList = result;
								this.state.loading = false;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async guardarLote(){
		this.state.loading = true;
		this.setState({state: this.state });

		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: this.state.selectedLote,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/guardar_lote",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result[0]!=1){
									alert("Se ha producido algún error...");
									this.state.loading = false;
									this.state.inLote = false;
									this.setState({editingLote: false});
								}
								else{
									this.loteDetails(this.state.selectedLote.LOTE[0].ID);
								}
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async createLote(){
		this.state.loading = true;
		this.setState({state: this.state });

		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: this.state.selectedLote,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/crear_lote",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								if(result[0]==null){
									alert("Se ha producido algún error...");
									this.state.loading = false;
									this.state.inLote = false;
									this.setState({editingLote: false});
								}
								else{
									this.loteDetails(result[0])
									
								}
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async makeActualLote(ID){
		this.state.loading = true;
		this.setState({state: this.state });

		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: ID,
				CODART: this.state.referencia,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/cambiar_actual",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								if(result[0]==null){
									alert("Se ha producido algún error...");
									this.state.loading = false;
									this.state.inLote = false;
									this.setState({editingLote: false});
								}
								else{
									this.buscarRef();
								}
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async loteActual(ID){
		this.state.loading = true;
		this.setState({state: this.state });

		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/cambiar_actual_todos",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								if(result[0]==null){
									alert("Se ha producido algún error...");
									this.state.loading = false;
									this.state.inLote = false;
									this.setState({editingLote: false});
								}
								else{
									this.loteDetails(ID);
								}
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async eliminarLote(ID){
		this.state.loading = true;
		this.setState({state: this.state });

		let bodyData = {
				user: sessionStorage.getItem('user'),
				busqueda: ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/eliminar_lote",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								if(result[0]==null){
									alert("Se ha producido algún error...");
									this.state.loading = false;
									this.state.inLote = false;
									this.setState({editingLote: false});
								}
								else{
									this.state.modelsInfo = {};
									this.buscarRef();
									this.editar();
								}
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
  
	chooseProduct(CODART, DESCART){
		var auxProduct = {CODART: CODART,DESCART:DESCART,ACTUAL: false};
		
		//COMPROBAR QUE NO ESTÉ YA ESE ARTÍCULO DADO QUE NO PUEDE ESTAR DUPLICADO
		if(this.state.searchingArticulo){
			for(var i=0;i<this.state.selectedLote.ARTICULOS.length;i++){
				if(this.state.selectedLote.ARTICULOS[i].CODART == CODART){
					alert("Un mismo lote no puede tener dos artículos iguales");
					return;
				}
			}
		}
		else{
			for(var i=0;i<this.state.selectedLote.COMPONENTES.length;i++){
				if(this.state.selectedLote.COMPONENTES[i].CODART == CODART){
					alert("Un mismo lote no puede tener dos componentes iguales");
					return;
				}
			}
		}
		
		if(this.state.searchingArticulo){
			this.state.selectedLote.ARTICULOS.push(auxProduct);
		}
		else
			this.state.selectedLote.COMPONENTES.push(auxProduct);
		this.setState({searchingArticulo: false,searchingProduct: false});
	}
	
	chooseProvee(CODPRO,NOMPRO,RAZON){
		
		for(var i=0;i<this.state.selectedLote.COMPONENTES.length;i++){
			if(this.state.selectedComponent==this.state.selectedLote.COMPONENTES[i].CODART){
				this.state.selectedLote.COMPONENTES[i].CODPRO = CODPRO;
				this.state.selectedLote.COMPONENTES[i].RAZON = RAZON;
				i=this.state.selectedLote.COMPONENTES.length;
			}
		}
		this.setState({searchingProvee: false,selectedComponent: ''});
	}
	
	goToSearchProduct(aux){
		this.state.productsList = [];
		if(aux==1)
			this.setState({searchingArticulo: true,searchingProduct: true});
		else
			this.setState({searchingArticulo: false,searchingProduct: true});
		
	}
	changeProvee(CODART){
		this.state.selectedComponent = CODART;
		this.state.productsList = [];
		this.setState({searchingProvee: true});
	}
	
	changeComponentLote(CODART,VALUE){
		for(var i=0;i<this.state.selectedLote.COMPONENTES.length;i++){
			if(this.state.selectedLote.COMPONENTES[i].CODART == CODART){
				this.state.selectedLote.COMPONENTES[i].LOTE = VALUE;
				i = this.state.selectedLote.COMPONENTES.length;
			}
		}
	}
	
	
	duplicarLote(){
		var lote_id = prompt("Introduce el nuevo número de lote",this.state.selectedLote.LOTE[0].LOTE);
		if(lote_id==null||lote_id==""||lote_id==this.state.selectedLote.LOTE[0].LOTE)
			return;
		else{
			for(var i=0;i<this.state.selectedLote.ARTICULOS.length;i++)
				this.state.selectedLote.ARTICULOS[i].ACTUAL = false;
			this.state.selectedLote.LOTE[0].LOTE=lote_id;
			this.createLote();
		}
	}
	
	createNewLote(){
		var lote_id = prompt("Introduce el nuevo número de lote","");
		if(lote_id==null||lote_id=="")
			return;
		else{
			this.state.selectedLote.LOTE = [];
			this.state.selectedLote.COMPONENTES = [];
			this.state.selectedLote.ARTICULOS = [];
			this.state.selectedLote.LOTE.push({LOTE: lote_id});
			this.createLote();
		}
	}
	

	
	render() {
		
		
		if(this.state.searchingProvee){
				return (
					<div>
						<div>
							<a style={{cursor:"pointer"}} onClick={()=>this.setState({searchingProvee: false})}><img src={backIcon} alt="back" /></a>
						</div>
						<form onSubmit={()=>this.searchProvee()}>
							<input onChange={e => this.state.productSearch=e.target.value}></input>
							<input type="submit" value="BUSCAR PROVEEDOR"/>
						</form>
						
						{this.state.productsList.map(({ CODPRO, NOMPRO,RAZON }) => (
							<a className="cursorPointer" onClick={()=>this.chooseProvee(CODPRO,NOMPRO,RAZON)} key={CODPRO}>
								<div className="list">
									{CODPRO} - {RAZON} - {NOMPRO}
								</div>
							</a>
							))}
						
					</div>
				);
		}
		else
		
		if(this.state.searchingProduct){
				
				return (
					<div>
						<div>
							<a style={{cursor:"pointer"}} onClick={()=>this.setState({searchingProduct: false,searchingArticulo:false})}><img src={backIcon} alt="back" /></a>
						</div>
						<form onSubmit={()=>this.searchProduct()}>
							<input onChange={e => this.state.productSearch=e.target.value}></input>
							<input type="submit" value="BUSCAR PRODUCTO"/>
						</form>
						
						{this.state.productsList.map(({ CODART, DESCART }) => (
							<a className="cursorPointer" onClick={()=>this.chooseProduct(CODART,DESCART)} key={CODART}>
								<div className="list">
									{CODART} - {DESCART}
								</div>
							</a>
							))}
						
					</div>
				);
		}
		else
		if(!this.state.inLote)
		return (
		<div>			
			<button className="floatRight" style={{cursor:"pointer"}} onClick={()=>{this.createNewLote()}}>
				CREAR NUEVO LOTE
			</button>
			<h2>BÚSQUEDA DE LOTE</h2>
			<br></br>
			<div className="divLeft">
				<form onSubmit={this.handleSubmit}>
					<label for="fref">POR REFERENCIA DE PRODUCTO: </label>
					<input type="text" id="fnumserie" name="fnumserie" value={this.state.valueForm} onChange={this.handleChange}/>
					<input type="submit" value="Buscar"/>
				</form>
				
				{this.state.noResults&&!this.state.loading&&
					<p>No se ha encontrado información...</p>
				}
				{!this.state.noResults&&
					<div>
						{this.state.searched&&
							<div><br></br>
							{this.state.tipoBusqueda==1&&
							<div className="gris">
								<p>Lotes del artículo <b>{this.state.data[0].CODART} {this.state.data[0].DESCART}</b></p>
								
								{this.state.data.map(({ ID, LOTE, FECHA_CREACION,OBSERVACIONES,ACTUAL,OPEN,COMPONENTES }) => (
									<div id={ID}>
										<p className="listArticulos"><a className="cursorPointer" onClick={()=>{this.loteDetails(ID)}}><img src={detailsIcon} /></a>
										&nbsp;&nbsp;LOTE <b>{LOTE}</b> de fecha {FECHA_CREACION}
										{ACTUAL&&
											<a>
												&nbsp;&nbsp;&nbsp;&nbsp;<img src={checkIcon}/>
											</a>
										}
										{!ACTUAL&&
											<a>
												&nbsp;&nbsp;&nbsp;&nbsp;<a className="cursorPointer" onClick={()=>{this.makeActualLote(ID)}}><img src={exchangeIcon}/></a>
											</a>
										}
										</p>
										{OPEN==1&&COMPONENTES.map(({CODART,LOTE,CODPRO,RAZON}) => (
											<a>
												Código componente:{CODART} &emsp; Lote: {LOTE} &emsp; Proveedor: {RAZON}<br></br>
											</a>
										))}
									</div>
								))}
							</div>
							}
							</div>
						}
					</div>
				}
			</div>
			<div className="divRight">
			
				<form onSubmit={this.handleSubmitFamily}>
					<label for="fmodel">POR FAMILIA DE PRODUCTO: </label>
					<select id="models" onChange={this.handleChangeFamily}>
						<option value='-' selected disabled>-</option>
						{this.state.families.map(fbb =>
							<option key={fbb.CAR5} value={fbb.CAR5}>{fbb.CAR5}</option>
						)};
					</select>
					<input type="submit" value="Buscar"/>
				</form>
			
				<form onSubmit={this.handleSubmitModels}>
					<label for="fmodel">POR MODELO DE PRODUCTO: </label>
					<select id="models" onChange={this.handleChangeModels}>
						<option value='-' selected disabled>-</option>
						{this.state.models.map(fbb =>
							<option key={fbb.CAR8} value={fbb.CAR8}>{fbb.CAR8}</option>
						)};
					</select>
					<input type="submit" value="Buscar"/>
				</form>
				
				<br></br>
				{this.state.modelsInfo.length>0&&
				<div className="gris">
					<table>
					{this.state.modelsInfo.map(({CODART,DESCART,LOTE,ID})=>(
						<tr>
							<td><a className="cursorPointer" onClick={()=>{this.loteDetails(ID)}}><img src={detailsIcon} /></a></td>
							<td>{CODART}</td>
							<td>{DESCART}</td>
							<td><b>{LOTE}</b></td>
						</tr>
					))}
					</table>
				</div>
				}
				
			</div>
			<br></br><br></br><br></br>
		</div>
		);
		else{
			return(
				<div>
					<div>
						<h2><a style={{cursor:"pointer",marginRight:"10px"}} onClick={()=>this.editar()}><img src={backIcon} alt="back" /></a>
						EDICIÓN Y VISUALIZACIÓN DE LOTES</h2>
					</div>
					{this.state.selectedLote.LOTE!=null&&!this.state.editingLote&&<a>
					<button className="floatRight" style={{cursor:"pointer"}} onClick={()=>this.duplicarLote()}>
						DUPLICAR LOTE
					</button>
					<button className="floatRight" style={{cursor:"pointer",marginLeft:"10px",marginRight:"10px"}} onClick={()=>this.editarLote()}>
						EDITAR LOTE
					</button>
					<button className="floatRight" style={{cursor:"pointer",marginLeft:"10px",marginRight:"10px"}} onClick={()=>this.eliminarLote(this.state.selectedLote.LOTE[0].ID)}>
						ELIMINAR LOTE
					</button>
					</a>}
					<br></br>
					
					{this.state.selectedLote.LOTE!=null&&<div>
						<p>LOTE&nbsp;
						{!this.state.editingLote&&<b>{this.state.selectedLote.LOTE[0].LOTE}</b>}
						{this.state.editingLote&&
							<input maxlength="25" type="text" placeholder={this.state.selectedLote.LOTE[0].LOTE} onChange={e => this.state.selectedLote.LOTE[0].LOTE = e.target.value}></input>
						}
						&nbsp;creado el {this.state.selectedLote.LOTE[0].FECHA}</p>
							{!this.state.editingLote&&this.state.selectedLote.LOTE[0].OBSERVACIONES!=''&&this.state.selectedLote.LOTE[0].OBSERVACIONES!=null&&this.state.selectedLote.LOTE[0].LOTE!=null&&
							<p>Observaciones: {this.state.selectedLote.LOTE[0].OBSERVACIONES}</p>
							}
							{this.state.editingLote&&
								<p>Observaciones:
									<textarea maxlength="255" onChange={e => this.state.selectedLote.LOTE[0].OBSERVACIONES = e.target.value}>
										{this.state.selectedLote.LOTE[0].OBSERVACIONES}
									</textarea>
								</p>
							}
						
						<p><b>COMPONENTES DE LOS ARTÍCULOS DE ESTE LOTE</b>
							{this.state.editingLote&&<a className="cursorPointer" style={{marginLeft:"10px"}} onClick={()=>{this.goToSearchProduct(2)}}>
							<img src={plusIcon} />
							</a>
							}</p>
						{this.state.selectedLote.COMPONENTES.length>0&&<table className="loteTable">
								<tr>
									<th>CÓDIGO</th>
									<th>DESRIPCIÓN COMPONENTE</th>
									<th>PROVEEDOR</th>
									<th>LOTE</th>
									<th></th>
								</tr>
							{this.state.selectedLote.COMPONENTES.map(({ CODART,CODPRO,RAZON,LOTE,DESCART }) => (
								<tr>
									<td>{CODART}</td>
									<td>{DESCART}</td>
									<td>{RAZON} {this.state.editingLote&&<a className="cursorPointer" onClick={()=>{this.changeProvee(CODART)}}>
									<img src={editIconSmall} />
									</a>}</td>
									{!this.state.editingLote&&
										<td>{LOTE}</td>
									}
									{this.state.editingLote&&
									<td><input maxlength="25" type="text" id={CODART} placeholder={LOTE} onChange={e => this.changeComponentLote(CODART,e.target.value)}></input></td>
									}
									<td>{this.state.editingLote&&<a className="cursorPointer" onClick={()=>{this.deleteComponent(CODART)}}>
									<img src={closeIcon} />
									</a>
									}</td>
								</tr>
							))}
							</table>
						}
						<br></br>
						<p><b>ARTÍCULOS PERTENECIENTES A ESTE LOTE</b>
						{!this.state.editingLote&&
							<button className="floatRight" style={{cursor:"pointer",marginLeft:"10px",marginRight:"10px"}} onClick={()=>this.loteActual(this.state.selectedLote.LOTE[0].ID)}>
								CONVERTIR EN LOTE ACTUAL PARA TODOS LOS ARTÍCULOS
							</button>
						}
						{this.state.editingLote&&
							<a className="cursorPointer" style={{marginLeft:"10px"}} onClick={()=>{this.goToSearchProduct(1)}}>
								<img src={plusIcon} />
							</a>
							}</p>
						{this.state.selectedLote.ARTICULOS.length>0&&<table className="loteTable">
								<tr>
									<th>CÓDIGO</th>
									<th>DESCRIPCIÓN ARTÍCULO</th>
									<th>LOTE ACTUAL</th>
									<th></th>
								</tr>
							{this.state.selectedLote.ARTICULOS.map(({ CODART,DESCART,ACTUAL }) => (
								<tr>
									<td>{CODART}</td>
									<td>{DESCART}</td>
									<td>
										{ACTUAL&&<a>SÍ	&nbsp;</a>}
										{!ACTUAL&&<a>NO	&nbsp;</a>}
											{this.state.editingLote&&<a className="cursorPointer" onClick={()=>{this.cambiarActual(CODART,ACTUAL)}}><img src={exchangeIcon} /></a>}
									</td>
									<td>{this.state.editingLote&&<a className="cursorPointer" onClick={()=>{this.deleteArticulo(CODART)}}>
									<img src={closeIcon} />
									</a>
									}</td>
								</tr>
							))}
							</table>
						}
						
						{this.state.editingLote&&
						<p style={{textAlign: "center"}}>
						<button style={{cursor:"pointer"}} onClick={()=>this.guardarLote()}>
							GUARDAR CAMBIOS
						</button>
						<button style={{cursor:"pointer",marginLeft:"10px"}} onClick={()=>{this.loteDetails(this.state.selectedLote.LOTE[0].ID)}}>
							CANCELAR CAMBIOS
						</button></p>
						}
					
					</div>}
					
				</div>
			);
		}
	}

}
 
export default Lotes;