import React, { Component } from "react";
import loadingGif from './assets/loading.gif';

class Sillas extends Component {
	
	constructor(props){
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.state = {
			loading: false,
			searched: false,
			data: [],
			noResults: false,
			numserie: '',
			valueForm: '',
			facts: [],
			regus: [],
			reparaciones: [],
			producto: null,
			productos: [],
			margen: 0,
			indirectos: 0,
		}
		
	}
	
	handleSubmit(event) {
		this.buscarEstructuraInicial(this.state.valueForm);
		event.preventDefault();
	}
	handleChange(event) {
		this.setState({valueForm: event.target.value});
	}
  
	componentDidMount(){
		//this.getData();
		/*
		const windowUrl = window.location.search;
		const params = windowUrl.substring(11);
		if(params!=null&&params!='')
			this.buscar(params);*/
	}

	
	async buscarEstructura(CODART,DESCART) {
		this.state.productos.push({ESTRUCTURA: null,CODART: CODART,DESCART: DESCART,OPEN: true,COSTE_UNITARIO:'?',MANO_UNITARIO: '?'});
		this.setState({state:this.state});
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: CODART
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/costes/estructura_articulo",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								for(var i=0;i<this.state.productos.length;i++){
									if(this.state.productos[i].CODART==CODART){
										this.state.productos[i].ESTRUCTURA = result.ESTRUCTURA;
										this.state.productos[i].COSTE_UNITARIO = result.PRCCOMPRA;
										this.state.productos[i].MANO_UNITARIO= result.PRCMANO;
									}
								}
								//this.state.productos.push({ESTRUCTURA: result.ESTRUCTURA,CODART: result.CODART,DESCART: result.DESCART});

								this.setState({state:this.state});
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async buscarEstructuraInicial(CODART) {
		
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: CODART
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/costes/estructura_articulo",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.productos = [];
								this.state.indirectos = result.INDIRECTOS;
								this.state.productos.push({ESTRUCTURA: result.ESTRUCTURA,CODART: result.CODART,DESCART: result.DESCART,OPEN: true,COSTE_UNITARIO:result.PRCCOMPRA,MANO_UNITARIO: result.PRCMANO});
								
								this.setState({loading: false });
								//alert(this.state.data[0].SERIE);
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	getColor(NIVEL){
		switch(NIVEL){
			case 0: return "#cdeeee"
			break;
			case 1: return "#FFF"
			break;
			case 2: return "#DDD"
			break;
			case 3: return "#0cff00"
			break;
			case 4: return "#fffd76"
			break;
			case 5: return "#ff6d6d"
			break;
			case 6: return "#76ffc7"
			break;
			case 7: return "#7f9aff"
			break;
			case 8: return "#f17fff"
			break;
			case 9: return "#ffd36a"
			break;
			case 10: return "#000"
			break;
		}
		
		return NIVEL==1 ? "#cdeeee" : "#FFF";
	}
	
	getCosteUnitario(CODART){
		for(var i=0;i<this.state.productos.length;i++){
			if(this.state.productos[i].CODART==CODART){
				return this.state.productos[i].COSTE_UNITARIO;
			}
		}
		
		return '?';
	}
	
	getMano(CODART){
		var found = -1
		for(var i=0;i<this.state.productos.length;i++){
			if(this.state.productos[i].CODART==CODART){
				found = i;
			}
		}
		
		var manoTotal = 0;
		
		if(found!=-1&&this.state.productos[found].ESTRUCTURA!=null){
			for(var i=0;i<this.state.productos[found].ESTRUCTURA.length;i++){
				//var coste = this.getCosteUnitario(this.state.productos[found].ESTRUCTURA[i].CODARTC);
				var coste = this.getMano(this.state.productos[found].ESTRUCTURA[i].CODARTC);
				if(coste=='?'||coste==null)
					return '?';
				else manoTotal += coste*this.state.productos[found].ESTRUCTURA[i].UNICOMPO;
			}
		}
		else return '?';
		
		manoTotal += this.state.productos[found].MANO_UNITARIO;
		return manoTotal;
	}
	
	getCoste(CODART){
		var found = -1
		for(var i=0;i<this.state.productos.length;i++){
			if(this.state.productos[i].CODART==CODART){
				found = i;
			}
		}
		
		var costeTotal = 0;
		
		if(found!=-1&&this.state.productos[found].ESTRUCTURA!=null){
			for(var i=0;i<this.state.productos[found].ESTRUCTURA.length;i++){
				//var coste = this.getCosteUnitario(this.state.productos[found].ESTRUCTURA[i].CODARTC);
				var coste = this.getCoste(this.state.productos[found].ESTRUCTURA[i].CODARTC);
				if(coste=='?'||coste==null)
					return '?';
				else costeTotal += coste*this.state.productos[found].ESTRUCTURA[i].UNICOMPO;
			}
		}
		else return '?';
		
		costeTotal += this.state.productos[found].COSTE_UNITARIO;
		return costeTotal;
	}
	
	getEstructura(CODART,DESCART,UNIDADES,NIVEL){
		
		var found = -1;
		for(var i=0;i<this.state.productos.length;i++){
			if(this.state.productos[i].CODART==CODART){
				found = i;
			}
		}
		if(found==-1){
			this.buscarEstructura(CODART,DESCART);
		}
		
		return (
			<>
				<tr style={{color:'#000',backgroundColor: this.getColor(NIVEL)}} onClick={() => {this.state.productos[found].OPEN = !this.state.productos[found].OPEN;this.setState({state:this.state});}}>
					<td>
						{CODART}
					</td>
					<td>
						{DESCART}
					</td>
					<td>
						{found!=-1 ? UNIDADES : '?' }
					</td>
					<td>
						{found!=-1 ? this.state.productos[found].MANO_UNITARIO=='?' ? '?' : this.state.productos[found].MANO_UNITARIO.toFixed(2)+" €" : '?'}
					</td>
					<td>
						{found!=-1 ? this.state.productos[found].COSTE_UNITARIO=='?' ? '?' : this.state.productos[found].COSTE_UNITARIO.toFixed(2)+" €" : '?'}
					</td>
					<td>
						{found!=-1&&this.state.productos[found].MANO_UNITARIO!='?'&&this.state.productos[found].MANO_UNITARIO!='?' ? this.getMano(CODART)=='?' ? '?' : (this.getMano(CODART)*UNIDADES).toFixed(2)+" €" : '?'}
					</td>
					<td>
						{found!=-1&&this.state.productos[found].COSTE_UNITARIO!='?'&&this.state.productos[found].COSTE_UNITARIO!='?' ? this.getCoste(CODART)=='?' ? '?' : (this.getCoste(CODART)*UNIDADES).toFixed(2)+" €" : '?'}
					</td>
					<td>
						{found!=-1&&this.state.productos[found].COSTE_UNITARIO!='?'&&this.state.productos[found].COSTE_UNITARIO!='?' ? this.getCoste(CODART)=='?' ? '?' : (this.getCoste(CODART)+this.getMano(CODART)).toFixed(2)+" €" : '?'}
					</td>
					<td>
						{found!=-1&&this.state.productos[found].COSTE_UNITARIO!='?'&&this.state.productos[found].COSTE_UNITARIO!='?' ? this.getCoste(CODART)=='?' ? '?' : (this.getCoste(CODART)*UNIDADES+this.getMano(CODART)*UNIDADES).toFixed(2)+" €" : '?'}
					</td>
					<td>
						{found!=-1&&this.state.productos[found].COSTE_UNITARIO!='?'&&this.state.productos[found].COSTE_UNITARIO!='?' ? this.getCoste(CODART)=='?' ? '?' : ((this.getCoste(CODART)*UNIDADES+this.getMano(CODART)*UNIDADES)/(1-this.state.indirectos)).toFixed(2)+" €" : '?'}
					</td>
					<td>
						{found!=-1&&this.state.productos[found].COSTE_UNITARIO!='?'&&this.state.productos[found].COSTE_UNITARIO!='?' ? this.getCoste(CODART)=='?' ? '?' : (((this.getCoste(CODART)*UNIDADES+this.getMano(CODART)*UNIDADES)/(1-this.state.indirectos))*(1+this.state.margen/100)).toFixed(2)+" €" : '?'}
					</td>
				</tr>
				{found!=-1&&this.state.productos[found].OPEN&&this.state.productos[found].ESTRUCTURA!=null&&this.state.productos[found].ESTRUCTURA.map(({ CODARTC,DESCARTC,UNICOMPO },index) => (
					this.getEstructura(CODARTC,DESCARTC,UNICOMPO*UNIDADES,NIVEL+1)
				))}
			</>
		);		
	}
  
	//<p>INTRODUCE NÚMERO DE SERIE: <input></input> <button onClick={()=>this.buscar()}>BUSCAR</button></p>
	render() {
		return (
		<div>
			<h2>COSTES POR PRODUCTO</h2>
			<br></br>
			<form onSubmit={this.handleSubmit}>
				<label for="fnumserie">INTRODUCE CÓDIGO ARTÍCULO: </label>
				<input type="text" id="codart" name="codart" value={this.state.valueForm} onChange={this.handleChange}/>
				<input type="submit" value="Buscar"/>
			</form>
			
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.productos.length>0&&
				<div>
					<table className="tablaTareas">
						<thead>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td style={{width:95,textAlign:'center'}}>
									CÓDIGO
								</td>
								<td>
									DESCRIPCIÓN
								</td>
								<td style={{width:75,textAlign:'center'}}>
									UDS.
								</td>
								<td style={{width:75,textAlign:'center'}}>
									M.O.
								</td>
								<td style={{width:75,textAlign:'center'}}>
									COSTE UNITARIO
								</td>
								<td style={{width:75,textAlign:'center'}}>
									M.O. TOTAL
								</td>
								<td style={{width:75,textAlign:'center'}}>
									COSTES TOTAL
								</td>
								<td style={{width:75,textAlign:'center'}}>
									TOTAL UNITARIO
								</td>
								<td style={{width:75,textAlign:'center'}}>
									TOTAL COSTE
								</td>
								<td style={{width:75,textAlign:'center'}}>
									+ GASTOS INDIRECTOS
								</td>
								<td style={{width:75,textAlign:'center'}}>
									+<input type="text" id="margen" name="margen" style={{width:25,float:'left'}} value={this.state.margen} onChange={(e) => this.setState({margen: e.target.value})}/>% MARGEN
								</td>
							</tr>
						</thead>
						<tbody className="tablaEntrada" style={{backgroundColor:"#FFF"}}>
							{this.getEstructura(this.state.productos[0].CODART,this.state.productos[0].DESCART,1,0)}
						</tbody>
					</table>
				</div>
			}
		</div>
		);
	}

}
 
export default Sillas;