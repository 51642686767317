import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';
import './styles/general.css';

class Salidas extends Component {
	
	state = {
		username:'',
		password:'',
		loading: false,
		programacion: [],
		addProgramacion: false,
		addMatriz: null,
		addNTochos: 0,
		addKg:0,
		addLongPerfil: 0,
		addLongTocho: 0,
		addCODART: '-',
		addDESCART: '-',
		matrices: [],
		imagen: null,
		culote: null,
		despunte: null,
		medidaMaxima: null,
		pesoTocho: null,
		culoteOri: null,
		despunteOri: null,
		medidaMaximaOri: null,
	};
	
	componentDidMount(){
		this.getMatrices();
	}
	
	seleccionarAddMatriz(e){
		for(var i=0;i<this.state.matrices.length;i++){
			if(this.state.matrices[i].ARTALIAS+'-'+this.state.matrices[i].NUMSERIE==e.target.value){
				this.state.addMatriz = this.state.matrices[i];
				this.state.addLongPerfil = this.state.addMatriz.VOLUMEN;
			}
		}
		this.calcularTochos();
	}
	
	async getMatrices(){
		
		//this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/get_matrices",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								this.state.matrices = result[0];
								for(var i=0;i<this.state.matrices.length;i++){
									if(this.state.matrices[i].PESO==0){
										this.state.matrices[i].PESO = this.state.matrices[i].PESO_TEORICO;
										this.state.matrices[i].PESO_ORI = this.state.matrices[i].PESO_TEORICO;
									}
									this.getImagenMatriz(this.state.matrices[i]);
								}
								
								this.setState({pesoTocho:result[1][0].PESO_TOCHO,culote:result[1][0].CULOTE,culoteOri:result[1][0].CULOTE,despunteOri:result[1][0].DESPUNTE,medidaMaximaOri:result[1][0].MEDIDA_MAXIMA,despunte:result[1][0].DESPUNTE,medidaMaxima:result[1][0].MEDIDA_MAXIMA})
								//this.setState({loading: false });
							})
							
		.catch((error) => {
			alert(error);
			//this.setState({loading: false });
			console.error(error);
		});

	}

	async guardarConfiguracion(){

		this.setState({loading: true});
		let bodyData = {
			user: sessionStorage.getItem('user'),
			culote: this.state.culote,
			despunte: this.state.despunte,
			medidaMaxima: this.state.medidaMaxima,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/guardar_configuracion",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE!=1)
									alert('ERROR AL GUARDAR LA CONFIGURACIÓN');
								else{
									this.state.culoteOri = this.state.culote;
									this.state.despunteOri = this.state.despunte;
									this.state.medidaMaximaOri = this.state.medidaMaxima;
								}
								this.setState({loading: false });
							})
							
		.catch((error) => {
			alert(error);
			//this.setState({loading: false });
			console.error(error);
		});
	}
	
	async getImagenMatriz(matriz){
		matriz.LOADINGIMAGE = true;
		this.setState({state: this.state});
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: matriz.CODART,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/get_imagen_articulo",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								matriz.LOADINGIMAGE = false;
								if(result.IMAGEN!='NO')
									matriz.IMAGEN = result.IMAGEN;
								
								if(this.state.addMatriz!=null){
									if(this.state.addMatriz.ARTALIAS+'-'+this.state.addMatriz.NUMSERIE==matriz.ARTALIAS+'-'+matriz.NUMSERIE){
										this.state.addMatriz.IMAGEN = matriz.IMAGEN;
									}
								}
								if(this.state.programacion!=null){
									for(var i=0;i<this.state.programacion.length;i++){
										if(this.state.programacion[i].ARTALIAS+'-'+this.state.programacion[i].NUMSERIE==matriz.ARTALIAS+'-'+matriz.NUMSERIE){
											this.state.programacion[i].IMAGEN = matriz.IMAGEN;
										}
									}
								}
								this.setState({state: this.state});
							})
							
		.catch((error) => {
			alert(error);
			matriz.LOADINGIMAGE = false;
			this.setState({state: this.state});
			console.error(error);
		});
		
	}

	doNothing(day){
		//console.log(day);
	}
	
	async getData(){
		if(this.state.startDate==null){
			var dia = new Date().getDate();
			if((""+dia).length==1) dia = '0'+dia;
			var mes = new Date().getMonth() + 1;
			if((""+mes).length==1) mes = '0'+mes;
			var today = ((mes)+"/"+dia+"/"+new Date().getFullYear());
		}
		else{
			var dia = this.state.startDate._d.getDate();
			if((""+dia).length==1) dia = '0'+dia;
			var mes = this.state.startDate._d.getMonth()+1;
			if((""+mes).length==1) mes = '0'+mes;
			var today=""+mes+"/"+dia+"/"+this.state.startDate._d.getFullYear();
		}
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			date: today,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/get_programacion_diaria",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								this.state.programacion = result[0];
								for(var i=0;i<this.state.programacion.length;i++){
									for(var j=0;j<this.state.matrices.length;j++){
										if(this.state.programacion[i].ARTALIAS==this.state.matrices[j].ARTALIAS&&this.state.programacion[i].SERIE_MATRIZ==this.state.matrices[j].NUMSERIE){
											this.state.programacion[i].IMAGEN = this.state.matrices[j].IMAGEN;
											break;
										}
									}
								}
								this.calcularTochosProgramacion();
								this.setState({loading: false });
							})
							
		.catch((error) => {
			alert(error);
			this.setState({loading: false });
			console.error(error);
		});

	}
	
	async confirmarAdd(){
		if(this.state.addMatriz==null||this.state.addLongPerfil==0||this.state.addLongTocho==0||this.state.addNTochos==0||this.state.addKg==0){
			alert("Rellena e indica todos los datos");
			return;
		}
			
		if(this.state.startDate==null){
			var dia = new Date().getDate();
			if((""+dia).length==1) dia = '0'+dia;
			var mes = new Date().getMonth() + 1;
			if((""+mes).length==1) mes = '0'+mes;
			var today = ((mes)+"/"+dia+"/"+new Date().getFullYear());
		}
		else{
			var dia = this.state.startDate._d.getDate();
			if((""+dia).length==1) dia = '0'+dia;
			var mes = this.state.startDate._d.getMonth()+1;
			if((""+mes).length==1) mes = '0'+mes;
			var today=""+mes+"/"+dia+"/"+this.state.startDate._d.getFullYear();
		}
		
		this.setState({loading: true });
		
		
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			date: today,
			kg: this.state.addKg,
			SERIE_MATRIZ: this.state.addMatriz.NUMSERIE,
			//n_tochos: this.state.addNTochos,
			//long_tocho: this.state.addLongTocho,
			//long_perfil: this.state.addLongPerfil,
			CODART: this.state.addMatriz.CODART_PERFIL,
			LONG_CORTE: this.state.addLongPerfil
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/add_programacion_diaria",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								this.state.programacion = result[0];
								//Compare every item on this.state.matrices to this.state.programacion and set programacion IMAGEN equal to the matrix that has the same ARTALIAS and NUMSERIE
								for(var i=0;i<this.state.programacion.length;i++){
									for(var j=0;j<this.state.matrices.length;j++){
										if(this.state.programacion[i].ARTALIAS==this.state.matrices[j].ARTALIAS&&this.state.programacion[i].SERIE_MATRIZ==this.state.matrices[j].NUMSERIE){
											this.state.programacion[i].IMAGEN = this.state.matrices[j].IMAGEN;
											break;
										}
									}
								}
								console.log(this.state.programacion);
								this.calcularTochosProgramacion();
								this.setState({loading: false,addNTochos:0,addKg:0,addLongPerfil:0,addLongTocho:0,addMatriz:null,addProgramacion:false });
							})
							
		.catch((error) => {
			alert(error);
			this.setState({loading: false });
			console.error(error);
		});
	}

	async duplicarRegistro(index){
		this.setState({loading: true });
		
		//Si this.state.programacion[index] no tiene el formato dd/MM/yyyy alert y return
		if(this.state.programacion[index].DATE.length!=10){
			alert("La fecha a indicar debe ser del formato dd/MM/aaaa");
			return;
		}
		//Cambia el formato de this.state.programacion[index] de dd/MM/yyyy a MM/dd/yyyy
		var dia = this.state.programacion[index].DATE.substring(0,2);
		var mes = this.state.programacion[index].DATE.substring(3,5);
		var anio = this.state.programacion[index].DATE.substring(6,10);
		var fecha = mes+"/"+dia+"/"+anio;

		//Check if fecha is a date
		if(isNaN(Date.parse(fecha))){
			alert("La fecha a indicar debe ser del formato dd/MM/aaaa");
			return;
		}

		//Check if fecha is >= today
		var today = new Date();
		var fechaDate = new Date(fecha);
		if(fechaDate<today){
			alert("La fecha a indicar debe ser mayor o igual al día de hoy");
			return;
		}

		//Check if fecha's year is less than 2030
		if(fechaDate.getFullYear()>2030){
			alert("La fecha a indicar debe ser menor al año 2031");
			return;
		}

		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			date: fecha,
			kg: this.state.programacion[index].KG,
			SERIE_MATRIZ: this.state.programacion[index].SERIE_MATRIZ,
			//n_tochos: this.state.addNTochos,
			//long_tocho: this.state.addLongTocho,
			//long_perfil: this.state.addLongPerfil,
			CODART: this.state.programacion[index].CODART,
			LONG_CORTE: this.state.programacion[index].LONG_CORTE,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/add_programacion_diaria",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}

								alert("Registro duplicado correctamente a fecha "+this.state.programacion[index].DATE);
								this.state.programacion[index].DATE = null;
								this.setState({loading: false });
							})
							
		.catch((error) => {
			alert(error);
			this.setState({loading: false });
			console.error(error);
		});
	}
	
	async eliminarRegistro(index){
		if(this.state.startDate==null){
			var dia = new Date().getDate();
			if((""+dia).length==1) dia = '0'+dia;
			var mes = new Date().getMonth() + 1;
			if((""+mes).length==1) mes = '0'+mes;
			var today = ((mes)+"/"+dia+"/"+new Date().getFullYear());
		}
		else{
			var dia = this.state.startDate._d.getDate();
			if((""+dia).length==1) dia = '0'+dia;
			var mes = this.state.startDate._d.getMonth()+1;
			if((""+mes).length==1) mes = '0'+mes;
			var today=""+mes+"/"+dia+"/"+this.state.startDate._d.getFullYear();
		}
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			date: today,
			TEMP: this.state.programacion[index].TEMP,
			ID: this.state.programacion[index].ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/extrusion/remove_programacion_diaria",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								this.state.programacion = result[0];
								for(var i=0;i<this.state.programacion.length;i++){
									for(var j=0;j<this.state.matrices.length;j++){
										if(this.state.programacion[i].ARTALIAS==this.state.matrices[j].ARTALIAS&&this.state.programacion[i].SERIE_MATRIZ==this.state.matrices[j].NUMSERIE){
											this.state.programacion[i].IMAGEN = this.state.matrices[j].IMAGEN;
											break;
										}
									}
								}
								this.calcularTochosProgramacion()
								this.setState({loading: false,addNTochos:0,addKg:0,addLongPerfil:0,addLongTocho:0,addMatriz:null,addProgramacion:false });
							})
							
		.catch((error) => {
			alert(error);
			this.setState({loading: false });
			console.error(error);
		});
	}
	
	calcularTochosProgramacion(){
		for(var i=0;i<this.state.programacion.length;i++){
			
			var kg = this.state.programacion[i].KG;
			var numSalidas = this.state.programacion[i].PARAM2;
			if(this.state.programacion[i].PESO==0){
				for(var j=0;j<this.state.matrices.length;j++)
					if(this.state.matrices[j].ARTALIAS==this.state.programacion[i].ARTALIAS&&this.state.matrices[j].NUMSERIE==this.state.programacion[i].SERIE_MATRIZ)
						this.state.programacion[i].PESO = this.state.matrices[j].PESO_TEORICO;
			}
			else var pesoPorMetro = this.state.programacion[i].PESO;
			var medidaCorte = this.state.programacion[i].LONG_CORTE;
			
			
			var numeroBarrasSolas = Math.floor(this.state.medidaMaxima/medidaCorte);
			var numeroBarras = numSalidas * numeroBarrasSolas;
			numeroBarras+=1;
			do {
				numeroBarras-=1;
				var longitudTotal = (numeroBarras*medidaCorte+(this.state.despunte))*numSalidas;
				
				var kgTocho = numeroBarras*medidaCorte*pesoPorMetro*numSalidas;	//kg neto por tocho
				
				var pesoTotal = longitudTotal*pesoPorMetro;
				
				var metrosTocho = (((pesoTotal / this.state.pesoTocho) + this.state.culote)*1000).toFixed(0);
			} while((metrosTocho>605&&numeroBarras>1)||this.state.medidaMaxima<medidaCorte*numeroBarras)
			this.state.programacion[i].NTOCHOS = (kg/kgTocho).toFixed(2);
			this.state.programacion[i].LONG_TOCHO = metrosTocho;
		}
	}
	
	calcularTochos(){
		if(this.state.addMatriz==null) return;
		var kg = this.state.addKg;
		var numSalidas = this.state.addMatriz.PARAM2;
		var pesoPorMetro = this.state.addMatriz.PESO;
		var medidaCorte = this.state.addLongPerfil;
		var numeroBarrasSolas = Math.floor(this.state.medidaMaxima/medidaCorte);
		var numeroBarras = numSalidas * numeroBarrasSolas;
		numeroBarras+=1;
		do {
			numeroBarras-=1;
			var longitudTotal = (numeroBarras*medidaCorte+(this.state.despunte))*numSalidas;
			var kgTocho = numeroBarras*medidaCorte*pesoPorMetro*numSalidas;	//kg neto por tocho
			
			var pesoTotal = longitudTotal*pesoPorMetro;
			
			var metrosTocho = (((pesoTotal / this.state.pesoTocho) + this.state.culote)*1000).toFixed(0);
		} while((metrosTocho>605&&numeroBarras>1)||this.state.medidaMaxima<medidaCorte*numeroBarras)
			
		this.setState({addNTochos:(kg/kgTocho).toFixed(2),addLongTocho:metrosTocho,addLongPerfil:medidaCorte});
	}

	transformStringTofloat(string){
		var string = string.replace(',','.');
		this.calcularTochos();
		return parseFloat(string);
	}
	
	render() {
		return (
		<div>
			{this.state.imagen!=null&&
				<div style={{position:"fixed",width:"85%",marginTop:-250,zIndex:99}}>
					<a style={{cursor:'pointer'}} onClick={() => this.setState({imagen:null})}>
						<img alt="Imagen ampliada" src={this.state.imagen} style={{display: "block",marginLeft: "auto", marginRight: "auto", width: "65%"}}/>
					</a>
				</div>
			}
			{this.state.imagen!=null&&
				<a style={{cursor:'pointer'}} onClick={() => this.setState({imagen:null})}>
					<div style={{position:"fixed",width:"100%",height:"300%",margin:-300,paddingRight:260,backgroundColor:'#ccc',zIndex:98,opacity:0.9}}>
					
					</div>
				</a>
			}
			<div style={{float: "right",marginRight:50}}>
				<SingleDatePicker
				  date={this.state.startDate} // momentPropTypes.momentObj or null
				  onDateChange={startDate => {this.state.startDate=startDate;this.getData()}} // PropTypes.func.isRequired
				  focused={this.state.focused} // PropTypes.bool
				  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
				  id="your_unique_id" // PropTypes.string.isRequired,
				  displayFormat="DD/MM/yyyy"
				  isOutsideRange={(day) => this.doNothing(day)}
				  placeholder= "Fecha"
				/>
			</div>
			
			<h2 style={{marginBottom:25}}>PROGRAMACIÓN DIARIA</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.startDate!=null&&
				<div>
					<table className="tablaTareas">
						<thead>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td>
									REF. PERFIL
								</td>
								<td>
									NOMBRE PERFIL
								</td>
								<td>
									MATRIZ
								</td>
								<td>
									Nº SALIDAS
								</td>
								<td>
									PESO
								</td>
								<td>
									LONG. PERFIL
								</td>
								<td>
									kg
								</td>
								<td>
									Nº TOCHOS
								</td>
								<td>
									LONG. TOCHO
								</td>
								<td>
									PLANO
								</td>
								<td>
									
								</td>
								<td>
									DUPLICAR
								</td>
							</tr>
						</thead>
					{this.state.programacion.map(({ DATE,SITUACION,KG_FAB,IMAGEN,CODART,DESCART,KG,N_TOCHOS,LONG_TOCHO,LONG_CORTE,ARTALIAS,PESO,PARAM2,NTOCHOS,SERIE_MATRIZ,MOSTRAR },index) => (
						<tbody key={index}>
							<tr style={{backgroundColor:"#FFF"}}>
								<td>
									{CODART}
								</td>
								<td>
									{DESCART}
								</td>
								<td>
									{ARTALIAS+'-'+SERIE_MATRIZ}
								</td>
								<td>
									{PARAM2}
								</td>
								<td>
									{PESO}
								</td>
								<td>
									{LONG_CORTE} m
								</td>
								<td>
									{KG}
								</td>
								<td>
									{NTOCHOS}
								</td>
								<td>
									{LONG_TOCHO} mm
								</td>
								<td>
									{!MOSTRAR&&
										<button type="button" onClick={() => {this.state.programacion[index].MOSTRAR=true;this.setState({state:this.state})}}>Ver plano</button>
									}
									{IMAGEN!=null&&MOSTRAR&&
										<img alt="Plano" src={IMAGEN} style={{width:150,cursor:'pointer'}} onClick={() => this.setState({imagen:IMAGEN})}/>	
									}
								</td>
								<td>
									{SITUACION=='A'&&KG_FAB==0&&
										<button type="button" onClick={() => this.eliminarRegistro(index)}>X</button>
									}
								</td>
								<td>
									<input
										value={DATE}
										onChange={(e) => {this.state.programacion[index].DATE = e.target.value;this.setState({ state:this.state })}}
										style={{width:75}}
										placeholder="Fecha"
									/>
									<button type="button" onClick={() => this.duplicarRegistro(index)} style={{marginLeft:5}}>+</button>
								</td>
							</tr>
						</tbody>
					))}
					{this.state.addProgramacion&&
						<tbody>
							<tr style={{backgroundColor:"#FFF"}}>
								<td>
									{this.state.addMatriz!=null ? this.state.addMatriz.CODART_PERFIL : '-'}
								</td>
								<td>
									{this.state.addMatriz!=null ? this.state.addMatriz.DESCART_PERFIL : '-'}
								</td>
								<td>
									<select name="matrices" id="matrices" onChange={(e) => this.seleccionarAddMatriz(e)} value={this.state.addMatriz==null ? null : this.state.addMatriz.ARTALIAS+'-'+this.state.addMatriz.NUMSERIE} style={{marginLeft:10,marginRight:3}}>
										<option value="matriz" disabled selected="selected">Matriz</option>
										{this.state.matrices.map(({ ARTALIAS,SERIE_DEFECTO,NUMSERIE,UNIDADES,IMAGEN }) => (
											UNIDADES>0 &&
											<option value={ARTALIAS+'-'+NUMSERIE} key={ARTALIAS+'-'+NUMSERIE}>
												{ARTALIAS+'-'+NUMSERIE}
											</option>
										))}
									</select>
								</td>
								<td>
									{this.state.addMatriz!=null ? this.state.addMatriz.PARAM2 : '-'}
								</td>
								<td>
									{this.state.addMatriz!=null ? this.state.addMatriz.PESO : '-'}
								</td>
								<td>
									<input
										type="number"
										value={this.state.addLongPerfil}
										onChange={(e) => {this.state.addLongPerfil=e.target.value;this.calcularTochos()}}
										style={{marginRight:10,width:75}}
										min="0"
									/> m
								</td>
								<td>
									<input
										type="number" 
										value={this.state.addKg}
										onChange={(e) => {this.state.addKg=e.target.value;this.calcularTochos()}}
										style={{marginRight:10,width:75}}
										min="0"
									/>
									kg
								</td>
								<td>
									{this.state.addNTochos}
								</td>
								<td>
									{this.state.addLongTocho} mm
								</td>
								<td>
									{this.state.addMatriz!=null&&this.state.addMatriz.IMAGEN!=null&&
										<img src={this.state.addMatriz.IMAGEN} style={{width:150,cursor:'pointer'}} onClick={() => this.setState({imagen:this.state.addMatriz.IMAGEN})} />
									}
								</td>
								<td>
									<button type="button" onClick={() => this.setState({loading: false,addNTochos:0,addKg:0,addLongPerfil:0,addLongTocho:0,addMatriz:null,addProgramacion:false })}>X</button>
								</td>
							</tr>
						</tbody>
					}
					</table>
					<div style={{marginRight:50}}>
						{this.state.addProgramacion&&
							<button type="button" onClick={() => this.confirmarAdd()} style={{marginLeft:50,marginTop:10}}>CONFIRMAR</button>
						}
						<button type="button" onClick={() => this.setState({addProgramacion: !this.state.addProgramacion})} style={{marginLeft:50,marginTop:10}}>{this.state.addProgramacion ? 'CANCELAR' : 'AÑADIR LÍNEA'}</button>
					</div>
				</div>
			}
			<div style={{marginTop:30}}>
				<p><b>INDICAR CULOTE, DESPUNTE Y MEDIDA MÁXIMA:</b></p>
				<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
					<div style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
						<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
							<p style={{marginRight:10}}>CULOTE:</p>
							<input
								type="number" 
								value={this.state.culote}
								onChange={(e) => this.setState({ culote: this.transformStringTofloat(e.target.value) })}
								style={{width:50}}
							/>
						</div>
						<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
							<p style={{marginRight:10}}>DESPUNTE:</p>
							<input
								type="number" 
								value={this.state.despunte}
								onChange={(e) => this.setState({ despunte: this.transformStringTofloat(e.target.value) })}
								style={{width:50}}
							/>
						</div>
						<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
							<p style={{marginRight:10}}>MEDIDA MÁXIMA:</p>
							<input
								type="number" 
								value={this.state.medidaMaxima}
								onChange={(e) => this.setState({ medidaMaxima: this.transformStringTofloat(e.target.value) })}
								style={{width:50}}
							/>
						</div>
					</div>
				</div>
				{(this.state.culote!=this.state.culoteOri || this.state.despunte!=this.state.despunteOri || this.state.medidaMaxima!=this.state.medidaMaximaOri) &&
					<button type="button" onClick={() => this.setState({culote:this.state.culoteOri,despunte:this.state.despunteOri,medidaMaxima:this.state.medidaMaximaOri})} style={{marginLeft:50,marginTop:10}}>REINICIAR CONFIGURACIÓN</button>
				}
				{(this.state.culote!=this.state.culoteOri || this.state.despunte!=this.state.despunteOri || this.state.medidaMaxima!=this.state.medidaMaximaOri) &&
					<button type="button" onClick={() => this.guardarConfiguracion()} style={{marginLeft:50,marginTop:10}}>GUARDAR CONFIGURACIÓN</button>
				}
			</div>
      </div>
    );
  }
}
 
export default Salidas;