import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';

class Salidas extends Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	async handleSubmit(event){
		if(this.state.año==null){
			alert('Selecciona un año');
			return;
		}

		this.getData();
	}

	state = {
		username:'',
		password:'',
		inventario: [],
		recibidas: true,
		creandoTarea: false,
		descripcion: '',
		asunto: '',
		destinatario: null,
		usuarios: [],
		mostrar_todos: 0,
		año: null,
	};
	
	componentDidMount(){
		//this.getData();
	}
	
	doNothing(day){
		//console.log(day);
	}
	
	
	async getData(){
		
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			anno: this.state.año,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/inventario/get_varios",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.inventario = result[0];
								this.setState({loading: false });
							})
							
		.catch((error) => {
			console.error(error);
		});

	}
	
	async convertirInmovilizado(index){
		this.state.inventario[index].CARGANDO_INMOVILIZADO = true;
		this.setState({state:this.state});
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.inventario[index].ID,
			INMOVILIZADO: this.state.inventario[index].ES_INMOVILIZADO==1 ? 0 : 1,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/inventario/cambiar_inmovilizado",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE == 1){
									//alert("Guardado correctamente")
									this.state.inventario[index].ES_INMOVILIZADO==1 ? this.state.inventario[index].ES_INMOVILIZADO=0 : this.state.inventario[index].ES_INMOVILIZADO=1
								}
								else alert("¡¡SE HA PRODUCIDO ALGÚN ERROR!!")
								this.state.inventario[index].CARGANDO_INMOVILIZADO = false;
								this.setState({state:this.state});
							})
							
		.catch((error) => {
			this.state.inventario[index].CARGANDO_INMOVILIZADO = false;
			this.setState({state:this.state});
			console.error(error);
		});
	}
	
	async guardarCANTIDAD(index){
		this.state.inventario[index].CARGANDO_CANTIDAD = true;
		this.setState({state:this.state});
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.inventario[index].ID,
			CANTIDAD: this.state.inventario[index].CANTIDAD_VARIOS,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/inventario/guardar_CANTIDAD_varios",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE == 1){
									//alert("Guardado correctamente")
									this.state.inventario[index].CANTIDAD_VARIOS_ORI = this.state.inventario[index].CANTIDAD_VARIOS;
								}
								else alert("¡¡SE HA PRODUCIDO ALGÚN ERROR!!")
								this.state.inventario[index].CARGANDO_CANTIDAD = false;
								this.setState({state:this.state});
							})
							
		.catch((error) => {
			this.state.inventario[index].CARGANDO_CANTIDAD = false;
			this.setState({state:this.state});
			console.error(error);
		});
	}
	
	async guardarCODART(index){
		this.state.inventario[index].CARGANDO_GUARDAR = true;
		this.setState({state:this.state});
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.inventario[index].ID,
			CODART: this.state.inventario[index].CODART_VARIOS,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/inventario/guardar_CODART_varios",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE == 1){
									//alert("Guardado correctamente")
									this.state.inventario[index].CODART_VARIOS_ORI = this.state.inventario[index].CODART_VARIOS;
								}
								else alert("¡¡SE HA PRODUCIDO ALGÚN ERROR!!")
								this.state.inventario[index].CARGANDO_GUARDAR = false;
								this.setState({state:this.state});
							})
							
		.catch((error) => {
			this.state.inventario[index].CARGANDO_GUARDAR = false;
			this.setState({state:this.state});
			console.error(error);
		});
	}
	
	async guardarPRECIO(index){
		this.state.inventario[index].CARGANDO_PRECIO = true;
		this.setState({state:this.state});
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.inventario[index].ID,
			PRECIO: this.state.inventario[index].PRECIO,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/inventario/guardar_PRECIO_varios",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE == 1){
									//alert("Guardado correctamente")
									this.state.inventario[index].PRECIO_ORI = this.state.inventario[index].PRECIO;
								}
								else alert("¡¡SE HA PRODUCIDO ALGÚN ERROR!!")
								this.state.inventario[index].CARGANDO_PRECIO = false;
								this.setState({state:this.state});
							})
							
		.catch((error) => {
			this.state.inventario[index].CARGANDO_PRECIO = false;
			this.setState({state:this.state});
			console.error(error);
		});
	}
	
	async cargarImagen(index){
		this.state.inventario[index].CARGANDO_IMAGEN = true;
		this.setState({state:this.state});
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.inventario[index].ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/get_picture_inventario",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.inventario[index].IMAGEN = result.IMAGEN;
								this.state.inventario[index].CARGANDO_IMAGEN = false;
								this.state.inventario[index].MOSTRAR_IMAGEN = true;
								this.setState({state:this.state});
							})
							
		.catch((error) => {
			this.state.inventario[index].CARGANDO_IMAGEN = false;
			this.state.inventario[index].MOSTRAR_IMAGEN = false;
			this.setState({state:this.state});
			console.error(error);
		});
	}
	
	handleCheckbox = () => {
		this.setState({mostrar_todos: this.state.mostrar_todos==1 ? 0 : 1})
	};
	
	render() {
		return (
		<div>
			<div style={{float: "right",marginRight:50}}>
				<label style={{marginRight:20}}>
					<input type="checkbox" 
						checked={this.state.mostrar_todos==1}
						onChange={this.handleCheckbox}
					/>
					Mostrar todos
				</label>
				<select name="tareas" id="tareas" onChange={(e) => this.setState({año: e.target.value})}>
					<option value="año" disabled selected="selected">Año</option>
						<option value={2022}>{2022}</option>
						<option value={2023}>{2023}</option>
				</select>
				<button type="button" onClick={this.handleSubmit} style={{marginLeft:50}}>BUSCAR</button>
			</div>
			<h2 style={{marginBottom:25}}>INVENTARIO (VARIOS)</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.inventario.length>0&&
				<a>
					<table className="tablaTareas">
						<thead>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td>
									PERSONA
								</td>
								<td>
									DESCRIPCIÓN
								</td>
								<td>
									CANTIDAD
								</td>
								<td>
									CANTIDAD REVISADA
								</td>
								<td>
									IMAGEN
								</td>
								<td>
									CÓDIGO
								</td>
								<td>
									PRECIO (€)
								</td>
								<td>
									INMOVILIZADO
								</td>
							</tr>
						</thead>
					{this.state.inventario.map(({ CANTIDAD_VARIOS,CANTIDAD_VARIOS_ORI,PERSONA,DESCART,CANTIDAD,IMAGEN,CODART,MOSTRAR_IMAGEN,CARGANDO_IMAGEN,CODART_VARIOS,CODART_VARIOS_ORI,CARGANDO_GUARDAR,CARGANDO_PRECIO,ES_INMOVILIZADO,CARGANDO_INMOVILIZADO,PRECIO,PRECIO_ORI,CARGANDO_CANTIDAD },index) => (
						(!(PRECIO_ORI!=0||ES_INMOVILIZADO==1)||this.state.mostrar_todos==1)&&
						<tbody className="tablaTareas" style={{backgroundColor: PRECIO_ORI!=0||ES_INMOVILIZADO==1 ? "#cdeeee" : "#fff"}} key={index}>
							<tr>
								<td>
									{PERSONA}
								</td>
								<td>
									{DESCART}
								</td>
								<td>
									{CANTIDAD}
								</td>
								<td>
									<input
										type="number" 
									  value={CANTIDAD_VARIOS}
									  onChange={(e) => {this.state.inventario[index].CANTIDAD_VARIOS = e.target.value; this.setState({state: this.state})}}
									  style={{width: "80px"}}
									/>
									{!CARGANDO_CANTIDAD&&CANTIDAD_VARIOS_ORI!=CANTIDAD_VARIOS&&
										<button type="button" style={{marginTop:3}} onClick={()=> this.guardarCANTIDAD(index)}>GUARDAR</button>
									}
									{!CARGANDO_CANTIDAD&&CANTIDAD_VARIOS_ORI==CANTIDAD_VARIOS&&
										<button type="button" style={{marginTop:3}} onClick={()=> this.doNothing()}>GUARDADO</button>
									}
									{CARGANDO_CANTIDAD&&
										<img alt="Loading..." src={loadingGif} style={{height:60,marginTop:-25,marginBottom:-25}}/>
									}
								</td>
								<td>
									{MOSTRAR_IMAGEN&&!CARGANDO_IMAGEN&&
										<a style={{textAlign:"center"}}>
											<img src={IMAGEN} style={{maxHeight:'450px'}} />
											<p>
												<button type="button" style={{marginTop:3}} onClick={()=> {this.state.inventario[index].MOSTRAR_IMAGEN=false;this.setState({state:this.state})}}>OCULTAR IMAGEN</button>
											</p>
										</a>
									}
									{!MOSTRAR_IMAGEN&&!CARGANDO_IMAGEN&&
										<a style={{textAlign:"center"}}>
											<button type="button" style={{marginTop:3}} onClick={()=> this.cargarImagen(index)}>MOSTRAR IMAGEN</button>
										</a>
									}
									{CARGANDO_IMAGEN&&
										<div style={{textAlign:"center"}}>
											<img alt="Loading..." src={loadingGif} style={{height:450}}/>
										</div>
									}
								</td>
								<td>
									<input
									  value={CODART_VARIOS}
									  onChange={(e) => {this.state.inventario[index].CODART_VARIOS = e.target.value; this.setState({state: this.state})}}
									  style={{width: "80px"}}
									/>
									{!CARGANDO_GUARDAR&&CODART_VARIOS_ORI!=CODART_VARIOS&&
										<button type="button" style={{marginTop:3}} onClick={()=> this.guardarCODART(index)}>GUARDAR</button>
									}
									{!CARGANDO_GUARDAR&&CODART_VARIOS_ORI==CODART_VARIOS&&
										<button type="button" style={{marginTop:3}} onClick={()=> this.doNothing()}>GUARDADO</button>
									}
									{CARGANDO_GUARDAR&&
										<img alt="Loading..." src={loadingGif} style={{height:60,marginTop:-25,marginBottom:-25}}/>
									}
								</td>
								<td>
									<input
									type="number" 
									  value={PRECIO}
									  onChange={(e) => {this.state.inventario[index].PRECIO = e.target.value; this.setState({state: this.state})}}
									  style={{width: "80px"}}
									/>
									{!CARGANDO_PRECIO&&PRECIO_ORI!=PRECIO&&
										<button type="button" style={{marginTop:3}} onClick={()=> this.guardarPRECIO(index)}>GUARDAR</button>
									}
									{!CARGANDO_PRECIO&&PRECIO_ORI==PRECIO&&
										<button type="button" style={{marginTop:3}} onClick={()=> this.doNothing()}>GUARDADO</button>
									}
									{CARGANDO_PRECIO&&
										<img alt="Loading..." src={loadingGif} style={{height:60,marginTop:-25,marginBottom:-25}}/>
									}
								</td>
								<td>
									{!CARGANDO_INMOVILIZADO&&
										<input type="checkbox" onChange={() => this.convertirInmovilizado(index)} checked={ES_INMOVILIZADO==1} />
									}
									{CARGANDO_INMOVILIZADO&&
										<img alt="Loading..." src={loadingGif} style={{height:60,marginTop:-25,marginBottom:-25,marginLeft:-25}}/>
									}
								</td>
							</tr>
						</tbody>
					))}
					</table>
					
				</a>
			}
      </div>
    );
  }
}
 
export default Salidas;