import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';

class Salidas extends Component {
	
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	state = {
		username:'',
		password:'',
		calendario: [],
		dias: [],
		INFO: '',
		clientX: 0,
		clientY: 0,
		FESTIVO: null,
		FESTIVO_LOADING: false,
		notas: '',
		diasPersonas: [],
		margenMinutos: 30
	};
	
	componentDidMount(){
	}
	
	doNothing(day){
		//console.log(day);
	}
	
	async handleSubmit(event){
		
		if(this.state.startDate==null){
			var today = ((new Date().getMonth() + 1)+"/"+new Date().getDate()+"/"+new Date().getFullYear());
			var tomorrow = today;
		}
		else{
				var today=""+(this.state.startDate._d.getMonth()+1)+"/"+this.state.startDate._d.getDate()+"/"+this.state.startDate._d.getFullYear();
			if(this.state.endDate!=null)
				var tomorrow = ""+(this.state.endDate._d.getMonth()+1)+"/"+this.state.endDate._d.getDate()+"/"+this.state.endDate._d.getFullYear();
			else var tomorrow= today;
		}
		this.setState({loading: true,calendario:[] });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			date: tomorrow,
			yesterday: today,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/RRHH/get_calendario",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.dias = this.getDaysArray(today,tomorrow);
								this.state.calendario = result[0];
								this.state.festivos = result[1];
								this.state.diasPersonas = result[2];
								this.setState({loading: false });
							})
							
		.catch((error) => {
			console.error(error);
		});

	}
	
	
	
	getDaysArray(start, end) {
		for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
			arr.push(new Date(dt));
		}
		return arr;
	};
	
	getWeekDayName(day){
		switch(day){
			case 0: return 'D';
			case 1: return 'L';
			case 2: return 'M';
			case 3: return 'X';
			case 4: return 'J';
			case 5: return 'V';
			case 6: return 'S';
		}
	}
	
	tieneAlgo(){
		
	}
	
	
	esFestivo(day){
		switch(day.getDay()){
			case 0: return '0';	//FIN DE SEMANA
			case 6: return '0';
		}
		
		var dia = day.getDate();
		if((""+dia).length==1) dia = '0'+dia;
		var mes = day.getMonth() + 1;
		if((""+mes).length==1) mes = '0'+mes;
		var date = (dia+"/"+mes+"/"+day.getFullYear());
		for(var i=0;i<this.state.festivos.length;i++){
			if(date==this.state.festivos[i].FECHA)
				return this.state.festivos[i].TIPO;
		}
		
		return '';
	}
	
	diasPersonas(day,persona){
		var res='';
		for(var i=0;i<this.state.diasPersonas.length;i++)
			if(this.state.diasPersonas[i].FECHA==this.getDate(day)&&this.state.diasPersonas[i].ID_PERSONA==this.state.calendario[persona].ID){
				if(res!='')
					res = '#ff00ea';
				else switch(this.state.diasPersonas[i].TIPO){
					case '0': res = "#00ffff"
					break;
					case '1': res = "#006600"
					break;
					case '2': res = "#7030a0"
					break;
					case '3': res = "#000000"
					break;
					case '4': res = "#60497a"
					break;
					case '5': res = "#002060"
					break;
					case '6': res = "#c6efce"
					break;
					case '7': res = "#ffc7ce"
					break;
					case '8': res = "#ff99cc"
					break;
					case '9': res = "#e26b0a"
					break;
					case 'A': res = "#006600"
					break;
					case 'B': res = "#ff0000"
					break;
					case 'P': res = "#0070c0"
					break;
					case 'R': res = "#ffff00"
					break;
					case 'J': res = "#f79646"
					break;
					case 'N': res = "#808080"
					break;
					case 'E': res = "#963634"
					break;
					case 'V': res = "#00FF00"
				}
			}
			
		return res;
	}
	
	hayRegistros(day,registros){
		var dia = day.getDate();
		if((""+dia).length==1) dia = '0'+dia;
		var mes = day.getMonth() + 1;
		if((""+mes).length==1) mes = '0'+mes;
		var date = (dia+"/"+mes+"/"+day.getFullYear());
		for(var i=0;i<registros.length;i++){
			if(registros[i].DIA==date)
				return true;
		}
		return false;
	}
	
	getRegistros(day,registros,persona){
		var dia = day.getDate();
		if((""+dia).length==1) dia = '0'+dia;
		var mes = day.getMonth() + 1;
		if((""+mes).length==1) mes = '0'+mes;
		var date = (dia+"/"+mes+"/"+day.getFullYear());
		
		var result = "";
		for(var i=0;i<registros.length;i++){
			if(registros[i].DIA==date)
				result += " "+registros[i].HORA+" ("+registros[i].TIPO+") ";
		}
		
		for(var i=0;i<this.state.diasPersonas.length;i++)
			if(this.state.diasPersonas[i].FECHA==this.getDate(day)&&this.state.diasPersonas[i].ID_PERSONA==this.state.calendario[persona].ID){
				switch(this.state.diasPersonas[i].TIPO){
					case '0': result += "LIBRE DISPOSICIÓN"
					break;
					case '1': result += "PERMISO JUSTIFICADO"
					break;
					case '2': result += "VARIOS"
					break;
					case '3': result += "BAJA EN EMPRESA"
					break;
					case '4': result += "MÉDICO HIJOS"
					break;
					case '5': result += "P/MATERNIDAD"
					break;
					case '6': result += "CONSULTA MÉDICA"
					break;
					case '7': result += "RECONOCIMIENTO MÉDICO"
					break;
					case '8': result += "ERTE"
					break;
					case '9': result += "TRABAJA DE MÁS"
					break;
					case 'A': result += "ALTA EMPRESA"
					break;
					case 'B': result += "BAJA"
					break;
					case 'P': result += "ASUNTOS PROPIOS"
					break;
					case 'R': result += "RECUPERA HORAS"
					break;
					case 'J': result += "SALIDA JUSTIFICADA"
					break;
					case 'N': result += "EXCEDENCIA/SUSPENSIÓN"
					break;
					case 'E': result += "ENFERMEDAD"
					break;
					case 'V': result += "VACACIONES"
					break;
				}
				if(this.state.diasPersonas[i].NOTA!=null&&this.state.diasPersonas[i].NOTA!='') result += "\n - "+this.state.diasPersonas[i].NOTA
				result += " // "
			}
		if(" // "==result.substring(result.length-4,result.length))
			result = result.substring(0,result.length-4);
		return result;
	}
	
	checkReloj(index,day){
		
		var dia = day.getDate();
		if((""+dia).length==1) dia = '0'+dia;
		var mes = day.getMonth() + 1;
		if((""+mes).length==1) mes = '0'+mes;
		var date = (dia+"/"+mes+"/"+day.getFullYear());
		
		for(var i=0;i<this.state.calendario[index].REGISTROS.length;i++){
			if(this.state.calendario[index].REGISTROS[i].DIA==date)
				if(this.state.calendario[index].REGISTROS[i].TIPO!=this.state.calendario[index].FICHAJE)
					return true;
		}
		return false;
	}
	
	contarRegistros(day,registros){
		var dia = day.getDate();
		if((""+dia).length==1) dia = '0'+dia;
		var mes = day.getMonth() + 1;
		if((""+mes).length==1) mes = '0'+mes;
		var date = (dia+"/"+mes+"/"+day.getFullYear());
		
		var cuenta = 0;
		for(var i=0;i<registros.length;i++){
			if(registros[i].DIA==date)
				cuenta++;
		}
		return cuenta;
	}
	
	async convertirPersona(tipo){
		let bodyData = {
			user: sessionStorage.getItem('user'),
			FECHA: this.getDateSQL(this.state.FESTIVO_PERSONA.DIA),
			TIPO: tipo,
			PERSONA: this.state.calendario[this.state.FESTIVO_PERSONA.PERSONA].ID,
			NOTA: this.state.notas,
		};
		
		this.setState({FESTIVO_LOADING: true });
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch("http://192.168.0.19:49264"+"/api/modulo/RRHH/convertir_dia_persona",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==1){
									this.state.diasPersonas.push({
										FECHA: this.getDate(this.state.FESTIVO_PERSONA.DIA),
										TIPO: tipo,
										NOTA: this.state.notas,
										ID_PERSONA: this.state.calendario[this.state.FESTIVO_PERSONA.PERSONA].ID,
									});
									if(tipo=='0'||tipo=='V')
										this.state.calendario[this.state.FESTIVO_PERSONA.PERSONA].COGIDOS += 1;
								}
								
								
								
								this.setState({FESTIVO_LOADING: false,FESTIVO_PERSONA:null,notas: '' });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async convertirEnFestivo(tipo){
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			FECHA: this.getDateSQL(this.state.FESTIVO),
			TIPO: tipo,
			ES_FESTIVO: 1,
		};
		
		this.setState({FESTIVO_LOADING: true });
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch("http://192.168.0.19:49264"+"/api/modulo/RRHH/convertir_festivo",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==1)
									this.state.festivos.push({
										FECHA: this.getDate(this.state.FESTIVO),
										TIPO: tipo,
										ES_FESTIVO: 1,
								});
								this.setState({FESTIVO_LOADING: false,FESTIVO:null,notas: '' });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async quitarFestivo(){
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			FECHA: this.getDateSQL(this.state.FESTIVO),
		};
		
		this.setState({FESTIVO_LOADING: true });
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch("http://192.168.0.19:49264"+"/api/modulo/RRHH/quitar_festivo",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								if(result.DONE==1)
									for(var i=0;i<this.state.festivos.length;i++){
										if(this.state.festivos[i].FECHA==this.getDate(this.state.FESTIVO)){
											this.state.festivos.splice(i,1);
											this.setState({FESTIVO_LOADING: false,FESTIVO:null,notas: '' });
											return;
										}
									}
								this.setState({FESTIVO_LOADING: false,FESTIVO:null,notas: '' });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async quitarPersona(){
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			FECHA: this.getDateSQL(this.state.FESTIVO_PERSONA.DIA),
			PERSONA: this.state.calendario[this.state.FESTIVO_PERSONA.PERSONA].ID,
		};
		
		this.setState({FESTIVO_LOADING: true });
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch("http://192.168.0.19:49264"+"/api/modulo/RRHH/quitar_dia_persona",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								if(result.DONE==1){
									for(var i=0;i<this.state.diasPersonas.length;i++){
										if(this.state.diasPersonas[i].FECHA==this.getDate(this.state.FESTIVO_PERSONA.DIA)&&this.state.diasPersonas[i].ID_PERSONA==this.state.calendario[this.state.FESTIVO_PERSONA.PERSONA].ID){
											if(this.state.diasPersonas[i].TIPO=='0'||this.state.diasPersonas[i].TIPO=='V')
												this.state.calendario[this.state.FESTIVO_PERSONA.PERSONA].COGIDOS -= 1;
											this.state.diasPersonas.splice(i,1);
											this.setState({FESTIVO_LOADING: false,FESTIVO_PERSONA:null,notas: '' });
											return;
										}
									}
								}
								this.setState({FESTIVO_LOADING: false,FESTIVO_PERSONA:null,notas: '' });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	getDate(day){
		var dia = day.getDate();
		if((""+dia).length==1) dia = '0'+dia;
		var mes = day.getMonth() + 1;
		if((""+mes).length==1) mes = '0'+mes;
		return (dia+"/"+mes+"/"+day.getFullYear());
	}
	
	getDateSQL(day){
		var dia = day.getDate();
		if((""+dia).length==1) dia = '0'+dia;
		return ((day.getMonth() + 1)+"/"+dia+"/"+day.getFullYear());
	}
	
	getLetra(day,persona){
		var res = '';
		for(var i=0;i<this.state.diasPersonas.length;i++)
			if(this.state.diasPersonas[i].FECHA==this.getDate(day)&&this.state.diasPersonas[i].ID_PERSONA==this.state.calendario[persona].ID){
				if(res!='')
					res = 'VA';
				else switch(this.state.diasPersonas[i].TIPO){
					case '0': res = "LB"
					break;
					case '1': res = "P"
					break;
					case '2': res = "*"
					break;
					case '3': res = "BE"
					break;
					case '4': res = "MH"
					break;
					case '5': res = "M"
					break;
					case '6': res = "CM"
					break;
					case '7': res = "RM"
					break;
					case '8': res = "ER"
					break;
					case '9': res = "TM"
					break;
					case 'A': res = "A"
					break;
					case 'B': res = "B"
					break;
					case 'P': res = "AP"
					break;
					case 'R': res = "R"
					break;
					case 'J': res = "S"
					break;
					case 'N': res = "EX"
					break;
					case 'E': res = "E"
					break;
					case 'V': res = "V"
				}
			}
			
		return res;
	}
	
	getDiasBaja(index){
		var contador = 0;
		for(var i=0;i<this.state.diasPersonas.length;i++)
			if(this.state.diasPersonas[i].ID_PERSONA==this.state.calendario[index].ID)
				if(this.state.diasPersonas[i].TIPO=='B'||this.state.diasPersonas[i].TIPO=='5'){
					contador++;
				}

		return contador==0 ? '' : contador==1 ? ' (1 día de baja)' : ' ('+contador+' días de baja)';
			
	}

	getLetraTipo(TIPO){
		var res = '-';
		switch(TIPO){
			case '0': res = "LB"
			break;
			case '1': res = "P"
			break;
			case '2': res = "*"
			break;
			case '3': res = "BE"
			break;
			case '4': res = "MH"
			break;
			case '5': res = "M"
			break;
			case '6': res = "CM"
			break;
			case '7': res = "RM"
			break;
			case '8': res = "ER"
			break;
			case '9': res = "TM"
			break;
			case 'A': res = "A"
			break;
			case 'B': res = "B"
			break;
			case 'P': res = "AP"
			break;
			case 'R': res = "R"
			break;
			case 'J': res = "S"
			break;
			case 'N': res = "EX"
			break;
			case 'E': res = "E"
			break;
			case 'V': res = "V"
		}
		return res;
	}
	
	async sumarDiaLibre(index,suma){
		this.setState({state:this.state});
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CLOCK_ID: this.state.calendario[index].CLOCK_ID,
			SUMA: this.state.calendario[index].LIBRES+suma,
			FECHA: this.state.startDate._d.getFullYear(),
		};
		this.state.calendario[index].LIBRELOADING= true;
		this.setState({state:this.state});
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch("http://192.168.0.19:49264"+"/api/modulo/RRHH/sumar_dia_persona",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								if(result.DONE==1){
									this.state.calendario[index].LIBRES += suma;
								}
								else alert("Algo ha salido mal");
								this.state.calendario[index].LIBRELOADING= false;
								this.setState({state:this.state});
							})
							
		.catch((error) => {
			this.state.calendario[index].LIBRELOADING= false;
			this.setState({state:this.state});
			alert("Algo ha salido mal");
			console.error(error);
		});
	}
	
	async cambiarReloj(index,fichaje){
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CLOCK_ID: this.state.calendario[index].CLOCK_ID,
			FICHAJE: fichaje,
		};
		
		this.setState({state:this.state});
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch("http://192.168.0.19:49264"+"/api/modulo/RRHH/cambiar_reloj",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								if(result.DONE==1){
									this.state.calendario[index].FICHAJE = fichaje;
								}
								else alert("Algo ha salido mal");
								
								this.setState({state:this.state});
							})
							
		.catch((error) => {
			
			this.setState({state:this.state});
			alert("Algo ha salido mal");
			console.error(error);
		});
	}
	
	cambiarHorario(entrada,hora,minutos,ind){
		if(hora.length==1)
			hora = '0'+hora;
		if(hora.length>2)
			hora = hora.substring(1,3);
		if(hora>23)
			hora = '0'+hora.substring(1,2);
		if(minutos.length==1)
			minutos = '0'+minutos;
		if(minutos.length>2)
			minutos = minutos.substring(1,3);
		if(minutos>59)
			minutos = '0'+minutos.substring(1,2);
		if(entrada)
			this.state.calendario[ind].HORA_ENTRADA = hora+':'+minutos;
		else this.state.calendario[ind].HORA_SALIDA = hora+':'+minutos;
		this.state.calendario[ind].CAMBIANDO_HORARIO = true;
		this.setState({state: this.state});
	}

	async guardarHorario(ind){
		this.setState({state: this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CLOCK_ID: this.state.calendario[ind].CLOCK_ID,
			HORA_ENTRADA: this.state.calendario[ind].HORA_ENTRADA,
			HORA_SALIDA: this.state.calendario[ind].HORA_SALIDA,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch("http://192.168.0.19:49264"+"/api/modulo/asistencia/cambiar_horario",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								if(result.DONE==1)
									this.state.calendario[ind].CAMBIANDO_HORARIO = false;
								else {
									alert("Se ha producido algún error");
								}
								this.setState({state: this.state});
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});

	}

	async cambiarCheckHorario(ind,value){
		this.setState({state: this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CLOCK_ID: this.state.calendario[ind].CLOCK_ID,
			CHECK_HORARIO: this.state.calendario[ind].CHECK_HORARIO==1 ? 0 : 1,
		};
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch("http://192.168.0.19:49264"+"/api/modulo/asistencia/cambiar_check_horario",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								if(result.DONE==1)
									this.state.calendario[ind].CHECK_HORARIO = !this.state.calendario[ind].CHECK_HORARIO==1;
								else {
									alert("Se ha producido algún error");
								}
								this.setState({state: this.state});
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});

	}

	enHora(day,REGISTROS,persona){
		
		var dia = day.getDate();
		if((""+dia).length==1) dia = '0'+dia;
		var mes = day.getMonth() + 1;
		if((""+mes).length==1) mes = '0'+mes;
		var date = (dia+"/"+mes+"/"+day.getFullYear());
		var control = 0;
		var controlAux = 0;
		for(let i=0;i<REGISTROS.length;i++){
			if(REGISTROS[i].DIA==date){
				controlAux++;
				if(REGISTROS[i].enHora==null||true){
					if(this.puntual(REGISTROS[i].HORA,this.state.calendario[persona].HORA_ENTRADA,this.state.calendario[persona].HORA_SALIDA,this.state.calendario[persona].CHECK_HORARIO)){
						REGISTROS[i].enHora = true;
						control++;
					}
					else return '#FFCDD2';
				}
				else 
					if(REGISTROS[i].enHora){
						control++;
					} else return '#FFCDD2';
			}
		}
		
		return '#CDEEEE';
	}

	puntual(horaDada, horaEntrada, horaSalida,checkHorario){
		if(!checkHorario){
			return true;
		}
		
		const horaDadaMinutos = parseInt(horaDada.split(':')[0]) * 60 + parseInt(horaDada.split(':')[1]);
		const horaEntradaMinutos = parseInt(horaEntrada.split(':')[0]) * 60 + parseInt(horaEntrada.split(':')[1]);
		const horaSalidaMinutos = parseInt(horaSalida.split(':')[0]) * 60 + parseInt(horaSalida.split(':')[1]);
		const estaAntesDeEntrada = horaDadaMinutos >= horaEntradaMinutos - this.state.margenMinutos && horaDadaMinutos <= horaEntradaMinutos;
		const estaDespuesDeSalida = horaDadaMinutos >= horaSalidaMinutos && horaDadaMinutos <= horaSalidaMinutos + this.state.margenMinutos;
		//console.log(horaDada+" "+horaEntrada+" "+horaSalida+" "+horaDadaMinutos+" "+horaEntradaMinutos+" "+horaSalidaMinutos+" "+estaAntesDeEntrada||estaDespuesDeSalida)
		//const estaDentroDelRango = horaDadaMinutos >= horaEntradaMinutos - this.state.margenMinutos && horaDadaMinutos <= horaSalidaMinutos + this.state.margenMinutos;
		return estaAntesDeEntrada||estaDespuesDeSalida;
	}

	render() {
		return (
		<div>
			{this.state.INFO!=''&&
				<div style={{position:'fixed',zIndex:100,height:'50px',padding:10,backgroundColor:'#EEE',border: '1px solid black',top:'10px',marginLeft:this.state.clientX-150,marginTop:this.state.clientY}}>
					{this.state.INFO}
				</div>
			}
			{this.state.FESTIVO!=null&&this.esFestivo(this.state.FESTIVO)!='0'&&
				<div style={{position:'fixed',zIndex:100,padding:10,backgroundColor:'#fff',border: '1px solid black',top:'10px',marginLeft:this.state.clientXfestivo-150,marginTop:this.state.clientYfestivo}}>
					<p style={{textAlign:'center',margin:0}}><b>{this.getDate(this.state.FESTIVO)}</b></p>
					{this.esFestivo(this.state.FESTIVO)==''&&!this.state.FESTIVO_LOADING&&
						<div>
							<button type="button" onClick={() => this.convertirEnFestivo('F')} style={{marginTop:5}}>PASAR A FESTIVO</button>
							<br></br>
							<button type="button" onClick={() => this.convertirEnFestivo('V')} style={{marginTop:5}}>PASAR A VACACIONES</button>
							<br></br>
							<button type="button" onClick={() => this.convertirEnFestivo('H')} style={{marginTop:5}}>PASAR A HORAS RETRIBUIDAS EN DESCANSO</button>
							<br></br>
						</div>
					}
					{this.esFestivo(this.state.FESTIVO)!=''&&!this.state.FESTIVO_LOADING&&
						<div>
							<button type="button" onClick={() => this.quitarFestivo()} style={{marginTop:5}}>QUITAR DE FESTIVO</button>
							<br></br>
						</div>
					}
					{!this.state.FESTIVO_LOADING&&<button type="button" onClick={() => this.setState({FESTIVO:null})} style={{marginTop:5}}>CANCELAR</button>}
					{this.state.FESTIVO_LOADING&&
						<img alt="Cargando..." src={loadingGif} style={{height:100}}/>
					}
				</div>
			}
			
			{this.state.FESTIVO_PERSONA!=null&&
				<div style={{position:'fixed',zIndex:100,padding:10,backgroundColor:'#fff',border: '1px solid black',left:'50%',marginLeft:-150,marginTop:50}}>
					<p style={{textAlign:'center',margin:0}}><b>{this.getDate(this.state.FESTIVO_PERSONA.DIA)}</b></p>
					<p style={{textAlign:'center',margin:0}}><b>{this.state.calendario[this.state.FESTIVO_PERSONA.PERSONA].NOMBRE}</b></p>
					{!this.state.FESTIVO_LOADING&&
						<div>
							<input
								type="text" 
								value={this.state.notas}
								onChange={(e) => this.setState({notas: e.target.value})}
								placeholder="Notas u observaciones"
								style={{marginTop:5,width:'100%'}}
								multiline={true}
							/>
							<button type="button" onClick={() => this.convertirPersona('A')} style={{marginTop:5}}>ALTA EMPRESA</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('B')} style={{marginTop:5}}>BAJA</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('E')} style={{marginTop:5}}>ENFERMEDAD</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('P')} style={{marginTop:5}}>ASUNTOS PROPIOS</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('R')} style={{marginTop:5}}>RECUPERA HORAS</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('J')} style={{marginTop:5}}>SALIDA JUSTIFICADA</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('1')} style={{marginTop:5}}>PERMISO JUSTIFICADO</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('2')} style={{marginTop:5}}>VARIOS</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('3')} style={{marginTop:5}}>BAJA EN EMPRESA</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('4')} style={{marginTop:5}}>MÉDICO HIJOS</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('5')} style={{marginTop:5}}>P/MATERNIDAD</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('6')} style={{marginTop:5}}>CONSULTA MÉDICA</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('7')} style={{marginTop:5}}>RECONOCIMIENTO MÉDICO</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('8')} style={{marginTop:5}}>ERTE</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('9')} style={{marginTop:5}}>TRABAJA DE MÁS</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('0')} style={{marginTop:5}}>LIBRE DISPOSICIÓN</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('N')} style={{marginTop:5}}>EXCEDENCIA/SUSPENSIÓN</button>
							<br></br>
							<button type="button" onClick={() => this.convertirPersona('V')} style={{marginTop:5}}>VACACIONES</button>
							<br></br>
						</div>
					}
					{this.diasPersonas(this.state.FESTIVO_PERSONA.DIA,this.state.FESTIVO_PERSONA.PERSONA)!=''&&!this.state.FESTIVO_LOADING&&
						<div>
							<button type="button" onClick={() => this.quitarPersona()} style={{marginTop:5}}>QUITAR INFORMACIÓN</button>
							<br></br>
						</div>
					}
					{!this.state.FESTIVO_LOADING&&<button type="button" onClick={() => this.setState({FESTIVO_PERSONA:null,notas: ''})} style={{marginTop:5}}>CANCELAR</button>}
					{this.state.FESTIVO_LOADING&&
						<img alt="Cargando..." src={loadingGif} style={{height:100}}/>
					}
				</div>
			}
			
			<div style={{float: "right",marginRight:50}}>
				<DateRangePicker
					startDate={this.state.startDate} // momentPropTypes.momentObj or null,
					startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
					endDate={this.state.endDate} // momentPropTypes.momentObj or null,
					endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
					onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
					focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
					onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
					startDatePlaceholderText= "Desde"
					endDatePlaceholderText= "Hasta"
					isOutsideRange={(day) => this.doNothing(day)}
					displayFormat="DD/MM/yyyy"
					firstDayOfWeek={1}
				/>
				<button type="button" onClick={this.handleSubmit} style={{marginLeft:50}}>BUSCAR</button>
			</div>
				<h2 style={{marginBottom:25}}>CALENDARIO LABORAL
				<div style={{marginRight:50}}>
					<a style={{fontSize:12,fontWeight:'normal'}}>Margen de minutos: </a>
					<input type="number" value={this.state.margenMinutos} onChange={(e) => this.setState({margenMinutos: e.target.value})} style={{width:50}}/>
				</div>
			</h2>
			
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{this.state.calendario.length>0&&
				<div>
					<table className="tablaTareas">
						<thead>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td>
									FICHAJE: 2->OFICINAS / 3->AVENIDA / 4->MECANIZADO
								</td>
								<td>
								</td>
								{this.state.dias.map(({},index,object) => (
									<td key={index} style={{textAlign:'center'}}>
										{this.getWeekDayName(this.state.dias[index].getDay())}
									</td>
								))}
							</tr>
						</thead>
						<tbody>
							<tr style={{backgroundColor:"#339999",color:"#fff"}}>
								<td>
									OPERARIO
								</td>
								<td style={{width:'70px'}}>
									DÍAS
								</td>
								{this.state.dias.map(({},index,object) => (
									<td key={index} style={{textAlign:'center',cursor:'pointer'}} onClick={(e) => {this.state.clientXfestivo = e.clientX;this.state.clientYfestivo = e.clientY;this.state.FESTIVO_PERSONA=null;this.setState({FESTIVO:this.state.dias[index]})}}>
										{this.state.dias[index].getDate()}
									</td>
								))}
							</tr>
						{this.state.calendario.map(({ CLOCK_ID,NOMBRE,ID,ESTADO,REGISTROS,LIBRES,COGIDOS,LIBRELOADING,DIAS_COGIDOS,FICHAJE,HORA_ENTRADA,HORA_SALIDA,CHECK_HORARIO,CAMBIANDO_HORARIO },ind) => (
							ESTADO!='F' &&
							<tr key={ind}>
								<td>
									<div style={{float:'left'}}>
										<select name="tareas" defaultValue={FICHAJE} id="tareas" onChange={(e) => this.cambiarReloj(ind,e.target.value)}>
											<option value='2'>2</option>
											<option value='3'>3</option>
											<option value='4'>4</option>
										</select>
									</div>
									{CHECK_HORARIO==1&&
										<div style={{float:'left',marginLeft:10}}>
											{' De '}
											<input
												type="number"
												value={HORA_ENTRADA.substring(0,2)}
												onChange={(e) => this.cambiarHorario(true,e.target.value,this.state.calendario[ind].HORA_ENTRADA.substring(3,5),ind)}
												style={{width:20}}
												/>
											{':'}
											<input
												type="number"
												value={HORA_ENTRADA.substring(3,5)}
												onChange={(e) => this.cambiarHorario(true,this.state.calendario[ind].HORA_ENTRADA.substring(0,2),e.target.value,ind)}
												style={{width:20}}
												/>
											{' a '}
											<input
												type="number"
												value={HORA_SALIDA.substring(0,2)}
												onChange={(e) => this.cambiarHorario(false,e.target.value,this.state.calendario[ind].HORA_SALIDA.substring(3,5),ind)}
												style={{width:20}}
												/>
											{':'}
											<input
												type="number"
												value={HORA_SALIDA.substring(3,5)}
												onChange={(e) => this.cambiarHorario(false,this.state.calendario[ind].HORA_SALIDA.substring(0,2),e.target.value,ind)}
												style={{width:20}}
												/>
										</div>
									}
									{CHECK_HORARIO!=1&&
										<div style={{float:'left',marginLeft:10,width:'164px'}}>-</div>
									}
									<input type="checkbox" checked={this.state.calendario[ind].CHECK_HORARIO==1} onChange={(e) => this.cambiarCheckHorario(ind,e.target.value)}/>
									{CAMBIANDO_HORARIO&&<button type="button" onClick={() => this.guardarHorario(ind)} style={{marginTop:0}}>GUARDAR</button>}
									{' '+NOMBRE}
									{this.getDiasBaja(ind)}
										{DIAS_COGIDOS.map(({FECHA,TIPO},i) => (
											<a key={i}>
												{' '}- {FECHA} ({this.getLetraTipo(TIPO)})
											</a>
										))}
								</td>
								<td>
									{COGIDOS}/{LIBRES}
									{!LIBRELOADING&&<button type="button" onClick={() => this.sumarDiaLibre(ind,-1)} style={{marginTop:0}}>-</button>}
									{!LIBRELOADING&&<button type="button" onClick={() => this.sumarDiaLibre(ind,1)} style={{marginTop:0}}>+</button>}
									{LIBRELOADING&&
										<img alt="Cargando..." src={loadingGif} style={{height:50,margin:-20,marginLeft:0}}/>
									}
								</td>
								{this.state.dias.map(({TEXTO},index) => (
									<td key={index} style={{textAlign:'center',cursor:'pointer',backgroundColor: this.diasPersonas(this.state.dias[index],ind)!='' ? this.diasPersonas(this.state.dias[index],ind) :
									this.hayRegistros(this.state.dias[index],REGISTROS) ? this.enHora(this.state.dias[index],REGISTROS,ind) : 
									this.esFestivo(this.state.dias[index])=='F'||this.esFestivo(this.state.dias[index])=='0' ? '#FF0000' : this.esFestivo(this.state.dias[index])=='V' ? '#26e201' : 
									this.esFestivo(this.state.dias[index])=='H' ? '#f5ff50' : '#FFF'}}
									onMouseOver={(e) => {this.state.clientX = e.clientX;this.state.clientY = e.clientY;this.state.dias[index].TEXTO=NOMBRE+": "+this.getRegistros(this.state.dias[index],REGISTROS,ind);if(this.state.dias[index].TEXTO!=NOMBRE+": ")this.setState({INFO:this.state.dias[index].TEXTO})}}
									onMouseOut={(e) => {this.setState({INFO:''})}}
									onClick={(e) => {this.state.clientXfestivo = e.clientX;this.state.clientYfestivo = e.clientY;this.state.FESTIVO=null;this.setState({FESTIVO_PERSONA:{DIA: this.state.dias[index],PERSONA: ind}})}}>
										{this.getLetra(this.state.dias[index],ind)}
										{this.contarRegistros(this.state.dias[index],REGISTROS)%2!=0&&
											'?'
										}
										{this.checkReloj(ind,this.state.dias[index])&&
											'~'
										}
									</td>
								))}
							</tr>
						))}
						</tbody>
					</table>
				</div>
			}
	  </div>
    );
  }
}
 
export default Salidas;