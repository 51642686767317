import React, { Component } from "react";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css'; 
import loadingGif from './assets/loading.gif';

class Salidas extends Component {
	
	constructor(props) {
		super(props);
		this.onSubmitNothing = this.onSubmitNothing.bind(this);
	}
	
	state = {
		username:'',
		password:'',
		loggedIn: false,
		startDate: null,
		endDate: null,
		focusedInput: null,
		loading:true,
		moldes: [],
		imagen: null,
		filtro: '',
		busquedaArtMat: '',
		articulosBusMat: [],
		busquedaArtHer: '',
		articulosBusHer: [],
		busquedaMaq: '',
		maquinasBus: [],
		proveedores: [],
	};
	
	componentDidMount(){
		this.getData();
	}
	
	doNothing(day){
		//console.log(day);
	}
	
	onSubmitNothing(event){
		event.preventDefault();
	}
	
	seleccionarImagenFicha(index,event){
		if(event.target.files.length>0)
			this.state.moldes[index].IMAGENES.push({IMG: URL.createObjectURL(event.target.files[0]),file: event.target.files[0],SUBIENDO:true});
		this.setState({state:this.state});
	}
	
	seleccionarImagenMantenimiento(index,event){
		if(event.target.files.length>0){
			if(this.state.moldes[index].IMAGENES_NUEVO_MANTENIMIENTO==null) this.state.moldes[index].IMAGENES_NUEVO_MANTENIMIENTO=[];
			this.state.moldes[index].IMAGENES_NUEVO_MANTENIMIENTO.push({IMG: URL.createObjectURL(event.target.files[0]),file: event.target.files[0],SUBIENDO:true});
		}
		this.setState({state:this.state});
	}
	
	async getData(event){
		this.setState({loading: true });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/get_moldes",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.moldes = result[0];
								for(var i=0;i<this.state.moldes.length;i++){
									this.state.moldes[i].IMAGENES = [];
									this.state.moldes[i].SHOW = false;
									this.state.moldes[i].OPEN = false;
									this.state.moldes[i].LOADING = false;
									this.state.moldes[i].MODIFICANDO = false;
									this.state.moldes[i].MATERIALES = [];
									this.state.moldes[i].HERRAMENTALES = [];
									this.state.moldes[i].IND = i;
								}
								this.setState({loading: false });
							})
							
		.catch((error) => {
			console.error(error);
		});

	}
	
	async getArticulosHerra(index,subIndex){
		
		if(this.state.moldes[index].HERRAMENTALES[subIndex].OPEN){
			this.state.moldes[index].HERRAMENTALES[subIndex].OPEN = false;
			this.setState({state: this.state });
			return;
		}
		
		if(this.state.moldes[index].HERRAMENTALES[subIndex].SEARCH){
			this.state.moldes[index].HERRAMENTALES[subIndex].OPEN = true;
			this.setState({state: this.state });
			return;
		}

		this.state.moldes[index].HERRAMENTALES[subIndex].LOADING = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: this.state.moldes[index].HERRAMENTALES[subIndex].CODART,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/get_articulos_herramental",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.moldes[index].HERRAMENTALES[subIndex].LOADING = false;
								this.state.moldes[index].HERRAMENTALES[subIndex].OPEN = true;
								this.state.moldes[index].HERRAMENTALES[subIndex].SEARCH = true;
								this.state.moldes[index].HERRAMENTALES[subIndex].ARTICULOS = result[0];
								this.state.moldes[index].HERRAMENTALES[subIndex].MATERIALES = result[1];
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async getLineas(index,again){
		
		if(!again){
			if(this.state.moldes[index].OPEN){
				this.state.moldes[index].OPEN = false;
				this.setState({state: this.state });
				return;
			}
			
			if(this.state.moldes[index].SEARCH){
				this.state.moldes[index].OPEN = true;
				this.setState({state: this.state });
				return;
			}
		}
		this.state.moldes[index].LOADING = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: this.state.moldes[index].CODART,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/get_info_molde",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								this.state.moldes[index].LOADING = false;
								this.state.moldes[index].OPEN = true;
								this.state.moldes[index].SEARCH = true;
								this.state.moldes[index].IMAGENES = result[0];
								result[1].forEach( mat => {
									mat.EDITING = false;
								});
								this.state.moldes[index].MATERIALES = result[1];
								result[2].forEach( herra => {
									herra.SHOW = false;
									herra.OPEN = false;
									herra.LOADING = false;
									herra.ARTICULOS = [];
								});
								this.state.moldes[index].HERRAMENTALES = result[2];
								this.state.moldes[index].MAQUINAS = result[3];
								this.state.moldes[index].MANTENIMIENTOS = result[4];
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	filtrar(index){
		var salida = this.state.moldes[index];
		var FILTRO = this.state.filtro.toUpperCase();
		if(salida.CODART.includes(FILTRO)||salida.DESCART.includes(FILTRO))
			return true;
		else return false;
	}
	
	crearMantenimiento(index){
		if(this.state.moldes[index].CREANDO_MANTENIMIENTO==null||!this.state.moldes[index].CREANDO_MANTENIMIENTO){
			this.state.moldes[index].CREANDO_MANTENIMIENTO = true;
			this.setState({state: this.state });
		}
		else{
			this.state.moldes[index].comentariosNuevoMantenimiento = '';
			this.state.moldes[index].comentariosDespuesNuevoMantenimiento = "";
			this.state.moldes[index].proveedorNuevoMantenimiento = null;
			this.state.moldes[index].IMAGENES_NUEVO_MANTENIMIENTO = [];
			this.state.date=null;
			this.state.moldes[index].CREANDO_MANTENIMIENTO = false;
			this.setState({state: this.state });
		}
	}
	
	async doCrearMantenimiento(index){
		
		
		if(this.state.date==null||this.state.moldes[index].proveedorNuevoMantenimiento==null||this.state.moldes[index].comentariosNuevoMantenimiento==null){
			alert("Rellena todos los datos");
			return;
		}
		
		let formData = new FormData();
		formData.append('user', sessionStorage.getItem('user'));
		if(this.state.moldes[index].comentariosDespuesNuevoMantenimiento!=null&&this.state.moldes[index].comentariosDespuesNuevoMantenimiento!='')
			formData.append('ESTADO', 'T');
		else formData.append('ESTADO', 'A');
		formData.append('FECHA', ""+(this.state.date._d.getMonth()+1)+"/"+this.state.date._d.getDate()+"/"+this.state.date._d.getFullYear());
		if(this.state.moldes[index].comentariosDespuesNuevoMantenimiento!=null&&this.state.moldes[index].comentariosDespuesNuevoMantenimiento!='')
			formData.append('FECHA_FIN', ""+(this.state.date._d.getMonth()+1)+"/"+this.state.date._d.getDate()+"/"+this.state.date._d.getFullYear());
		formData.append('COMENTARIOS', this.state.moldes[index].comentariosNuevoMantenimiento);
		formData.append('COMENTARIOS_DESPUES', this.state.moldes[index].comentariosDespuesNuevoMantenimiento);
		formData.append('CODPRO', this.state.moldes[index].proveedorNuevoMantenimiento.CODPRO);
		formData.append('CODART', this.state.moldes[index].CODART);
		if(this.state.moldes[index].IMAGENES_NUEVO_MANTENIMIENTO==null)
			this.state.moldes[index].IMAGENES_NUEVO_MANTENIMIENTO = [];
		for(var i=0;i<this.state.moldes[index].IMAGENES_NUEVO_MANTENIMIENTO.length;i++){
			formData.append('file',this.state.moldes[index].IMAGENES_NUEVO_MANTENIMIENTO[i].file)
		}
		
		let data ={
			method: 'POST',
			headers: {
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: formData
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/crear_nuevo_mantenimiento_molde",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.resultado==1){
									this.state.moldes[index].comentariosNuevoMantenimiento = '';
									this.state.moldes[index].proveedorNuevoMantenimiento = null;
									this.state.moldes[index].IMAGENES_NUEVO_MANTENIMIENTO = [];
									this.state.date=null;
									this.state.moldes[index].CREANDO_MANTENIMIENTO = false;
									this.getData();
									alert('Registro de mantenimiento de molde creado correctamente');
									return;
								}
								else alert('Algo ha salido mal');
								this.setState({state: this.state });
								
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	eliminarMantenimiento(IND,index){
		window.confirm('¿Estás seguro? Vas a eliminar este registro.') ? this.doEliminarMantenimiento(IND,index) : this.doNothing();
	}
	
	async doEliminarMantenimiento(IND,index){
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID_REGISTRO: this.state.moldes[IND].MANTENIMIENTOS[index].ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/inyeccion/eliminar_registro_mantenimiento_molde",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.DONE==1){
									this.state.moldes[IND].MANTENIMIENTOS.splice(index,1);
								}
								else alert('Algo ha salido mal');
								this.setState({state: this.state });
								
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async guardarAcciones(IND,index){
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID_REGISTRO: this.state.moldes[IND].MANTENIMIENTOS[index].ID,
			ACCIONES: this.state.moldes[IND].MANTENIMIENTOS[index].ACCIONES,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/inyeccion/guardar_acciones_registro",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.DONE!=1){
									alert('Algo ha salido mal');
								}
								else alert('Comentarios guardados');
								this.setState({state: this.state });
								
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	cerrarRegistro(IND,index){
		window.confirm('¿Estás seguro? Vas a dar por finalizado este registro.') ? this.doCerrarRegistro(IND,index) : this.doNothing();
	}
	
	async doCerrarRegistro(IND,index){
		
		let formData = new FormData();
		console.log(this.state.moldes[IND].MANTENIMIENTOS[index]);
		formData.append('user', sessionStorage.getItem('user'));
		formData.append('ACCIONES', this.state.moldes[IND].MANTENIMIENTOS[index].ACCIONES);
		formData.append('ID_REGISTRO', this.state.moldes[IND].MANTENIMIENTOS[index].ID);
		/*
		if(this.state.moldes[IND].MANTENIMIENTOS[index].IMAGENES!=null)
		for(var i=0;i<this.state.moldes[IND].MANTENIMIENTOS[index].IMAGENES.length;i++){
			if(this.state.moldes[IND].MANTENIMIENTOS[index].IMAGENES[i].TEMP){
				formData.append('file',this.state.moldes[IND].MANTENIMIENTOS[index].IMAGENES[i].file)
			}
		}
		*/
		let data ={
			method: 'POST',
			headers: {
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: formData
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/completar_registro_molde",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.resultado==1){
									alert('El registro se ha completado correctamente');
									this.state.moldes[IND].MANTENIMIENTOS[index].ESTADO = 'T';
									/*
									for(var i=0;i<this.state.moldes[IND].MANTENIMIENTOS[index].IMAGENES.length;i++)
										this.state.moldes[IND].MANTENIMIENTOS[index].IMAGENES[i].TEMP=false;
									*/
								}
								else alert('Algo ha salido mal');
								this.setState({state: this.state });
								
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async modificarObs(index,cancel){
		
		this.state.moldes[index].MODIFICANDO= !this.state.moldes[index].MODIFICANDO;
		
		if(this.state.moldes[index].MODIFICANDO)
			this.state.moldes[index].OBSERVACIONES_COPY = this.state.moldes[index].OBSERVACIONES;
		else{
			if(cancel)
				this.state.moldes[index].OBSERVACIONES_COPY = null;
			else{
				this.state.moldes[index].LOADING = true;
				this.setState({state: this.state });
				
				let bodyData = {
					user: sessionStorage.getItem('user'),
					CODART: this.state.moldes[index].CODART,
					OBS: this.state.moldes[index].OBSERVACIONES_COPY,
				};
				
				let data ={
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+sessionStorage.getItem('token'),
					},
					body: JSON.stringify(bodyData)
				}

				fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/guardar_observaciones_moldes",data)
									.then(result => result.json())
									.then(result => {
										if(result[0]!=null)
										if(result[0].token==0){
											alert('Renovando sesión...');
											window.location.reload(false);
										}
										
										this.state.moldes[index].OBSERVACIONES = this.state.moldes[index].OBSERVACIONES_COPY;	
										this.state.moldes[index].LOADING = false;
										this.setState({state: this.state });
									})
									
				.catch((error) => {
					console.error(error);
				});
				
			}
		}
		
		this.setState({state: this.state });
	}
	
	async editarCtdMat(IND,index,confirmar){
		if(!this.state.moldes[IND].MATERIALES[index].EDITING)
			this.state.moldes[IND].MATERIALES[index].PERC_COPY = this.state.moldes[IND].MATERIALES[index].PERC;
		
		
		if(confirmar&&this.state.moldes[IND].MATERIALES[index].EDITING&&this.state.moldes[IND].MATERIALES[index].PERC_COPY!=this.state.moldes[IND].MATERIALES[index].PERC){
			
				this.state.moldes[IND].LOADING = true;
				this.setState({state: this.state });
				
				let bodyData = {
					user: sessionStorage.getItem('user'),
					ID: this.state.moldes[IND].MATERIALES[index].ID,
					CTD: this.state.moldes[IND].MATERIALES[index].PERC_COPY,
				};
				
				let data ={
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+sessionStorage.getItem('token'),
					},
					body: JSON.stringify(bodyData)
				}

				await fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/cambiar_perc_material_molde",data)
									.then(result => result.json())
									.then(result => {
										if(result[0]!=null)
										if(result[0].token==0){
											alert('Renovando sesión...');
											window.location.reload(false);
										}
										
										this.state.moldes[IND].MATERIALES[index].PERC = this.state.moldes[IND].MATERIALES[index].PERC_COPY;
										this.state.moldes[IND].MATERIALES[index].EDITING = !this.state.moldes[IND].MATERIALES[index].EDITING;			
										this.state.moldes[IND].LOADING = false;
										this.setState({state: this.state });
									})
									
				.catch((error) => {
					console.error(error);
				});
			
		}
		else{
		
			this.state.moldes[IND].MATERIALES[index].EDITING = !this.state.moldes[IND].MATERIALES[index].EDITING;			
			this.setState({state: this.state });
		}
	}
	
	async editarCtdMatHer(IND,index,ind,confirmar){
		if(!this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].EDITING)
			this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].PERC_COPY = this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].PORCENTAJE;
		
		
		if(confirmar&&this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].EDITING&&this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].PERC_COPY!=this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].PORCENTAJE){

				this.state.moldes[IND].HERRAMENTALES[index].LOADING = true;
				this.setState({state: this.state });
				
				let bodyData = {
					user: sessionStorage.getItem('user'),
					CODMAT: this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].CODMAT,
					CODART: this.state.moldes[IND].HERRAMENTALES[index].CODART,
					CTD: this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].PERC_COPY,
				};
				
				let data ={
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'Authorization': 'Bearer '+sessionStorage.getItem('token'),
					},
					body: JSON.stringify(bodyData)
				}

				await fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/cambiar_perc_material_herramental",data)
									.then(result => result.json())
									.then(result => {
										if(result[0]!=null)
										if(result[0].token==0){
											alert('Renovando sesión...');
											window.location.reload(false);
										}
										
										this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].PORCENTAJE = this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].PERC_COPY;
										this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].EDITING = !this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].EDITING;			
										this.state.moldes[IND].HERRAMENTALES[index].LOADING = false;
										this.setState({state: this.state });
									})
									
				.catch((error) => {
					alert('Algo ha salido mal');
					this.state.moldes[IND].HERRAMENTALES[index].LOADING = false;
					console.error(error);
				});
			
		}
		else{
			this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].EDITING = !this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].EDITING;			
			this.setState({state: this.state });
		}
	}
	
	async quitarImagenFicha(IND,index){
		this.state.moldes[index].LOADING = true;
		this.setState({state: this.state });
		
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: this.state.moldes[IND].CODART,
			CAMINO: this.state.moldes[IND].IMAGENES[index].CAMINO,
			TIPO: this.state.moldes[IND].IMAGENES[index].TIPO,
			COMENTARIOS: this.state.moldes[IND].IMAGENES[index].COMENTARIOS,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}

		await fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/quitar_img_ficha_molde",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									window.location.reload(false);
								}
								
								if(result.resultado==1){
									this.state.moldes[IND].IMAGENES.splice(index,1);
									this.state.moldes[IND].LOADING = false;
									this.setState({state: this.state });
									return;
								}
								else alert('Algo ha salido mal');
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			console.error(error);
		});
	}
	
	async subirImagenFicha(IND,index){
		
		if(this.state.moldes[IND].IMAGENES[index].COMENTARIOS==''||this.state.moldes[IND].IMAGENES[index].COMENTARIOS==null){
			alert("Indica un comentario...");
			return;
		}
		
		//this.state.moldes[IND].LOADING = true;
		//this.setState({state: this.state });
		
		let formData = new FormData();
		formData.append('user', sessionStorage.getItem('user'));
		formData.append('TIPO', 'N');
		formData.append('COMENTARIOS', this.state.moldes[IND].IMAGENES[index].COMENTARIOS);
		formData.append('CODART', this.state.moldes[IND].CODART);
		formData.append('file',this.state.moldes[IND].IMAGENES[index].file)
		
		let data ={
			method: 'POST',
			headers: {
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: formData
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/add_imagen_ficha_molde",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.resultado==1){
									this.state.moldes[IND].IMAGENES[index].SUBIENDO = false;
									this.state.moldes[IND].LOADING = false;
									this.setState({state: this.state });
									return;
								}
								else alert('Algo ha salido mal');
								this.setState({state: this.state });
								
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async cambiarMaquinaPorDefecto(IND,index){
		
		this.state.moldes[IND].LOADING = true;
		this.setState({state:this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART_MOLDE: this.state.moldes[IND].CODART,
			MAQUINA: this.state.moldes[IND].MAQUINAS[index],
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/maquina_por_defecto",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.length>0){
									this.state.moldes[IND].MAQUINAS = result[0];
								}
								else alert("Algo ha salido mal");
								this.state.moldes[IND].LOADING = false;
								this.setState({state:this.state});
							})
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	
	async nuevoMantenimiento(IND,index){
		console.log(IND);
		console.log(index);
	}
	
	async verImagenMantenimiento(IND,index){
		
		if(this.state.moldes[IND].MANTENIMIENTOS[index].SEARCH){
			this.state.moldes[IND].MANTENIMIENTOS[index].OPEN = !this.state.moldes[IND].MANTENIMIENTOS[index].OPEN;
			this.setState({state:this.state });
			return;
		}
		
		this.setState({state:this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART_MOLDE: this.state.moldes[IND].CODART,
			ID_REGISTRO: this.state.moldes[IND].MANTENIMIENTOS[index].ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/get_imagenes_registro_mantenimiento_molde",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.length>0){
									this.state.moldes[IND].MANTENIMIENTOS[index].IMAGENES = result[0];
									this.state.moldes[IND].MANTENIMIENTOS[index].SEARCH = true;
									this.state.moldes[IND].MANTENIMIENTOS[index].OPEN = true;
								}
								else alert("Algo ha salido mal");
								this.setState({state:this.state});
							})
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async eliminarMaquina(IND,index){
		
		this.state.moldes[IND].LOADING = true;
		this.setState({state:this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART_MOLDE: this.state.moldes[IND].CODART,
			CODMAQ: this.state.moldes[IND].MAQUINAS[index].CODMAQ,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/eliminar_maquina_molde",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.length>0){
									this.state.moldes[IND].MAQUINAS = result[0];
								}
								else alert("Algo ha salido mal");
								this.state.moldes[IND].LOADING = false;
								this.setState({state:this.state});
							})
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async buscarMaquina(IND){
		this.setState({state: this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			busqueda: this.state.busquedaArtHer,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/inyeccion/get_maquinas",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								this.state.maquinasBus = result[0];
								for(var i=0;i<this.state.moldes[IND].MAQUINAS.length;i++)
									for(var j=0;j<this.state.maquinasBus.length;j++)
										if(this.state.maquinasBus[j].CODMAQ==this.state.moldes[IND].MAQUINAS[i].CODMAQ)
											this.state.maquinasBus.splice(j,1);
								
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async buscarArticuloHer(e){
		e.preventDefault();
		this.setState({state: this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			busqueda: this.state.busquedaArtHer,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/buscar_producto",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								this.state.articulosBusHer = result;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async addCheck(IND,index){
		let formData = new FormData();
		formData.append('user', sessionStorage.getItem('user'));
		formData.append('token',sessionStorage.getItem('token'));
		formData.append('CODART', this.state.moldes[IND].HERRAMENTALES[index].CODART);
		formData.append('DESCRIPCION', this.state.moldes[IND].HERRAMENTALES[index].DESCRIPCION_CHECK);
		if(this.state.moldes[IND].HERRAMENTALES[index].IMAGEN_CHECK!=null)
			formData.append('file',this.state.moldes[IND].HERRAMENTALES[index].IMAGEN_CHECK)
		//hOLA
		let data ={
			method: 'POST',
			headers: {
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: formData
		}
		console.log("llego");
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/add_check_inyeccion",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								console.log("no llego")
								if(result.DONE==1){
									this.state.moldes[IND].HERRAMENTALES[index].CHECKS.push({
										DESCRIPCION:this.state.moldes[IND].HERRAMENTALES[index].DESCRIPCION_CHECK,
										IMAGEN: result.IMAGEN,
										ID: result.ID
									});
									this.state.moldes[IND].HERRAMENTALES[index].ADDING_CHECK=false;
									this.state.moldes[IND].HERRAMENTALES[index].IMAGEN_CHECK=null;
									this.state.moldes[IND].HERRAMENTALES[index].DESCRIPCION_CHECK=null
									this.setState({state: this.state });
								}
								else alert('Se ha producido algún error');
							})
							
		.catch((error) => {
			this.setState({state: this.state });
			alert("ERROR DE RED");
			console.error(error);
		});
	}

	calcularDiferencia(fecha1,fecha2){
		//Calcula la diferencia en segundos entre fecha1 y fecha2 teniendo en cuenta que son strings con el formato dd/MM/yyyy HH:mm
		let d1 = new Date(fecha1.substring(6,10),fecha1.substring(3,5)-1,fecha1.substring(0,2),fecha1.substring(11,13),fecha1.substring(14,16),0,0);
		let d2 = new Date(fecha2.substring(6,10),fecha2.substring(3,5)-1,fecha2.substring(0,2),fecha2.substring(11,13),fecha2.substring(14,16),0,0);
		return (d2.getTime()-d1.getTime())/1000;
	}

	async quitarCheck(IND,index,ind){
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.moldes[IND].HERRAMENTALES[index].CHECKS[ind].ID,
		};
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/remove_check_inyeccion",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.DONE==1){
									this.state.moldes[IND].HERRAMENTALES[index].CHECKS.splice(ind,1);
									this.setState({state: this.state });
								}
								else alert('Se ha producido algún error');
							})
							
		.catch((error) => {
			this.setState({state: this.state });
			alert("ERROR DE RED");
			console.error(error);
		});
	}

	async addChecksCODART(IND,index){
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART_ORIGINAL: this.state.moldes[IND].HERRAMENTALES[index].CODART,
			CODART_COPIAR: this.state.moldes[IND].HERRAMENTALES[index].CODART_ADD,
		};
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/add_checks_inyeccion",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.DONE==1){
									this.state.moldes[IND].HERRAMENTALES[index].CHECKS = result.CHECKS;
									this.setState({state: this.state });
								}
								else alert('No se ha encontrado el producto o dicho producto no tiene checks asociados');
							})
							
		.catch((error) => {
			this.setState({state: this.state });
			alert("ERROR DE RED");
			console.error(error);
		});
	}

	async cambiarFrecuencia(e,IND,index){
		e.preventDefault();
		console.log(this.state.moldes[IND].HERRAMENTALES[index].FRECUENCIA+" "+IND+" "+index);
	}

	async buscarArticuloMat(e,IND,index){
		e.preventDefault();
		this.setState({state: this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			busqueda: IND==null ? this.state.busquedaArtMat : this.state.moldes[IND].HERRAMENTALES[index].PALABRA,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/buscar_producto",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								result.forEach(articulo => {
									articulo.PERC=0;
									articulo.MATERIAL=articulo.CODART;
								});
								if(IND==null)
									this.state.articulosBusMat = result;
								else this.state.moldes[IND].HERRAMENTALES[index].BUSQUEDA = result;
								
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	
	addHerramental(IND,index){
		if(this.state.articulosBusHer[index].MOLDE!=''){
			window.confirm('Este herramental ya está asignado a otro molde, ¿seguro que quieres cambiarlo?') ? this.doAddHerramental(IND,index) : this.doNothing();
		}
		else this.doAddHerramental(IND,index);
			
	}
	
	async buscarProveedor(){
		this.setState({state: this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			busqueda: this.state.busquedaProv,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/control_lotes/buscar_proveedor",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								this.state.proveedores = result;
								this.setState({state: this.state });
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async doAddHerramental(IND,index){
		
		this.state.moldes[IND].LOADING = true;
		this.setState({state:this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART_MOLDE: this.state.moldes[IND].CODART,
			CODART: this.state.articulosBusHer[index].CODART,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/add_herr_molde",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								this.state.articulosBusHer = [];
								this.state.busquedaArtHer = '';
								this.state.moldes[IND].LOADING = false;
								if(result.resultado==1){
									this.getLineas(IND,true);
									return;
								}
								alert("Algo ha salido mal");
								this.setState({state:this.state});
							})
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async addMaquina(IND,index){
		this.state.moldes[IND].LOADING = true;
		this.setState({state:this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART_MOLDE: this.state.moldes[IND].CODART,
			CODMAQ: this.state.maquinasBus[index].CODMAQ,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/add_maquina_molde",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.DONE==1){
									this.state.moldes[IND].MAQUINAS.push(this.state.maquinasBus[index]);
									this.state.moldes[IND].LOADING = false;
									this.setState({maquinasBus:[]})
								}
								else alert('Se ha producido algún error');
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	async addMaterial(IND,index){
		this.state.moldes[IND].LOADING = true;
		this.setState({state:this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART_MOLDE: this.state.moldes[IND].CODART,
			CODART: this.state.articulosBusMat[index].CODART,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/add_material_molde",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								this.state.articulosBusMat[index].ID = result.ID;
								this.state.moldes[IND].MATERIALES.push(this.state.articulosBusMat[index]);
								this.state.moldes[IND].LOADING = false;
								this.setState({articulosBusMat:[],busquedaArtMat:''})
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
		
	}
	
	async addMaterialHerr(IND,index,herramental){
		this.state.moldes[IND].HERRAMENTALES[herramental].LOADING = true;
		this.setState({state:this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART_HERRAMENTAL: this.state.moldes[IND].HERRAMENTALES[herramental].CODART,
			CODART:this.state.moldes[IND].HERRAMENTALES[herramental].BUSQUEDA[index].CODART,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/add_material_herramental",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								this.state.moldes[IND].HERRAMENTALES[herramental].MATERIALES.push({CODMAT: this.state.moldes[IND].HERRAMENTALES[herramental].BUSQUEDA[index].CODART,PORCENTAJE: 100,ARTALIAS: this.state.moldes[IND].HERRAMENTALES[herramental].BUSQUEDA[index].ARTALIAS,DESCART:this.state.moldes[IND].HERRAMENTALES[herramental].BUSQUEDA[index].DESCART});
								this.state.moldes[IND].HERRAMENTALES[herramental].LOADING = false;
								this.state.moldes[IND].HERRAMENTALES[herramental].BUSQUEDA = [];
								this.state.moldes[IND].HERRAMENTALES[herramental].PALABRA = '';
								this.setState({articulosBusMat:[],busquedaArtMat:''})
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			this.state.moldes[IND].HERRAMENTALES[herramental].LOADING = false;
			console.error(error);
		});
		
	}
	
	eliminarMat(IND,index){
		window.confirm('¿Estás seguro? Vas a eliminar este material de este molde.') ? this.doEliminarMat(IND,index) : this.doNothing();
	}
	eliminarMatHerr(IND,index,ind){
		window.confirm('¿Estás seguro? Vas a eliminar este material de este herramental.') ? this.doEliminarMatHerr(IND,index,ind) : this.doNothing();
	}
	
	async doEliminarMat(IND,index){
		this.state.moldes[IND].LOADING = true;
		this.setState({state:this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			ID: this.state.moldes[IND].MATERIALES[index].ID,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/remove_material_molde",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.resultado==0){
									alert('Se ha producido algún error');
									this.state.moldes[IND].LOADING = false;
									this.setState({state:this.state });
									return;
								}
								this.state.moldes[IND].MATERIALES.splice(index,1);
								this.state.moldes[IND].LOADING = false;
								this.setState({state:this.state });
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
		
	}
	
	async doEliminarMatHerr(IND,index,ind){
		this.state.moldes[IND].HERRAMENTALES[index].LOADING = true;
		this.setState({state:this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODMAT: this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].CODMAT,
			CODART: this.state.moldes[IND].HERRAMENTALES[index].CODART,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/remove_material_herramental",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.resultado==0){
									alert('Se ha producido algún error');
									this.state.moldes[IND].HERRAMENTALES[index].LOADING = false;
									this.setState({state:this.state });
									return;
								}
								this.state.moldes[IND].HERRAMENTALES[index].MATERIALES.splice(ind,1);
								this.state.moldes[IND].HERRAMENTALES[index].LOADING = false;
								this.setState({state:this.state });
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			this.state.moldes[IND].HERRAMENTALES[index].LOADING = false;
			console.error(error);
		});
		
	}
	
	async indicarFechador(fechador,index){
		//this.state.moldes[index].LOADING = true;
		this.setState({state:this.state });
		let bodyData = {
			user: sessionStorage.getItem('user'),
			CODART: this.state.moldes[index].CODART,
			FECHADOR: fechador,
		};
		
		let data ={
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'Authorization': 'Bearer '+sessionStorage.getItem('token'),
			},
			body: JSON.stringify(bodyData)
		}
		
		fetch(await "http://192.168.0.19:49264"+"/api/modulo/fabricacion/cambiar_fechador",data)
							.then(result => result.json())
							.then(result => {
								if(result[0]!=null)
								if(result[0].token==0){
									alert('Renovando sesión...');
									return;
								}
								if(result.DONE==0){
									alert('Se ha producido algún error');
								}
								else fechador ? this.state.moldes[index].TIENE_FECHADOR = 'T' : this.state.moldes[index].TIENE_FECHADOR = 'F';
								//this.state.moldes[index].LOADING = false;
								this.setState({state:this.state });
							})
							
		.catch((error) => {
			alert("ERROR DE RED");
			console.error(error);
		});
	}
	
	render() {
		return (
		<div>
			<div style={{float: "right",marginRight:50}}>
				<button type="button" onClick={()=>this.getData()} style={{marginLeft:50}}>ACTUALIZAR</button>
			</div>
			<div style={{float: "right",margin:15,marginTop:0}}>
				<form onSubmit={this.onSubmitNothing}>
				  <label>
					<input
						  type="text" 
						  value={this.state.filtro}
						  onChange={(e) => this.setState({filtro: e.target.value})}
						  placeholder="Filtro"
						/>
				  </label>
				</form>
			</div>
			<h2 style={{marginBottom:25}}>LISTADO DE MOLDES</h2>
			{this.state.loading&&
				<div style={{textAlign:"center"}}>
					<img alt="Loading..." src={loadingGif} style={{height:350}}/>
				</div>
			}
			{!this.state.loading&&this.state.moldes.length>0&&<a>
				<table className="tablaEntrada">
					<tr style={{backgroundColor:"#339999",color:"#fff"}}>
						<td style={{width:"10%"}}>
							COD.ART.
						</td>
						<td style={{width:"50%"}}>
							DESCRIPCIÓN MOLDE
						</td>
						<td style={{width:"10%",textAlign:'center'}}>
							FICHA
						</td>
						<td style={{width:"10%",textAlign:'center'}}>
							IMG. NO APTOS
						</td>
						<td style={{width:"10%",textAlign:'center'}}>
							MÁQUINA
						</td>
						<td style={{width:"10%",textAlign:'center'}}>
							¿FECHADOR?
						</td>
					</tr>
					</table>
				{this.state.moldes.map(({ proveedorNuevoMantenimiento,comentariosNuevoMantenimiento,comentariosDespuesNuevoMantenimiento,IMAGENES_NUEVO_MANTENIMIENTO,CREANDO_MANTENIMIENTO,TIENE_FECHADOR,MAQUINAS,CODART,DESCART,LINEAS,TIENE_IMG,TIENE_MATERIAL,TIENE_MAQUINA,TIENE_HERRAMENTALES,OBSERVACIONES,OPEN,SHOW,LOADING,IMAGENES,INCIDENCIA,MODIFICANDO,OBSERVACIONES_COPY,MATERIALES,HERRAMENTALES,MANTENIMIENTOS,IND },index) => (
					<a key={index}>
						{this.filtrar(index)&&<a>
					<table className="tablaEntrada" style={INCIDENCIA>0 ? {backgroundColor:"#ffCCCC"} : {backgroundColor:"#FFF"}}>
						<tr onClick={() => this.getLineas(index)}>
							<td style={{width:"10%"}}>
								{CODART}
							</td>
							<td style={{width:"50%"}}>
								{DESCART}
							</td>
							<td style={{width:"10%",textAlign:'center'}}>
								{OBSERVACIONES!='' ? 'SÍ' : ''}
							</td>
							<td style={{width:"10%",textAlign:'center'}}>
								{TIENE_IMG>0 ? 'SÍ' : ''}
							</td>
							<td style={{width:"10%",textAlign:'center'}}>
								{TIENE_MAQUINA>0 ? 'SÍ' : ''}
							</td>
							<td style={{width:"10%",textAlign:'center'}}>
								{TIENE_FECHADOR=='T' ? 'SÍ' : 'NO'}
							</td>
						</tr>
						</table>
						<table className="tablaLineas">
							{LOADING&&
								<div style={{textAlign:"center"}}>
									<img alt="Loading..." src={loadingGif} style={{height:150}}/>
								</div>
							}
							{OPEN&&!LOADING&&
								<tr>
									<td colspan="4" style={{whiteSpace: "pre-line",padding:10}}>
										<div id='materiales'>
											<a style={{fontWeight:'bold'}}>MATERIALES:</a>
											<table className="tablaLineas">
												<tr style={{backgroundColor:'#339999',color:"#fff"}}>
													<td>
														COD.ART.
													</td>
													<td style={{textAlign:'center'}}>
														%
													</td>
													<td>
														MATERIAL (ALIAS)
													</td>
													<td>
														DESCRIPCIÓN ARTÍCULO
													</td>
													<td style={{textAlign:"center"}}>
														QUITAR
													</td>
												</tr>
												{MATERIALES.map(({ ID,PERC,PERC_COPY,MATERIAL,DESCART,ARTALIAS,EDITING },index) => (
													<tr style={{backgroundColor:'#fff'}}>
														<td>
															{MATERIAL}
														</td>
														{!EDITING&&
															<td style={{textAlign:'center'}}>
																{PERC} % <button type="button" onClick={()=> this.editarCtdMat(IND,index)}>EDITAR</button>
															</td>
														}
														{EDITING&&
															<td style={{textAlign:'center'}}>
																<input
																  type="number" 
																  maxlength="3"
																  value={PERC_COPY}
																  onChange={(e) => {this.state.moldes[IND].MATERIALES[index].PERC_COPY = e.target.value.replace(',','.'); this.setState({state: this.state})}}
																  style={{width: "40px"}}
																/> % 
																<button style={{marginLeft:5}} type="button" onClick={()=> this.editarCtdMat(IND,index,true)}>GUARDAR</button>
																<button style={{marginLeft:5}} type="button" onClick={()=> this.editarCtdMat(IND,index,false)}>X</button>
															</td>
														}
														<td>
															{ARTALIAS}
														</td>
														<td>
															{DESCART}
														</td>
														<td style={{textAlign:"center"}}>
															<button style={{marginLeft:5}} type="button" onClick={()=> this.eliminarMat(IND,index)}>X</button>
														</td>
													</tr>
												))}
											</table>
											<form onSubmit={(e) => this.buscarArticuloMat(e)} style={{marginTop:10}}>
												<input
													type="text" 
													value={this.state.busquedaArtMat}
													onChange={(e) => this.setState({busquedaArtMat: e.target.value})}
													placeholder="Busca para añadir material"
													style={{width:'20%',margin:10,marginTop:0,marginBottom:0}}
													maxlength="32"
												/>
											</form>
											{this.state.articulosBusMat.length>0&&<button style={{margin:5,float:'right'}} type="button" onClick={()=> this.setState({articulosBusMat:[]})}>CANCELAR</button>}
											{this.state.articulosBusMat.length>0&&
												<table className="tablaEntrada" style={{marginTop:5}}>
													<thead>
														<tr style={{backgroundColor:"#339999",color:"#fff"}}>
															<td>
																COD.ART.
															</td>
															<td>
																DESCRIPCIÓN
															</td>
														</tr>
													</thead>
													<tbody style={{backgroundColor:"#fff"}}>
														{this.state.articulosBusMat.map(({CODART,DESCART},index) => (
															<tr key={index} onClick={() => this.addMaterial(IND,index)}>
																<td>
																	{CODART}
																</td>
																<td>
																	{DESCART}
																</td>
															</tr>
														))}
													</tbody>
												</table>
											}
										</div>
										<div style={{width:"100%",height:5,backgroundColor:'#000',marginTop:20,marginBottom:20}}></div>
										<div id='maquinas' style={{marginTop:20}}>
											<a style={{fontWeight:'bold'}}>MÁQUINAS:</a><br/>
											<a>Las piezas de este molde solo se podrán inyectar en las máquinas seleccionadas a continuación y se seleccionará de forma predeterminada la indicada por defecto.</a>
											<table className="tablaTareas" style={{marginTop:5,marginBottom:15}}>
												<thead>
													<tr style={{backgroundColor:"#339999",color:"#fff"}}>
														<td>
															COD.MÁQ.
														</td>
														<td>
															NOMBRE
														</td>
														<td style={{textAlign:'center'}}>
															POR DEFECTO
														</td>
														<td style={{textAlign:'center'}}>
															QUITAR
														</td>
													</tr>
												</thead>
												<tbody style={{backgroundColor:"#fff"}}>
													{MAQUINAS!=null&&MAQUINAS.map(({CODMAQ,DESCMAQ,DEFECTO},index) => (
														<tr key={index} style={DEFECTO==1 ? {color:'#000',backgroundColor:'#fffe9c'} : {}}>
															<td>
																{CODMAQ}
															</td>
															<td>
																{DESCMAQ}
															</td>
															<td style={{textAlign:'center'}}>
																<button type="button" onClick={()=>this.cambiarMaquinaPorDefecto(IND,index)} style={{marginLeft:5}}>{DEFECTO!=1 ? 'DAR PRIORIDAD' : 'QUITAR PRIORIDAD'}</button>
															</td>
															<td style={{textAlign:'center'}}>
																<button type="button" onClick={()=>this.eliminarMaquina(IND,index)} style={{marginLeft:5}}>ELIMINAR</button>
															</td>
														</tr>
													))}
												</tbody>
											</table>
											{this.state.maquinasBus.length==0&&<button type="button" onClick={()=> this.buscarMaquina(IND)}>VER MÁQUINAS PARA AÑADIR</button>}
											{this.state.maquinasBus.length>0&&<button type="button" onClick={()=> this.setState({maquinasBus:[]})}>CANCELAR</button>}
											{this.state.maquinasBus.length>0&&
												<table className="tablaEntrada" style={{marginTop:5}}>
													<thead>
														<tr style={{backgroundColor:"#339999",color:"#fff"}}>
															<td>
																COD.MÁQ.
															</td>
															<td>
																NOMBRE
															</td>
														</tr>
													</thead>
													<tbody style={{backgroundColor:"#fff"}}>
														{this.state.maquinasBus.map(({CODMAQ,DESCMAQ},index) => (
															<tr key={index} onClick={() => this.addMaquina(IND,index)}>
																<td>
																	{CODMAQ}
																</td>
																<td>
																	{DESCMAQ}
																</td>
															</tr>
														))}
													</tbody>
												</table>
											}
										</div>
										<div id="fechador" style={{marginTop:20}}>
											<a style={{fontWeight:'bold'}}>¿TIENE FECHADOR?</a>
											<br></br>
											<input type="radio" id="fechador" name="fech" value="S" onChange={() => this.indicarFechador(true,index)} checked={TIENE_FECHADOR=='T'} />
											<label for="fechador">Sí, tiene fechador y es obligatorio indicarlo para comenzar una fabricación</label>
											<br></br>
											<input type="radio" id="no-fechador" name="fech" value="N" onChange={() => this.indicarFechador(false,index)} checked={TIENE_FECHADOR=='F'} />
											<label for="no-fechador">No tiene fechador o no es necesario indicarlo</label>
										</div>
										<div style={{width:"100%",height:5,backgroundColor:'#000',marginTop:20,marginBottom:20}}></div>
										<div id='ficha_molde' style={{marginTop:20}}>
											<a style={{fontWeight:'bold'}}>FICHA MOLDE:</a>
											{!MODIFICANDO&&
												<div style={{backgroundColor:'#fff',border:'1px solid black',padding:7}}>
													<a>
														{OBSERVACIONES}
													</a>
												</div>
											}
											{MODIFICANDO&&
												<textarea onChange={e => this.state.moldes[index].OBSERVACIONES_COPY = e.target.value} rows={(this.state.moldes[index].OBSERVACIONES_COPY.match(/n/g)||[]).length}>
													{this.state.moldes[index].OBSERVACIONES_COPY}
												</textarea>
											}
											<div style={{textAlign:'center',width:'100%',marginTop:5,marginBottom:20}}>
												<button type="button" onClick={()=> this.modificarObs(index,false)}>{MODIFICANDO ? 'GUARDAR' : 'MODIFICAR'}</button>
												{MODIFICANDO&&<button type="button" onClick={()=> this.modificarObs(index,true)} style={{marginLeft:20}}>CANCELAR</button>}
											</div>
										</div>
										<div style={{width:"100%",height:5,backgroundColor:'#000',marginTop:20,marginBottom:20}}></div>
										<div id='imagenes' style={{marginBottom:10}}>
											<a style={{fontWeight:'bold'}}>IMÁGENES MATERIAL NO APTO:</a><br></br>
											<div style={{width:'100%',marginBottom:10,textAlign:'center'}}>
												<table style={{borderCollapse: "collapse",margin:"auto"}}>
													<tr style={{backgroundColor:'#fff'}}>
													{IMAGENES.length>0&&IMAGENES.map(({IMG,COMENTARIOS,SUBIENDO,EDITING},index) => (
														<td key={index}>
															<img src={IMG} style={{maxHeight:'250px',maxWidth:'200px',padding:5}} />
															{!SUBIENDO&&<p style={{margin:0,textAlign:'center'}}>{COMENTARIOS}</p>}
															{SUBIENDO&&
																<input
																	type="text" 
																	value={COMENTARIOS}
																	onChange={(e) => {this.state.moldes[IND].IMAGENES[index].COMENTARIOS=e.target.value;this.setState({state: this.state})}}
																	placeholder="Añade un pie de foto"
																	style={{display: "block",margin:'auto'}}
																	maxlength="64"
																/>
															}
															{SUBIENDO&&
																<div>
																	<button type="button" onClick={()=>this.subirImagenFicha(IND,index)} style={{marginTop:5}}>CONFIRMAR Y SUBIR</button>
																	<button type="button" onClick={()=>{this.state.moldes[IND].IMAGENES.splice(index,1);this.setState({state:this.state})}} style={{marginLeft:5}}>X</button>
																</div>
															}
															{!SUBIENDO&&
																<button type="button" onClick={()=>this.quitarImagenFicha(IND,index)} style={{marginTop:5}}>QUITAR IMAGEN</button>
															}
														</td>
													))}
													</tr>
												</table>
												<label for="image_uploads" style={{cursor: 'pointer'}}><button type="button" style={{pointerEvents: 'none',marginTop:5}}>AÑADIR IMAGEN</button></label>
												<input type="file" id="image_uploads" name="file" onChange={(event) => this.seleccionarImagenFicha(index,event)} style={{display:'none'}} />
											</div>
										</div>
										<div style={{width:"100%",height:5,backgroundColor:'#000',marginTop:20,marginBottom:20}}></div>
										<div id='herramentales'>
											<a style={{fontWeight:'bold'}}>HERRAMENTALES - Para editar los herramentales accede a A3ERP. El peso está en la ventana principal del artículo. El colorante está en la pestaña campos externos como 'Cód. artículo alquiler' y 
											las piezas de las que está compuesto son las de la pestaña 'Kit Herramental'. Desde aquí solo podrás añadir herramentales al molde.</a>
											<table className="tablaEntrada" style={{backgroundColor:"#FFF"}}>
												<tr style={{backgroundColor:'#339999',color:"#fff"}}>
													<td style={{width:"8%"}}>
														COD.ART.
													</td>
													<td style={{width:"25%"}}>
														DESCRIPCIÓN ARTÍCULO
													</td>
													<td style={{textAlign:'center',width:"10%"}}>
														PESO PIEZA
													</td>
													<td style={{textAlign:'center',width:"8%"}}>
														COD.COL.
													</td>
													<td style={{width:"20%"}}>
														COLORANTE
													</td>
													<td style={{width:"5%"}}>
														s/iny.
													</td>
													<td style={{textAlign:'center',width:"24%"}}>
														IMAGEN/PLANO
													</td>
												</tr>
											</table>
											{HERRAMENTALES.map(({ CODART_ADD,SEGUNDOS_INYECTADA,IMAGEN_CHECK,DESCRIPCION_CHECK,ADDING_CHECK,CHECKS,FRECUENCIA,CODART,DESCART,IMAGEN,ARTICULOS,LOADING,SHOW,OPEN,DESCCOL,CODCOL,PESO,MEDIDAPESO,MATERIALES,BUSQUEDA,PALABRA },index) => (
												<a key={index}>
													<table className="tablaEntrada" style={{backgroundColor:"#FFF"}}>
														<tr onClick={() => this.getArticulosHerra(IND,index)}>
															<td style={{width:"8%"}}>
																{CODART}
															</td>
															<td style={{width:"25%"}}>
																{DESCART}
															</td>
															<td style={{textAlign:'center',width:"10%"}}>
																<a style={{display:'block'}}>{PESO} {MEDIDAPESO=='' ? 'g' : MEDIDAPESO}</a>
																	{false&&
																<button type="button" onClick={()=> this.editarPesoHerr(IND,index)}>EDITAR</button>
																	}
															</td>
															<td style={{textAlign:'center',width:"8%"}}>
																<a style={{display:'block'}}>{CODCOL}</a>
																{CODCOL==null&&false&&<button type="button" onClick={()=> this.addColoranteHer(IND,index)}>AÑADIR</button>}
																{CODCOL!=null&&false&&<button type="button" onClick={()=> this.quitarColoranteHer(IND,index)}>QUITAR</button>}
															</td>
															<td style={{width:"20%"}}>
																{DESCCOL}
															</td>
															<td style={{width:"5%"}}>
																{SEGUNDOS_INYECTADA.toFixed(2)}
															</td>
															<td style={{textAlign:'center',width:"24%"}}>
																<img src={IMAGEN} style={{maxHeight:'250px',padding:5}} />
															</td>
														</tr>
													</table>
													{OPEN&&
														<table className="tablaLineas">
															<tr style={{backgroundColor: MATERIALES.length>0 ? '#a800ff' : '#777',color:"#fff"}}>
																<td>
																	COD.MAT.
																</td>
																<td style={{textAlign:'center'}}>
																	%
																</td>
																<td>
																	MATERIAL (ALIAS)
																</td>
																<td>
																	DESCRIPCIÓN ARTÍCULO
																</td>
																<td style={{textAlign:"center",maxWidth:'185px'}}>
																	{BUSQUEDA!=null&&BUSQUEDA.length>0&&<button style={{float:'left',marginRight:3}} type="button" onClick={()=> {this.state.moldes[IND].HERRAMENTALES[index].BUSQUEDA=[];this.setState({state:this.state})}}>CANCELAR</button>}
																	<form onSubmit={(e) => this.buscarArticuloMat(e,IND,index)} style={{float:'right'}}>
																				<input
																					type="text" 
																					value={PALABRA}
																					onChange={(e) => {this.state.moldes[IND].HERRAMENTALES[index].PALABRA=e.target.value;this.setState({state:this.state})}}
																					placeholder="Añade material específico de este herramental"
																					style={{width:'300px',margin:0,marginTop:0,marginBottom:0}}
																					maxlength="32"
																				/>
																			</form>
																</td>
															</tr>
															{MATERIALES.map(({ ID,PORCENTAJE,PERC_COPY,CODMAT,DESCART,ARTALIAS,EDITING },ind) => (
																<tr style={{backgroundColor:'#ebc5ff'}} key={ind}>
																	<td>
																		{CODMAT}
																	</td>
																	{!EDITING&&
																		<td style={{textAlign:'center'}}>
																			{PORCENTAJE} % <button type="button" onClick={()=> this.editarCtdMatHer(IND,index,ind)}>EDITAR</button>
																		</td>
																	}
																	{EDITING&&
																		<td style={{textAlign:'center'}}>
																			<input
																			  type="number" 
																			  maxlength="3"
																			  value={PERC_COPY}
																			  onChange={(e) => {this.state.moldes[IND].HERRAMENTALES[index].MATERIALES[ind].PERC_COPY = e.target.value.replace('.','').replace(',',''); this.setState({state: this.state})}}
																			  style={{width: "40px"}}
																			/> % 
																			<button style={{marginLeft:5}} type="button" onClick={()=> this.editarCtdMatHer(IND,index,ind,true)}>GUARDAR</button>
																			<button style={{marginLeft:5}} type="button" onClick={()=> this.editarCtdMatHer(IND,index,ind,false)}>X</button>
																		</td>
																	}
																	<td>
																		{ARTALIAS}
																	</td>
																	<td>
																		{DESCART}
																	</td>
																	<td style={{textAlign:"center"}}>
																		<button style={{marginLeft:5}} type="button" onClick={()=> this.eliminarMatHerr(IND,index,ind)}>X</button>
																	</td>
																</tr>
															))}
														</table>
													}
													{OPEN&&
														<table className="tablaLineas">
															<tr style={{backgroundColor:  '#feffeb',color:"#000"}}>
																<td>
																	DESCRIPCIÓN CHECK
																	{!ADDING_CHECK&&
																		<button style={{marginLeft:20}} type="button" onClick={()=> {this.state.moldes[IND].HERRAMENTALES[index].ADDING_CHECK=true;this.setState({state:this.state})}}>AÑADIR CHECK</button>
																	}
																	{!ADDING_CHECK&&
																		<a style={{float:'right'}}>
																			<a style={{float:'left'}}>
																			Añadir checks del artículo: 
																			</a>
																			<form onSubmit={(e) => this.addChecksCODART(IND,index)}
																					style={{width:'150px',margin:0,marginTop:0,marginBottom:0,float:'right',paddingRight:'100px'}}>
																				<input
																					type="text"
																					value={CODART_ADD}
																					onChange={(e) => {this.state.moldes[IND].HERRAMENTALES[index].CODART_ADD=e.target.value;this.setState({state:this.state})}}
																					placeholder="CÓDIGO HERRAMENTAL"
																					maxlength="32"
																				/>
																			</form>
																		</a>
																	}
																</td>
																<td style={{textAlign:'center'}}>
																	IMAGEN
																</td>
																<td style={{textAlign:"center",width:'300px'}}>
																	FRECUENCIA (min)
																	<form onSubmit={(e) => this.cambiarFrecuencia(e,IND,index)} style={{float:'right'}}>
																		<input
																			type="number" 
																			value={FRECUENCIA==''||FRECUENCIA==null ? '120' : FRECUENCIA}
																			onChange={(e) => {this.state.moldes[IND].HERRAMENTALES[index].FRECUENCIA=e.target.value;this.setState({state:this.state})}}
																			placeholder="120"
																			style={{width:'100px',margin:0,marginTop:0,marginBottom:0}}
																			maxlength="32"
																		/>
																	</form>
																</td>
																<td style={{width:'50px'}}></td>
															</tr>
															{CHECKS.map(({ DESCRIPCION,IMAGEN,ID },ind) => (
																<tr style={{backgroundColor:'#feffeb'}} key={ind}>
																	<td>
																		{DESCRIPCION}
																	</td>
																	<td style={{textAlign:"center"}} colspan="2">
																		<img src={IMAGEN} style={{maxHeight:'250px',padding:5}} />
																	</td>
																	<td style={{textAlign:"center"}}>
																		<button style={{marginLeft:0}} type="button" onClick={()=> this.quitarCheck(IND,index,ind)}>X</button>
																	</td>
																</tr>
															))}
															{ADDING_CHECK&&
																<tr style={{backgroundColor:'#feffeb'}}>
																	<td>
																		<input
																			type="text"
																			value={DESCRIPCION_CHECK}
																			onChange={(e) => {this.state.moldes[IND].HERRAMENTALES[index].DESCRIPCION_CHECK=e.target.value;this.setState({state:this.state})}}
																			placeholder="Descripción del check"
																			style={{width:'90%',margin:0,marginTop:0,marginBottom:0}}
																		/>
																	</td>
																	<td style={{textAlign:"center"}} colspan="2">
																		<input
																			type="file"
																			onChange={(e) => {this.state.moldes[IND].HERRAMENTALES[index].IMAGEN_CHECK=e.target.files[0];this.setState({state:this.state})}}
																			style={{width:'90%',margin:0,marginTop:0,marginBottom:0}}
																			maxlength="32"
																		/>
																	</td>
																	<td style={{textAlign:"center"}}>
																	<button style={{marginLeft:0}} type="button" onClick={()=> {this.addCheck(IND,index)}}>+</button>
																		<button style={{marginLeft:0}} type="button" onClick={()=> {this.state.moldes[IND].HERRAMENTALES[index].ADDING_CHECK=false;this.state.moldes[IND].HERRAMENTALES[index].IMAGEN_CHECK=null;this.state.moldes[IND].HERRAMENTALES[index].DESCRIPCION_CHECK=null;this.setState({state:this.state})}}>X</button>
																	</td>
																</tr>
															}
														</table>
													}
													{OPEN&&BUSQUEDA!=null&&BUSQUEDA.length>0&&
														<table className="tablaEntrada" style={{marginTop:5}}>
															<thead>
																<tr style={{backgroundColor:"#339999",color:"#fff"}}>
																	<td>
																		COD.ART.
																	</td>
																	<td>
																		DESCRIPCIÓN
																	</td>
																</tr>
															</thead>
															<tbody style={{backgroundColor:"#fff"}}>
																{BUSQUEDA.map(({CODART,DESCART},ind) => (
																	<tr key={ind} onClick={() => this.addMaterialHerr(IND,ind,index)}>
																		<td>
																			{CODART}
																		</td>
																		<td>
																			{DESCART}
																		</td>
																	</tr>
																))}
															</tbody>
														</table>
													}
													{OPEN&&ARTICULOS.length>0&&
														<table className="tablaLineas">
															<tr style={{backgroundColor:'#339999',color:"#fff"}}>
																<td style={{width:"10%"}}>
																	COD.PIEZA
																</td>
																<td style={{textAlign:'center',width:"10%"}}>
																	UNIDADES
																</td>
																<td style={{width:"50%"}}>
																	DESCRIPCIÓN PIEZA
																</td>
																<td style={{textAlign:'center',width:"30%"}}>
																	IMAGEN
																</td>
															</tr>
															{ARTICULOS.map(({ CODART,DESCART,CANTIDAD,IMAGEN },index) => (
																<tr key={index} style={{background:'#ddd'}}>
																	<td style={{width:"10%"}}>
																		{CODART}
																	</td>
																	<td style={{textAlign:'center',width:"10%"}}>
																		{CANTIDAD}
																	</td>
																	<td style={{width:"50%"}}>
																		{DESCART}
																	</td>
																	<td style={{textAlign:'center',width:"30%"}}>
																		<img src={IMAGEN} style={{maxHeight:'200px',padding:5}} />
																	</td>
																</tr>
															))}
														</table>
													}
												</a>
											))}
											<form onSubmit={(e) => this.buscarArticuloHer(e)} style={{marginTop:10}}>
												<input
													type="text" 
													value={this.state.busquedaArtHer}
													onChange={(e) => this.setState({busquedaArtHer: e.target.value})}
													placeholder="Busca para añadir herramentales"
													style={{width:'20%',margin:10,marginTop:0,marginBottom:this.state.articulosBusHer.length>0 ? 0 : 20}}
													maxlength="32"
												/>
											</form>
											{this.state.articulosBusHer.length>0&&<button style={{margin:5,float:'right'}} type="button" onClick={()=> this.setState({articulosBusHer:[]})}>CANCELAR</button>}
											{this.state.articulosBusHer.length>0&&
												<table className="tablaEntrada" style={{marginTop:5,marginBottom:20}}>
													<thead>
														<tr style={{backgroundColor:"#339999",color:"#fff"}}>
															<td>
																COD.ART.
															</td>
															<td>
																DESCRIPCIÓN
															</td>
															<td>
																MOLDE
															</td>	
														</tr>
													</thead>
													<tbody style={{backgroundColor:"#fff"}}>
														{this.state.articulosBusHer.map(({CODART,DESCART,MOLDE},index) => (
															<tr key={index} onClick={() => this.addHerramental(IND,index)}>
																<td>
																	{CODART}
																</td>
																<td>
																	{DESCART}
																</td>
																<td>
																	{MOLDE}
																</td>
															</tr>
														))}
													</tbody>
												</table>
											}
											<div style={{width:"100%",height:5,backgroundColor:'#000',marginTop:20,marginBottom:20}}></div>
											<a style={{fontWeight:'bold'}}>REGISTROS DE MANTENIMIENTOS DEL MOLDE:</a>
											{MANTENIMIENTOS.length>0&&
												<table className="tablaEntrada" style={{marginTop:5}}>
													<thead>
														<tr style={{backgroundColor:"#339999",color:"#fff",cursor:'auto'}}>
															<td>
																FECHA
															</td>
															<td>
																NºDOC.
															</td>
															<td>
																NOMBRE
															</td>
															<td>
																COD.PROVE.
															</td>
															<td>
																PROVEEDOR
															</td>
															<td>
																RAZONES DE LLEVAR EL MOLDE A AJUSTAR
															</td>
															<td>
																COMENTARIOS TRAS EL AJUSTE
															</td>
															<td style={{width:'200px'}}>
																
															</td>
														</tr>
													</thead>
													<tbody style={{backgroundColor:"#fff"}}>
															{MANTENIMIENTOS.map(({ SERIE,NUMDOC,OBSERVACIONES,FECHA,CODPRO,NOMPRO,HAYIMAGEN,OPEN,IMAGENES,ACCIONES,NOMBRE,ESTADO },index) => (
															<tr key={index} onClick={()=> ESTADO=='T' ? this.verImagenMantenimiento(IND,index) : this.doNothing()}>
																<td>{FECHA}</td>
																<td>{SERIE}/{NUMDOC}</td>
																<td>{NOMBRE}</td>
																<td>{CODPRO}</td>
																<td>{NOMPRO}</td>
																<td>{OBSERVACIONES}</td>
																<td>
																	{ESTADO!='T'&&
																		<input
																			type="text" 
																			value={ACCIONES}
																			onChange={(e) => {this.state.moldes[IND].MANTENIMIENTOS[index].ACCIONES=e.target.value;this.setState({state: this.state})}}
																			placeholder="Indica unos comentarios (tienes que guardar para que se graben)"
																			style={{width:'90%'}}
																		/>
																	}
																	{ESTADO=='T'&&
																		ACCIONES
																	}
																</td>
																{!OPEN&&
																	<td>
																		{ESTADO=='T'&&HAYIMAGEN>0&&<a>VER IMÁGENES</a>}
																		{ESTADO=='T'&&HAYIMAGEN==0&&<a>NO HAY IMÁGENES</a>}
																		{ESTADO!='T'&&
																			<a>
																				<button type="button" style={{marginTop:0}} onClick={()=> this.guardarAcciones(IND,index)}>GUARDAR</button>
																				<button type="button" style={{marginTop:3}} onClick={()=> this.cerrarRegistro(IND,index)}>GUARDAR Y FINALIZAR</button>
																				<button type="button" style={{marginTop:3}} onClick={()=> this.eliminarMantenimiento(IND,index)}>ELIMINAR REGISTRO</button>
																			</a>
																		}
																	</td>
																}
																{OPEN&&
																	<td>
																		{IMAGENES.map(({IMG},index) => (
																			<img src={IMG} style={{maxHeight:'250px',maxWidth:'200px',padding:5,float:'left'}} />
																		))}
																	</td>
																}
															</tr>
														))}
													</tbody>
												</table>
											}
											<button type="button" style={{marginTop:10}} onClick={()=> this.crearMantenimiento(IND)}>{CREANDO_MANTENIMIENTO ? 'CANCELAR CREACIÓN MANTENIMIENTO' : 'CREAR NUEVO MANTENIMIENTO'}</button>
											{CREANDO_MANTENIMIENTO&&<button type="button" style={{marginTop:10}} onClick={()=> this.doCrearMantenimiento(IND)}>CREAR NUEVO MANTENIMIENTO</button>}
											{CREANDO_MANTENIMIENTO&&
												<div>
													<p><b>FECHA NUEVO MANTENIMIENTO:</b></p>
													<SingleDatePicker
													  date={this.state.date} // momentPropTypes.momentObj or null
													  onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
													  focused={this.state.focused} // PropTypes.bool
													  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
													  id="your_unique_id" // PropTypes.string.isRequired,
													  displayFormat="DD/MM/yyyy"
													  isOutsideRange={(day) => this.doNothing(day)}
													  placeholder= "Fecha"
													/>
													
													<p><b>PROVEEDOR NUEVO MANTENIMIENTO:</b></p>
													<form onSubmit={() => this.buscarProveedor()}>
														<input
															type="text" 
															value={this.state.busquedaProv}
															onChange={(e) => this.setState({busquedaProv: e.target.value})}
															placeholder="Busca proveedor (opcional)"
															style={{width:'250px',margin:10,marginTop:0,marginBottom:10}}
															maxlength="32"
														/>
													</form>
													{this.state.proveedores.length>0&&
														<table className="tablaEntrada">
															<thead>
																<tr style={{backgroundColor:"#339999",color:"#fff"}}>
																	<td>
																		COD.PRO.
																	</td>
																	<td>
																		NOMBRE PROVEEDOR
																	</td>
																	<td>
																		RAZÓN SOCIAL PROVEEDOR
																	</td>
																</tr>
															</thead>
															<tbody style={{backgroundColor:"#fff"}}>
																{this.state.proveedores.map(({RAZON,NOMPRO,CODPRO},index) => (
																	<tr key={index} onClick={() => {this.state.moldes[IND].proveedorNuevoMantenimiento=this.state.proveedores[index];this.setState({proveedores:[],busquedaProv:''})}}>
																		<td>
																			{CODPRO}
																		</td>
																		<td>
																			{NOMPRO}
																		</td>
																		<td>
																			{RAZON}
																		</td>
																	</tr>
																))}
															</tbody>
														</table>
													}
													{proveedorNuevoMantenimiento!=null&&
														<table className="tablaEntrada">
															<thead>
																<tr style={{backgroundColor:"#339999",color:"#fff"}}>
																	<td>
																		COD.PRO.
																	</td>
																	<td>
																		NOMBRE PROVEEDOR
																	</td>
																	<td>
																		RAZÓN SOCIAL PROVEEDOR
																	</td>
																</tr>
															</thead>
															<tbody style={{backgroundColor:"#fff"}}>
																<tr key={index}>
																	<td>
																		{proveedorNuevoMantenimiento.CODPRO}
																	</td>
																	<td>
																		{proveedorNuevoMantenimiento.NOMPRO}
																	</td>
																	<td>
																		{proveedorNuevoMantenimiento.RAZON}
																	</td>
																</tr>
															</tbody>
														</table>
													}
													<p><b>RAZONES DEL AJUSTE DEL MOLDE</b></p>
													<textarea 
														type="text" 
														value={comentariosNuevoMantenimiento==null ? '' : comentariosNuevoMantenimiento}
														onChange={(e) => {this.state.moldes[index].comentariosNuevoMantenimiento=e.target.value;this.setState({state:this.state})}}
														placeholder="¿Qué le pasa al molde? ¿Por qué se lleva a ajustar?"
														
													/>
													<p><b>ACCIONES LLEVADAS A CABO EN EL AJUSTE</b></p>
													<textarea 
														type="text" 
														value={comentariosDespuesNuevoMantenimiento==null ? '' : comentariosDespuesNuevoMantenimiento}
														onChange={(e) => {this.state.moldes[index].comentariosDespuesNuevoMantenimiento=e.target.value;this.setState({state:this.state})}}
														placeholder="¿Qué le ha hecho el proveedor al molde? Comentarios sobre el estado, etc. Dejar en blanco para no cerrar el mantenimiento."
														
													/>
													
													{IMAGENES_NUEVO_MANTENIMIENTO!=null&&IMAGENES_NUEVO_MANTENIMIENTO.map(({IMG},index) => (
														<img src={IMG} style={{maxHeight:'250px',maxWidth:'200px',padding:5}} />
													))}
													
													<label for="image_uploads_mantenimiento" style={{cursor: 'pointer'}}><button type="button" style={{pointerEvents: 'none',marginTop:5}}>AÑADIR IMAGEN</button></label>
													<input type="file" id="image_uploads_mantenimiento" name="file" onChange={(event) => this.seleccionarImagenMantenimiento(index,event)} style={{display:'none'}} />
												</div>
											}
										</div>
									</td>
								</tr>
							}
							{SHOW&&OPEN&&!LOADING&&LINEAS.map(({ID,CODART,DESCART,CANTIDAD},index) => (
									<tr>
										<td>{CODART}</td>
										<td>{DESCART}</td>
										<td>{CANTIDAD}</td>
									</tr>
							))}
						</table>
						</a>}
					</a>
				))}
				</a>
			}
      </div>
    );
  }
}
 
export default Salidas;